import Vue from 'vue'

// monsters / framework
Vue.component('ag-grid-vue', require('ag-grid-vue').AgGridVue)
Vue.component('ag-charts-vue', require('@/module/components/ag-grid/ag-charts-vue').default)
Vue.component('lolth-explorer', require('@/module/components/lolth-explorer').default)
Vue.component('lolth-editable-table', require('@/module/components/lolth-editable-table').default)
Vue.component('lolth-pagination-grid', require('@/module/components/lolth-pagination-grid.vue').default)
Vue.component('lolth-list-view', require('@/module/components/lolth-list-view').default)

// RowRequest filter
Vue.component('lolth-filter', require('@/module/components/lolth-filter').default)
Vue.component('flag-filter', require('@/module/components/lolth-filter/flag-filter').default)
Vue.component('enum-flag-filter', require('@/module/components/lolth-filter/enum-flag-filter').default)
Vue.component('null-filter', require('@/module/components/lolth-filter/null-filter').default)

// input
Vue.component('lolth-input-select', require('@/module/components/lolth-input-select').default)
Vue.component('lolth-valued-enum-select', require('@/module/components/lolth-valued-enum-select').default)
Vue.component('lolth-input-file', require('@/module/components/lolth-input-file').default)
Vue.component('lolth-image-viewer', require('@/module/components/lolth-image-viewer').default)
Vue.component('lolth-input-tags', require('@/module/components/lolth-input-tags').default)
Vue.component('lolth-input-percentage', require('@/module/components/lolth-input-percentage').default)
Vue.component('lolth-input-list', require('@/module/components/lolth-input-list').default)
Vue.component('lolth-input-extra', require('@/module/components/lolth-input-extra').default)
Vue.component('lolth-input-error-info', require('@/module/components/lolth-input-error-info').default)
Vue.component('lolth-upload-dialog', require('@/module/components/lolth-upload-dialog.vue').default)

// typed input
Vue.component('lolth-typed-chooser', require('@/module/components/typed/lolth-typed-chooser').default)
Vue.component('lolth-typed-input-chooser', require('@/module/components/typed/lolth-typed-input-chooser').default)
Vue.component('lolth-typed-input-select', require('@/module/components/typed/lolth-typed-input-select').default)

// hot table cell renderer/editor
Vue.component('hot-valued-enum-cell', require('@/module/components/hot-table/hot-valued-enum-cell').default)

// misc.
Vue.component('lolth-icmp-dialog', require('@/module/components/lolth-icmp-dialog').default)
Vue.component('lolth-errors', require('@/module/components/lolth-errors').default)
Vue.component('lolth-boolean', require('@/module/components/lolth-boolean').default)
Vue.component('lolth-file-item', require('@/module/components/lolth-file-item').default)
Vue.component('lolth-dialog', require('@/module/components/lolth-dialog').default)
Vue.component('lolth-more-text-viewer', require('@/module/components/lolth-more-text-viewer').default)
Vue.component('lolth-fabric', require('@/module/components/lolth-fabric.vue').default)
Vue.component('lolth-valued-enum-label', require('@/module/components/lolth-valued-enum-label.vue').default)

// shared
Vue.component('cmp-diag-state-filter', require('@/module/components/validation/cmp-diag-state-filter').default)
