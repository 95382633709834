
































































import { Component, Model, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { promptServerError } from '@/module/common/util/error-util'
import _ from 'lodash'
import { FILE_KEY_SEPARATOR, FileType, UploadedFile } from '@/module/common/file-service/types'
import { ElUpload } from 'element-ui/types/upload'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import LolthFile from './lolth-file.vue'
import FileService from '@/module/common/file-service'
import json5 from 'json5'
import util from '@/d2admin/libs/util'

@Component({
  components: {
    LolthFile
  }
})
export default class LolthInputFile extends Vue {
  @Ref() readonly elUpload: ElUpload & any
  @Ref() readonly elPasteInput: HTMLInputElement

  @Prop() readonly disabled?: boolean
  @Prop() readonly accept?: string
  @Prop() readonly limit?: number
  @Prop({ default: 'small' }) readonly size: 'mini' | 'small' | 'medium' | 'large'
  @Prop() readonly vendor?: string
  @Prop({ default: true }) readonly pasteModalAppendToBody: boolean
  @Prop() readonly fileSizeLimit?: number
  @Prop() readonly allowReplacing?: boolean

  @Model('change', { required: true })
  private fileKeys!: string[] | string

  private mFiles: any[] = []
  private mFileKeys: string[] | string = null
  private mPastDialogVisible: boolean = false

  private mViewerIndex: number = 0
  private mViewerVisible = false

  private mDisabledJumper = false
  private mReplacingFile: UploadedFile = null

  get files(): UploadedFile[] {
    return this.syncFileKeys.map(value =>
      FileService.getFileService(this.vendor, value).valueToUploadedFile(value))
  }

  get viewerUrls(): string[] {
    return this.files
      .filter(file => this.getFileType(file) === FileType.IMAGE || this.getFileType(file) === FileType.PDF)
      .map(file => file.previewUrl)
  }

  get uploadUrl() {
    return this.fileService.getUploadUrl()
  }

  get fileService() {
    return FileService.getFileService(this.vendor)
  }

  get syncFileKeys() {
    return !this.fileKeys ? []
      : _.isArray(this.fileKeys) ? this.fileKeys : [this.fileKeys]
  }

  get uploadDisabled() {
    return this.disabled ||
      ((this.limit === 1 && this.syncFileKeys.length === 1) && !this.mDisabledJumper)
  }

  created() {
    if (!this.fileKeys) return
    this.initFiles()
  }

  initFiles() {
    this.mFileKeys = this.fileKeys
    util.objects.clear(this.mFiles)
    this.mFiles.push(...this.syncFileKeys.map(value =>
      FileService.getFileService(this.vendor, value).valueToUploadedFile(value)))
  }

  @Watch('fileKeys')
  onFileKeyChanged() {
    if (this.mFileKeys !== this.fileKeys) {
      this.initFiles()
    }
  }

  private getSizeClass() {
    let safeSize = this.size
    if (safeSize !== 'mini' && safeSize !== 'small' && safeSize !== 'medium' && safeSize !== 'large') {
      safeSize = 'small'
    }
    return 'size-' + safeSize
  }

  private onRemove(file: UploadedFile) {
    this.elUpload.handleRemove(file)
    this.removeFile(file)
  }

  private onReplace(file: UploadedFile) {
    this.mReplacingFile = file
    this.mDisabledJumper = true
    this.$nextTick(() => {
      this.elUpload.$refs['upload-inner'].handleClick()
      this.$nextTick(() => {
        this.mDisabledJumper = false
      })
    })
  }

  private onSuccess(key: string, file: UploadedFile) {
    key = this.fileService.resolveFileKey(key)
    file.key = key
    file.url = this.fileService.getThumbnailUrl(key + FILE_KEY_SEPARATOR + file.name)
    file.previewUrl = this.fileService.getPreviewUrl(key + FILE_KEY_SEPARATOR + file.name)
    file.downloadUrl = this.fileService.getDownloadUrl(key + FILE_KEY_SEPARATOR + file.name)

    if (this.mReplacingFile?.key === key) {
      util.objects.replace(this.mFiles, this.mReplacingFile, file)
      this.mReplacingFile = null
      // FIXME: 更新文件后拼上一个uuid以重新加载图片, 但是重新加载控件时还是会显示旧图片
      file.url += '&' + uuidv4()
      file.previewUrl += '&' + uuidv4()
      file.downloadUrl += '&' + uuidv4()
    } else {
      this.addOrUpdateFile(file)
    }
  }

  private onError(err: Error, file: File) {
    promptServerError(err)
    util.objects.remove(this.mFiles, file)
    if (this.mReplacingFile) this.mReplacingFile = null
    // this.elUpload.handleRemove(file)
  }

  onFileNoteUpdated(file: UploadedFile) {
    this.addOrUpdateFile(file)
  }

  private handleCommand(cmd: string) {
    switch (cmd) {
      case 'choose':
        this.elUpload.$refs['upload-inner'].handleClick(); break
      case 'paste':
        this.mPastDialogVisible = true; break
    }
  }

  private onPasteDialogOpened() {
    this.$nextTick(() => {
      this.elPasteInput.focus()
      this.elPasteInput.addEventListener('paste', this.handlePaste)
    })
  }

  private onPasteDialogClosed() {
    this.elPasteInput.removeEventListener('paste', this.handlePaste)
  }

  private handlePaste(event: any) {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items
    if (items.length > 0 && items[0].kind === 'string') {
      // 上传复制文件引用
      items[0].getAsString((content: string) => {
        let file = json5.parse(content)
        if (_.isString(file) && file.indexOf(FILE_KEY_SEPARATOR) > 0) {
          file = this.fileService.valueToUploadedFile(file)
        }
        if (file.key && file.name) {
          const fileKey = file.key + FILE_KEY_SEPARATOR + file.name
          if (this.syncFileKeys.indexOf(fileKey) >= 0) {
            this.$message({ message: '文件已存在, 请勿重复添加', type: 'warning', showClose: true })
            return
          }
          this.mFiles.push(file)
          this.addOrUpdateFile(file)
          this.mPastDialogVisible = false
        } else {
          this.$message({ message: '无效的文件', type: 'warning', showClose: true })
        }
      })
    } else if (items?.length) {
      // 上传截图/文件
      const files = []
      for (let item of items) {
        if (item.kind !== 'file') continue
        const file = item.getAsFile()
        if (!file.name) {
          // noinspection JSConstantReassignment
          file.name = uuidv4().substr(0, 8) +
              '-' + moment(Date.now()).format('yyMMddHHmmss') + '.png'
        }
        files.push(file)
      }
      this.elUpload.$refs['upload-inner'].uploadFiles(files)
      this.mPastDialogVisible = false
    }
  }

  addOrUpdateFile(file: UploadedFile) {
    const fileKey = this.fileService.uploadedFileToValue(file)
    if (this.limit === 1) {
      this.mFileKeys = fileKey
      this.$emit('change', fileKey)
    } else {
      util.objects.replaceOrPush(this.fileKeys as string[], key => {
        return this.fileService.resolveFileKey(key) === file.key
      }, fileKey)
      this.emitChangeDebounce()
    }
    this.$emit('uploaded', file)
  }

  emitChangeDebounce = _.debounce(() => {
    this.$emit('change', this.fileKeys)
  }, 500)

  removeFile(file: UploadedFile) {
    if (this.limit === 1) {
      this.mFileKeys = null
      this.$emit('change', null)
    } else {
      util.objects.remove((this.fileKeys as string[]), key => {
        return this.fileService.resolveFileKey(key) === file.key
      })
      this.$emit('change', this.fileKeys)
    }
  }

  onPreview(file: UploadedFile) {
    this.mViewerIndex = this.viewerUrls.indexOf(file.previewUrl!)
    this.mViewerVisible = true
  }

  getFileType(file: File) {
    return this.fileService.getFileType(file.name)
  }

  validateFileSize(file: File, uploadFiles: File[]) {
    if (!this.fileSizeLimit) return
    if (file.size > this.fileSizeLimit) {
      this.$message({ message: '文件大小超出限制' + this.fileSizeLimit.formatSize(), type: 'warning', showClose: true })
    }
  }

  doUpload(file: File) {
    if (this.mReplacingFile) {
      _.set(file, 'key', this.fileService.uploadedFileToValue(this.mReplacingFile))
    }
    return this.fileService.upload(file)
  }
}
