import _ from 'lodash'
import moment from 'moment'
import util from '@/d2admin/libs/util'
import { v4 as uuidv4 } from 'uuid'
import { hashCode } from 'hashcode'
import json5 from 'json5'
import { MD_TABLE_VIEW_PREFIX } from '@/module/common/constants'

export default {
  install(Vue, options) {
    Vue.prototype.$_ = _
    window._ = _

    Vue.prototype.$moment = moment
    Vue.prototype.$util = util
    Vue.prototype.$hash = hashCode().value
    Vue.prototype.$json5 = json5
    Vue.prototype.$MD_TABLE_VIEW_PREFIX = MD_TABLE_VIEW_PREFIX

    Vue.mixin({
      created: function () {
        this.$uuid = uuidv4()
      }
    })

    Vue.prototype.$formatDate = (date) => {
      return (date && moment(date).format('YYYY-MM-DD')) || ''
    }

    Vue.prototype.$formatDateTime = (date) => {
      return (date && moment(date).format('YYYY-MM-DD HH:mm:ss')) || ''
    }

    Vue.directive('number', {
      bind(el, binding, vnode) {
        el.addEventListener('input', function () {
          let value = this.querySelector('input').value
          if (value === '') {
            _.set(vnode.context, vnode.data.model.expression, null)
          }
        })
      }
    })
  }
}

export class HandledError extends Error {}
