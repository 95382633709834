



















































































































import { Component, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import { SysExportTemplate } from '@/module/graphql'
import { buildGraphQLInput, submitMutation } from '@/module/common/util/graphql-util'
import gql from 'graphql-tag'
import { verifyQlExpress } from '@/module/butler/butler-util'
import { ElForm } from 'element-ui/types/form'

@Component
export default class CmpExplorerTemplateFormDialog extends Vue {
  @Ref() readonly vForm: ElForm & any

  @Prop({ required: true }) readonly entity: SysExportTemplate

  @PropSync('visible')
  syncedVisible!: boolean

  private mSubmitted = false

  get isNew() {
    return !this.entity?.id
  }

  get title() {
    return (this.isNew ? '创建' : '修改') + '导出打印'
  }

  @Watch('entity')
  initEntity(entity: SysExportTemplate) {
    CmpExplorerTemplateFormDialog.initEntity(entity)
  }

  public static initEntity(entity = {} as SysExportTemplate) {
    if (_.isUndefined(entity.name)) entity.name = ''
    if (_.isNil(entity.printOrientation)) entity.printOrientation = 1
    return entity
  }

  verifyScript(script: String) {
    verifyQlExpress(this, script)
  }

  private async saveExportTemplate() {
    const postData = buildGraphQLInput(this.entity)
    submitMutation(this, {
      mutationOptions: {
        mutation: gql`mutation saveSysExportTemplate($id: ID, $ver: Int, $modelName: String!, $name: String!, $templateFileKey: String!,
                                                    $forPrint: Boolean, $forXlsExport: Boolean, $forPdfExport: Boolean, $rowsPerPage: Int,
                                                    $groupByScript: String, $printPaperWidth: Float, $printPaperHeight: Float, $printOrientation: Int,
                                                    $maxRow:Int, $fileNameScript: String) {
            saveSysExportTemplate(id: $id, ver: $ver, modelName: $modelName, name: $name, templateFileKey: $templateFileKey,
                                  forPrint: $forPrint, forXlsExport: $forXlsExport, forPdfExport: $forPdfExport,
                                  rowsPerPage: $rowsPerPage, groupByScript: $groupByScript, printPaperWidth: $printPaperWidth,
                                  printPaperHeight: $printPaperHeight, printOrientation: $printOrientation,
                                  maxRow:$maxRow, fileNameScript: $fileNameScript) {
              id ver
            }
        }`,
        variables: postData
      }
    }).then(data => {
      this.$message({
        message: '保存成功', type: 'success', showClose: true
      })
      this.syncedVisible = false
      this.mSubmitted = true
      this.$emit('submit')
    })
  }

  private cancelEditing() {
    if (this.isNew && !this.mSubmitted) this.$emit('cancel-new')
  }
}
