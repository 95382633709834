












import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { FilterCondition, FilterType, MdoEndProduct, SortColumn, SortType } from '@/module/graphql'
import LocalDbDao from '@/module/common/local-db-dao'
import { RowDoubleClickedEvent } from 'ag-grid-community'
import LolthExplorer from '@/module/components/lolth-explorer/index.vue'
import { GridOptions } from 'ag-grid-community/dist/lib/entities/gridOptions'
import { setShowInTableColumns, showMdoEndProductDialog } from '@/module/plugin-md-product-order/mdo-util'

@Component
export default class CmpMdoEndProduct extends Vue {
  @Ref() readonly vExplorer: LolthExplorer
  @Prop() readonly myOnly: boolean
  @Prop() readonly productOrderCode: string

  private mFixedFilterCondition: FilterCondition = null
  private mDialogVisible: boolean = false
  private mSelectedEndProducts: MdoEndProduct[] = []

  private mDefaultSort: SortColumn[] = [
    { field: 'productOrderCode', sort: SortType.Desc },
    { field: 'sortKey', sort: SortType.Asc }
  ]

  created() {
    LocalDbDao.prefetchPermissions(['MdoProductOrderNew', 'MdoProductOrderManage'])
    if (this.myOnly) {
      this.mFixedFilterCondition = {
        filterType: FilterType.Array,
        field: 'principals',
        operator: 'in',
        value: LocalDbDao.getPrincipal().identity
      }
    }
    if (this.productOrderCode) {
      if (this.mFixedFilterCondition) {
        this.mFixedFilterCondition = {
          filterType: FilterType.And,
          conditions: [
            this.mFixedFilterCondition,
            {
              filterType: FilterType.String,
              field: 'productOrderCode',
              operator: 'equals',
              value: this.productOrderCode
            }
          ]
        }
      } else {
        this.mFixedFilterCondition = {
          filterType: FilterType.String,
          field: 'productOrderCode',
          operator: 'equals',
          value: this.productOrderCode
        }
      }
    }
  }

  refresh() {
    this.vExplorer.refresh()
  }

  onRowDoubleClicked(event: RowDoubleClickedEvent) {
    showMdoEndProductDialog(this, event.data)
  }

  private extendGridOptions(explorer: LolthExplorer, gridOptions: GridOptions) {
    setShowInTableColumns(gridOptions)
  }
}
