






















import { Component, Mixins, Prop, PropSync, Ref } from 'vue-property-decorator'
import { ElDialog } from 'element-ui/types/dialog'
import MixinMdoBoxingSheetEntry
  from '@/module/plugin-md-product-order/views/components/boxing/mixin-mdo-boxing-sheet-entry'
import { FilterCondition } from '@/module/graphql'

@Component
export default class CmpMdoBoxingSheetEntryDialog extends Mixins(MixinMdoBoxingSheetEntry) {
  @Ref() readonly vDialog: ElDialog & any
  @Prop() fixedFilterCondition: FilterCondition

  @PropSync('visible')
  syncedVisible!: boolean
}
