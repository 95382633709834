







































import { Component, Ref, Vue } from 'vue-property-decorator'
import { CustomExplorerType } from '@/module/components/lolth-explorer/explorer-type'
import { RestDataSource } from '@/module/components/lolth-explorer/rest-data-source'
import { ExplorerDataSourceArgs } from '@/module/components/lolth-explorer/explorer-data-source'
import { ColDef, GetContextMenuItemsParams, GridOptions, MenuItemDef } from 'ag-grid-community'
import axios from '@/d2admin/plugin/axios/index'
import moment from 'moment'
import LolthExplorer from '@/module/components/lolth-explorer/index.vue'
import _ from 'lodash'
import {
  buildDatePickerOption,
  checkMenuAccessibility,
  findAgGridColumnDef,
  patchMultiSelectOnContextMenu
} from '@/module/common/util/view-util'
import { Maybe } from 'graphql/jsutils/Maybe'

@Component
export default class PageRecycleBin extends Vue {
  @Ref() private vExplorer: LolthExplorer

  private mSelectedData: any[] = []

  private mScheduleDeletionVisible: boolean = false
  private mScheduleDeletedDate: Date = moment().add(7, 'days').toDate()
  private mPickerOptions = buildDatePickerOption()

  private mReferenceInfoDialogVisible: boolean = false
  private mReferenceInfoList: Maybe<any> = []

  readonly deletedDataType = new CustomExplorerType({
    name: 'DeletedData',
    description: '已删除记录',
    fields: [
      { name: 'realmCode', description: '业务模块', type: { name: 'String' }, directives: [] },
      { name: 'modelName', description: '数据类型', type: { name: 'String' }, directives: [] },
      { name: 'dataKey', description: '删除数据', type: { name: 'String' }, directives: [] },
      { name: 'dataSummary', description: '数据摘要', type: { name: 'String' }, directives: [] },
      { name: 'createdAt', description: '删除时间', type: { name: 'DateTime' }, directives: [] },
      { name: 'createdBy', description: '操作人员', type: { name: 'String' }, directives: [] },
      {
        name: 'referenceInfoList',
        description: '关联信息',
        type: { name: 'Json' },
        directives: [{
          name: 'explorerCustomCellRenderer',
          args: [{
            name: 'renderer',
            value: 'listItemCountRenderer'
          }]
        }]
      },
      { name: 'scheduledDeletingDate', description: '计划清除时间', type: { name: 'DateTime' }, directives: [] }
    ],
    directives: [{
      name: 'bizType',
      args: [{
        name: 'defaultSortBy',
        value: 'createdAt'
      }, {
        name: 'defaultSortOrder',
        value: 'DESC'
      }, {
        name: 'dataSourceProvider',
        value: (args: ExplorerDataSourceArgs) => {
          args.urlPrefix = '/butler'
          return new RestDataSource(args)
        }
      }]
    }]
  })

  private extendGridOptions(explorer: LolthExplorer, gridOptions: GridOptions) {
    const colDef = findAgGridColumnDef(gridOptions, 'referenceInfoList')
    colDef.cellRendererParams = {
      onClick: this.onReferenceInfoListCountClicked
    }

    gridOptions.columnDefs?.filter((colDef: ColDef) => {
      return colDef.field === 'createdAt' || colDef.field === 'createdBy'
    }).forEach((colDef: ColDef) => {
      colDef.hide = false
    })
  }

  private getContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
    const vue = this
    return [{
      name: '设置清除计划',
      action() {
        patchMultiSelectOnContextMenu(params.node,
          vue.vExplorer, vue.mSelectedData)

        vue.mScheduleDeletionVisible = true
      },
      disabled: checkMenuAccessibility(params.node, vue, {
        requireSelection: true
      })
    }, {
      name: '取消清除计划',
      action() {
        patchMultiSelectOnContextMenu(params.node,
          vue.vExplorer, vue.mSelectedData)
        vue.$confirm('确认取消清除计划?').then(() => {
          axios.post('/butler/recycle-bin/set-deletion-schedule/' + vue.mSelectedData[0].id).then(() => {
            vue.$message({
              message: '设置成功',
              type: 'success',
              showClose: true
            })
            vue.vExplorer.refresh()
          })
        }).catch(() => {})
      },
      disabled: checkMenuAccessibility(params.node, vue, {
        requireSelection: true,
        customCheckFunc: data => _.isNil(params.node.data.scheduledDeletingDate)
      })
    }, {
      name: '更新关联信息',
      action() {
        patchMultiSelectOnContextMenu(params.node,
          vue.vExplorer, vue.mSelectedData)
        axios.post('/butler/recycle-bin/check-reference/' + vue.mSelectedData[0].id).then((referenceInfoList: any) => {
          vue.mReferenceInfoList = referenceInfoList
          vue.mReferenceInfoDialogVisible = true
          vue.vExplorer.refresh()
        })
      },
      disabled: checkMenuAccessibility(params.node, vue, {
        requireSelection: true
      })
    }, {
      name: '立即清除',
      action() {
        axios.post('/butler/recycle-bin/permanent-delete/' + vue.mSelectedData[0].id).then((referenceInfoList: any) => {
          if (referenceInfoList && referenceInfoList.length > 0) {
            vue.mReferenceInfoList = referenceInfoList
            vue.mReferenceInfoDialogVisible = true
          } else {
            vue.$message({
              message: '该数据已完全清除',
              type: 'success',
              showClose: true
            })
            vue.vExplorer.refresh()
          }
        })
      },
      disabled: checkMenuAccessibility(params.node, vue, {
        requireSelection: true
      })
    }, {
      name: '恢复数据',
      action() {
        vue.$confirm('确认恢复删除数据?').then(() => {
          axios.post('/butler/recycle-bin/restore/' + vue.mSelectedData[0].id).then(() => {
            vue.$message({
              message: '恢复成功',
              type: 'success',
              showClose: true
            })
            vue.vExplorer.refresh()
          })
        }).catch(() => {})
      },
      disabled: checkMenuAccessibility(params.node, vue, {
        requireSelection: true
      })
    }, {
      name: '执行全面清理',
      action() {
        vue.$confirm('即将执行全面清理, 是否继续?').then(() => {
          axios.post('/butler/recycle-bin/full-scan').then(() => {
            vue.$message({
              message: '执行完成',
              type: 'success',
              showClose: true
            })
            vue.vExplorer.refresh()
          })
        }).catch(() => {})
      }
    }]
  }

  private async onScheduleDeletionSet() {
    const formData = new FormData()
    formData.set('scheduledDeletingDate', this.$formatDateTime(this.mScheduleDeletedDate))
    await axios.post('/butler/recycle-bin/set-deletion-schedule/' + this.mSelectedData[0].id, formData)
    this.$message({
      message: '设置成功',
      type: 'success',
      showClose: true
    })
    this.vExplorer.refresh()
    this.mScheduleDeletionVisible = false
  }

  private onReferenceInfoListCountClicked(referenceInfoList: []) {
    this.mReferenceInfoList = referenceInfoList
    this.mReferenceInfoDialogVisible = true
  }
}
