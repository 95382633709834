






























































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import CmpPortal from './components/cmp-portal.vue'
import axios from '@/d2admin/plugin/axios/index'
import qs from 'qs'
import { sendCaptchaSms, setCountDownTimer } from '@/module/butler/butler-util'

@Component({
  name: 'page-sign-up',
  components: {
    CmpPortal
  }
})
export default class PageSignUp extends Vue {
  @Ref() readonly elAccountInfoForm!: any
  @Ref() readonly elJoinApplicationForm!: any
  // 审批不通过时, 传入accountId作为参数
  @Prop() readonly accountId?: number
  // 点击注册邀请链接时, 传入invitationCode作为参数
  @Prop() readonly invitationCode?: string
  // 企业微信扫码登录时, 传入ewxUserId作为参数
  @Prop() readonly ewxUserId?: string
  @Prop() readonly ewxUserMobile?: string

  private mStep: number = 0
  private nextDisabled = true
  private signUpInfo: any
  private nextText: string = '下一步'
  private mCountDownOption = {
    captchaCountDownText: '获取验证码',
    sendCaptchaBtnDisable: false
  }
  private mMobileError: string = ''
  private mDisplayNameDisabled = false
  private mEmployeeNoDisabled = false

  private mAccountInfo: any = {
    mobilePhone: '',
    smsCaptcha: '',
    password: '',
    passwordRepeat: '',
    username: '',
    ewxUserId: null,
    extra: {
      employeeNo: '',
      displayName: '',
      joinApplicationId: null
    }
  }

  private signUpFormRules = {
    mobilePhone: [
      { required: true, message: '请输入手机号', trigger: 'blur' },
      { message: '手机号格式不正确', trigger: 'blur', pattern: /^1[3456789]\d{9}$/ }
    ],
    username: [
      { required: true, message: '用户名只能包含字母、数字、下划线,长度为 3~20位', pattern: /^[a-z0-9A-Z_-]{3,20}$/, trigger: 'blur' },
      {
        validator: (rule: any, value: any, callback: any) => {
          axios.get('/auth/is-user-registered', {
            params: {
              username: this.mAccountInfo.username
            }
          }).then(resp => {
            if (resp) {
              callback(new Error('用户名已注册'))
            } else {
              callback()
            }
          })
        },
        trigger: 'blur'
      }
    ],
    smsCaptcha: [
      { required: true, message: '请输入验证码', trigger: 'blur' }
    ],
    password: [
      { min: 6, message: '至少6个字符', trigger: 'blur' },
      {
        validator: (rule: any, value: any, callback: any) => {
          if (value === '') {
            callback(new Error('请输入密码'))
          } else if (this.mAccountInfo.passwordRepeat !== '' && this.mAccountInfo.password !== this.mAccountInfo.passwordRepeat) {
            callback(new Error('两次输入密码不一致'))
          } else {
            callback()
          }
        },
        required: true,
        trigger: 'blur'
      }
    ],
    passwordRepeat: [
      { min: 6, message: '至少6个字符', trigger: 'blur' },
      {
        validator: (rule: any, value: any, callback: any) => {
          if (value === '') {
            callback(new Error('请再次输入密码'))
          } else if (this.mAccountInfo.password !== '' && this.mAccountInfo.password !== this.mAccountInfo.passwordRepeat) {
            callback(new Error('两次输入密码不一致'))
          } else {
            callback()
          }
        },
        trigger: 'blur'
      }
    ]
  }

  private joinApplicationFormRules = {
    displayName: [
      { required: true, message: '请输入员工姓名', trigger: 'blur' }
    ]
  }

  created() {
    if (this.accountId) {
      this.mStep = 1
      this.nextText = '提交注册'
    }
    if (this.invitationCode) {
      axios.get('/anon/joining-application/find-by-code?code=' + this.invitationCode).then((resp: any) => {
        this.mAccountInfo.extra.displayName = resp.displayName
        this.mAccountInfo.extra.employeeNo = resp.employeeNo
        this.mDisplayNameDisabled = !!this.mAccountInfo.extra.displayName
        this.mEmployeeNoDisabled = !!this.mAccountInfo.extra.employeeNo
        this.mAccountInfo.extra.joinApplicationId = resp.id
      })
    }
    if (this.ewxUserId) {
      this.mAccountInfo.ewxUserId = this.ewxUserId
    }
    if (this.ewxUserMobile) {
      this.mAccountInfo.mobilePhone = this.ewxUserMobile
    }
  }

  @Watch('mAccountInfo', { deep: true })
  onAccountInfoChange() {
    if ((this.mAccountInfo.mobilePhone && this.mAccountInfo.smsCaptcha &&
      this.mAccountInfo.password && this.mAccountInfo.passwordRepeat) ||
      (this.accountId && this.mAccountInfo.extra.displayName && this.mAccountInfo.extra.employeeNo)) {
      this.nextDisabled = false
    } else {
      this.nextDisabled = true
    }
  }

  previous() {
    this.mStep--
    this.nextText = '下一步'
  }

  next() {
    if (this.mStep === 0) {
      this.elAccountInfoForm.validate((valid: boolean) => {
        if (valid) {
          axios.get('/auth/validate-captcha', {
            params: {
              mobile: this.mAccountInfo.mobilePhone,
              captcha: this.mAccountInfo.smsCaptcha
            }
          }).then((resp: any) => {
            if (resp.msg) {
              this.$message({
                message: resp.msg, type: 'error', showClose: true
              })
            } else {
              this.nextText = '提交注册'
              this.mStep++
            }
          }).catch()
        } else {
          this.$message({
            message: '提交信息有误,请修正', type: 'error', showClose: true
          })
        }
      })
    } else if (this.mStep === 1) {
      this.elJoinApplicationForm.validate((valid: boolean) => {
        if (valid) {
          if (this.accountId) {
            axios.post('/butler/invitation/join',
              qs.stringify({ ...this.mAccountInfo, accountId: this.accountId })
            ).then((resp: any) => {
              this.mAccountInfo.username = resp.username
              this.mStep++
            })
          } else {
            axios.post('/auth/sign-up', this.mAccountInfo).then((resp: any) => {
              if (resp.msg) {
                this.$message({
                  message: resp.msg, type: 'error', showClose: true
                })
              } else {
                this.mStep++
              }
            })
          }
        } else {
          this.$message({
            message: '提交信息有误,请修正', type: 'error', showClose: true
          })
        }
      })
    }
  }

  getCaptcha() {
    this.elAccountInfoForm.validateField(['mobilePhone'], (error: string) => {
      if (!error) {
        axios.get('/auth/is-mobile-registered', {
          params: {
            mobile: this.mAccountInfo.mobilePhone
          }
        }).then(resp => {
          if (resp) {
            this.mMobileError = '手机号已注册'
          } else {
            this.mMobileError = ''
            sendCaptchaSms(this, this.mAccountInfo.mobilePhone, this.mCountDownOption)
          }
        }).catch()
      }
    })
  }

  onMobileChange() {
    this.mMobileError = ''
    setCountDownTimer(this.mCountDownOption, this.mAccountInfo.mobilePhone)
  }
}
