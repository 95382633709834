















































































































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/module/components/lolth-explorer/explorer-setting-panel.vue"
}
