import { delegate, Indexed } from '@/d2admin/delegate'
import { clearUserDb, dbGet, dbGetAsync, dbSet, dbSetAsync } from '@/d2admin/libs/util.db'
import { Setting, SyncData, UserPrincipal, ValuedEnum } from '@/module/common/types'
import _ from 'lodash'
import { ExplorerView, MdDataFixedFieldOption, MdDomain, MdDomainCache, User } from '@/module/graphql'
import { ExplorerType } from '@/module/components/lolth-explorer/explorer-type'
import { GroupedErrors } from '@/module/common/index'
import axios from '@/d2admin/plugin/axios'
import { FixedField } from '@/module/master-data/types'
import low from 'lowdb'
import LocalStorage from 'lowdb/adapters/LocalStorage'

import { buildGraphQLQueryPartInput } from '@/module/common/util/graphql-util'
import util from '@/d2admin/libs/util'
import { exitIfError, hasError } from '@/module/common/util/error-util'
import { Maybe } from 'graphql/jsutils/Maybe'
import LoginResult = delegate.LoginResult

export const DB_KEYS = Object.freeze({
  DB_KEY_LOGIN_TOKENS: 'loginTokens',
  DB_KEY_SYNC_DATA: 'syncData',
  DB_KEY_EXPLORER_TYPES: 'explorerTypes',
  DB_KEY_ACCESS_TOKEN: 'access-token',
  DB_KEY_REFRESH_TOKEN: 'refresh-token',
  DB_KEY_MD_DOMAIN: 'mdDomain',
  DB_KEY_PRINCIPAL: 'principal',
  DB_KEY_PERMISSIONS: 'permissions',
  DB_KEY_CUSTOM_DATA: 'customData',
  DB_KEY_EXPLORER_VIEWS: 'explorerViews',
  DB_KEY_USING_EXPLORER_VIEWS: 'usingExplorerViews'
})

const cache:{ [dbKey: string]: any } = {}
cache[DB_KEYS.DB_KEY_EXPLORER_TYPES] = {}
cache[DB_KEYS.DB_KEY_MD_DOMAIN] = {}

const adapter = new LocalStorage('lolth-session')
const session = low(adapter)
session.defaults({}).write()

export const DEFAULT_VIEW_HASH = '∆'

const LocalDbDao = {
  isUserLoggedIn(): boolean {
    return !!util.cookies.get('uuid')
  },

  loadLoginTokens(): Promise<Indexed> {
    return dbGetAsync({ path: DB_KEYS.DB_KEY_LOGIN_TOKENS }).then((loginTokens: Indexed) => {
      if (!loginTokens) loginTokens = {}
      return loginTokens
    })
  },

  saveLoggedInSession(loginResult: LoginResult) {
    session
      .set(DB_KEYS.DB_KEY_PRINCIPAL, loginResult.saveToPrivate[DB_KEYS.DB_KEY_PRINCIPAL])
      .set(DB_KEYS.DB_KEY_ACCESS_TOKEN, loginResult.saveToPrivate[DB_KEYS.DB_KEY_ACCESS_TOKEN])
      .set(DB_KEYS.DB_KEY_REFRESH_TOKEN, loginResult.saveToPrivate[DB_KEYS.DB_KEY_REFRESH_TOKEN])
      .write()
  },

  getPrincipal(): UserPrincipal {
    return dbGet({ path: DB_KEYS.DB_KEY_PRINCIPAL, user: true })
  },

  flushTokenCache() {
    session.unset(DB_KEYS.DB_KEY_ACCESS_TOKEN)
    session.unset(DB_KEYS.DB_KEY_REFRESH_TOKEN)
    session.write()
  },

  getAccessToken(): string {
    const cachedToken = session.read().get(DB_KEYS.DB_KEY_ACCESS_TOKEN).value()
    if (cachedToken) return cachedToken

    const token = dbGet({ path: DB_KEYS.DB_KEY_ACCESS_TOKEN, user: true })
    session.set(DB_KEYS.DB_KEY_ACCESS_TOKEN, token).write()
    return token
  },

  getAccessTokenAsync(): Promise<string> {
    const cachedToken = session.read().get(DB_KEYS.DB_KEY_ACCESS_TOKEN).value()
    if (cachedToken) return Promise.resolve(cachedToken)

    return dbGetAsync({ path: DB_KEYS.DB_KEY_ACCESS_TOKEN, user: true }).then(token => {
      session.set(DB_KEYS.DB_KEY_ACCESS_TOKEN, token).write()
      return token
    })
  },

  saveAccessToken(token: string) {
    if (!token) return Promise.resolve()
    session.set(DB_KEYS.DB_KEY_ACCESS_TOKEN, token).write()
    dbSet({ path: DB_KEYS.DB_KEY_ACCESS_TOKEN, value: token, user: true })
  },

  removeLoginToken(userName: string) {
    const loginTokens = dbGet({ path: DB_KEYS.DB_KEY_LOGIN_TOKENS })
    delete loginTokens[userName]
    dbSet({
      path: DB_KEYS.DB_KEY_LOGIN_TOKENS,
      value: loginTokens
    })
  },

  getRefreshToken(): string {
    const cachedToken = session.read().get(DB_KEYS.DB_KEY_REFRESH_TOKEN).value()
    if (cachedToken) return cachedToken

    const token = dbGet({ path: DB_KEYS.DB_KEY_REFRESH_TOKEN, user: true })
    session.set(DB_KEYS.DB_KEY_REFRESH_TOKEN, token).write()
    return token
  },

  saveRefreshToken(token: string) {
    if (!token) return Promise.resolve()
    session.set(DB_KEYS.DB_KEY_REFRESH_TOKEN, token).write()
    dbSet({ path: DB_KEYS.DB_KEY_REFRESH_TOKEN, value: token, user: true })
  },

  loadSyncData(preferCache: boolean = true): SyncData {
    if (preferCache && cache[DB_KEYS.DB_KEY_SYNC_DATA]) {
      return cache[DB_KEYS.DB_KEY_SYNC_DATA]
    }

    let syncData = dbGet({ path: DB_KEYS.DB_KEY_SYNC_DATA, user: true })
    if (!syncData) syncData = new SyncData()
    else Object.setPrototypeOf(syncData, SyncData.prototype)
    cache[DB_KEYS.DB_KEY_SYNC_DATA] = syncData
    return syncData
  },

  getSysSetting(realm: string, settingCode: string): Setting | null {
    const syncData = this.loadSyncData()
    return _.find(syncData.sysSettings, s => s.code === settingCode && s.realm === realm)
  },

  getSysSettingValue(realm: string, settingCode: string): any | null {
    const setting = this.getSysSetting(realm, settingCode)
    return setting?.value
  },

  saveSysSetting(setting: Setting) {
    return axios.post('/butler/sys-setting/save/' + setting.id,
      JSON.stringify(setting.value),
      { headers: { 'Content-Type': 'application/json' } }).then(resp => {
      return resp as any as Setting
    })
  },

  updateLocalSysSettings(settings: Setting[]) {
    const syncData = this.loadSyncData()
    settings.forEach(setting => {
      const localSetting = _.find(syncData.sysSettings,
        s => {
          if (setting.id) return s.id === setting.id
          else return s.realm === setting.realm && s.code === setting.code
        })
      if (localSetting) {
        localSetting.value = setting.value
      } else {
        syncData.sysSettings.push(setting)
      }
    })
    syncData.sysSettingEtag = '-1'
    return LocalDbDao.saveSyncData(syncData)
  },

  getUserSetting(realm: string, settingCode: string): Setting | null {
    const syncData = this.loadSyncData()
    return _.find(syncData.userSettings, s => s.code === settingCode && s.realm === realm)
  },

  saveUserSetting(setting: Setting) {
    return axios.post(`/butler/user-setting/save/${setting.realm}/${setting.code}`,
      JSON.stringify(setting.value),
      { headers: { 'Content-Type': 'application/json' } }).then(resp => {
      return resp as any as Setting
    })
  },

  updateLocalUserSetting(settings: Setting[]) {
    const syncData = this.loadSyncData()
    settings.forEach(setting => {
      const localSetting = _.find(syncData.userSettings,
        s => {
          if (setting.id) return s.id === setting.id
          else return s.realm === setting.realm && s.code === setting.code
        })
      if (localSetting) {
        localSetting.value = setting.value
      } else {
        syncData.userSettings.push(setting)
      }
    })
    syncData.userSettingEtag = '-1'
    return LocalDbDao.saveSyncData(syncData)
  },

  getGlobalVariable(code: string): any | null {
    const syncData = this.loadSyncData()
    const variable = _.find(syncData.globalVariables, s => s.code === code)
    return variable?.value
  },

  getValuedEnum(name: string): ValuedEnum[] {
    return LocalDbDao.loadSyncData().valuedEnums?.filter(e => e.groupCode === name)
      .sort((lhs, rhs) => lhs.seq - rhs.seq) || []
  },

  getValuedEnumItem(name: string, key: any): ValuedEnum | undefined {
    return _.find(LocalDbDao.getValuedEnum(name), item => item.key === _.toString(key))
  },

  getValuedEnumItemByValue(name: string, value: any): ValuedEnum | undefined {
    return _.find(LocalDbDao.getValuedEnum(name), item => item.value === _.toString(value))
  },

  worstErrorLevel(errors: GroupedErrors): ValuedEnum | undefined {
    if (!errors) return

    if (_.isArray(errors)) {
      if (errors.map(error => error.elv).filter(level => level === 'fatal').length > 0) {
        return LocalDbDao.getValuedEnumItem('ErrorLevel', 'fatal')
      }

      if (errors.map(error => error.elv).filter(level => level === 'error').length > 0) {
        return LocalDbDao.getValuedEnumItem('ErrorLevel', 'error')
      }

      if (errors.map(error => error.elv).filter(level => level === 'warn').length > 0) {
        return LocalDbDao.getValuedEnumItem('ErrorLevel', 'warn')
      }
    } else {
      return LocalDbDao.worstErrorLevel(_.flatten(_.values(errors)))
    }

    return undefined
  },

  saveSyncData(syncData: SyncData): Promise<void> {
    return dbGetAsync({ path: DB_KEYS.DB_KEY_SYNC_DATA, user: true }).then((localSyncData: SyncData) => {
      if (!localSyncData) localSyncData = new SyncData()

      if (syncData.sysSettingEtag &&
        syncData.sysSettingEtag !== localSyncData.sysSettingEtag &&
        syncData.sysSettings) {
        localSyncData.sysSettings = syncData.sysSettings
      }

      if (syncData.userSettingEtag &&
        syncData.userSettingEtag !== localSyncData.userSettingEtag &&
        syncData.userSettings) {
        localSyncData.userSettings = syncData.userSettings
      }

      if (syncData.globalVariableEtag &&
        syncData.globalVariableEtag !== localSyncData.globalVariableEtag &&
        syncData.globalVariables) {
        localSyncData.globalVariables = syncData.globalVariables
      }

      if (syncData.menusEtag &&
        syncData.menusEtag !== localSyncData.menusEtag &&
        syncData.menus) {
        localSyncData.menus = syncData.menus
      }

      if (syncData.valuedEnumsEtag &&
        syncData.valuedEnumsEtag !== localSyncData.valuedEnumsEtag &&
        syncData.valuedEnums) {
        localSyncData.valuedEnums = syncData.valuedEnums
      }

      cache[DB_KEYS.DB_KEY_SYNC_DATA] = syncData
      dbSet({ path: DB_KEYS.DB_KEY_SYNC_DATA, value: localSyncData, user: true })
    })
  },

  getExplorerTypes(): { [typeKey: string]: ExplorerType } {
    if (_.size(cache[DB_KEYS.DB_KEY_EXPLORER_TYPES]) > 0) {
      return cache[DB_KEYS.DB_KEY_EXPLORER_TYPES]
    }

    let types: { [typeKey: string]: ExplorerType } = dbGet({ path: DB_KEYS.DB_KEY_EXPLORER_TYPES })
    if (!types) types = {}
    cache[DB_KEYS.DB_KEY_EXPLORER_TYPES] = types
    return types
  },

  getExplorerType(typeKey: string): ExplorerType {
    let types = LocalDbDao.getExplorerTypes()
    const explorerType = types[typeKey]
    if (!explorerType) {
      throw new Error(`无效的类型 ${typeKey}`)
    }

    Object.setPrototypeOf(explorerType, ExplorerType.prototype)
    return explorerType
  },

  saveExplorerTypes(types: { [typeKey: string]: ExplorerType }) {
    const savedTypes = this.getExplorerTypes()
    const updatedTypes = _.assign(savedTypes, types)
    cache[DB_KEYS.DB_KEY_EXPLORER_TYPES] = updatedTypes
    dbSetAsync({ path: DB_KEYS.DB_KEY_EXPLORER_TYPES, value: updatedTypes })
  },

  getMdDomain(): MdDomain {
    let mdDomains = cache[DB_KEYS.DB_KEY_MD_DOMAIN]
    if (_.size(cache[DB_KEYS.DB_KEY_MD_DOMAIN]) <= 0) {
      mdDomains = dbGet({ path: DB_KEYS.DB_KEY_MD_DOMAIN })
    }
    const mdDomain = mdDomains
    cache[DB_KEYS.DB_KEY_MD_DOMAIN] = mdDomain
    return mdDomain
  },

  saveMdDomain(cacheable: MdDomainCache) {
    cache[DB_KEYS.DB_KEY_MD_DOMAIN] = cacheable.mdDomain
    dbSetAsync({ path: DB_KEYS.DB_KEY_MD_DOMAIN, value: cache[DB_KEYS.DB_KEY_MD_DOMAIN] })
  },

  forEachMdDomain(callbackFn: (fieldKey: string, option: MdDataFixedFieldOption) => void) {
    const mdDomain = LocalDbDao.getMdDomain()
    const sortedOptions: {
      field: string,
      option: MdDataFixedFieldOption
    }[] = []
    for (let i = 0; i < 10; i++) {
      const field = 'field' + i
      const optionField = field + 'Option'
      if (optionField in mdDomain) {
        const option: MdDataFixedFieldOption = _.get(mdDomain, optionField)
        sortedOptions.push({ field, option })
      } else {
        sortedOptions.push({
          field,
          option: {
            enabled: false,
            width: 0,
            seq: 99
          }
        })
      }
    }
    sortedOptions.sort((lhs, rhs) => lhs.option.seq! - rhs.option.seq!)
    sortedOptions.forEach(option => {
      callbackFn(option.field, option.option)
    })
  },

  getMdDomainFixedFields(cascadeFieldName: string = '', includeDisabled = false) {
    const fixedFields: FixedField[] = []
    LocalDbDao.forEachMdDomain((fieldKey, option) => {
      if (!option.enabled) {
        if (includeDisabled) {
          fixedFields.push({
            field: cascadeFieldName ? `${cascadeFieldName}.${fieldKey}` : fieldKey,
            disable: true,
            name: fieldKey,
            width: 0,
            seq: 99
          })
        }
        return
      }
      fixedFields.push({
        field: cascadeFieldName ? `${cascadeFieldName}.${fieldKey}` : fieldKey,
        name: option.name || fieldKey,
        width: option.width || 150,
        seq: option.seq || 99
      })
    })
    return fixedFields
  },

  async prefetchPermissions(permCodes: string[]) {
    let permCache: { [permKey: string]: boolean } = await dbGetAsync({ path: DB_KEYS.DB_KEY_PERMISSIONS, user: true })
    if (!permCache) permCache = {}
    if (_.difference(permCodes, _.keys(permCache)).length <= 0) return
    return axios.post('/butler/check-permissions', permCodes).then((resp: any) => {
      // 重新取一遍权限, 防止被其实请求污染
      permCache = dbGet({ path: DB_KEYS.DB_KEY_PERMISSIONS, user: true })
      if (!permCache) permCache = {}
      _.forEach(permCodes, (code) => {
        permCache[code] = resp[code] || false
      })
      dbSet({
        path: DB_KEYS.DB_KEY_PERMISSIONS,
        value: permCache,
        user: true
      })
      return permCache
    })
  },

  checkPermission(permCode: string): boolean | Promise<boolean> {
    let permCache: { [permKey: string]: boolean } = dbGet({ path: DB_KEYS.DB_KEY_PERMISSIONS, user: true })
    if (!permCache) permCache = {}
    if (_.has(permCache, permCode)) return permCache[permCode]

    return axios.post('/butler/check-permissions', [permCode]).then((resp: any) => {
      permCache = dbGet({ path: DB_KEYS.DB_KEY_PERMISSIONS, user: true })
      if (!permCache) permCache = {}
      permCache[permCode] = resp[permCode] || false
      dbSet({
        path: DB_KEYS.DB_KEY_PERMISSIONS,
        value: permCache,
        user: true
      })
      return permCache[permCode] || false
    })
  },

  flushPermissionCache() {
    dbSet({
      path: DB_KEYS.DB_KEY_PERMISSIONS,
      value: {},
      user: true
    })
  },

  async clearUserData(userId: string) {
    clearUserDb({ userId })
  },

  saveCustomData(key: string, data: any, user = false) {
    dbSet({ path: DB_KEYS.DB_KEY_CUSTOM_DATA + '.' + key, value: data, user })
  },

  getCustomData(key: string, user = false): any {
    return dbGet({ path: DB_KEYS.DB_KEY_CUSTOM_DATA + '.' + key, user })
  },

  buildDefaultExplorerView(viewName: string) {
    return {
      id: '0',
      hash: DEFAULT_VIEW_HASH,
      name: '默认视图',
      modelName: viewName,
      options: {
        gridOptions: {}
      },
      owner: { id: null } as User,
      publicScope: false
    }
  },

  async getExplorerViews(modelName: string): Promise<ExplorerView[]> {
    const localCache = dbGet({
      path: DB_KEYS.DB_KEY_EXPLORER_VIEWS,
      defaultValue: {},
      user: true
    })
    if (localCache[modelName]) {
      return Promise.resolve(localCache[modelName])
    }
    const resp = await axios.post('/graphql', {
      query: `query getExplorerViews {
        getExplorerViews(modelName: "${modelName}") {
          id owner {id} modelName name hash publicScope options
        }
      }`
    })
    if (hasError(resp.data.errors)) return []
    localCache[modelName] = resp.data.data.getExplorerViews
    dbSet({
      path: DB_KEYS.DB_KEY_EXPLORER_VIEWS,
      value: localCache,
      user: true
    })
    return resp.data.data.getExplorerViews
  },

  async saveExplorerView(explorerView: ExplorerView): Promise<ExplorerView> {
    const localCache = dbGet({
      path: DB_KEYS.DB_KEY_EXPLORER_VIEWS,
      defaultValue: {},
      user: true
    })
    const savingExplorerView = _.cloneDeep(explorerView)
    delete savingExplorerView.id
    delete savingExplorerView.owner
    const resp = await axios.post('/graphql', {
      query: `mutation {
        saveExplorerView(${buildGraphQLQueryPartInput(savingExplorerView)}) {
          id owner {id} modelName name hash publicScope options
        }
      }`
    })
    exitIfError(resp.data.errors)
    const view = resp.data.data.saveExplorerView
    const explorerViews: ExplorerView[] =
      localCache[view.modelName] || []
    util.objects.replaceOrPush(explorerViews,
      v => v.id === view.id, view)
    localCache[view.modelName] = explorerViews
    dbSet({
      path: DB_KEYS.DB_KEY_EXPLORER_VIEWS,
      value: localCache,
      user: true
    })
    return view
  },

  async deleteExplorerView(explorerView: ExplorerView): Promise<void> {
    const localCache = dbGet({
      path: DB_KEYS.DB_KEY_EXPLORER_VIEWS,
      defaultValue: {},
      user: true
    })
    const explorerViews: ExplorerView[] =
      localCache[explorerView.modelName]
    const resp = await axios.post('/graphql', {
      query: `mutation {
        deleteExplorerView(modelName: "${explorerView.modelName}", hash: "${explorerView.hash}")
      }`
    })
    exitIfError(resp.data.errors)
    util.objects.remove(explorerViews, v => v.hash === explorerView.hash)
    localCache[explorerView.modelName] = explorerViews
    dbSet({
      path: DB_KEYS.DB_KEY_EXPLORER_VIEWS,
      value: localCache,
      user: true
    })
  },

  getUsingExplorerView(modelName: string, explorerViews: ExplorerView[],
                       hash: string = undefined): ExplorerView {
    const usingViewHash = hash || (dbGet({
      path: DB_KEYS.DB_KEY_USING_EXPLORER_VIEWS,
      user: true
    }) || {})[modelName]
    return _.find(explorerViews, view => view.hash === usingViewHash) ||
      _.find(explorerViews, view => view.hash === '∆')
  },

  saveUsingExplorerView(view: ExplorerView) {
    const usingViewHashes = (dbGet({
      path: DB_KEYS.DB_KEY_USING_EXPLORER_VIEWS,
      user: true
    }) || {})
    usingViewHashes[view.modelName] = view.hash

    dbSet({
      path: DB_KEYS.DB_KEY_USING_EXPLORER_VIEWS,
      value: usingViewHashes,
      user: true
    })
  },

  flushExplorerViewCache(modelName: Maybe<string>) {
    let localCache = dbGet({
      path: DB_KEYS.DB_KEY_EXPLORER_VIEWS,
      defaultValue: {},
      user: true
    })
    if (modelName) {
      delete localCache[modelName]
    } else {
      localCache = {}
    }
    dbSet({
      path: DB_KEYS.DB_KEY_EXPLORER_VIEWS,
      value: localCache,
      user: true
    })
  }
}

export default LocalDbDao
