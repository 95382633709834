




import { Component, Model, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import { Indexed } from '@/d2admin/delegate'

@Component
export default class LolthValuedEnumLabel extends Vue {
  @Prop({ required: true }) readonly enumGroup!: string
  @Prop() readonly customClass: string | { [propName : string]: string }

  @Model('change')
  readonly value: string

  get cBgClass() {
    if (!this.customClass) return ''
    if (_.isString(this.customClass)) return this.customClass
    if (_.isObject(this.customClass)) {
      return _.toString(this.customClass[this.value]) || ''
    }
  }
}
