



































































import { Component, Ref, Vue } from 'vue-property-decorator'
import LolthExplorer from '@/module/components/lolth-explorer/index.vue'
import { GetContextMenuItemsParams, GridOptions, MenuItemDef, RowDoubleClickedEvent } from 'ag-grid-community'
import UserInfoDialog from '@/module/butler/views/components/cmp-user-info-dialog.vue'
import UserPasswordDialog from '@/module/butler/views/components/cmp-user-password-dialog.vue'
import LocalDbDao from '@/module/common/local-db-dao'
import { submitMutation } from '@/module/common/util/graphql-util'
import gql from 'graphql-tag'
import { User } from '@/module/graphql'
import _ from 'lodash'
import { checkMenuAccessibility, findAgGridColumnDef } from '@/module/common/util/view-util'
import axios from '@/d2admin/plugin/axios'
import CmpJoiningApplicationDialog from '@/module/butler/views/components/cmp-joining-application-dialog.vue'
import copy from 'copy-to-clipboard'
import CmpCreateUserDialog from '@/module/butler/views/components/cmp-create-user-dialog.vue'

@Component({
  name: 'PageUser',
  components: {
    CmpCreateUserDialog,
    UserInfoDialog,
    UserPasswordDialog,
    CmpJoiningApplicationDialog
  }
})
export default class PageUser extends Vue {
  @Ref() readonly vExplorer: LolthExplorer
  @Ref() readonly elUserInfoForm!: any
  @Ref() readonly elUserForm!: any
  private accountId: number = 0
  private dialogVisible: boolean = false
  private editUserDialogVisible: boolean = false
  private setAdminLevelDialogVisible: boolean = false
  private passwordDialogVisible: boolean = false
  private adminLevel: any = null
  private user: User | null = null
  private excludeAdminLevelKeys:any[] = []
  private mCreateUserDialogVisible: boolean = false
  private mInviteDialogVisible: boolean = false

  private userInfo: any = {
    mobilePhone: '',
    displayName: '',
    employeeNo: '',
    accountId: ''
  }

  private mUser: any = {
    displayName: '',
    employeeNo: ''
  }

  private mUserRules = {
    displayName: [
      { required: true, message: '请输入员工姓名', trigger: 'blur' }
    ]
  }

  private rules = {
    mobilePhone: [
      { required: true, message: '请输入手机号', trigger: 'blur' },
      { message: '手机号格式不正确', trigger: 'blur', pattern: /^1[3456789]\d{9}$/ },
      {
        validator: (rule: any, value: any, callback: any) => {
          axios.get('/auth/is-mobile-registered', {
            params: {
              mobile: this.userInfo.mobilePhone,
              accountId: this.userInfo.accountId
            }
          }).then(resp => {
            if (resp) {
              callback(new Error('手机号已注册'))
            } else {
              callback()
            }
          }).catch()
        },
        trigger: 'blur'
      }
    ],
    displayName: [
      { required: true, message: '请输入员工姓名', trigger: 'blur' }
    ]
  }

  private onRowDoubleClicked(event: RowDoubleClickedEvent) {
    this.dialogVisible = true
    this.accountId = event.data.account.id
  }

  private extendGridOptions(explorer: LolthExplorer, gridOptions: GridOptions) {
    findAgGridColumnDef(gridOptions, 'account.username').headerName = '用户名'
    findAgGridColumnDef(gridOptions, 'account.mobilePhone').headerName = '电话'
  }

  private getContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
    let principal = LocalDbDao.getPrincipal()
    if (principal.adminLevel === 2) {
      this.excludeAdminLevelKeys = ['1']
    }
    let disabled = params.node && ((principal.adminLevel === 2 && params.node.data.adminLevel === 1) ||
            principal.adminLevel === 0 ||
            (params.node.data.account && principal.identity === params.node.data.account.id) ||
            !params.node.data.account)
    const vue = this
    return [{
      name: '创建用户',
      action() {
        vue.mCreateUserDialogVisible = true
      },
      disabled: checkMenuAccessibility(params.node, vue, {
        customCheckFunc: () => principal.adminLevel === 0
      })
    }, {
      name: '邀请注册',
      action() {
        vue.mInviteDialogVisible = true
        vue.user = params.node.data
      },
      disabled: checkMenuAccessibility(params.node, vue, {
        requireSelection: true,
        customCheckFunc: account => !!account.account || !!account.joiningApplication
      })
    }, {
      name: '复制邀请链接',
      action() {
        copy(vue.$baseUrl + '/#/invitation/' + params.node.data.joiningApplication.invitationCode)
        vue.$notify({
          type: 'info',
          title: '消息',
          message: '邀请链接已复制到剪贴板'
        })
      },
      disabled: checkMenuAccessibility(params.node, vue, {
        requireSelection: true,
        customCheckFunc: account => !!account.account || !account.joiningApplication
      })
    }, {
      name: '设置管理员等级',
      action() {
        vue.setAdminLevelDialogVisible = true
        vue.adminLevel = params.node.data.adminLevel.toString()
        vue.user = params.node.data
      },
      disabled: checkMenuAccessibility(params.node, vue, {
        requireSelection: true,
        customCheckFunc: () => disabled
      })
    }, {
      name: '修改用户信息',
      action() {
        vue.editUserDialogVisible = true
        vue.userInfo.employeeNo = params.node.data.employeeNo
        vue.userInfo.displayName = params.node.data.displayName
        vue.userInfo.mobilePhone = params.node.data.account.mobilePhone
        vue.userInfo.accountId = params.node.data.account.id
        vue.user = params.node.data
      },
      disabled: checkMenuAccessibility(params.node, vue, {
        requireSelection: true,
        customCheckFunc: () => disabled
      })
    }, {
      name: '修改密码',
      action() {
        vue.passwordDialogVisible = true
        vue.user = params.node.data
      },
      disabled: checkMenuAccessibility(params.node, vue, {
        requireSelection: true,
        customCheckFunc: () => disabled
      })
    }, {
      name: '启用',
      action() {
        submitMutation(vue, {
          mutationOptions: {
            mutation: gql`mutation enableUser($userId: ID!) {
              enableUser(userId: $userId) {
                id, disabled
              }
            }`,
            variables: {
              userId: params.node.data.id
            }
          }
        }).then(data => {
          vue.$message({
            message: '启用成功', type: 'success', showClose: true
          })
          vue.vExplorer.refresh()
        })
      },
      disabled: checkMenuAccessibility(params.node, vue, {
        requireSelection: true,
        customCheckFunc: account => !account.disabled || disabled
      })
    }, {
      name: '禁用',
      action() {
        submitMutation(vue, {
          mutationOptions: {
            mutation: gql`mutation disableUser($userId: ID!) {
              disableUser(userId: $userId) {
                id, disabled
              }
            }`,
            variables: {
              userId: params.node.data.id
            }
          }
        }).then(data => {
          vue.$message({
            message: '禁用成功', type: 'success', showClose: true
          })
          vue.vExplorer.refresh()
        })
      },
      disabled: checkMenuAccessibility(params.node, vue, {
        requireSelection: true,
        customCheckFunc: account => account.disabled || disabled
      })
    }]
  }

  private saveAdminLevel() {
    submitMutation(this, {
      mutationOptions: {
        mutation: gql`mutation setUserAdminLevel($id: ID!, $ver: Int!, $adminLevel: Int!) {
          setUserAdminLevel(id: $id, ver: $ver, adminLevel: $adminLevel) {
            id
          }
        }`,
        variables: {
          id: this.user?.id,
          ver: this.user?.ver,
          adminLevel: this.adminLevel
        }
      },
      ignoreWarning: false
    }).then(data => {
      if (!data) return
      this.$message({
        message: '设置成功', type: 'success', showClose: true
      })
      this.setAdminLevelDialogVisible = false
      this.user = null
      this.vExplorer.refresh()
    })
  }

  private saveUser() {
    this.elUserInfoForm.validate((valid: boolean) => {
      if (valid) {
        this.submitSaveUser()
      } else {
        this.$message({
          message: '提交信息有误,请修正', type: 'error', showClose: true
        })
      }
    })
  }

  private submitSaveUser() {
    submitMutation(this, {
      mutationOptions: {
        mutation: gql`mutation adminSetUserInfo($id: ID!, $ver: Int!, $mobile: String!, $displayName: String!, $employeeNo: String) {
          adminSetUserInfo(id: $id, ver: $ver, mobile: $mobile, displayName: $displayName, employeeNo: $employeeNo)  {
            id
          }
        }`,
        variables: {
          id: this.user?.id,
          ver: this.user?.ver,
          displayName: this.userInfo.displayName,
          mobile: this.userInfo.mobilePhone,
          employeeNo: this.userInfo.employeeNo
        }
      },
      ignoreWarning: false
    }).then(data => {
      if (!data) return
      this.$message({
        message: '设置成功', type: 'success', showClose: true
      })
      this.editUserDialogVisible = false
      this.user = null
      this.vExplorer.refresh()
    })
  }

  refreshGrid() {
    this.vExplorer.refresh()
  }
}
