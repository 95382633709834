export default [
  {
    path: '/mdp/structure',
    name: '产品结构',
    meta: {
      title: '产品结构管理',
      cache: true,
      auth: true,
      modelName: 'MdpStructure'
    },
    component: () => import('./views/page-mdp-structure')
  }, {
    path: '/mdp/on-shelf-product',
    name: 'PageMdpOnShelfProduct',
    meta: {
      title: '已上架产品',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-mdp-on-shelf-product')
  }, {
    path: '/mdp/match-md-data',
    name: 'PageMdpMatchMdData',
    meta: {
      title: '品号关联管理',
      cache: true,
      auth: true,
      modelName: 'MdpStructureMdDataView'
    },
    component: () => import('./views/page-mdp-match-md-data')
  }, {
    path: '/mdp/on-shelf-product-detail',
    name: 'PageMdpOnShelfProductDetail',
    meta: {
      title: '查看产品',
      cache: true,
      auth: true

    },
    component: () => import('./views/page-mdp-on-shelf-product-detail')
  }, {
    path: '/mdp/product-gallery',
    name: 'PageMdpProductGallery',
    meta: {
      title: '产品目录',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-mdp-product-gallery')
  }, {
    path: '/mdp/fin-model',
    name: 'PageMdpFinModel',
    meta: {
      title: '蛙鞋型号',
      cache: true,
      auth: true,
      modelName: 'MdpFinModel'
    },
    component: () => import('./views/page-mdp-fin-model')
  }, {
    path: '/mdp/goggle-model',
    name: 'PageMdpGoggleModel',
    meta: {
      title: '泳镜型号',
      cache: true,
      auth: true,
      modelName: 'MdpGoggleModel'
    },
    component: () => import('./views/page-mdp-goggle-model')
  }, {
    path: '/mdp/gallery-catalog',
    name: 'PageMdpGalleryCatalog',
    meta: {
      title: '产品目录管理',
      cache: true,
      auth: true,
      modelName: ''
    },
    component: () => import('./views/page-mdp-gallery-catalog')
  }, {
    path: '/mdp/gallery-sku',
    name: 'PageMdpGallerySku',
    meta: {
      title: 'SKU管理',
      cache: true,
      auth: true,
      modelName: 'MdpGallerySku'
    },
    component: () => import('./views/page-mdp-gallery-sku')
  }, {
    path: '/mdp/work-manual-template',
    name: 'PageMdpManualTemplate',
    meta: {
      title: '作业指导模板',
      cache: true,
      auth: true,
      modelName: 'MdpWorkManualTemplate'
    },
    component: () => import('./views/page-mdp-work-manual-template')
  }, {
    path: '/mdp/md-data-view',
    name: 'PageMdpMdDataView',
    meta: {
      title: '结构化视图',
      cache: true,
      auth: true,
      modelName: 'MdpMdDataView'
    },
    component: () => import('./views/page-mdp-md-data-view')
  }
]
