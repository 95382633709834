import { PluginObject } from 'vue/types/plugin'
import LocalDbDao from '@/module/common/local-db-dao'
import _ from 'lodash'
import { UserPrincipal, ValuedEnum } from '@/module/common/types'

declare module 'vue/types/vue' {
  interface Vue {
    $perm: (permCode: string) => boolean
    $enum: (name: string, key: any) => string
    $enumItems: (name: string) => ValuedEnum[]
    $currentPrincipal: () => UserPrincipal
    $currentPrincipalIdentity: () => string
    $gv: (key: string) => any
  }
}

const ButlerVuePlugin: PluginObject<any> = {
  install(Vue, options) {
    Vue.mixin({
      data() {
        return {
          $_perm: {}
        }
      }
    })

    Vue.prototype.$currentPrincipalIdentity = function () {
      return LocalDbDao.getPrincipal()?.identity || null
    }

    Vue.prototype.$currentPrincipal = function () {
      return LocalDbDao.getPrincipal()
    }

    Vue.prototype.$enum = function (name: string, key: any) {
      return LocalDbDao.getValuedEnumItem(name, _.toString(key))?.value
    }

    Vue.prototype.$enumItems = function (name: string) {
      return LocalDbDao.getValuedEnum(name)
    }

    Vue.prototype.$perm = function (permCode?: string) {
      const vue = this
      if (!permCode) return false
      if (_.has(vue.$data.$_perm, permCode)) return vue.$data.$_perm[permCode]
      let result = LocalDbDao.checkPermission(permCode)
      if (_.isBoolean(result)) {
        vue.$set(vue.$data.$_perm, permCode, result)
        return result
      } else {
        result.then(result => {
          vue.$set(vue.$data.$_perm, permCode, result)
          return result
        })
        return false
      }
    }

    Vue.prototype.$gv = function (key: string) {
      return LocalDbDao.getGlobalVariable(key)
    }
  }
}

export default ButlerVuePlugin
