export default [
  {
    path: '/mdo/config',
    name: 'PageMdoConfig',
    meta: {
      title: '订单配置',
      auth: true
    },
    component: () => import('./views/page-mdo-config')
  },
  {
    path: '/mdo/po',
    name: 'PageMdoProductOrder',
    meta: {
      title: '业务订单管理',
      auth: true
    },
    component: () => import('./views/page-mdo-product-order')
  },
  {
    path: '/mdo/packing-option',
    name: 'PageMdoPackingOption',
    meta: {
      title: '装箱方式管理',
      auth: true
    },
    component: () => import('./views/page-mdo-packing-option')
  },
  {
    path: '/mdo/design-document',
    name: 'PageMdoDesignDocument',
    meta: {
      title: '订单图档管理',
      auth: true
    },
    component: () => import('./views/page-mdo-design-document')
  },
  {
    path: '/mdo/design-document-unit-query',
    name: 'PageMdoDesignDocumentUnitQuery',
    meta: {
      title: '产品图档查询',
      auth: true
    },
    component: () => import('./views/page-mdo-design-document-unit')
  },
  {
    path: '/mdo/boxing-sheet',
    name: 'PageMdoBoxingSheet',
    meta: {
      title: '装箱单管理',
      auth: true,
      modelName: 'MdoBoxingSheet'
    },
    component: () => import('./views/page-mdo-boxing-sheet')
  },
  {
    path: '/mdo/box-work-order',
    name: 'PageMdoBoxWorkOrder',
    meta: {
      title: '纸箱工单管理',
      auth: true,
      modelName: 'MdoBoxWorkOrder'
    },
    component: () => import('./views/page-mdo-box-work-order')
  },
  {
    path: '/mdo/box-paperboard',
    name: 'PageBoxPaperboard',
    meta: {
      title: '纸板需求管理',
      auth: true,
      modelName: 'MdoBoxPaperboardView'
    },
    component: () => import('./views/page-mdo-box-paperboard')
  },
  {
    path: '/mdo/mark-print-template',
    name: 'PageMdoMarkPrintTemplate',
    meta: {
      title: '唛头模板管理',
      auth: true,
      modelName: 'MdoMarkPrintTemplate'
    },
    component: () => import('./views/page-mdo-mark-print-template')
  },
  {
    path: '/mdo/boxing-sheet-entry',
    name: 'PageMdoBoxingSheetEntry',
    meta: {
      title: '装箱单历史',
      auth: true,
      modelName: 'MdoBoxingSheetEntry'
    },
    component: () => import('./views/page-mdo-boxing-sheet-entry')
  },
  {
    path: '/mdo/delivery-sheet',
    name: 'PageMdoDeliverySheet',
    meta: {
      title: '出货单管理',
      auth: true,
      modelName: 'MdoDeliverySheet'
    },
    component: () => import('./views/page-mdo-delivery-sheet')
  }
]
