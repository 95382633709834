export default [
  {
    path: '/kti/talent',
    name: 'PageKtiTalent',
    meta: {
      title: '人才库管理',
      auth: true,
      modelName: 'KtiTalent'
    },
    component: () => import('./views/page-kti-talent.vue')
  }, {
    path: '/kti/attendance',
    name: 'PageKtiAttendance',
    meta: {
      title: '出勤管理',
      auth: true,
      modelName: 'KtiAttendance'
    },
    component: () => import('./views/page-kti-attendance.vue')
  }, {
    path: '/kti/work-report',
    name: 'PageKtiWorkingReport',
    meta: {
      title: '工作汇报',
      auth: true,
      modelName: 'KtiWorkingReport'
    },
    component: () => import('./views/page-kti-working-report.vue')
  }, {
    path: '/kti/research-achievement',
    name: 'PageKtiResearchAchievement',
    meta: {
      title: '科研成果',
      auth: true,
      modelName: 'KtiResearchAchievement'
    },
    component: () => import('./views/page-kti-research-achievement.vue')
  }, {
    path: '/kti/talent-assessment',
    name: 'PageKtiTalentAssessment',
    meta: {
      title: '考核管理',
      auth: true,
      modelName: 'KtiTalentAssessment'
    },
    component: () => import('./views/page-kti-talent-assessment.vue')
  }, {
    path: '/kti/intellectual-property',
    name: 'PageKtiIntellectualProperty',
    meta: {
      title: '知识产权库',
      auth: true,
      modelName: 'KtiIntellectualProperty'
    },
    component: () => import('./views/page-kti-intellectual-property.vue')
  }
]
