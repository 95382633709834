



























import { Component, Mixins, Prop } from 'vue-property-decorator'
import {
  ColDef,
  ColGroupDef,
  GetContextMenuItems,
  GetContextMenuItemsParams,
  GridOptions,
  GridReadyEvent,
  IServerSideDatasource,
  MenuItemDef,
  RowSelectedEvent
} from 'ag-grid-community'
import LocalDbDao from '@/module/common/local-db-dao'
import {
  convertSortColumns,
  DefaultAgGridClientSideDataOptions,
  DefaultAgGridServerSideDataOptions,
  FrameworkComponentsRegistry,
  getRowId
} from './supports'
import { ChartView, FilterCondition, FilterType, SortColumn } from '@/module/graphql'
import _ from 'lodash'
import { CellDoubleClickedEvent, RowDoubleClickedEvent } from 'ag-grid-community/dist/lib/events'
import { ExplorerType } from './explorer-type'
import { FilterViewDef } from '@/module/components/lolth-filter/types'
import { GraphQLDataSource } from './graphql-data-source'
import { ExplorerDataSourceArgs } from '@/module/components/lolth-explorer/explorer-data-source'
import EventBus from '@/d2admin/plugin/event/event-bus'
import ExplorerSelectionWidget from './explorer-selection-widget.vue'
import { Indexed } from '@/d2admin/delegate'
import { SelectionMode } from '@/module/common/types'
import gql from 'graphql-tag'
import util from '@/d2admin/libs/util'
import ExplorerViewSwitchWidget from '@/module/components/lolth-explorer/explorer-view-switch-widget.vue'
import MixinLolthExplorerView from '@/module/components/lolth-explorer/mixin-lolth-explorer-view'
import ExplorerChartViewSaver from '@/module/components/lolth-explorer/explorer-chart-view-saver.vue'

@Component({
  name: 'lolth-explorer',
  components: { ExplorerSelectionWidget, ExplorerViewSwitchWidget, ExplorerChartViewSaver },
  provide() {
    return {
      explorer: this
    }
  }
})
export default class LolthExplorer extends Mixins(MixinLolthExplorerView) {
  @Prop() readonly id?: string
  @Prop({ required: true }) readonly explorerTypeKey!: string
  @Prop() readonly customExplorerType!: ExplorerType
  @Prop({ default: SelectionMode.Multiple }) readonly selectionMode!: SelectionMode

  @Prop() readonly overriddenColumnDefs?: { [field: string] : ColDef }
  @Prop() readonly onRowDoubleClicked?: (event: RowDoubleClickedEvent) => void
  @Prop() readonly onCellDoubleClicked?: (event: CellDoubleClickedEvent) => void
  @Prop() readonly getContextMenuItems?: GetContextMenuItems
  @Prop({ default: 'ag-grid-full' }) readonly gridClass!: string
  @Prop({ default: false }) readonly groupNestedHeader!: boolean

  @Prop() readonly extendFilterViewDefs?: () => FilterViewDef[]
  @Prop() readonly extendGridOptions?: (explorer: LolthExplorer, gridOptions: GridOptions) => void
  @Prop() readonly extendDetailGridOptions?: (gridOptions: GridOptions) => void

  @Prop() readonly explorerViewSuffix?: string

  private mDataSource!: IServerSideDatasource

  private mTempContext?: Indexed // non-reactive by purpose, used to hold context params before gridOptions is created

  get explorerType(): ExplorerType {
    return this.customExplorerType ||
        LocalDbDao.getExplorerType(this.explorerTypeKey)
  }

  get columnDefs() {
    return this.explorerType.agGridColumnDef([], this.groupNestedHeader)
  }

  public getContextParam<T>(key: string): T {
    return (this.mGridOptions?.context || this.mTempContext)[key] as T
  }

  public setContextParam(key: string, value: any) {
    (this.mGridOptions?.context || this.mTempContext)[key] = value
  }

  public getExplorerViewModelName(): string {
    return this.explorerType.key + (this.explorerViewSuffix || '')
  }

  protected setupDataSource() {
    this.gridApi().setServerSideDatasource(this.mDataSource)
    this.gridApi().closeToolPanel()
  }

  protected suspendDataSource() {
    this.gridApi().setServerSideDatasource(null)
  }

  protected getExplorerTypeDefaultSort(): SortColumn {
    return this.explorerType.defaultSort
  }

  async created() {
    this.mTempContext = {}
    this.updateMergedFilterConditions(this.mFixedFilterConditions,
      this.getFixedFilterConditions())
    this.mGridOptions = this.buildGridOptions()
    // shift temp context params to formal gridOptions.context
    Object.assign(this.mGridOptions.context, this.mTempContext)
    const dataSourceConstructorArgs: ExplorerDataSourceArgs = {
      explorerType: this.explorerType,
      gridOptions: this.mGridOptions,
      explorer: this
    }
    this.mDataSource = this.explorerType.dataSourceProvider
      ? this.explorerType.dataSourceProvider(dataSourceConstructorArgs)
      : new GraphQLDataSource(dataSourceConstructorArgs)
    this.$apollo.query({
      query: gql`query {
        getChartViews(modelName: "${this.explorerTypeKey}") {
          id ver name modelName hash chartType chartOptions rowRequest note
        }
      }`
    }).then(resp => {
      this.mChartViews = (resp.data.getChartViews as ChartView[])
        .filter(chartView => chartView.chartType === 'ag_grid')
      if (this.chartViewId) {
        this.showChartView(this.mChartViews
          .filter(chartView => parseInt(chartView.id) === this.chartViewId)[0])
      }
    })

    this.$watch('fixedFilterCondition', this.onFixedConditionChanged)
    EventBus.$on('refresh-explorer', () => {
      this.refresh()
    })
  }

  destroyed() {
    EventBus.$off('refresh-explorer')
  }

  private buildGridOptions(): GridOptions {
    // 以默认设置为模板设置Grid
    const gridOptions = this.buildBasicGridOptions()
    gridOptions.onRowSelected = this.onSelectionChanged
    gridOptions.onRowDoubleClicked = this.onRowDoubleClicked
    gridOptions.onCellDoubleClicked = this.onCellDoubleClicked
    gridOptions.getContextMenuItems = this.mergedContextMenuItems
    gridOptions.columnDefs = this.columnDefs
    gridOptions.rowSelection = this.selectionMode
    gridOptions.rowMultiSelectWithClick = this.selectionMode === SelectionMode.Multiple

    // 注册额外的cellRenderer
    Object.assign(gridOptions.frameworkComponents, FrameworkComponentsRegistry)

    // 覆盖列设置
    if (this.overriddenColumnDefs) {
      gridOptions.columnDefs?.forEach(colDef => this.applyOverriddenColumnDef(colDef))
    }
    // 自定义扩展表格设置
    if (this.extendGridOptions) {
      this.extendGridOptions(this, gridOptions)
    }

    const expanderField = this.explorerType.getExpanderField()
    // master-detail config
    if (expanderField?.expandType) {
      // ServerSide加载方式
      const expandingType = LocalDbDao.getExplorerType(expanderField.expandType)
      let detailFixedCondition: FilterCondition
      let defaultSort: SortColumn
      const detailGridOptions:GridOptions = _.assign(_.cloneDeep(DefaultAgGridServerSideDataOptions), {
        columnDefs: expandingType.agGridColumnDef(expanderField.expandIgnoreFields,
          this.groupNestedHeader, expanderField),
        onGridReady: (event:GridReadyEvent) => {
          if (expanderField.defaultSort) {
            event.columnApi?.applyColumnState(convertSortColumns([expanderField.defaultSort]))
          }
          detailGridOptions.api = event.api
          detailGridOptions.columnApi = event.columnApi
          event.api?.setServerSideDatasource(new GraphQLDataSource({
            explorerType: expandingType,
            gridOptions: detailGridOptions,
            fixedFilterCondition: detailFixedCondition,
            ignoreForExpanding: true
          }))
        }
      })
      // 注册额外的cell renderers
      Object.assign(detailGridOptions.frameworkComponents, FrameworkComponentsRegistry)
      detailGridOptions.context.isDetailGrid = true
      // post process detailGridOptions
      if (this.extendDetailGridOptions) this.extendDetailGridOptions(detailGridOptions)
      // setup detail grid
      gridOptions.detailCellRendererParams = {
        detailGridOptions,
        getDetailRowData: (params: any) => {
          detailFixedCondition = {
            filterType: FilterType.String,
            operator: 'equals',
            field: expanderField.associatingField,
            value: _.get(params.data, expanderField.dominatorField) || getRowId(params.data)
          }
          defaultSort = expanderField.defaultSort
        }
      }
    // client side 加载方式
    } else if (expanderField?.expandField) {
      const expandingField = this.explorerType.getExpandingField(expanderField)
      const expandingType = LocalDbDao.getExplorerType(expandingField.typeKey)
      const detailGridOptions:GridOptions = _.assign(_.cloneDeep(DefaultAgGridClientSideDataOptions), {
        columnDefs: expandingType.agGridColumnDef(expanderField.expandIgnoreFields,
          this.groupNestedHeader, expanderField),
        onGridReady: (event:GridReadyEvent) => {
          if (expanderField.defaultSort) {
            if (!event.columnApi?.getColumn(expanderField.defaultSort.field)) {
              throw new Error(`sort field ${expanderField.defaultSort.field} is not define in this grid`)
            }
            event.columnApi?.applyColumnState(convertSortColumns([expanderField.defaultSort]))
          }
        }
      })
      // 注册额外的cell renderers
      Object.assign(detailGridOptions.frameworkComponents, FrameworkComponentsRegistry)
      detailGridOptions.context.isDetailGrid = true
      // post process detailGridOptions
      if (this.extendDetailGridOptions) this.extendDetailGridOptions(detailGridOptions)
      // setup detail grid
      gridOptions.detailCellRendererParams = {
        detailGridOptions,
        getDetailRowData: (params:any) => {
          return this.$apollo.query({
            query: gql(`query {
              ${this.explorerType.key}(id: "${getRowId(params.data)}") {
                ${expandingField.key} {
                  ${expandingType.graphQLFetchFields(expanderField.expandIgnoreFields || [])}
                }
              }
            }`)
          }).then(data => {
            params.successCallback(data.data[this.explorerType.key][expandingField.key])
          })
        }
      }
    }
    return gridOptions
  }

  private applyOverriddenColumnDef(columnDef: ColDef | ColGroupDef) {
    if (!this.overriddenColumnDefs) return

    if ('field' in columnDef && columnDef.field) {
      const overriddenColumnDef = this.overriddenColumnDefs[columnDef.field]
      if (overriddenColumnDef) {
        _.assignIn(columnDef, overriddenColumnDef)
      }
    }
    if ('children' in columnDef && columnDef.children) {
      columnDef.children.forEach(child => this.applyOverriddenColumnDef(child))
    }
  }

  private mergedContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
    const vue = this
    const extendedContextMenuItems = (this.getContextMenuItems && this.getContextMenuItems(params)) || []
    if (extendedContextMenuItems && extendedContextMenuItems.length > 0) {
      extendedContextMenuItems.push('separator')
    }
    return [
      ...extendedContextMenuItems,
      {
        name: '刷新',
        action() {
          vue.refresh()
        }
      }, {
        name: '重置筛选与分组',
        action() {
          vue.columnApi().resetColumnState()
          vue.resetSortModel()
          vue.resetFilterConditions()
        }
      },
      'separator',
      'copy',
      'copyWithHeaders',
      {
        name: '复制单元格',
        action() {
          (vue.mGridOptions.api as any)?.clipboardService.copyFocusedCellToClipboard(false)
        }
      },
      'separator',
      ...this.buildCustomExportMenuItems(vue),
      'separator',
      'chartRange',
      {
        name: '查看图表',
        subMenu: vue.mChartViews.map(chartView => {
          return {
            name: chartView.name,
            action() {
              vue.showChartView(chartView)
            }
          }
        })
      }]
  }

  public getFilterViewDefs(): FilterViewDef[] {
    const defs = this.columnDefs
      .filter(colDef => colDef.filterParams && colDef.type !== 'rawColumn')
      .map(colDef => colDef.filterParams.viewDef)
    if (this.extendFilterViewDefs) defs.push(...this.extendFilterViewDefs())
    return defs
  }

  private onSelectionChanged(event: RowSelectedEvent): void {
    // ag-grid will resort selection, here restore previous selection order
    if (event.node.isSelected()) {
      if (this.selectionMode === SelectionMode.Multiple) {
        if (!_.find(this.syncedSelectedData, row => {
          return getRowId(row) === getRowId(event.data)
        })) {
          this.syncedSelectedData.push(event.data)
        }
      } else {
        this.syncedSelectedData.splice(0, 1, event.data)
      }
    } else {
      util.objects.remove(this.syncedSelectedData, row => {
        return getRowId(row) === getRowId(event.data)
      })
    }
    this.$emit('update:selectedData', this.syncedSelectedData)
  }

  public clearSelections() {
    this.syncedSelectedData = []
    this.gridApi().deselectAll()
    this.gridApi().clearRangeSelection()
    this.mNumericCellSum = 0
  }

  public deselect(item: any) {
    const rowNode = this.gridApi().getRowNode(getRowId(item))
    if (rowNode) rowNode.setSelected(false, true, true)
  }

  public restoreSelection() {
    this.syncedSelectedData.forEach(item => {
      const rowNode = this.gridApi().getRowNode(getRowId(item))
      if (rowNode) {
        rowNode.setSelected(true, false, true)
        util.objects.replace(this.syncedSelectedData, item => {
          return getRowId(item) === getRowId(rowNode.data)
        }, rowNode.data)
      }
    })
  }
}
