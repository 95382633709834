export default [
  {
    path: '/md/dictionary',
    name: 'PageMdDictionary',
    meta: {
      title: '选项表管理',
      cache: true,
      auth: true,
      modelName: 'MdDictionary'
    },
    component: () => import('./views/page-md-dictionary')
  }, {
    path: '/md/field',
    name: 'PageMdField',
    meta: {
      title: '字段管理',
      cache: true,
      auth: true,
      modelName: 'MdField'
    },
    component: () => import('./views/page-md-field')
  }, {
    path: '/md/unit',
    name: 'PageMdUnit',
    meta: {
      title: '单位管理',
      cache: true,
      auth: true,
      modelName: 'MdUnit'
    },
    component: () => import('./views/page-md-unit')
  }, {
    path: '/md/data',
    name: 'PageMdData',
    meta: {
      title: '主数据管理',
      cache: true,
      auth: true,
      modelName: 'MdData'
    },
    component: () => import('./views/page-md-data')
  }, {
    path: '/md/validation-rule',
    name: 'PageMdValidationRule',
    meta: {
      title: '主数据校验规则',
      cache: true,
      auth: true,
      modelName: 'MdValidationRule'
    },
    component: () => import('./views/page-md-validation-rule')
  }, {
    path: '/md/diagnosis',
    name: 'PageMdDiagnosis',
    meta: {
      title: '主数据校验结果',
      cache: true,
      auth: true,
      modelName: 'MdDiagnosis'
    },
    component: () => import('./views/page-md-diagnosis')
  }, {
    path: '/md/table',
    name: 'PageMdTable',
    meta: {
      title: '我的表格',
      cache: true,
      auth: true,
      modelName: 'md_table_view_'
    },
    component: () => import('./views/table/page-md-table')
  }, {
    path: '/md/table-mgr',
    name: 'PageMdTableMgr',
    meta: {
      title: '表格管理',
      cache: true,
      auth: true,
      modelName: 'MdTable'
    },
    component: () => import('./views/table/page-md-table-mgr')
  }, {
    path: '/md/file',
    name: 'PageMdFile',
    meta: {
      title: '文件管理',
      cache: true,
      auth: true,
      modelName: 'MdFile'
    },
    component: () => import('./views/file/page-md-file')
  }, {
    path: '/md/script',
    name: 'PageMdScriptProcessor',
    meta: {
      title: '数据处理脚本工具',
      cache: true,
      auth: true,
      modelName: 'MdScriptProcessor'
    },
    component: () => import('./views/page-md-script-processor')
  }, {
    path: '/md/tally-account-set',
    name: 'PageMdTallyAccountSet',
    meta: {
      title: '计数账套配置',
      cache: true,
      auth: true,
      modelName: 'MdTallyAccountSet'
    },
    component: () => import('./views/tally/page-md-tally-account-set.vue')
  }, {
    path: '/md/tally',
    name: 'PageMdTally',
    meta: {
      title: '计数流水查询',
      cache: true,
      auth: true,
      modelName: 'MdTally'
    },
    component: () => import('./views/tally/page-md-tally')
  }, {
    path: '/md/tally-surplus',
    name: 'PageMdTallySurplus',
    meta: {
      title: '计数结余查询',
      cache: true,
      auth: true,
      modelName: 'MdTallySurplus'
    },
    component: () => import('./views/tally/page-md-tally-surplus')
  }, {
    path: '/md/invoice',
    name: 'PageMdInvoice',
    meta: {
      title: '发票管理',
      cache: true,
      auth: true,
      modelName: 'MdInvoice'
    },
    component: () => import('./views/invoice/page-md-invoice')
  }, {
    path: '/md/field-template',
    name: 'PageMdFieldTemplate',
    meta: {
      title: '属性模板管理',
      cache: true,
      auth: true,
      modelName: 'MdFieldTemplate'
    },
    component: () => import('./views/field-template/page-md-field-template')
  }
]
