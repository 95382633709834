




























































import { Component, Ref, Vue } from 'vue-property-decorator'
import LolthExplorer from '@/module/components/lolth-explorer/index.vue'
import LocalDbDao from '@/module/common/local-db-dao'
import { GetContextMenuItemsParams, GridOptions, MenuItemDef } from 'ag-grid-community'
import { MdTally } from '@/module/graphql'
import { submitMutation } from '@/module/common/util/graphql-util'
import gql from 'graphql-tag'
import { checkMenuAccessibility, patchMultiSelectOnContextMenu } from '@/module/common/util/view-util'

@Component
export default class PageMdTally extends Vue {
  @Ref() private vExplorer!: LolthExplorer

  private mTopics: string[] = []
  private mSelectedTopic: string = null

  private mSelectedData: MdTally[] = []

  private mModifyDialogVisible = false
  private mModifyingTally: MdTally = null
  private mModifyNote: string = null
  private mSubmitAsAdjusting = false

  created() {
    LocalDbDao.prefetchPermissions([
      'MdTallyAdjustQtyUnit', 'MdTallyModify'
    ])
    this.$apollo.query({
      query: gql`query {
        getAllMdTallyTopics
      }`
    }).then(resp => {
      this.mTopics = resp.data.getAllMdTallyTopics
      this.mSelectedTopic = this.mTopics[0]
    })
  }

  private extendGridOptions(explorer: LolthExplorer, gridOptions: GridOptions) {
    explorer.setContextParam('extraFieldName', 'info')
    explorer.setContextParam('extraQueryParameters', { topic: this.mSelectedTopic })
  }

  private getContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
    const vue = this
    return [{
      name: '修改',
      subMenu: [{
        name: '直接修改',
        action() {
          vue.mSubmitAsAdjusting = false
          vue.showModifyDialog(params.node.data)
        },
        disabled: checkMenuAccessibility(params.node, vue, {
          requireSelection: true,
          requirePermissions: ['MdTallyModify']
        })
      }, {
        name: '插入调整流水',
        action() {
          vue.mSubmitAsAdjusting = true
          vue.showModifyDialog(params.node.data)
        },
        disabled: checkMenuAccessibility(params.node, vue, {
          requireSelection: true,
          requirePermissions: ['MdTallyModify']
        })
      }]
    }, {
      name: '*删除',
      action() {
        patchMultiSelectOnContextMenu(params.node,
          vue.vExplorer, vue.mSelectedData)
        vue.$confirm('确认删除流水记录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          submitMutation(vue, {
            mutationOptions: {
              mutation: gql`mutation deleteMdTallies($ids: [ID]!) {
                deleteMdTallies(topic: "${vue.mSelectedTopic}", ids: $ids)
              }`,
              variables: {
                ids: vue.mSelectedData.length > 0 ? vue.mSelectedData.map(row => row.id) : [params.node.data.id]
              }
            }
          }).then(() => {
            vue.$message({
              message: '删除成功', type: 'success', showClose: true
            })
            vue.vExplorer.refresh()
          })
        })
      },
      disabled: checkMenuAccessibility(params.node, vue, {
        requireSelection: true,
        requirePermissions: ['MdTallyModify']
      })
    }]
  }

  switchTopic(topic: string) {
    this.mSelectedTopic = topic
    this.vExplorer?.setContextParam('extraQueryParameters', { topic })
    this.vExplorer?.refresh()
  }

  showModifyDialog(mdTally: MdTally) {
    this.mModifyingTally = mdTally
    this.mModifyNote = null
    this.mModifyDialogVisible = true
  }

  submitModifying() {
    const mutation = this.mSubmitAsAdjusting ? 'adjustMdTally' : 'modifyMdTally'
    submitMutation(this, {
      mutationOptions: {
        // noinspection GraphQLSchemaValidation
        mutation: gql`mutation {
          ${mutation}(
            topic: "${this.mSelectedTopic}",
            tallyId: "${this.mModifyingTally.id}",
            ver: ${this.mModifyingTally.ver},
            qty: ${this.mModifyingTally.qty},
            unit: "${this.mModifyingTally.unit.code}",
            operatedAt: "${this.mModifyingTally.operatedAt}",
            note: "${this.mModifyNote}") { id ver }
        }`
      }
    }).then(resp => {
      this.$message({
        message: '修改成功', type: 'success', showClose: true
      })
      this.mModifyDialogVisible = false
      this.vExplorer.refresh()
    })
  }
}
