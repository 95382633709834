import gql from 'graphql-tag'
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date Scalar in format of yyyy-MM-dd */
  Date: Date;
  /** DateTime Scalar in format of yyyy-MM-dd HH:mm:ss */
  DateTime: Date;
  /** File handle scalar in String */
  File: any;
  /** Json Scalar */
  Json: any;
  /** Map Scalar */
  Map: any;
  /** ValuedEnum Scalar */
  ValuedEnum: any;
  /** Void Scalar */
  Void: any;
};

/** 账号信息 */
export type Account = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  identity?: Maybe<Scalars['String']>;
  /** 是否禁用 */
  disabled?: Maybe<Scalars['Boolean']>;
  /** 用户名 */
  username?: Maybe<Scalars['String']>;
  /** 电话 */
  mobilePhone?: Maybe<Scalars['String']>;
  /** 用户 */
  user?: Maybe<User>;
};

export type AggregationColumn = {
  field: Scalars['String'];
  aggFunc: Scalars['String'];
  extra?: Maybe<Scalars['Map']>;
};

export type AtsPoInvSheetEntryInput = {
  productOrderCode: Scalars['String'];
  entrySeq: Scalars['String'];
  verifiedQty: Scalars['Float'];
};

export type AtsPurchaseRequisitionEntryInput = {
  productCode: Scalars['String'];
  qty: Scalars['Int'];
  expectedDate?: Maybe<Scalars['Date']>;
  note?: Maybe<Scalars['String']>;
};

/** 登录历史 */
export type AuthLog = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 登录账号 */
  accountName: Scalars['String'];
  /** 用户标识 */
  accountIdentity: Scalars['String'];
  /** 登录时间 */
  loginAt: Scalars['DateTime'];
  /** 登录方式 */
  authType: Scalars['ValuedEnum'];
  /** IP地址 */
  ipAddr?: Maybe<Scalars['String']>;
  /** 位置 */
  location?: Maybe<Scalars['String']>;
  /** 浏览器 */
  userAgent?: Maybe<Scalars['String']>;
  /** 操作系统 */
  platform?: Maybe<Scalars['String']>;
  /** 客户端版本 */
  version?: Maybe<Scalars['String']>;
};

export type AuthLogGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type AuthLogPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<AuthLog>>>;
};

/** 通用目录 */
export type BrCatalog = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 名称 */
  name: Scalars['String'];
  /** 上级目录 */
  parent?: Maybe<BrCatalog>;
  /** 排序 */
  seq: Scalars['Int'];
  /** 下级目录 */
  children?: Maybe<Array<Maybe<BrCatalog>>>;
  /** 关联的数据 */
  entities?: Maybe<Array<Maybe<Scalars['Map']>>>;
  /** 目录下面数据数量 */
  entityCount?: Maybe<Scalars['Int']>;
  /** 关联数据类型 */
  modelName: Scalars['String'];
  /** 路径 */
  path?: Maybe<Scalars['String']>;
};

/** 图表看板 */
export type ChartDashboard = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 目录 */
  catalog?: Maybe<BrCatalog>;
  /** 名称 */
  name: Scalars['String'];
  /** 布局 */
  rows: Scalars['Json'];
  /** 可查看的用户 */
  readerIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** 引用的图表 */
  charViews?: Maybe<Array<Maybe<ChartView>>>;
};

export type ChartDashboardColumn = {
  uid: Scalars['String'];
  /** 图表 */
  chartView?: Maybe<ChartView>;
  /** 内嵌行 */
  rows?: Maybe<Array<Maybe<ChartDashboardRow>>>;
  /** 列高 */
  height?: Maybe<Scalars['Int']>;
  /** 列宽 */
  width?: Maybe<Scalars['Float']>;
  /** 字体大小(px) */
  fontSize?: Maybe<Scalars['Float']>;
  /** 文字对齐 */
  textAlign?: Maybe<Scalars['String']>;
};

export type ChartDashboardGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type ChartDashboardPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<ChartDashboard>>>;
};

export type ChartDashboardRow = {
  uid: Scalars['String'];
  /** 包含的列 */
  columns?: Maybe<Array<Maybe<ChartDashboardColumn>>>;
  /** 行高 */
  height?: Maybe<Scalars['Int']>;
};

/** 报表视图 */
export type ChartView = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 数据模型名称 */
  modelName?: Maybe<Scalars['String']>;
  /** 图表类型 */
  chartType?: Maybe<Scalars['ValuedEnum']>;
  /** 图表名称 */
  name: Scalars['String'];
  /** 目录 */
  catalog?: Maybe<BrCatalog>;
  /** 图表hash */
  hash: Scalars['String'];
  /** 预览图 */
  snapshot?: Maybe<Scalars['String']>;
  /** 数据筛选条件 */
  rowRequest?: Maybe<Scalars['Json']>;
  /** 图表设置 */
  chartOptions?: Maybe<Scalars['Json']>;
  /** labelSQL */
  labelSql?: Maybe<Scalars['String']>;
  /** tableSQL */
  tableSql?: Maybe<Scalars['String']>;
  /** 外部数据源 */
  externalDataSource?: Maybe<ExternalDataSource>;
  /** label脚本 */
  labelExpress?: Maybe<Scalars['String']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 删除标记 */
  deleted?: Maybe<Scalars['Boolean']>;
  /** 看板 */
  dashboards?: Maybe<Scalars['String']>;
  /** 看板用户 */
  readerIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ChartViewGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type ChartViewPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<ChartView>>>;
};

export type ChartViewSqlTableData = {
  columns?: Maybe<Array<Maybe<Scalars['String']>>>;
  values?: Maybe<Array<Maybe<Scalars['Map']>>>;
  size?: Maybe<Scalars['Int']>;
};

export type CodeBasedModel = {
  code?: Maybe<Scalars['ID']>;
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
};

export type Column = {
  field: Scalars['String'];
  extra?: Maybe<Scalars['Map']>;
};

export type DashboardGaugeConfigInput = {
  id: Scalars['ID'];
  level: Scalars['String'];
};

/** 错误信息 */
export type ErrorInfo = {
  /** 错误代码 */
  ec?: Maybe<Scalars['Int']>;
  /** 错误信息 */
  msg?: Maybe<Scalars['String']>;
  /** 错误等级 */
  elv?: Maybe<Scalars['ValuedEnum']>;
  /** 额外错误信息 */
  extra?: Maybe<Scalars['Map']>;
};

/** 账本 */
export type EstAccountBook = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 名称 */
  name: Scalars['String'];
  /** 维护组 */
  owners?: Maybe<Array<Maybe<User>>>;
  /** 协作组 */
  cooperators?: Maybe<Array<Maybe<User>>>;
  /** 只读组 */
  readers?: Maybe<Array<Maybe<User>>>;
  /** 账本等级 */
  vipLevel: Scalars['ValuedEnum'];
  /** 付费过期时间 */
  vipExpireAt?: Maybe<Scalars['Date']>;
  /** 收款方式 */
  receivableMethods?: Maybe<Array<Maybe<EstReceivableMethod>>>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 结余 */
  surplus: MdTallySurplus;
  /** 应付 */
  receivableSum: Scalars['Float'];
  /** 应收 */
  payableSum: Scalars['Float'];
};

export type EstAccountBookGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type EstAccountBookPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<EstAccountBook>>>;
};

/** 账本流水条目 */
export type EstAccountTallyEntry = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 账本 */
  accountBook: EstAccountBook;
  /** 编号 */
  code: Scalars['String'];
  /** 发生时间 */
  operatedAt: Scalars['Date'];
  /** 记账类型 */
  accountType: Scalars['ValuedEnum'];
  /** 事项描述 */
  summary?: Maybe<Scalars['String']>;
  /** 金额 */
  qty: Scalars['Float'];
  /** 本次结余 */
  surplus: Scalars['Float'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 附件 */
  attachments?: Maybe<Array<Maybe<Scalars['File']>>>;
  /** 交易对象 */
  tradingTarget?: Maybe<EstTradingTarget>;
  /** 手续费 */
  serviceFee?: Maybe<Scalars['Float']>;
  /** 项目 */
  contract?: Maybe<EstContract>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EstAccountTallyEntryGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type EstAccountTallyEntryPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<EstAccountTallyEntry>>>;
};

export type EstComment = {
  uid: Scalars['String'];
  openId: Scalars['String'];
  nickName: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  postAt: Scalars['DateTime'];
  attachment?: Maybe<Scalars['File']>;
};

/** 项目 */
export type EstContract = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 账本 */
  accountBook: EstAccountBook;
  /** 名称 */
  name: Scalars['String'];
  /** 收付类型 */
  accountType: Scalars['ValuedEnum'];
  /** 交易对象 */
  tradingTarget?: Maybe<EstTradingTarget>;
  /** 总金额 */
  totalAmount?: Maybe<Scalars['Float']>;
  /** 起始日期 */
  startDate?: Maybe<Scalars['Date']>;
  /** 结束日期 */
  endDate?: Maybe<Scalars['Date']>;
  /** 文件附件 */
  attachments?: Maybe<Array<Maybe<Scalars['File']>>>;
  /** 收付款周期 */
  reminderPeriod?: Maybe<Scalars['ValuedEnum']>;
  /** 提醒时间点 */
  reminderTiming?: Maybe<Scalars['ValuedEnum']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 关闭 */
  closed: Scalars['Boolean'];
  /** 已收付金额 */
  actualAmount?: Maybe<Scalars['Float']>;
  /** 关联流水 */
  tallyEntries?: Maybe<Array<Maybe<EstAccountTallyEntry>>>;
};

/** 项目 */
export type EstContractTallyEntriesArgs = {
  limit?: Maybe<Scalars['Int']>;
};

export type EstContractGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type EstContractPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<EstContract>>>;
};

export type EstContractTodoInput = {
  scheduleAt: Scalars['Date'];
  estimateAmount?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
};

/**
 * ##############################################################################
 *  Accessory Types
 * ##############################################################################
 */
export type EstContractTodoPreview = {
  scheduleAt: Scalars['Date'];
  estimateAmount?: Maybe<Scalars['Float']>;
};

export type EstInvitationToken = {
  token: Scalars['String'];
  accountBookName: Scalars['String'];
  inviterId: Scalars['ID'];
  inviterName: Scalars['String'];
  inviterAvatar?: Maybe<Scalars['String']>;
};

export type EstOpHistory = {
  uid: Scalars['String'];
  operatedBy: Scalars['String'];
  operatedAt: Scalars['DateTime'];
  operation: Scalars['String'];
};

/** 应付对账 */
export type EstPayableReconciliation = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 账本 */
  accountBook: EstAccountBook;
  /** 标识 */
  identity: Scalars['String'];
  /** 编号 */
  code: Scalars['String'];
  /** 数量 */
  qty: Scalars['Float'];
  /** 结余 */
  surplus: Scalars['Float'];
  /** 单位 */
  unit: MdUnit;
  /** 发起时间 */
  appliedAt?: Maybe<Scalars['DateTime']>;
  /** 操作时间 */
  operatedAt: Scalars['DateTime'];
  /** 摘要 */
  summary?: Maybe<Scalars['String']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 交易对象 */
  tradingTarget?: Maybe<EstTradingTarget>;
  accountEntryId?: Maybe<Scalars['ID']>;
  todoId?: Maybe<Scalars['ID']>;
  planId?: Maybe<Scalars['ID']>;
  stepId?: Maybe<Scalars['ID']>;
  /** 实收付 */
  solid: Scalars['Boolean'];
  /** 调账 */
  adjust: Scalars['Boolean'];
};

export type EstPayableReconciliationGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type EstPayableReconciliationPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<EstPayableReconciliation>>>;
};

export type EstReceivableMethod = {
  uid: Scalars['String'];
  name: Scalars['String'];
  bank?: Maybe<Scalars['String']>;
  bankNo?: Maybe<Scalars['String']>;
  barcode?: Maybe<Scalars['String']>;
  defaultMethod?: Maybe<Scalars['Boolean']>;
};

export type EstReceivableMethodInput = {
  uid?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  bank?: Maybe<Scalars['String']>;
  bankNo?: Maybe<Scalars['String']>;
  barcode?: Maybe<Scalars['String']>;
  defaultMethod?: Maybe<Scalars['Boolean']>;
};

/** 收款计划 */
export type EstReceivablePlan = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 账本 */
  accountBook: EstAccountBook;
  /** 名称 */
  name: Scalars['String'];
  /** 描述 */
  description?: Maybe<Scalars['String']>;
  /** 总应收金额 */
  totalAmount: Scalars['Float'];
  /** 公开附件 */
  publicAttachments?: Maybe<Array<Maybe<Scalars['File']>>>;
  /** 不公开附件 */
  privateAttachments?: Maybe<Array<Maybe<Scalars['File']>>>;
  /** 付款方 */
  payer?: Maybe<EstTradingTarget>;
  /** 付款方微信号 */
  payerOpenId?: Maybe<Scalars['String']>;
  /** 是否允许转发 */
  forwardable: Scalars['Boolean'];
  /** 结束关闭 */
  closed: Scalars['Boolean'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 收款任务 */
  steps?: Maybe<Array<Maybe<EstReceivableStep>>>;
  /** 操作历史 */
  opHistories?: Maybe<Array<Maybe<EstOpHistory>>>;
};

export type EstReceivablePlanGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type EstReceivablePlanPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<EstReceivablePlan>>>;
};

/** 应收对账 */
export type EstReceivableReconciliation = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 账本 */
  accountBook: EstAccountBook;
  /** 标识 */
  identity: Scalars['String'];
  /** 编号 */
  code: Scalars['String'];
  /** 数量 */
  qty: Scalars['Float'];
  /** 结余 */
  surplus: Scalars['Float'];
  /** 单位 */
  unit: MdUnit;
  /** 发起时间 */
  appliedAt?: Maybe<Scalars['DateTime']>;
  /** 操作时间 */
  operatedAt: Scalars['DateTime'];
  /** 摘要 */
  summary?: Maybe<Scalars['String']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 交易对象 */
  tradingTarget?: Maybe<EstTradingTarget>;
  accountEntryId?: Maybe<Scalars['ID']>;
  todoId?: Maybe<Scalars['ID']>;
  planId?: Maybe<Scalars['ID']>;
  stepId?: Maybe<Scalars['ID']>;
  /** 实收付 */
  solid: Scalars['Boolean'];
  /** 调账 */
  adjust: Scalars['Boolean'];
};

export type EstReceivableReconciliationGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type EstReceivableReconciliationPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<EstReceivableReconciliation>>>;
};

/** 收款任务 */
export type EstReceivableStep = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 名称 */
  plan: EstReceivablePlan;
  /** 应收金额 */
  amount: Scalars['Float'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 收款方 */
  receiver?: Maybe<EstTradingTarget>;
  /** 收款方附件 */
  receiverAttachments?: Maybe<Array<Maybe<Scalars['File']>>>;
  /** 收款方式 */
  receivableMethod?: Maybe<EstReceivableMethod>;
  /** 收款方微信号 */
  receiverOpenId?: Maybe<Scalars['String']>;
  /** 付款方附件 */
  payerAttachments?: Maybe<Array<Maybe<Scalars['File']>>>;
  /** 附言 */
  comments?: Maybe<Array<Maybe<EstComment>>>;
  /** 状态 */
  state?: Maybe<Scalars['ValuedEnum']>;
  /** 序号 */
  seq: Scalars['Int'];
  /** 操作历史 */
  opHistories?: Maybe<Array<Maybe<EstOpHistory>>>;
};

export type EstReceivableStepGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type EstReceivableStepInput = {
  id?: Maybe<Scalars['ID']>;
  amount: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
  receiverId?: Maybe<Scalars['ID']>;
  receiverAttachments?: Maybe<Array<Maybe<Scalars['File']>>>;
  receivableMethod?: Maybe<EstReceivableMethodInput>;
};

export type EstReceivableStepPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<EstReceivableStep>>>;
};

/** 合并待办任务(待办和收款计划) */
export type EstTask = CodeBasedModel & {
  code: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 账本 */
  accountBook: EstAccountBook;
  /** 类型 */
  type: Scalars['String'];
  /** 数据id */
  entityId: Scalars['ID'];
  /** 名称 */
  name: Scalars['String'];
  /** 收付类型 */
  accountType: Scalars['ValuedEnum'];
  /** 金额 */
  amount?: Maybe<Scalars['Float']>;
  /** 计划日期 */
  scheduleAt?: Maybe<Scalars['Date']>;
  /** 交易对象 */
  tradingTarget?: Maybe<EstTradingTarget>;
};

export type EstTaskGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type EstTaskPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<EstTask>>>;
};

/** 账本待办 */
export type EstTodo = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 账本 */
  accountBook: EstAccountBook;
  /** 名称 */
  name: Scalars['String'];
  /** 收付类型 */
  accountType: Scalars['ValuedEnum'];
  /** 计划日期 */
  scheduleAt?: Maybe<Scalars['Date']>;
  /** 应收付金额 */
  estimateAmount?: Maybe<Scalars['Float']>;
  /** 关联项目 */
  contract?: Maybe<EstContract>;
  /** 交易对象 */
  tradingTarget?: Maybe<EstTradingTarget>;
  /** 附件 */
  attachments?: Maybe<Array<Maybe<Scalars['File']>>>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 请款链接hash */
  requestHash?: Maybe<Scalars['String']>;
  /** 来源请款Todo */
  fromRequestTodo?: Maybe<EstTodo>;
  /** 请款处理 */
  accepterProcessed?: Maybe<Scalars['Boolean']>;
  /** 归档 */
  archived: Scalars['Boolean'];
};

export type EstTodoGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type EstTodoPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<EstTodo>>>;
};

/** 交易对象 */
export type EstTradingTarget = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 账本 */
  accountBook: EstAccountBook;
  /** 名称 */
  name: Scalars['String'];
  /** 收款方式 */
  receivableMethods?: Maybe<Array<Maybe<EstReceivableMethod>>>;
  /** 关联微信号 */
  openId?: Maybe<Scalars['String']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 结余 */
  surplus?: Maybe<MdTallySurplus>;
  /** 应收 */
  receivableSurplus: MdTallySurplus;
  /** 应付 */
  payableSurplus: MdTallySurplus;
  /** 应付代办数量 */
  payableTaskCount?: Maybe<Scalars['Int']>;
  /** 应收代办数量 */
  receivableTaskCount?: Maybe<Scalars['Int']>;
};

export type EstTradingTargetGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type EstTradingTargetPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<EstTradingTarget>>>;
};

/** 自定义视图 */
export type ExplorerView = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 创建者 */
  owner?: Maybe<User>;
  /** 数据模型名称 */
  modelName: Scalars['String'];
  /** 视图名称 */
  name: Scalars['String'];
  /** 视图hash */
  hash: Scalars['String'];
  /** 是否公共视图 */
  publicScope: Scalars['Boolean'];
  /** 视图配置 */
  options?: Maybe<Scalars['Json']>;
};

/** 外部数据源 */
export type ExternalDataSource = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 名称 */
  name: Scalars['String'];
  /** URL */
  url: Scalars['String'];
  /** 用户名 */
  username?: Maybe<Scalars['String']>;
  /** 密码 */
  password?: Maybe<Scalars['String']>;
  /** 驱动 */
  driver?: Maybe<Scalars['ValuedEnum']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
};

export type ExternalDataSourceGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type ExternalDataSourcePage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<ExternalDataSource>>>;
};

export type FilterCondition = {
  filterType: FilterType;
  field?: Maybe<Scalars['String']>;
  operator?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  not?: Maybe<Scalars['Boolean']>;
  extra?: Maybe<Scalars['Map']>;
  conditions?: Maybe<Array<Maybe<FilterCondition>>>;
};

export enum FilterType {
  ById = 'byId',
  InIds = 'inIds',
  IsNull = 'isNull',
  IsNotNull = 'isNotNull',
  String = 'string',
  Number = 'number',
  Date = 'date',
  Bool = 'bool',
  Array = 'array',
  VEnum = 'vEnum',
  Custom = 'custom',
  Not = 'not',
  And = 'and',
  Or = 'or'
}

export type GroupByRowRequest = {
  start?: Maybe<Scalars['Int']>;
  rows?: Maybe<Scalars['Int']>;
  /** GroupBy哪些字段 */
  groupByCols: Array<Maybe<Column>>;
  /** 当前请求Group值, 与groupByCols数据下标对应, 要翻译成`WHERE xxx=yyy` */
  groupKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Group后要做聚合运算的字段 */
  aggCols?: Maybe<Array<Maybe<AggregationColumn>>>;
  filter?: Maybe<FilterCondition>;
  sort?: Maybe<Array<Maybe<SortColumn>>>;
};

export type IdBasedModel = {
  id?: Maybe<Scalars['ID']>;
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
};

/** 用户加入申请 */
export type JoiningApplication = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 账号信息 */
  account?: Maybe<Account>;
  /** 员工姓名 */
  displayName: Scalars['String'];
  /** 工号 */
  employeeNo?: Maybe<Scalars['String']>;
  /** 审批状态 */
  approvalStatus?: Maybe<Scalars['ValuedEnum']>;
  /** 审批日期 */
  approvedDate?: Maybe<Scalars['DateTime']>;
  /** 申请审批人 */
  approvedBy?: Maybe<User>;
  /** 申请不通过原因 */
  rejectReason?: Maybe<Scalars['String']>;
  /** 邀请码 */
  invitationCode?: Maybe<Scalars['String']>;
};

export type JoiningApplicationGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type JoiningApplicationPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<JoiningApplication>>>;
};

/** 出勤记录 */
export type KtiAttendance = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 人员 */
  talent: KtiTalent;
  /** 月份 */
  month: Scalars['Date'];
  /** 需出勤(天) */
  workDays?: Maybe<Scalars['Float']>;
  /** 实际出勤(天) */
  attendanceDays: Scalars['Float'];
  /** 请假(天) */
  leaveDays?: Maybe<Scalars['Float']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 应付薪资(元) */
  salary?: Maybe<Scalars['Float']>;
  /** 已付薪资(元) */
  paidSalary?: Maybe<Scalars['Float']>;
  /** 未付薪资(元) */
  unPaidSalary?: Maybe<Scalars['Float']>;
  /** 薪酬备注 */
  salaryNote?: Maybe<Scalars['String']>;
  /** 是否存在违规 */
  infractions?: Maybe<Array<Maybe<MdDictionaryEntry>>>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type KtiAttendanceGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type KtiAttendanceInput = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Date']>;
  workDays?: Maybe<Scalars['Float']>;
  attendanceDays?: Maybe<Scalars['Float']>;
  leaveDays?: Maybe<Scalars['Float']>;
  talent?: Maybe<KtiTalentInput>;
  infractionIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  salary?: Maybe<Scalars['Float']>;
};

export type KtiAttendancePage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<KtiAttendance>>>;
};

/** 教育经历 */
export type KtiEducation = {
  /** 学校 */
  school?: Maybe<Scalars['String']>;
  /** 开始时间 */
  startDate?: Maybe<Scalars['Date']>;
  /** 结束时间 */
  endDate?: Maybe<Scalars['Date']>;
  /** 教育内容 */
  description?: Maybe<Scalars['String']>;
};

export type KtiEducationInput = {
  school?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
};

/** 知识产权 */
export type KtiIntellectualProperty = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 专利类型 */
  category: Scalars['ValuedEnum'];
  /** 申请号/专利号 */
  patentSN: Scalars['String'];
  /** 申请日 */
  applyDate: Scalars['Date'];
  /** 发明名称 */
  patentName: Scalars['String'];
  /** 申请人 */
  applyBy?: Maybe<Scalars['String']>;
  /** 发明人 */
  talentIds: Array<Maybe<Scalars['ID']>>;
  /** 分类号 */
  categorySN?: Maybe<Scalars['String']>;
  /** 授权公告号 */
  authorizationSN?: Maybe<Scalars['String']>;
  /** 授权公告日 */
  authorizationDate?: Maybe<Scalars['Date']>;
  /** 状态 */
  state: Scalars['ValuedEnum'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 附件 */
  attachments?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type KtiIntellectualPropertyGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type KtiIntellectualPropertyPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<KtiIntellectualProperty>>>;
};

/** 劳动合同 */
export type KtiLaborContract = {
  /** 开始时间 */
  startDate?: Maybe<Scalars['Date']>;
  /** 结束时间 */
  endDate?: Maybe<Scalars['Date']>;
  /** 合同附件 */
  attachments?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
};

export type KtiLaborContractInput = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  attachments?: Maybe<Array<Maybe<Scalars['String']>>>;
  note?: Maybe<Scalars['String']>;
};

/** 科研成果 */
export type KtiResearchAchievement = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 作者 */
  talentIds: Array<Maybe<Scalars['ID']>>;
  /** 成果类型 */
  category: Scalars['ValuedEnum'];
  /** 研究内容 */
  content?: Maybe<Scalars['String']>;
  /** 研究方向 */
  researchField?: Maybe<Scalars['String']>;
  /** 论文摘要 */
  summary?: Maybe<Scalars['String']>;
  /** 附件 */
  attachments?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type KtiResearchAchievementGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type KtiResearchAchievementPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<KtiResearchAchievement>>>;
};

/** 科研服务协议 */
export type KtiServiceContract = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 签订人 */
  talent: KtiTalent;
  /** 开始日期 */
  startDate?: Maybe<Scalars['Date']>;
  /** 结束日期 */
  endDate?: Maybe<Scalars['Date']>;
  /** 科研薪资 */
  salary?: Maybe<Scalars['Float']>;
  /** 定向服务单位 */
  serveIn: Scalars['String'];
  /** 选题方向 */
  topic?: Maybe<Scalars['String']>;
  /** 是否生效 */
  activated?: Maybe<Scalars['Boolean']>;
  /** 协议附件 */
  attachments?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type KtiServiceContractGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type KtiServiceContractInput = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  salary?: Maybe<Scalars['Float']>;
  activated?: Maybe<Scalars['Boolean']>;
  attachments?: Maybe<Array<Maybe<Scalars['String']>>>;
  serveIn?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

export type KtiServiceContractPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<KtiServiceContract>>>;
};

/** 导师评价 */
export type KtiSupervisorComment = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 被评价人 */
  talent: KtiTalent;
  /** 评价 */
  comment: Scalars['String'];
};

export type KtiSupervisorCommentGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type KtiSupervisorCommentPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<KtiSupervisorComment>>>;
};

/** 人员信息 */
export type KtiTalent = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 姓名 */
  name: Scalars['String'];
  /** 身份证号码 */
  idCard?: Maybe<Scalars['String']>;
  /** 工号 */
  employeeNO?: Maybe<Scalars['String']>;
  /** 性别 */
  gender: Scalars['ValuedEnum'];
  /** 出生日期 */
  birthDay?: Maybe<Scalars['Date']>;
  /** 学历 */
  qualification?: Maybe<Scalars['ValuedEnum']>;
  /** 毕业院校 */
  college?: Maybe<Scalars['String']>;
  /** 籍贯 */
  nativePlace?: Maybe<Scalars['String']>;
  /** 手机 */
  mobile?: Maybe<Scalars['String']>;
  /** 邮箱 */
  email?: Maybe<Scalars['String']>;
  /** 定向服务单位 */
  serveIn?: Maybe<Scalars['String']>;
  /** 入职时间 */
  employAt?: Maybe<Scalars['Date']>;
  /** 离职时间 */
  leaveAt?: Maybe<Scalars['Date']>;
  /** 银行卡号 */
  bankAccount?: Maybe<Scalars['String']>;
  /** 社保卡号 */
  socialSecurityNO?: Maybe<Scalars['String']>;
  /** 居住地址 */
  address?: Maybe<Scalars['String']>;
  /** 教育经历 */
  education?: Maybe<Array<Maybe<KtiEducation>>>;
  /** 工作经历 */
  workExp?: Maybe<Array<Maybe<KtiWorkExp>>>;
  /** 劳动合同 */
  laborContracts?: Maybe<Array<Maybe<KtiLaborContract>>>;
  /** 导师 */
  supervisor?: Maybe<Scalars['String']>;
  /** 选题方向 */
  topic?: Maybe<Scalars['String']>;
  /** 状态 */
  state: Scalars['ValuedEnum'];
  /** 科研协议 */
  serviceContracts?: Maybe<Array<Maybe<KtiServiceContract>>>;
};

/** 人员考核 */
export type KtiTalentAssessment = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 被考核人 */
  talent: KtiTalent;
  /** 考核类型 */
  category: Scalars['ValuedEnum'];
  /** 考核内容 */
  content?: Maybe<Scalars['String']>;
  /** 考核状态 */
  state: Scalars['ValuedEnum'];
  /** 原因 */
  comment?: Maybe<Scalars['String']>;
  /** 附件 */
  attachments?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type KtiTalentAssessmentGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type KtiTalentAssessmentPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<KtiTalentAssessment>>>;
};

export type KtiTalentGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

/**
 * ##############################################################################
 *  Inputs
 * ##############################################################################
 */
export type KtiTalentInput = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  idCard?: Maybe<Scalars['String']>;
  employeeNO?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['ValuedEnum']>;
  age?: Maybe<Scalars['Int']>;
  birthDay?: Maybe<Scalars['Date']>;
  qualification?: Maybe<Scalars['ValuedEnum']>;
  college?: Maybe<Scalars['String']>;
  nativePlace?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  employAt?: Maybe<Scalars['Date']>;
  leaveAt?: Maybe<Scalars['Date']>;
  bankAccount?: Maybe<Scalars['String']>;
  socialSecurityNO?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  education?: Maybe<Array<Maybe<KtiEducationInput>>>;
  workExp?: Maybe<Array<Maybe<KtiWorkExpInput>>>;
  laborContracts?: Maybe<Array<Maybe<KtiLaborContractInput>>>;
  supervisor?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['ValuedEnum']>;
  serviceContracts?: Maybe<Array<Maybe<KtiServiceContractInput>>>;
};

export type KtiTalentPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<KtiTalent>>>;
};

/** 工作经历 */
export type KtiWorkExp = {
  /** 公司 */
  company?: Maybe<Scalars['String']>;
  /** 开始时间 */
  startDate?: Maybe<Scalars['Date']>;
  /** 结束时间 */
  endDate?: Maybe<Scalars['Date']>;
  /** 工作内容 */
  description?: Maybe<Scalars['String']>;
};

export type KtiWorkExpInput = {
  company?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
};

/** 工作汇报 */
export type KtiWorkingReport = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 汇报人 */
  talentIds: Array<Maybe<Scalars['ID']>>;
  /** 报告日期 */
  reportDate?: Maybe<Scalars['Date']>;
  /** 报告类型 */
  category: Scalars['ValuedEnum'];
  /** 汇报内容 */
  content?: Maybe<Scalars['String']>;
  /** 附件 */
  attachments?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type KtiWorkingReportGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type KtiWorkingReportPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<KtiWorkingReport>>>;
};

/** 主数据属性 */
export type MdAttribute = {
  /** 字段 */
  field: MdField;
  /** 值 */
  value?: Maybe<Scalars['Json']>;
  extra?: Maybe<Scalars['Json']>;
  /** 别名 */
  alias?: Maybe<Scalars['String']>;
  /** 特征属性 */
  feature?: Maybe<Scalars['Boolean']>;
  /** Bom特征属性 */
  bomFeature?: Maybe<Scalars['Boolean']>;
  /** 必填 */
  mandatory?: Maybe<Scalars['Boolean']>;
  /** 待确认 */
  tbd?: Maybe<Scalars['Boolean']>;
  /** 隐藏属性 */
  hidden?: Maybe<Scalars['Boolean']>;
  /** 格式化值 */
  readableValue?: Maybe<Scalars['String']>;
};

export type MdAttributeInput = {
  /** 字段id */
  fieldId: Scalars['ID'];
  /** 数据内容 */
  value?: Maybe<Scalars['Json']>;
  /** 存放字段类型相关额外信息 */
  extra?: Maybe<Scalars['Json']>;
  /** 字段别名, 展示用 */
  alias?: Maybe<Scalars['String']>;
  /** 是否特征属性 */
  feature?: Maybe<Scalars['Boolean']>;
  /** 是否BOM特征属性 */
  bomFeature?: Maybe<Scalars['Boolean']>;
  /** 是否待确认, 特征属性如果待确认, 则生成UID填充 */
  tbd?: Maybe<Scalars['Boolean']>;
  /** 隐藏字段, 通常为程序计算生成自动. 例: bomDump */
  hidden?: Maybe<Scalars['Boolean']>;
  /** 是否变更 */
  changed?: Maybe<Scalars['Boolean']>;
};

/** 类别 */
export type MdCategory = {
  dataId: Scalars['ID'];
  /** 编号 */
  key: Scalars['String'];
  /** 值 */
  value: Scalars['String'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  mdFields: Array<MdField>;
  param: Scalars['Json'];
};

/** 用于业务模型展示类别 */
export type MdCategoryInfo = {
  dataId: Scalars['ID'];
  key: Scalars['String'];
  keyValue: Scalars['String'];
};

export type MdCategoryPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdCategory>>>;
};

/** 主数据 */
export type MdData = IdBasedModel & {
  id?: Maybe<Scalars['ID']>;
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 编号 */
  code: Scalars['ID'];
  field0?: Maybe<Scalars['String']>;
  field1?: Maybe<Scalars['String']>;
  field2?: Maybe<Scalars['String']>;
  field3?: Maybe<Scalars['String']>;
  field4?: Maybe<Scalars['String']>;
  field5?: Maybe<Scalars['String']>;
  field6?: Maybe<Scalars['String']>;
  field7?: Maybe<Scalars['String']>;
  field8?: Maybe<Scalars['String']>;
  field9?: Maybe<Scalars['String']>;
  /** 主图片 */
  mainPicture?: Maybe<Scalars['File']>;
  /** 图片列表 */
  pictures?: Maybe<Array<Scalars['File']>>;
  /** 特征属性 */
  featureAttributes?: Maybe<Array<Maybe<MdAttribute>>>;
  /** 属性 */
  attributes?: Maybe<Array<MdAttribute>>;
  /** Bom元件 */
  bomEntries?: Maybe<Array<MdDataBomEntry>>;
  /** Bom元件个数 */
  bomEntryCount?: Maybe<Scalars['Int']>;
  /** 引用Bom数量 */
  referredByBomCount?: Maybe<Scalars['Int']>;
  /** 总库存 */
  totalInvQty?: Maybe<Scalars['Float']>;
  /** 可用库存 */
  availableInvQty?: Maybe<Scalars['Float']>;
  /** 结构Key */
  structureKey?: Maybe<Scalars['String']>;
  /** 校验结果 */
  diagState?: Maybe<Scalars['ValuedEnum']>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 额外信息 */
  extra?: Maybe<Scalars['Json']>;
  /** Bom模板 */
  bomTemplate?: Maybe<MdDataBomTemplate>;
};

/** 主数据 */
export type MdDataAttributesArgs = {
  mdFieldIds?: Maybe<Array<Scalars['ID']>>;
};

/** 主数据Bom表 */
export type MdDataBomEntry = IdBasedModel & {
  id?: Maybe<Scalars['ID']>;
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 序号 */
  seq: Scalars['Int'];
  /** 用量 */
  count: Scalars['Float'];
  /** 用量基数 */
  countBase: Scalars['Int'];
  /** 单位 */
  unit: Scalars['String'];
  /** 主数据 */
  data: MdData;
  /** Bom条目主数据 */
  entryData: MdData;
  /** Bom模板元件key */
  bomTemplateEntryKey?: Maybe<Scalars['String']>;
  /** 额外信息 */
  extra?: Maybe<Scalars['Json']>;
};

export type MdDataBomEntryInput = {
  /** Bom条目uid */
  uid: Scalars['String'];
  /** Bom模板元件key */
  bomTemplateEntryKey?: Maybe<Scalars['String']>;
  /** Bom条目主数据 */
  entryDataCode?: Maybe<Scalars['String']>;
  /** 用量 */
  count: Scalars['Float'];
  /** 用量基数 */
  countBase?: Maybe<Scalars['Int']>;
  /** 单位 */
  unit: Scalars['String'];
  /** 额外信息 */
  extra?: Maybe<Scalars['Json']>;
};

/** 主数据Bom特性 */
export type MdDataBomFeature = IdBasedModel & {
  id?: Maybe<Scalars['ID']>;
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 主数据 */
  data: MdData;
  /** 特征组 */
  groupKey: Scalars['String'];
  /** 特征属性集合 */
  attributes: Array<Maybe<MdAttribute>>;
};

/** Bom模板 */
export type MdDataBomTemplate = {
  /** 结构Key */
  structureKey: Scalars['String'];
  /** 名称 */
  name: Scalars['String'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 摘要 */
  summary?: Maybe<Scalars['String']>;
  /** 严格匹配 */
  strict?: Maybe<Scalars['Boolean']>;
  /** Bom模板元件 */
  bomEntries?: Maybe<Array<MdDataBomTemplateEntry>>;
};

/** Bom模板元件 */
export type MdDataBomTemplateEntry = {
  /** Bom模板元件Key */
  templateEntryKey: Scalars['String'];
  /** Bom模板元件匹配Key */
  templateEntryMatchKey?: Maybe<Scalars['String']>;
  /** 填入该模板元件的主数据应该包含的属性 */
  attributes: Array<MdAttribute>;
  /** 用量 */
  count?: Maybe<Scalars['Float']>;
  /** 用量底数 */
  countBase?: Maybe<Scalars['Int']>;
  /** 单位 */
  unit?: Maybe<Scalars['String']>;
  /** 是否可选 */
  optional?: Maybe<Scalars['Boolean']>;
  /** 用量可编辑 */
  countEditable?: Maybe<Scalars['Boolean']>;
};

/** 固定字段设置 */
export type MdDataFixedFieldOption = {
  name?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  width?: Maybe<Scalars['Int']>;
  seq?: Maybe<Scalars['Int']>;
};

export type MdDataFixedFieldOptionInput = {
  name?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
};

export type MdDataGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdDataPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdData>>>;
};

/** 批量保存MdData的结果. 如果正确保存id不为空. 如果保存失败 */
export type MdDataSavingResult = {
  id?: Maybe<Scalars['ID']>;
  uid?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  code: Scalars['String'];
  errors?: Maybe<Array<ErrorInfo>>;
  field0?: Maybe<Scalars['String']>;
  field1?: Maybe<Scalars['String']>;
  field2?: Maybe<Scalars['String']>;
  field3?: Maybe<Scalars['String']>;
  field4?: Maybe<Scalars['String']>;
  field5?: Maybe<Scalars['String']>;
  field6?: Maybe<Scalars['String']>;
  field7?: Maybe<Scalars['String']>;
  field8?: Maybe<Scalars['String']>;
  field9?: Maybe<Scalars['String']>;
  attributes: Array<MdAttribute>;
  structureKey?: Maybe<Scalars['String']>;
  duplicateMdDataCode?: Maybe<Scalars['String']>;
};

/** 数据类型 */
export type MdDataType = {
  /** 名称 */
  name?: Maybe<Scalars['String']>;
  /** 默认设置 */
  option?: Maybe<Scalars['Json']>;
};

export type MdDataValues = {
  ver?: Maybe<Scalars['Int']>;
  uid?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  field0?: Maybe<Scalars['String']>;
  field1?: Maybe<Scalars['String']>;
  field2?: Maybe<Scalars['String']>;
  field3?: Maybe<Scalars['String']>;
  field4?: Maybe<Scalars['String']>;
  field5?: Maybe<Scalars['String']>;
  field6?: Maybe<Scalars['String']>;
  field7?: Maybe<Scalars['String']>;
  field8?: Maybe<Scalars['String']>;
  field9?: Maybe<Scalars['String']>;
  attributes?: Maybe<Array<Maybe<MdAttributeInput>>>;
  removingAttrMdFieldIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  structureKey?: Maybe<Scalars['String']>;
  bomEntries?: Maybe<Array<Maybe<MdDataBomEntryInput>>>;
  mainPicture?: Maybe<Scalars['File']>;
  pictures?: Maybe<Array<Maybe<Scalars['File']>>>;
};

/** 校验结果 */
export type MdDiagnosis = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 目标模型 */
  targetModel?: Maybe<Scalars['String']>;
  /** 数据Id */
  dataId?: Maybe<Scalars['ID']>;
  /** 数据编号 */
  dataKey?: Maybe<Scalars['String']>;
  /** 初次校验时间 */
  firstDiagAt?: Maybe<Scalars['DateTime']>;
  /** 最后校验时间 */
  lastDiagAt?: Maybe<Scalars['DateTime']>;
  /** 最后不通过时间 */
  lastFailedAt?: Maybe<Scalars['DateTime']>;
  /** 校验结果 */
  diagState: Scalars['ValuedEnum'];
  details?: Maybe<Scalars['Json']>;
};

/** 校验结果分页数据 */
export type MdDiagnosisPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<MdDiagnosis>>;
};

/** 选项表 */
export type MdDictionary = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 名称 */
  name: Scalars['String'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 选项数量 */
  entryCount: Scalars['Int'];
  entries?: Maybe<Array<MdDictionaryEntry>>;
  entryPage?: Maybe<MdDictionaryEntryPage>;
  /** 分组 */
  groups?: Maybe<Scalars['Json']>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 内置数据 */
  builtin?: Maybe<Scalars['Boolean']>;
};

/** 选项表 */
export type MdDictionaryEntryPageArgs = {
  rowRequest: RowRequest;
};

/** 选项表选项 */
export type MdDictionaryEntry = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 分组 */
  groupPath?: Maybe<Scalars['String']>;
  /** 编号 */
  key: Scalars['String'];
  /** 值 */
  value: Scalars['String'];
  /** 简称 */
  valueForShort?: Maybe<Scalars['String']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 序号 */
  seq?: Maybe<Scalars['Int']>;
  /** 选项表 */
  dictionary?: Maybe<MdDictionary>;
  /** 内置数据 */
  builtin?: Maybe<Scalars['Boolean']>;
};

export type MdDictionaryEntryGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

/** 选项分页数据 */
export type MdDictionaryEntryPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdDictionaryEntry>>>;
};

export type MdDictionaryEntryValues = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  valueForShort?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  groupPath?: Maybe<Scalars['String']>;
};

export type MdDictionaryGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdDictionaryPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdDictionary>>>;
};

/** 主数据域 */
export type MdDomain = {
  name: Scalars['String'];
  field0Option?: Maybe<MdDataFixedFieldOption>;
  field1Option?: Maybe<MdDataFixedFieldOption>;
  field2Option?: Maybe<MdDataFixedFieldOption>;
  field3Option?: Maybe<MdDataFixedFieldOption>;
  field4Option?: Maybe<MdDataFixedFieldOption>;
  field5Option?: Maybe<MdDataFixedFieldOption>;
  field6Option?: Maybe<MdDataFixedFieldOption>;
  field7Option?: Maybe<MdDataFixedFieldOption>;
  field8Option?: Maybe<MdDataFixedFieldOption>;
  field9Option?: Maybe<MdDataFixedFieldOption>;
};

/**
 * ##############################################################################
 *  Accessory Types
 * ##############################################################################
 */
export type MdDomainCache = {
  mdDomain?: Maybe<MdDomain>;
  etag: Scalars['String'];
};

/** 扩展数据 */
export type MdExtendData = {
  dataId: Scalars['String'];
  /** 编号 */
  dataKey: Scalars['String'];
  /** 值 */
  dataValue: Scalars['String'];
  /** 备注 */
  dataNote?: Maybe<Scalars['String']>;
  /** 额外信息 */
  extra?: Maybe<Scalars['Map']>;
};

/** 扩展数据分页数据 */
export type MdExtendDataPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdExtendData>>>;
};

/** 字段 */
export type MdField = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 名称 */
  name: Scalars['String'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 类型 */
  dataType: Scalars['String'];
  /** 摘要 */
  summary?: Maybe<Scalars['String']>;
  /** 设置 */
  option?: Maybe<Scalars['Json']>;
  /** 模板扩展附带设置 */
  payload?: Maybe<MdFieldTemplatePayload>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 内置数据 */
  builtin?: Maybe<Scalars['Boolean']>;
  /** 选项表 */
  dictionary?: Maybe<MdDictionary>;
};

/** 字段额外设置 */
export type MdFieldExtraOption = {
  /** 字段 */
  field: MdField;
  /** 额外设置 */
  option?: Maybe<Scalars['Json']>;
  extra?: Maybe<Scalars['Json']>;
};

export type MdFieldExtraOptionInput = {
  fieldId: Scalars['ID'];
  option?: Maybe<Scalars['Json']>;
};

export type MdFieldGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdFieldPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdField>>>;
};

/** 属性字段模板 */
export type MdFieldTemplate = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 业务主题 */
  topic: Scalars['String'];
  /** 名称 */
  name: Scalars['String'];
  /** 内置 */
  builtin?: Maybe<Scalars['Boolean']>;
};

/** 模板字段 */
export type MdFieldTemplateBranch = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 业务主题 */
  template: MdFieldTemplate;
  /** 所属分支 */
  entry?: Maybe<MdFieldTemplateEntry>;
  /** 名称 */
  name: Scalars['String'];
  /** 是否默认分支 */
  defaultBranch: Scalars['Boolean'];
  /** 分支条件 */
  branchFilter?: Maybe<Scalars['Json']>;
  /** 顺序 */
  seq: Scalars['Int'];
  /** 包含的字段 */
  entries?: Maybe<Array<Maybe<MdFieldTemplateEntry>>>;
  /** 包含的字段, 前端用 */
  children?: Maybe<Array<Maybe<MdFieldTemplateEntry>>>;
};

export type MdFieldTemplateBranchGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdFieldTemplateBranchPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdFieldTemplateBranch>>>;
};

/** 模板字段 */
export type MdFieldTemplateEntry = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 业务主题 */
  template: MdFieldTemplate;
  /** 所属分支 */
  branch?: Maybe<MdFieldTemplateBranch>;
  /** 名称 */
  name: Scalars['String'];
  /** 别名 */
  alias?: Maybe<Scalars['String']>;
  /** 字段 */
  mdField?: Maybe<MdField>;
  /** 强制使用默认值 */
  forceDefaultValue?: Maybe<Scalars['Boolean']>;
  /** 内置字段 */
  builtin?: Maybe<Scalars['Boolean']>;
  /** 字段额外设置 */
  option?: Maybe<Scalars['Json']>;
  /** 属性额外标记 */
  attrExtra?: Maybe<Scalars['Map']>;
  /** 顺序 */
  seq: Scalars['Int'];
  /** 派生的分支 */
  branches?: Maybe<Array<Maybe<MdFieldTemplateBranch>>>;
  /** 包含的字段, 前端用 */
  children?: Maybe<Array<Maybe<MdFieldTemplateBranch>>>;
};

export type MdFieldTemplateEntryGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdFieldTemplateEntryPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdFieldTemplateEntry>>>;
};

export type MdFieldTemplateGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdFieldTemplatePage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdFieldTemplate>>>;
};

/**
 * ##############################################################################
 *  Accessory Types
 * ##############################################################################
 */
export type MdFieldTemplatePayload = {
  alias?: Maybe<Scalars['String']>;
  templateBranch?: Maybe<Scalars['Boolean']>;
  indent?: Maybe<Scalars['Int']>;
  extra?: Maybe<Scalars['Json']>;
  context?: Maybe<Scalars['Json']>;
};

export type MdFieldValues = {
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  option?: Maybe<Scalars['Json']>;
};

/** 文件 */
export type MdFile = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 文件名称 */
  fileName: Scalars['String'];
  /** 文件标识 */
  fileKey?: Maybe<Scalars['String']>;
  /** 是否目录 */
  isDirectory: Scalars['Boolean'];
  /** 上级文件 */
  parent?: Maybe<MdFile>;
  /** 历史版本 */
  revisions?: Maybe<Scalars['Json']>;
  /** 是否共享 */
  shared?: Maybe<Scalars['Boolean']>;
  /** 是否叶子 */
  leaf?: Maybe<Scalars['Boolean']>;
  /** 路径 */
  path?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 路径id */
  pathIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** 根目录id */
  rootDirId?: Maybe<Scalars['ID']>;
};

/**
 * ##############################################################################
 *  Inputs
 * ##############################################################################
 */
export type MdFileInput = {
  fileName: Scalars['String'];
  fileKey: Scalars['String'];
};

/** 字段分页数据 */
export type MdFilePage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdFile>>>;
};

/** 根目录 */
export type MdFileRootDir = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 共享用户ID */
  shareToUserId?: Maybe<Scalars['ID']>;
  /** 用户 */
  shareToUser?: Maybe<Scalars['Json']>;
  /** 目录 */
  directory: MdFile;
  /** 所有者ID */
  ownerId: Scalars['ID'];
  /** 所有者ID */
  owner: Scalars['Json'];
  /** 所有人可见 */
  shareToEveryone: Scalars['Boolean'];
  /** 写权限 */
  writable: Scalars['Boolean'];
  /** 删除权限 */
  deletable: Scalars['Boolean'];
};

/** 发票票据 */
export type MdInvoice = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 发票大类 */
  type: Scalars['ValuedEnum'];
  /** 发票类型 */
  typeDesc?: Maybe<Scalars['String']>;
  /** 发票状态 */
  state: Scalars['ValuedEnum'];
  /** 金额 */
  amount?: Maybe<Scalars['Float']>;
  /** 提交人 */
  submittedBy?: Maybe<User>;
  /** 提交时间 */
  submittedAt?: Maybe<Scalars['Date']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 其它附件 */
  extraFiles?: Maybe<Array<Maybe<Scalars['File']>>>;
  /** 购方 */
  buyer?: Maybe<Scalars['String']>;
  /** 购方税号 */
  buyerTaxId?: Maybe<Scalars['String']>;
  /** 购方地址 */
  buyerAddress?: Maybe<Scalars['String']>;
  /** 购方电话 */
  buyerPhone?: Maybe<Scalars['String']>;
  /** 购方开户行 */
  buyerBank?: Maybe<Scalars['String']>;
  /** 购买方银行账号 */
  buyerBankAccount?: Maybe<Scalars['String']>;
  /** 购买方Email */
  buyerEmail?: Maybe<Scalars['String']>;
  /** 发票名称 */
  summary?: Maybe<Scalars['String']>;
  /** 编号 */
  code?: Maybe<Scalars['String']>;
  /** 开票日期 */
  issuedAt?: Maybe<Scalars['Date']>;
  /** 税额 */
  taxAmount?: Maybe<Scalars['Float']>;
  /** 售方 */
  seller?: Maybe<Scalars['String']>;
  /** 售方税号 */
  sellerTaxId?: Maybe<Scalars['String']>;
  /** 发票内容 */
  data?: Maybe<Scalars['Json']>;
  /** 发票内容明细 */
  details?: Maybe<Scalars['Json']>;
  /** 发票文件 */
  files?: Maybe<Array<Maybe<Scalars['File']>>>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MdInvoiceDataSchema = {
  fields?: Maybe<Scalars['Map']>;
  nestedFields?: Maybe<Scalars['Map']>;
};

export type MdInvoiceGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdInvoiceInput = {
  id?: Maybe<Scalars['ID']>;
  uid?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  type: Scalars['ValuedEnum'];
  typeDesc?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  submittedBy?: Maybe<Scalars['ID']>;
  submittedAt?: Maybe<Scalars['Date']>;
  note?: Maybe<Scalars['String']>;
  extraFiles?: Maybe<Array<Maybe<Scalars['File']>>>;
  buyer?: Maybe<Scalars['String']>;
  buyerTaxId?: Maybe<Scalars['String']>;
  buyerAddress?: Maybe<Scalars['String']>;
  buyerPhone?: Maybe<Scalars['String']>;
  buyerBank?: Maybe<Scalars['String']>;
  buyerBankAccount?: Maybe<Scalars['String']>;
  buyerEmail?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  issuedAt?: Maybe<Scalars['Date']>;
  taxAmount?: Maybe<Scalars['Float']>;
  seller?: Maybe<Scalars['String']>;
  sellerTaxId?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['Map']>;
  details?: Maybe<Array<Maybe<Scalars['Map']>>>;
  files?: Maybe<Array<Maybe<Scalars['File']>>>;
};

export type MdInvoicePage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdInvoice>>>;
};

/**
 * ##############################################################################
 *  Accessory Types
 * ##############################################################################
 */
export type MdScriptExecutionResult = {
  total?: Maybe<Scalars['Int']>;
  failedCount?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<ErrorInfo>>>;
};

/** 数据处理脚本 */
export type MdScriptProcessor = IdBasedModel & {
  id?: Maybe<Scalars['ID']>;
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 名称 */
  name: Scalars['String'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 目标模型 */
  targetModel: Scalars['String'];
  /** 目标对象筛选条件 */
  filter?: Maybe<Scalars['Json']>;
  /** 启动脚本 */
  startingScript?: Maybe<Scalars['String']>;
  /** 处理脚本 */
  actionScript?: Maybe<Scalars['String']>;
  /** 失败处理脚本 */
  failedScript?: Maybe<Scalars['String']>;
  /** 结束脚本 */
  closingScript?: Maybe<Scalars['String']>;
  /** 每批处理数量 */
  batchSize?: Maybe<Scalars['Int']>;
};

export type MdScriptProcessorGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdScriptProcessorPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdScriptProcessor>>>;
};

/** 结构层级 */
export type MdStructureLevel = {
  key: Scalars['String'];
  label: Scalars['String'];
  groupKey?: Maybe<Scalars['String']>;
  attributes: Array<Maybe<MdAttribute>>;
  children?: Maybe<Array<Maybe<MdStructureLevel>>>;
  structureCount: Scalars['Int'];
  illegalStructureCount?: Maybe<Scalars['Int']>;
  extra?: Maybe<Scalars['Json']>;
};

/** 智能表格 */
export type MdTable = CodeBasedModel & {
  code: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 表名 */
  name: Scalars['String'];
  /** 目录 */
  catalog?: Maybe<BrCatalog>;
  /** 预选配置 */
  presets?: Maybe<Array<Maybe<MdTableImportMappingPreset>>>;
  /** 表格列定义 */
  columns: Array<Maybe<MdTableColumn>>;
  /** 私有表格 */
  isPrivate: Scalars['Boolean'];
  /** 维护组 */
  ownerIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** 协作组 */
  cooperatorIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** 只读组 */
  readerIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** 导入状态, 不为空时不允许编辑修改 */
  importContext?: Maybe<MdTableImportContext>;
  /** 导入数据的SQL语句 */
  importFromSql?: Maybe<Scalars['String']>;
  /** 外部数据源 */
  externalDataSourceId?: Maybe<Scalars['ID']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 当前导入作业 */
  importProgress?: Maybe<Scalars['String']>;
  /** 是否有定时任务 */
  hasJob?: Maybe<Scalars['Boolean']>;
  /** 创建的图表 */
  chartViews?: Maybe<Array<Maybe<ChartView>>>;
};

/** 智能表格列定义 */
export type MdTableColumn = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 表格 */
  table: MdTable;
  /** 名称 */
  name: Scalars['String'];
  /** 序号 */
  seq: Scalars['Int'];
  /** 物理列名 */
  colName: Scalars['String'];
  /** 数据类型 */
  dataType: Scalars['String'];
  /** 列设置 */
  option?: Maybe<Scalars['Json']>;
  /** 主键 */
  uniqueKey: Scalars['Boolean'];
  /** 只读 */
  readonly: Scalars['Boolean'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
};

export type MdTableColumnInput = {
  name: Scalars['String'];
  colName?: Maybe<Scalars['String']>;
  dataType: Scalars['String'];
  option?: Maybe<Scalars['Json']>;
  uniqueKey?: Maybe<Scalars['Boolean']>;
  readonly?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
};

export type MdTableColumnValueInput = {
  columnId: Scalars['ID'];
  value?: Maybe<Scalars['Json']>;
};

/** 智能表格数据 */
export type MdTableData = IdBasedModel & {
  id?: Maybe<Scalars['ID']>;
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  values?: Maybe<Scalars['Json']>;
};

/** 智能表格分组数据 */
export type MdTableDataGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

/** 智能表格数据 */
export type MdTableDataPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<MdTableData>>;
};

export type MdTableGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

/** 智能表格导入状态 */
export type MdTableImportContext = {
  /** 正在导入的智能表格 */
  table?: Maybe<MdTable>;
  /** 导入模式 */
  importMode?: Maybe<Scalars['ValuedEnum']>;
  /** 导入列名 */
  importColumns: Array<Maybe<Scalars['String']>>;
  /** 导入原始数据 */
  rawData: Array<Maybe<Array<Maybe<Scalars['String']>>>>;
  /** 导入原始数据数量 */
  rawDataCount?: Maybe<Scalars['Int']>;
  /** 映射关系 */
  mappings?: Maybe<Array<Maybe<MdTableImportMappingEntry>>>;
  /** 导入设置 */
  options?: Maybe<Scalars['Json']>;
  /** 导入预览调整数据 */
  deltaData?: Maybe<Array<Maybe<MdTableImportData>>>;
  /** 导入流程进行到哪一步 */
  currentStep?: Maybe<Scalars['Int']>;
};

/** 智能表格导入状态 */
export type MdTableImportContextRawDataArgs = {
  rows?: Maybe<Scalars['Int']>;
};

/** 智能表格导入状态 */
export type MdTableImportContextDeltaDataArgs = {
  deltaStates?: Maybe<Array<Maybe<Scalars['ValuedEnum']>>>;
};

/** 智能表格导入数据 */
export type MdTableImportData = {
  /** 导入数据行id */
  importDataId?: Maybe<Scalars['ID']>;
  /** 导入数据 */
  importValues?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 导入数据预览 */
  importDataPreview?: Maybe<MdTableData>;
  /** 表格数据 */
  tableData?: Maybe<MdTableData>;
  /** 差异状态 */
  deltaState?: Maybe<Scalars['ValuedEnum']>;
  /** 是否保存变更 */
  merging: Scalars['Boolean'];
  /** 错误信息 */
  errors?: Maybe<Array<Maybe<ErrorInfo>>>;
};

export type MdTableImportFromSqlPreview = {
  columns: Array<Maybe<Scalars['String']>>;
  values: Array<Maybe<Array<Maybe<Scalars['String']>>>>;
  size: Scalars['Int'];
};

/** 智能表格导入映射配置 */
export type MdTableImportMappingEntry = {
  /** 是否对齐的主键 */
  alignKey?: Maybe<Scalars['Boolean']>;
  /** 保持不变 */
  ignoreChange?: Maybe<Scalars['Boolean']>;
  /** 导入列名 */
  importingColumn?: Maybe<Scalars['String']>;
  /** 对应的表格列 */
  mdTableColumn?: Maybe<MdTableColumn>;
  /** 对应的表格列名 */
  mdTableColName?: Maybe<Scalars['String']>;
};

export type MdTableImportMappingInput = {
  /** 导入列名 */
  importingColumn?: Maybe<Scalars['String']>;
  /** 对应的表格列 */
  mdTableColName?: Maybe<Scalars['String']>;
  /** 是否对齐的主键 */
  alignKey?: Maybe<Scalars['Boolean']>;
  /** 是否保持不变 */
  ignoreChange?: Maybe<Scalars['Boolean']>;
};

/** 智能表格导入映射预设配置 */
export type MdTableImportMappingPreset = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 名称 */
  name: Scalars['String'];
  /** 映射配置 */
  mappings: Array<Maybe<MdTableImportMappingEntry>>;
  /** 导入选项 */
  options?: Maybe<Scalars['Json']>;
};

export type MdTableImportResult = {
  importCount: Scalars['Int'];
  insertCount: Scalars['Int'];
  updateCount: Scalars['Int'];
  deleteCount: Scalars['Int'];
  dropCount: Scalars['Int'];
  importCfgYaml?: Maybe<Scalars['String']>;
};

export type MdTablePage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdTable>>>;
};

export type MdTableVisitHistory = {
  mdTable: MdTable;
  visitAt: Scalars['String'];
};

/** 账目流水 */
export type MdTally = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 业务主题 */
  topic: Scalars['String'];
  /** 标识 */
  identity: Scalars['String'];
  /** 编号 */
  code: Scalars['String'];
  /** 流水 */
  seq?: Maybe<Scalars['Int']>;
  /** 数量 */
  qty: Scalars['Float'];
  /** 调整后数量 */
  adjustedQty?: Maybe<Scalars['Float']>;
  /** 本次记账类型 */
  accountType?: Maybe<Scalars['ValuedEnum']>;
  /** 本次结余 */
  surplus?: Maybe<Scalars['Float']>;
  /** 单位 */
  unit: MdUnit;
  /** 发起时间 */
  appliedAt?: Maybe<Scalars['DateTime']>;
  /** 操作时间 */
  operatedAt: Scalars['DateTime'];
  /** 摘要 */
  summary?: Maybe<Scalars['String']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 业务信息 */
  info?: Maybe<Scalars['Map']>;
  /** 调整流水 */
  adjustTargetTally?: Maybe<MdTally>;
};

/** 账套 */
export type MdTallyAccountSet = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 编号 */
  code: Scalars['String'];
  /** 名称 */
  name: Scalars['String'];
  /** 业务主题 */
  topic: Scalars['String'];
  /** 是否该业务分组的默认账套 */
  defaultForTopic: Scalars['Boolean'];
  /** 特征 */
  featureKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 其它业务信息 */
  extraKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 记账配置 */
  matchers?: Maybe<Array<Maybe<MdTallyAccountTypeMatcher>>>;
  /** 是否允许负数 */
  allowNegative: Scalars['Boolean'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 内置 */
  builtin: Scalars['Boolean'];
};

export type MdTallyAccountSetGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdTallyAccountSetInput = {
  name: Scalars['String'];
  topic: Scalars['String'];
  defaultForTopic?: Maybe<Scalars['Boolean']>;
  featureKeys: Array<Maybe<Scalars['String']>>;
  extraKeys: Array<Maybe<Scalars['String']>>;
  matchers: Array<Maybe<MdTallyAccountTypeMatcherInput>>;
  allowNegative?: Maybe<Scalars['Boolean']>;
  builtin?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
};

export type MdTallyAccountSetPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdTallyAccountSet>>>;
};

export type MdTallyAccountTypeMatcher = {
  key: Scalars['String'];
  value: Scalars['String'];
  accountType: Scalars['ValuedEnum'];
};

export type MdTallyAccountTypeMatcherInput = {
  key: Scalars['String'];
  value: Scalars['String'];
  accountType: Scalars['ValuedEnum'];
};

export type MdTallyFlowSurplus = {
  tallyId: Scalars['ID'];
  surplus: Scalars['Float'];
  accountType: Scalars['ValuedEnum'];
};

/** 计数流水分页数据 */
export type MdTallyPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdTally>>>;
};

/** 账目结余 */
export type MdTallySurplus = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 业务主题 */
  topic: Scalars['String'];
  /** 当前结余 */
  surplus: Scalars['Float'];
  /** 总借(加) */
  debitQty: Scalars['Float'];
  /** 总贷(减) */
  creditQty: Scalars['Float'];
  /** 单位 */
  unit?: Maybe<MdUnit>;
  /** 结余特征 */
  features?: Maybe<Scalars['Map']>;
  /** 最早操作时间 */
  firstOperatedAt?: Maybe<Scalars['DateTime']>;
  /** 最近操作时间 */
  lastOperatedAt?: Maybe<Scalars['DateTime']>;
  /** 补充信息 */
  extra?: Maybe<Scalars['Map']>;
  /** 核销状态 */
  debtState: Scalars['ValuedEnum'];
};

/** 计数结余分页数据 */
export type MdTallySurplusPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdTallySurplus>>>;
};

/** 汇总账目结余 */
export type MdTallyTimeSerialSurplus = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 当期开始时间 */
  rangeStartAt?: Maybe<Scalars['DateTime']>;
  /** 本期合计 */
  currentTotal: Scalars['Float'];
  /** 本期借(加)合计 */
  currentDebitTotal: Scalars['Float'];
  /** 本期贷(减)合计 */
  currentCreditTotal: Scalars['Float'];
  /** 当前结余 */
  surplus: Scalars['Float'];
  /** 总借(加) */
  debitQty: Scalars['Float'];
  /** 总贷(减) */
  creditQty: Scalars['Float'];
  /** 单位 */
  unit?: Maybe<MdUnit>;
  /** 结余特征 */
  features?: Maybe<Scalars['Map']>;
  /** 最早操作时间 */
  firstOperatedAt?: Maybe<Scalars['DateTime']>;
  /** 最近操作时间 */
  lastOperatedAt?: Maybe<Scalars['DateTime']>;
  /** 补充信息 */
  extra?: Maybe<Scalars['Map']>;
  /** 核销状态 */
  debtState: Scalars['ValuedEnum'];
};

/** 计数结余分页数据 */
export type MdTallyTimeSerialSurplusPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdTallyTimeSerialSurplus>>>;
};

/** 单位 */
export type MdUnit = CodeBasedModel & {
  code: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
};

/** 单位转换关系 */
export type MdUnitConversion = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 源单位 */
  sourceUnit: MdUnit;
  /** 目标单位 */
  targetUnit: MdUnit;
  /** 换算因子 */
  factor: Scalars['Float'];
};

export type MdUnitConversionGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdUnitConversionPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdUnitConversion>>>;
};

export type MdUnitGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdUnitPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdUnit>>>;
};

/** 主数据校验规则 */
export type MdValidationRule = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 名称 */
  name: Scalars['String'];
  /** 描述 */
  description?: Maybe<Scalars['String']>;
  /** 校验器类型 */
  type?: Maybe<Scalars['ValuedEnum']>;
  /** 数据模型 */
  targetModel: Scalars['String'];
  /** 数据过滤条件 */
  targetFilterCondition?: Maybe<Scalars['Json']>;
  /** 来源功能模块 */
  realmCode?: Maybe<Scalars['String']>;
  /** 来源业务代码 */
  providerCode: Scalars['String'];
  /** 保存时校验 */
  checkOnSave: Scalars['Boolean'];
  /** 是否启用 */
  enabled: Scalars['Boolean'];
  /** 额外信息 */
  extra?: Maybe<Scalars['Json']>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** 主数据校验规则分页数据 */
export type MdValidationRulePage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<MdValidationRule>>;
};

/** BOM结构元件 */
export type MdaBomEntry = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 所属结构 */
  structureBom: MdaStructure;
  /** 序号 */
  seq: Scalars['Int'];
  /** 元件类别 */
  category: MdCategoryInfo;
  /** 元件属性 */
  attributes?: Maybe<Array<MdAttribute>>;
  /** 用量 */
  count?: Maybe<Scalars['Float']>;
  /** 底数 */
  countBase?: Maybe<Scalars['Int']>;
  /** 占比 */
  weight?: Maybe<Scalars['Float']>;
  /** 单位 */
  unit: MdUnit;
  /** 可选 */
  optional: Scalars['Boolean'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
};

/** 客户产品库 */
export type MdaCustomerCatalog = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 客户 */
  customer: MdeCustomer;
  /** 类别编号 */
  categoryKey: Scalars['String'];
  /** 类别名称 */
  categoryKeyValue: Scalars['String'];
  /** 型号 */
  productModel: Scalars['String'];
  /** 成品品名 */
  mdDataNames: Scalars['String'];
  /** 成品规格 */
  mdDataSpecs: Scalars['String'];
  /** 客户产品信息 */
  attributes?: Maybe<Array<MdAttribute>>;
  /** 混装分组 */
  mixinGroup?: Maybe<Scalars['String']>;
  /** 购买次数 */
  purchaseQty?: Maybe<Scalars['Int']>;
  /** 订单总量 */
  orderQty?: Maybe<Scalars['Float']>;
  /** 最后下单时间 */
  lastOrderDate?: Maybe<Scalars['DateTime']>;
  /** 成品品号 */
  mdDataCodes: Scalars['String'];
  /** 客户产品库特征 */
  dump?: Maybe<Scalars['String']>;
};

export type MdaCustomerCatalogGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdaCustomerCatalogPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdaCustomerCatalog>>>;
};

/** 客户采购历史 */
export type MdaCustomerEndProduct = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 订单号 */
  productOrderCode: Scalars['String'];
  /** 客户 */
  customer: MdeCustomer;
  /** 类别名称 */
  categoryKeyValue: Scalars['String'];
  /** 型号 */
  productModel: Scalars['String'];
  /** 混装分组 */
  mixinGroup?: Maybe<Scalars['String']>;
  /** 混装比例 */
  mixinRates?: Maybe<Scalars['String']>;
  /** 成品品名 */
  mdDataNames: Scalars['String'];
  /** 成品规格 */
  mdDataSpecs: Scalars['String'];
  /** 订单数量 */
  orderQty?: Maybe<Scalars['Float']>;
  /** 客户产品信息 */
  attributes?: Maybe<Array<MdAttribute>>;
  /** 成品品号 */
  mdDataCodes: Scalars['String'];
  /** 预交时间 */
  deliveryDate?: Maybe<Scalars['DateTime']>;
  /** 下单时间 */
  orderConfirmDate?: Maybe<Scalars['DateTime']>;
  /** 类别编号 */
  categoryKey: Scalars['String'];
  /** 下单人员 */
  principals?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** 序号 */
  seqs?: Maybe<Scalars['String']>;
  /** 客户产品库特征 */
  dump?: Maybe<Scalars['String']>;
  /** 成品状态 */
  state?: Maybe<Scalars['String']>;
};

export type MdaCustomerEndProductGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdaCustomerEndProductPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdaCustomerEndProduct>>>;
};

/** 注塑镜片分析结果 */
export type MdaLensColour = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 数据编号 */
  code?: Maybe<Scalars['String']>;
  /** 材质编号 */
  materialKey: Scalars['String'];
  /** 色号 */
  colourCode: Scalars['String'];
  /** 材质名称 */
  materialValue: Scalars['String'];
  /** 自定义色系 */
  customColourFamily?: Maybe<Scalars['String']>;
  /** 颜色名称 */
  colourName: Scalars['String'];
  /** 产品图片 */
  picture?: Maybe<Scalars['File']>;
  /** 色卡 */
  colourRGB?: Maybe<Scalars['String']>;
  /** panton */
  panton?: Maybe<Scalars['String']>;
  /** 是否常用 */
  commonUsed: Scalars['Boolean'];
  /** 最小起订量 */
  moq?: Maybe<Scalars['Float']>;
  /** 销量 */
  salesVolume?: Maybe<Scalars['Float']>;
  /** 购买客户 */
  involvedCustomerCount?: Maybe<Scalars['Int']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 色号 */
  mdeColour?: Maybe<MdeColour>;
  /** 透明效果 */
  transparency?: Maybe<MdDictionaryEntry>;
  /** 颜色组合 */
  combination?: Maybe<MdDictionaryEntry>;
  /** 色系 */
  colourFamily?: Maybe<MdDictionaryEntry>;
  /** 是否显示 */
  shown: Scalars['Boolean'];
};

export type MdaLensColourGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdaLensColourPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdaLensColour>>>;
};

/** 注塑镜片电镀颜色分析结果 */
export type MdaLensElectroplateColour = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 数据编号 */
  code?: Maybe<Scalars['String']>;
  /** 电镀编号 */
  electroplateKey: Scalars['String'];
  /** 色号 */
  colourCode: Scalars['String'];
  /** 电镀名称 */
  electroplateValue: Scalars['String'];
  /** 自定义色系 */
  customColourFamily?: Maybe<Scalars['String']>;
  /** 颜色名称 */
  colourName: Scalars['String'];
  /** 产品图片 */
  picture?: Maybe<Scalars['File']>;
  /** 色卡 */
  colourRGB?: Maybe<Scalars['String']>;
  /** panton */
  panton?: Maybe<Scalars['String']>;
  /** 是否常用 */
  commonUsed: Scalars['Boolean'];
  /** 最小起订量 */
  moq?: Maybe<Scalars['Float']>;
  /** 销量 */
  salesVolume?: Maybe<Scalars['Float']>;
  /** 购买客户 */
  involvedCustomerCount?: Maybe<Scalars['Int']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 色号 */
  mdeColour?: Maybe<MdeColour>;
  /** 透明效果 */
  transparency?: Maybe<MdDictionaryEntry>;
  /** 颜色组合 */
  combination?: Maybe<MdDictionaryEntry>;
  /** 色系 */
  colourFamily?: Maybe<MdDictionaryEntry>;
  /** 是否显示 */
  shown: Scalars['Boolean'];
};

export type MdaLensElectroplateColourGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdaLensElectroplateColourPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdaLensElectroplateColour>>>;
};

/** 注塑镜片型号颜色分析结果 */
export type MdaLensProductModelColour = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 数据编号 */
  code?: Maybe<Scalars['String']>;
  /** 产品型号编号 */
  productModelKey: Scalars['String'];
  /** 色号 */
  colourCode: Scalars['String'];
  /** 产品型号名称 */
  productModelValue: Scalars['String'];
  /** 自定义色系 */
  customColourFamily?: Maybe<Scalars['String']>;
  /** 颜色名称 */
  colourName: Scalars['String'];
  /** 产品图片 */
  picture?: Maybe<Scalars['File']>;
  /** 色卡 */
  colourRGB?: Maybe<Scalars['String']>;
  /** panton */
  panton?: Maybe<Scalars['String']>;
  /** 是否常用 */
  commonUsed: Scalars['Boolean'];
  /** 最小起订量 */
  moq?: Maybe<Scalars['Float']>;
  /** 销量 */
  salesVolume?: Maybe<Scalars['Float']>;
  /** 购买客户 */
  involvedCustomerCount?: Maybe<Scalars['Int']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 色号 */
  mdeColour?: Maybe<MdeColour>;
  /** 透明效果 */
  transparency?: Maybe<MdDictionaryEntry>;
  /** 颜色组合 */
  combination?: Maybe<MdDictionaryEntry>;
  /** 色系 */
  colourFamily?: Maybe<MdDictionaryEntry>;
  /** 是否显示 */
  shown: Scalars['Boolean'];
};

export type MdaLensProductModelColourGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdaLensProductModelColourPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdaLensProductModelColour>>>;
};

/** 产品销量分析 */
export type MdaSalesVolume = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  customerCode?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  salesYear?: Maybe<Scalars['String']>;
  salesVolumeYearly?: Maybe<Scalars['Float']>;
};

export type MdaSalesVolumeGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdaSalesVolumePage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdaSalesVolume>>>;
};

/** 产品结构 */
export type MdaStructure = IdBasedModel & {
  id?: Maybe<Scalars['ID']>;
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 类别 */
  category: MdCategoryInfo;
  /** 名称 */
  name: Scalars['String'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 属性 */
  attributes?: Maybe<Array<MdAttribute>>;
  /** 结构Bom元件 */
  bomEntries?: Maybe<Array<MdaBomEntry>>;
  /** Bom元件个数 */
  bomEntryCount?: Maybe<Scalars['Int']>;
  /** 主图片 */
  mainPicture?: Maybe<Scalars['File']>;
  /** 产品图片 */
  pictures?: Maybe<Array<Scalars['File']>>;
  /** 属性dump */
  attrDump?: Maybe<Scalars['String']>;
  /** BOM结构dump */
  bomDump?: Maybe<Scalars['String']>;
  /** 关联主数据 */
  mdDataCount: Scalars['Int'];
  /** 关联主数据 */
  mdDatas?: Maybe<Array<Maybe<MdaStructureToMdData>>>;
  extra?: Maybe<Scalars['Json']>;
};

export type MdaStructureGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdaStructurePage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdaStructure>>>;
};

/** 产品结构字段 */
export type MdaStructureTemplate = {
  /** 类别 */
  category: MdCategoryInfo;
  /** 字段 */
  mdFields: Array<MdField>;
};

/** 结构关联的主数据 */
export type MdaStructureToMdData = {
  /** 结构 */
  mdaStructure: MdaStructure;
  /** 品号 */
  mdDataCode: Scalars['String'];
  /** 品名 */
  mdDataName: Scalars['String'];
  /** 规格 */
  mdDataSpec: Scalars['String'];
  /** 主数据 */
  mdData?: Maybe<MdData>;
};

export type MdaStructureToMdDataGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdaStructureToMdDataPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdaStructureToMdData>>>;
};

/** 主数据检索条件 */
export type MdcDataSearch = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 类别 */
  category: MdCategoryInfo;
  /** 产品检索 */
  productSearch?: Maybe<Scalars['Boolean']>;
  /** 包材检索 */
  packingSearch?: Maybe<Scalars['Boolean']>;
  /** 主件检索字段 */
  searchFields?: Maybe<Array<MdField>>;
  /** 子件检索条件 */
  bomEntrySearchItems?: Maybe<Array<MdcDataSearchBomEntryItem>>;
};

/** 主数据子件检索条件 */
export type MdcDataSearchBomEntryItem = {
  /** 类别 */
  category: MdCategoryInfo;
  /** 检索字段 */
  entrySearchFields?: Maybe<Array<MdField>>;
};

export type MdcDataSearchGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdcDataSearchPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdcDataSearch>>>;
};

export type MdcDataSearchValues = {
  productSearch?: Maybe<Scalars['Boolean']>;
  packingSearch?: Maybe<Scalars['Boolean']>;
  searchFieldIds?: Maybe<Array<Scalars['ID']>>;
  bomEntrySearchItems?: Maybe<Array<BomEntrySearchItemInput>>;
};

/** 地址信息 */
export type MdeAddress = {
  /** 公司名称 */
  companyName: Scalars['String'];
  /** 地址 */
  address: Scalars['String'];
  /** 默认地址 */
  defaultAddress?: Maybe<Scalars['Boolean']>;
  /** 联系人 */
  contactName?: Maybe<Scalars['String']>;
  /** 联系电话 */
  contactNumber?: Maybe<Scalars['String']>;
  /** 电子邮箱 */
  email?: Maybe<Scalars['String']>;
  /** 传真 */
  fax?: Maybe<Scalars['String']>;
  /** 国家/地区 */
  area?: Maybe<Scalars['String']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
};

export type MdeAddressInput = {
  /** 公司名称 */
  companyName?: Maybe<Scalars['String']>;
  /** 地址 */
  address?: Maybe<Scalars['String']>;
  /** 默认地址 */
  defaultAddress?: Maybe<Scalars['Boolean']>;
  /** 联系人 */
  contactName?: Maybe<Scalars['String']>;
  /** 联系电话 */
  contactNumber?: Maybe<Scalars['String']>;
  /** 电子邮箱 */
  email?: Maybe<Scalars['String']>;
  /** 传真 */
  fax?: Maybe<Scalars['String']>;
  /** 国家/地区 */
  area?: Maybe<Scalars['String']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
};

/** 色号 */
export type MdeColour = CodeBasedModel & {
  code: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 颜色名称 */
  name: Scalars['String'];
  /** panton */
  pantonCode?: Maybe<Scalars['String']>;
  /** 工艺 */
  craft?: Maybe<Scalars['String']>;
  /** 透明效果 */
  transparency?: Maybe<MdDictionaryEntry>;
  /** 颜色组合 */
  combination?: Maybe<MdDictionaryEntry>;
  /** 颜色RGB值 */
  rgb?: Maybe<Scalars['String']>;
  /** 颜色排序权重 */
  sortWeight?: Maybe<Scalars['Float']>;
  /** 色度 */
  hue?: Maybe<Scalars['Float']>;
  /** 包和度 */
  saturation?: Maybe<Scalars['Float']>;
  /** 亮度 */
  brightness?: Maybe<Scalars['Float']>;
  /** 色系 */
  colorFamily?: Maybe<MdDictionaryEntry>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
};

export type MdeColourGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdeColourPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdeColour>>>;
};

/** 币种 */
export type MdeCurrency = CodeBasedModel & {
  code: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 名称 */
  name: Scalars['String'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 币种符号 */
  symbol?: Maybe<Scalars['String']>;
  /** 供应商默认币种 */
  supplierDefault?: Maybe<Scalars['Boolean']>;
  /** 客户商默认币种 */
  customerDefault?: Maybe<Scalars['Boolean']>;
  /** 生效的汇率 */
  exchangeRate?: Maybe<MdeCurrencyExchangeRate>;
};

/** 汇率 */
export type MdeCurrencyExchangeRate = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 买进汇率 */
  buyInRate?: Maybe<Scalars['Float']>;
  /** 报关汇率 */
  clearanceRate?: Maybe<Scalars['Float']>;
  /** 生效日期 */
  effectiveDate?: Maybe<Scalars['DateTime']>;
};

export type MdeCurrencyGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdeCurrencyInput = {
  /** 名称 */
  name?: Maybe<Scalars['String']>;
  /** 描述 */
  note?: Maybe<Scalars['String']>;
  /** 币种符号 */
  symbol?: Maybe<Scalars['String']>;
  /** 供应商默认币种 */
  supplierDefault?: Maybe<Scalars['Boolean']>;
  /** 客户商默认币种 */
  customerDefault?: Maybe<Scalars['Boolean']>;
};

export type MdeCurrencyPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdeCurrency>>>;
};

/** 客户 */
export type MdeCustomer = CodeBasedModel & {
  code: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 名称 */
  name: Scalars['String'];
  /** 全称 */
  fullName?: Maybe<Scalars['String']>;
  /** 地址 */
  address?: Maybe<Array<Maybe<MdeAddress>>>;
  /** 付款条件 */
  paymentCondition?: Maybe<MdDictionaryEntry>;
  /** 交易币种 */
  currency?: Maybe<MdeCurrency>;
  /** 税种 */
  taxType?: Maybe<Scalars['ValuedEnum']>;
  /** 税率 */
  taxRate?: Maybe<Scalars['Float']>;
  /** 定金比例 */
  depositRate?: Maybe<Scalars['Float']>;
  /** 负责人 */
  manager?: Maybe<Scalars['String']>;
  /** 联系人 */
  contact?: Maybe<Scalars['String']>;
  /** 联系电话 */
  contactNumber?: Maybe<Scalars['String']>;
  /** 电子邮件 */
  email?: Maybe<Scalars['String']>;
  /** 传真号 */
  faxNumber?: Maybe<Scalars['String']>;
  /** 国家/地区 */
  country?: Maybe<Scalars['String']>;
  /** 汇款银行 */
  remittingBank?: Maybe<Scalars['String']>;
  /** 汇款帐号 */
  remittingAccount?: Maybe<Scalars['String']>;
  /** 税号 */
  taxAccount?: Maybe<Scalars['String']>;
  /** 联系地址 */
  contactAddress?: Maybe<Scalars['String']>;
  /** 帐单地址 */
  billAddress?: Maybe<Scalars['String']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MdeCustomerGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdeCustomerInput = {
  /** 名称 */
  name: Scalars['String'];
  /** 全称 */
  fullName?: Maybe<Scalars['String']>;
  /** 付款条件 */
  paymentConditionId?: Maybe<Scalars['ID']>;
  /** 交易币种 */
  currencyCode?: Maybe<Scalars['String']>;
  /** 税种 */
  taxType?: Maybe<Scalars['Int']>;
  /** 税种 */
  taxRate?: Maybe<Scalars['Float']>;
  /** 定金比例 */
  depositRate?: Maybe<Scalars['Float']>;
  /** 负责人 */
  manager?: Maybe<Scalars['String']>;
  /** 联系人 */
  contact?: Maybe<Scalars['String']>;
  /** 联系电话 */
  contactNumber?: Maybe<Scalars['String']>;
  /** 电子邮件 */
  email?: Maybe<Scalars['String']>;
  /** 传真号 */
  faxNumber?: Maybe<Scalars['String']>;
  /** 国家/地区 */
  country?: Maybe<Scalars['String']>;
  /** 汇款银行 */
  remittingBank?: Maybe<Scalars['String']>;
  /** 汇款帐号 */
  remittingAccount?: Maybe<Scalars['String']>;
  /** 税号 */
  taxAccount?: Maybe<Scalars['String']>;
  /** 联系地址 */
  contactAddress?: Maybe<Scalars['String']>;
  /** 帐单地址 */
  billAddress?: Maybe<Scalars['String']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 地址 */
  address?: Maybe<Array<Maybe<MdeAddressInput>>>;
};

export type MdeCustomerPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdeCustomer>>>;
};

/** 币种 */
export type MdeIncoterms = CodeBasedModel & {
  code: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 名称 */
  name: Scalars['String'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
};

export type MdeIncotermsGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdeIncotermsInput = {
  /** 名称 */
  name?: Maybe<Scalars['String']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
};

export type MdeIncotermsPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdeIncoterms>>>;
};

/** 供应商 */
export type MdeSupplier = CodeBasedModel & {
  code: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 名称 */
  name: Scalars['String'];
  /** 地址 */
  address?: Maybe<Array<Maybe<MdeAddress>>>;
  /** 全称 */
  fullName?: Maybe<Scalars['String']>;
  /** 付款条件 */
  paymentCondition?: Maybe<MdDictionaryEntry>;
  /** 交易币种 */
  currency?: Maybe<MdeCurrency>;
  /** 税种 */
  taxType?: Maybe<Scalars['ValuedEnum']>;
  /** 税率 */
  taxRate?: Maybe<Scalars['Float']>;
  /** 定金比例 */
  depositRate?: Maybe<Scalars['Float']>;
  /** 负责人 */
  manager?: Maybe<Scalars['String']>;
  /** 联系人 */
  contact?: Maybe<Scalars['String']>;
  /** 联系电话 */
  contactNumber?: Maybe<Scalars['String']>;
  /** 电子邮件 */
  email?: Maybe<Scalars['String']>;
  /** 传真号 */
  faxNumber?: Maybe<Scalars['String']>;
  /** 国家/地区 */
  country?: Maybe<Scalars['String']>;
  /** 汇款银行 */
  remittingBank?: Maybe<Scalars['String']>;
  /** 汇款帐号 */
  remittingAccount?: Maybe<Scalars['String']>;
  /** 税号 */
  taxAccount?: Maybe<Scalars['String']>;
  /** 采购人员 */
  purchaser?: Maybe<Scalars['String']>;
  /** 联系地址 */
  contactAddress?: Maybe<Scalars['String']>;
  /** 帐单地址 */
  billAddress?: Maybe<Scalars['String']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MdeSupplierGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdeSupplierInput = {
  /** 名称 */
  name?: Maybe<Scalars['String']>;
  /** 全称 */
  fullName?: Maybe<Scalars['String']>;
  /** 付款条件 */
  paymentConditionId?: Maybe<Scalars['ID']>;
  /** 交易币种 */
  currencyCode?: Maybe<Scalars['String']>;
  /** 税种 */
  taxType?: Maybe<Scalars['Int']>;
  /** 税种 */
  taxRate?: Maybe<Scalars['Float']>;
  /** 定金比例 */
  depositRate?: Maybe<Scalars['Float']>;
  /** 负责人 */
  manager?: Maybe<Scalars['String']>;
  /** 联系人 */
  contact?: Maybe<Scalars['String']>;
  /** 联系电话 */
  contactNumber?: Maybe<Scalars['String']>;
  /** 电子邮件 */
  email?: Maybe<Scalars['String']>;
  /** 传真号 */
  faxNumber?: Maybe<Scalars['String']>;
  /** 国家/地区 */
  country?: Maybe<Scalars['String']>;
  /** 汇款银行 */
  remittingBank?: Maybe<Scalars['String']>;
  /** 汇款帐号 */
  remittingAccount?: Maybe<Scalars['String']>;
  /** 税号 */
  taxAccount?: Maybe<Scalars['String']>;
  /** 采购人员 */
  purchaser?: Maybe<Scalars['String']>;
  /** 联系地址 */
  contactAddress?: Maybe<Scalars['String']>;
  /** 帐单地址 */
  billAddress?: Maybe<Scalars['String']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 地址 */
  address?: Maybe<Array<Maybe<MdeAddressInput>>>;
};

export type MdeSupplierPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdeSupplier>>>;
};

/** 仓库 */
export type MdeWarehouse = CodeBasedModel & {
  code: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 仓库名称 */
  name: Scalars['String'];
  /** 仓库地址 */
  address?: Maybe<Scalars['String']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 线边仓 */
  lineSide?: Maybe<Scalars['Boolean']>;
  /** 允许超额出库 */
  allowExceed?: Maybe<Scalars['Boolean']>;
  /** 纳入可用量计算 */
  countAsAvailable?: Maybe<Scalars['Boolean']>;
  /** 次品仓 */
  defect?: Maybe<Scalars['Boolean']>;
  /** 仓库上限 */
  limitation?: Maybe<Scalars['Float']>;
};

export type MdeWarehouseGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

/**
 * ##############################################################################
 *  Inputs
 * ##############################################################################
 */
export type MdeWarehouseInput = {
  /** 仓库名称 */
  name?: Maybe<Scalars['String']>;
  /** 仓库地址 */
  address?: Maybe<Scalars['String']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 线边仓 */
  lineSide?: Maybe<Scalars['Boolean']>;
  /** 允许超额出库 */
  allowExceed?: Maybe<Scalars['Boolean']>;
  /** 纳入可用量计算 */
  countAsAvailable?: Maybe<Scalars['Boolean']>;
  /** 次品仓 */
  defect?: Maybe<Scalars['Boolean']>;
  /** 仓库上限 */
  limitation?: Maybe<Scalars['Float']>;
};

export type MdeWarehousePage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdeWarehouse>>>;
};

/** 迁移Bom元件 */
export type MdmBomInboundEntry = {
  /** 元件品号 */
  code?: Maybe<Scalars['String']>;
  /** 元件品名 */
  name?: Maybe<Scalars['String']>;
  /** 元件规格 */
  spec?: Maybe<Scalars['String']>;
  /** 用量 */
  count?: Maybe<Scalars['Float']>;
  /** 用量基数 */
  countBase?: Maybe<Scalars['Int']>;
  /** 单位 */
  unit?: Maybe<Scalars['String']>;
  /** 序号 */
  seq?: Maybe<Scalars['Int']>;
};

/** BOM迁移状态 */
export type MdmBomInboundView = CodeBasedModel & {
  code: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 是否一致 */
  consistent?: Maybe<Scalars['Boolean']>;
  /** 类别 */
  category: MdCategoryInfo;
  /** 主数据名称 */
  name?: Maybe<Scalars['String']>;
  /** 主数据规格 */
  spec?: Maybe<Scalars['String']>;
  /** Bom元件 */
  entries?: Maybe<Array<MdmBomInboundEntry>>;
  /** bomDump */
  bomDump?: Maybe<Scalars['String']>;
  /** 原uid */
  bomUID?: Maybe<Scalars['String']>;
  /** 原创建人 */
  bomCreatedBy?: Maybe<Scalars['String']>;
  /** 原创建时间 */
  bomCreatedAt?: Maybe<Scalars['DateTime']>;
  /** 原更新人员 */
  bomUpdatedBy?: Maybe<Scalars['String']>;
  /** 原更新时间 */
  bomUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** 是否需要迁移 */
  needSync?: Maybe<Scalars['Boolean']>;
  /** 迁移人员 */
  operator?: Maybe<Scalars['String']>;
  /** 迁移时间 */
  operateAt?: Maybe<Scalars['DateTime']>;
  /** 迁移结果 */
  migResult?: Maybe<Scalars['ValuedEnum']>;
  /** 失败原因 */
  migFailedReason?: Maybe<Scalars['String']>;
};

export type MdmBomInboundViewGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdmBomInboundViewPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdmBomInboundView>>>;
};

/** 主数据迁移状态 */
export type MdmDataInboundView = CodeBasedModel & {
  code: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 是否一致 */
  consistent?: Maybe<Scalars['Boolean']>;
  /** 类别 */
  category: MdCategoryInfo;
  /** 外部数据名称 */
  name?: Maybe<Scalars['String']>;
  /** 外部数据规格 */
  spec?: Maybe<Scalars['String']>;
  /** 外部数据属性 */
  attributes?: Maybe<Array<MdAttribute>>;
  selectAttributes?: Maybe<Array<MdAttribute>>;
  /** 原uid */
  dataUID?: Maybe<Scalars['String']>;
  /** 原创建人 */
  dataCreatedBy?: Maybe<Scalars['String']>;
  /** 原创建时间 */
  dataCreatedAt?: Maybe<Scalars['DateTime']>;
  /** 原更新人员 */
  dataUpdatedBy?: Maybe<Scalars['String']>;
  /** 原更新时间 */
  dataUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** 是否需要迁移 */
  needSync?: Maybe<Scalars['Boolean']>;
  /** 迁移人员 */
  operator?: Maybe<Scalars['String']>;
  /** 迁移时间 */
  operateAt?: Maybe<Scalars['DateTime']>;
  /** 迁移结果 */
  migResult?: Maybe<Scalars['ValuedEnum']>;
  /** 失败原因 */
  migFailedReason?: Maybe<Scalars['String']>;
};

/** 主数据迁移状态 */
export type MdmDataInboundViewSelectAttributesArgs = {
  mdFieldIds?: Maybe<Array<Scalars['ID']>>;
};

export type MdmDataInboundViewGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdmDataInboundViewPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdmDataInboundView>>>;
};

/** 主数据重构记录 */
export type MdmDataRefactor = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 记录批次 */
  mdmDataRefactorBatch?: Maybe<MdmDataRefactorBatch>;
  /** 品号 */
  mdDataCode: Scalars['String'];
  /** 品名 */
  mdDataName: Scalars['String'];
  /** 规格 */
  mdDataSpec: Scalars['String'];
  /** 变更前属性 */
  originAttributes?: Maybe<Array<MdAttribute>>;
  /** 变更后属性 */
  resultAttributes?: Maybe<Array<MdAttribute>>;
  /** 关键属性 */
  keyAttributes?: Maybe<Array<MdAttribute>>;
  /** 处理时间 */
  processAt?: Maybe<Scalars['DateTime']>;
  /** 处理人员 */
  processBy?: Maybe<Scalars['String']>;
  /** 处理状态 */
  status: Scalars['ValuedEnum'];
  /** 是否有变化 */
  changed: Scalars['Boolean'];
  /** 错误信息 */
  errors?: Maybe<Array<Maybe<ErrorInfo>>>;
};

/** 主数据重构记录批次 */
export type MdmDataRefactorBatch = CodeBasedModel & {
  code: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 脚本名称 */
  mdScriptProcessorName?: Maybe<Scalars['String']>;
  /** 脚本处理状态 */
  mdScriptProcessorStatus?: Maybe<Scalars['ValuedEnum']>;
  /** 处理数据条数 */
  executedCount?: Maybe<Scalars['Int']>;
  /** 开始时间 */
  startTime: Scalars['DateTime'];
  /** 结束时间 */
  endTime?: Maybe<Scalars['DateTime']>;
  /** 执行人 */
  executeBy: Scalars['String'];
  /** 自动确认 */
  autoConfirm?: Maybe<Scalars['Boolean']>;
};

export type MdmDataRefactorBatchGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdmDataRefactorBatchPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdmDataRefactorBatch>>>;
};

export type MdmDataRefactorGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdmDataRefactorPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdmDataRefactor>>>;
};

/** 选项表迁移状态 */
export type MdmDictionaryEntryInboundView = CodeBasedModel & {
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 选项编号(待迁移) */
  entryCodeLhs: Scalars['String'];
  /** 选项值(待迁移) */
  entryValueLhs: Scalars['String'];
  /** 简称(待迁移) */
  entryValueForShortLhs?: Maybe<Scalars['String']>;
  /** 选项描述(待迁移) */
  entryNoteLhs?: Maybe<Scalars['String']>;
  /** 序号(待迁移) */
  entrySeqLhs: Scalars['Int'];
  /** 选项表名称(待迁移) */
  dictNameLhs: Scalars['String'];
  /** 选项表描述(待迁移) */
  dictNoteLhs?: Maybe<Scalars['String']>;
  code: Scalars['ID'];
  /** 选项表类型 */
  dictType: Scalars['String'];
  /** 原uid */
  entryUID?: Maybe<Scalars['String']>;
  /** 原创建人 */
  entryCreatedBy?: Maybe<Scalars['String']>;
  /** 原创建时间 */
  entryCreatedAt?: Maybe<Scalars['DateTime']>;
  /** 原更新人员 */
  entryUpdatedBy?: Maybe<Scalars['String']>;
  /** 原更新时间 */
  entryUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** 选项表名称(已迁移) */
  dictNameRhs?: Maybe<Scalars['String']>;
  /** 选项表描述(已迁移) */
  dictNoteRhs?: Maybe<Scalars['String']>;
  /** 选项编号(已迁移) */
  entryCodeRhs?: Maybe<Scalars['String']>;
  /** 选项值(已迁移) */
  entryValueRhs?: Maybe<Scalars['String']>;
  /** 简称(已迁移) */
  entryValueForShortRhs?: Maybe<Scalars['String']>;
  /** 序号(已迁移) */
  entrySeqRhs?: Maybe<Scalars['Int']>;
  /** 选项描述(已迁移) */
  entryNoteRhs?: Maybe<Scalars['String']>;
  dictEntryRhs?: Maybe<Array<MdDictionaryEntry>>;
  /** 是否一致 */
  consistent?: Maybe<Scalars['Boolean']>;
  /** 是否需要迁移 */
  needSync?: Maybe<Scalars['Boolean']>;
  /** 迁移人员 */
  operator?: Maybe<Scalars['String']>;
  /** 迁移时间 */
  operateAt?: Maybe<Scalars['DateTime']>;
  /** 迁移结果 */
  migResult?: Maybe<Scalars['ValuedEnum']>;
  /** 失败原因 */
  migFailedReason?: Maybe<Scalars['String']>;
};

export type MdmDictionaryEntryInboundViewGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdmDictionaryEntryInboundViewPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdmDictionaryEntryInboundView>>>;
};

/** 装箱历史数据 */
export type MdmOrderPacking = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 订单信息 */
  orderInfo: Scalars['String'];
  /** 订单日期 */
  orderCreatedAt: Scalars['DateTime'];
  /** 序号 */
  seq: Scalars['Int'];
  /** 类型 */
  boxType: Scalars['String'];
  /** 品名 */
  productName: Scalars['String'];
  /** 规格 */
  productSpec: Scalars['String'];
  /** 装箱方式 */
  packingType: Scalars['String'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 分组 */
  groupKey: Scalars['String'];
  /** 是否分析 */
  analyzeSkip?: Maybe<Scalars['Boolean']>;
  /** 不分析原因 */
  skipReason?: Maybe<Scalars['String']>;
};

export type MdmOrderPackingGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdmOrderPackingPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdmOrderPacking>>>;
};

/** 装箱方式装箱历史 */
export type MdmOrderPackingView = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  /** 订单信息 */
  orderInfo: Scalars['String'];
  /** 订单日期 */
  orderCreatedAt: Scalars['DateTime'];
  /** 序号 */
  seq: Scalars['Int'];
  /** 类型 */
  boxType: Scalars['String'];
  /** 品名 */
  productName: Scalars['String'];
  /** 规格 */
  productSpec: Scalars['String'];
  /** 装箱方式 */
  packingType: Scalars['String'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
};

/** 装箱方式迁移状态 */
export type MdmPackingOptionInboundView = CodeBasedModel & {
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 装箱历史 */
  entries?: Maybe<Array<Maybe<MdmOrderPackingView>>>;
  /** 名称 */
  name: Scalars['String'];
  /** 客户 */
  customer?: Maybe<MdeCustomer>;
  /** 包装物特征 */
  packingAttributes?: Maybe<Array<Maybe<MdAttribute>>>;
  /** 产品特征 */
  productAttributes?: Maybe<Array<Maybe<MdAttribute>>>;
  /** 外箱装数 */
  outerBoxQty?: Maybe<Scalars['Int']>;
  /** 外箱尺寸 */
  outerBoxSize?: Maybe<Scalars['String']>;
  /** 外箱特征 */
  outerBoxAttributes?: Maybe<Array<Maybe<MdAttribute>>>;
  /** 内盒装数 */
  innerBoxQty?: Maybe<Scalars['Int']>;
  /** 内盒特征 */
  innerBoxAttributes?: Maybe<Array<Maybe<MdAttribute>>>;
  /** 内盒尺寸 */
  innerBoxSize?: Maybe<Scalars['String']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 迁移人员 */
  operator?: Maybe<Scalars['String']>;
  /** 迁移时间 */
  operateAt?: Maybe<Scalars['DateTime']>;
  /** 迁移结果 */
  migStatus?: Maybe<Scalars['ValuedEnum']>;
  /** 失败原因 */
  migFailedReason?: Maybe<Scalars['String']>;
  /** 装箱方式编号 */
  packingOptionCode?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['ID']>;
};

export type MdmPackingOptionInboundViewGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdmPackingOptionInboundViewPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdmPackingOptionInboundView>>>;
};

/** 待回滚量化业务流水 */
export type MdmRqaDeletedTallyInbound = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 业务ID */
  code: Scalars['String'];
  /** 业务组 */
  topic: Scalars['String'];
};

export type MdmRqaDeletedTallyInboundGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdmRqaDeletedTallyInboundPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdmRqaDeletedTallyInbound>>>;
};

/** 可选传id, 或topic+code */
export type MdmRqaTallyInboundRecordInput = {
  id?: Maybe<Scalars['ID']>;
  topic?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

/** 量化业务流水迁移 */
export type MdmRqaTallyInboundView = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 业务ID */
  code: Scalars['String'];
  /** 业务标识 */
  identity: Scalars['String'];
  /** 业务组 */
  topic: Scalars['String'];
  /** 数量 */
  qty: Scalars['Float'];
  /** 单位 */
  unitCode: Scalars['String'];
  /** 开单日期 */
  appliedAt?: Maybe<Scalars['DateTime']>;
  /** 业务数据操作时间 */
  operatedAt: Scalars['DateTime'];
  /** 数据内容唯一性标识 */
  dump: Scalars['String'];
  /** 属性 */
  info?: Maybe<Scalars['Json']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 迁移记录ID */
  recordId?: Maybe<Scalars['Int']>;
  /** 是否需要同步 */
  needSync?: Maybe<Scalars['Boolean']>;
  /** 迁移人员 */
  operator?: Maybe<Scalars['String']>;
  /** 迁移时间 */
  syncAt?: Maybe<Scalars['DateTime']>;
  /** 迁移结果 */
  migResult?: Maybe<Scalars['ValuedEnum']>;
  /** 失败原因 */
  migFailedReason?: Maybe<Scalars['String']>;
};

export type MdmRqaTallyInboundViewGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdmRqaTallyInboundViewPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdmRqaTallyInboundView>>>;
};

/** ATS库存流水 */
export type MdmTallyInbound = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 业务分组 */
  topic: Scalars['String'];
  /** 标识 */
  identity: Scalars['String'];
  /** 库位编号 */
  warehouseCode: Scalars['String'];
  /** 库位名称 */
  warehouseName: Scalars['String'];
  /** 数量 */
  qty?: Maybe<Scalars['Float']>;
  /** 单位 */
  unit?: Maybe<Scalars['String']>;
  /** 摘要 */
  summary?: Maybe<Scalars['String']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 上次结余 */
  preFlowSurplus?: Maybe<Scalars['Float']>;
  /** ats流水id */
  movementId?: Maybe<Scalars['ID']>;
  /** 流水创建时间 */
  movementCreatedAt?: Maybe<Scalars['DateTime']>;
  /** 品名 */
  productName?: Maybe<Scalars['String']>;
  /** 规格 */
  productSpec?: Maybe<Scalars['String']>;
  /** 品号描述 */
  productDescription?: Maybe<Scalars['String']>;
  /** 类别 */
  category?: Maybe<Scalars['String']>;
  /** 结构品号 */
  structureCode?: Maybe<Scalars['String']>;
  /** 年份 */
  year?: Maybe<Scalars['String']>;
  /** 月份 */
  month?: Maybe<Scalars['String']>;
  /** 库存操作类型 */
  stockType?: Maybe<Scalars['String']>;
  /** 库存单性质 */
  sheetType?: Maybe<Scalars['String']>;
  /** 库存单号 */
  sheetCode?: Maybe<Scalars['String']>;
  /** 库存单别 */
  sheetCategoryCode?: Maybe<Scalars['String']>;
  /** 库存单据名称 */
  sheetCategoryName?: Maybe<Scalars['String']>;
  /** 库存单创建时间 */
  sheetAppliedDate?: Maybe<Scalars['DateTime']>;
  /** 库存单单身序号 */
  sheetSeq?: Maybe<Scalars['Int']>;
  /** 库存单的来源业务单据号 */
  sourceOrderCode?: Maybe<Scalars['String']>;
  /** 库存单的来源业务单据单别 */
  sourceOrderCategoryCode?: Maybe<Scalars['String']>;
  /** 订单号 */
  productOrderCode?: Maybe<Scalars['String']>;
  /** 工单号 */
  workOrderCode?: Maybe<Scalars['String']>;
  /** 供应商编号 */
  supplierCode?: Maybe<Scalars['String']>;
  /** 供应商名称 */
  supplierName?: Maybe<Scalars['String']>;
  /** 供应商全称 */
  supplierFullName?: Maybe<Scalars['String']>;
  /** 客户编码 */
  customerCode?: Maybe<Scalars['String']>;
  /** 客户名称 */
  customerName?: Maybe<Scalars['String']>;
  /** 客户全称 */
  customerFullName?: Maybe<Scalars['String']>;
  /** 操作人员 */
  operators?: Maybe<Scalars['String']>;
  /** 删除标记 */
  deleted?: Maybe<Scalars['Boolean']>;
};

export type MdmTallyInboundGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdmTallyInboundPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdmTallyInbound>>>;
};

export type MdmTallyMigrateResult = {
  total?: Maybe<Scalars['Int']>;
  processedCount?: Maybe<Scalars['Int']>;
};

/** 计数结余核对 */
export type MdmTallySurplusDiffView = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 标识 */
  identity: Scalars['String'];
  /** 业务分组 */
  topic: Scalars['String'];
  /** 库位编号 */
  warehouseCode: Scalars['String'];
  /** ats结余 */
  qtyLhs?: Maybe<Scalars['Float']>;
  /** ats单位 */
  unitLhs?: Maybe<Scalars['String']>;
  /** dm结余 */
  qtyRhs?: Maybe<Scalars['Float']>;
  /** dm单位 */
  unitRhs?: Maybe<Scalars['String']>;
};

export type MdmTallySurplusDiffViewGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdmTallySurplusDiffViewPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdmTallySurplusDiffView>>>;
};

/** 出货单记录 */
export type MdoAvailableDeliveryQty = {
  availableEntries?: Maybe<Array<Maybe<MdoBoxingSheetEntry>>>;
  availableBoxQty?: Maybe<Scalars['Float']>;
  notAvailableBoxQty?: Maybe<Scalars['Float']>;
  totalBoxQty?: Maybe<Scalars['Float']>;
  deliveredTotalBoxQty?: Maybe<Scalars['Float']>;
};

/** 纸板需求 */
export type MdoBoxPaperboardView = CodeBasedModel & {
  code: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 订单号 */
  productOrderCode: Scalars['String'];
  /** 客户 */
  customer?: Maybe<MdeCustomer>;
  /** 品号 */
  mdDataCode?: Maybe<Scalars['String']>;
  /** 品名 */
  mdDataName?: Maybe<Scalars['String']>;
  /** 规格 */
  mdDataSpec?: Maybe<Scalars['String']>;
  /** 采购对象 */
  purchaseTarget: Scalars['ValuedEnum'];
  /** 纸箱类型 */
  boxTypes?: Maybe<Array<Maybe<Scalars['ValuedEnum']>>>;
  /** 请购数量 */
  purchaseRequisitionQty?: Maybe<Scalars['Float']>;
  /** 已同步数量 */
  syncQty?: Maybe<Scalars['Float']>;
  /** 同步状态 */
  syncState?: Maybe<Scalars['ValuedEnum']>;
  /** 已结单 */
  purchaseFinished: Scalars['Boolean'];
  /** 请购单号 */
  purchaseRequisitionCode?: Maybe<Scalars['String']>;
  /** 纸箱工单 */
  boxWorkOrders?: Maybe<Array<Maybe<MdoBoxWorkOrder>>>;
  /** 工单备注 */
  noteConcat?: Maybe<Scalars['String']>;
  /** 工单序号 */
  batchSeqConcat?: Maybe<Scalars['String']>;
  /** 工单ids */
  workOrderIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type MdoBoxPaperboardViewGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdoBoxPaperboardViewPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdoBoxPaperboardView>>>;
};

/** 纸箱工单 */
export type MdoBoxWorkOrder = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 序号 */
  batchSeq: Scalars['Int'];
  /** 订单号 */
  productOrderCode: Scalars['String'];
  /** 纸箱品名 */
  boxMdDataName: Scalars['String'];
  /** 纸箱规格 */
  boxMdDataSpec: Scalars['String'];
  /** 纸箱数量 */
  orderQty: Scalars['Float'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 采购对象 */
  purchaseTarget: Scalars['ValuedEnum'];
  /** 纸板规格 */
  paperboardMdDataSpec?: Maybe<Scalars['String']>;
  /** 纸板数量 */
  paperboardQty?: Maybe<Scalars['Float']>;
  /** 纸箱类型 */
  boxType?: Maybe<Scalars['ValuedEnum']>;
  /** 编号 */
  code: Scalars['String'];
  /** 状态 */
  state: Scalars['ValuedEnum'];
  /** 关联的装箱单记录 */
  boxingSheetEntries: Array<Maybe<MdoBoxingSheetEntry>>;
  /** 包装物纸箱数量明细 */
  qtyDetailsByPackingAttr?: Maybe<Scalars['Json']>;
  /** 已结单 */
  purchaseFinished: Scalars['Boolean'];
  /** 手工工单 */
  manual: Scalars['Boolean'];
  /** 纸箱品号 */
  boxMdData: MdData;
  /** 纸箱品号 */
  boxMdDataCode: Scalars['String'];
  /** 数量变更记录 */
  changeRecords?: Maybe<Scalars['String']>;
  /** 计划版本 */
  lrpVersion: Scalars['Int'];
  /** 纸箱纸度 */
  boxDimension?: Maybe<Scalars['Float']>;
  /** 纸箱纸长 */
  boxLength?: Maybe<Scalars['Float']>;
  /** 纸板倍数 */
  paperboardScale?: Maybe<Scalars['Float']>;
  /** 纸板品号 */
  paperboardMdData?: Maybe<MdData>;
  /** 纸板品号 */
  paperboardMdDataCode?: Maybe<Scalars['String']>;
  /** 纸板品名 */
  paperboardMdDataName?: Maybe<Scalars['String']>;
  /** 纸板纸度 */
  paperboardDimension?: Maybe<Scalars['Float']>;
  /** 纸板纸长 */
  paperboardLength?: Maybe<Scalars['Float']>;
  /** 平卡尺寸 */
  horizontalSeparatorSize?: Maybe<Scalars['String']>;
  /** 变更情况 */
  diff?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 错误信息 */
  paperboardMdDataErrors?: Maybe<Array<Maybe<ErrorInfo>>>;
  groupKey?: Maybe<Scalars['String']>;
  mergeKey?: Maybe<Scalars['String']>;
};

export type MdoBoxWorkOrderCopyInput = {
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  orderQty: Scalars['Int'];
  purchaseTarget: Scalars['String'];
};

export type MdoBoxWorkOrderEditInput = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  paperboardDimension?: Maybe<Scalars['Float']>;
  paperboardLength?: Maybe<Scalars['Float']>;
  paperboardScale?: Maybe<Scalars['Float']>;
};

export type MdoBoxWorkOrderGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdoBoxWorkOrderInput = {
  id?: Maybe<Scalars['ID']>;
  boxingSheetEntryIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  qtyDetailsByPackingAttrJson?: Maybe<Scalars['String']>;
  boxMdDataId?: Maybe<Scalars['ID']>;
  paperboardBoxMdDataId?: Maybe<Scalars['ID']>;
  orderQty?: Maybe<Scalars['Int']>;
  boxDimension?: Maybe<Scalars['Float']>;
  boxLength?: Maybe<Scalars['Float']>;
  paperboardDimension?: Maybe<Scalars['Float']>;
  paperboardLength?: Maybe<Scalars['Float']>;
  paperboardScale?: Maybe<Scalars['Float']>;
  purchaseTarget?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  groupKey?: Maybe<Scalars['String']>;
  mergeKey?: Maybe<Scalars['String']>;
  batchSeq?: Maybe<Scalars['Int']>;
  boxType?: Maybe<Scalars['ValuedEnum']>;
  horizontalSeparatorSize?: Maybe<Scalars['String']>;
};

export type MdoBoxWorkOrderPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdoBoxWorkOrder>>>;
};

/** 装箱单记录成品明细 */
export type MdoBoxingEndProduct = {
  /** 订单成品 */
  endProduct?: Maybe<MdoEndProduct>;
  /** 装箱数量 */
  boxingQty?: Maybe<Scalars['Float']>;
  /** 待处理尾数 */
  residualQty?: Maybe<Scalars['Float']>;
  /** 混装分组 */
  mixinGroup?: Maybe<Scalars['String']>;
  /** 混装比例 */
  mixinRate?: Maybe<Scalars['Float']>;
  /** 数量拆分标记(PO) */
  splitKey?: Maybe<Scalars['String']>;
  /** 数量拆分备注(目的港 */
  splitNote?: Maybe<Scalars['String']>;
  /** 拆分数量 */
  splitQty?: Maybe<Scalars['Float']>;
  /** 类别Key */
  categoryKey?: Maybe<Scalars['String']>;
  /** 类别信息 */
  categoryKeyValue?: Maybe<Scalars['String']>;
  /** 型号 */
  productModel?: Maybe<Scalars['String']>;
  /** 成品主数据 */
  mdDataCode?: Maybe<Scalars['String']>;
  /** 成品品名 */
  mdDataName?: Maybe<Scalars['String']>;
  /** 成品规格 */
  mdDataSpec?: Maybe<Scalars['String']>;
};

/** 纸箱品号 */
export type MdoBoxingMdData = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 订单号 */
  productOrderCode: Scalars['String'];
  /** 装箱方式 */
  packingOption: MdoPackingOption;
  /** 纸板材质 */
  paperMaterial: MdDictionaryEntry;
  /** 纸箱类型 */
  boxType?: Maybe<Scalars['ValuedEnum']>;
  /** 纸箱品号 */
  boxMdData: MdData;
  /** 纸箱品号 */
  boxMdDataCode: Scalars['String'];
  /** 纸箱品名 */
  boxMdDataName: Scalars['String'];
  /** 纸箱规格 */
  boxMdDataSpec: Scalars['String'];
  /** 错误信息 */
  boxMdDataErrors?: Maybe<Array<Maybe<ErrorInfo>>>;
};

export type MdoBoxingMdDataInput = {
  packingOptionId: Scalars['ID'];
  paperMaterialId: Scalars['ID'];
  boxMdDataId: Scalars['ID'];
  boxType?: Maybe<Scalars['ValuedEnum']>;
};

/** 订单装箱单 */
export type MdoBoxingSheet = IdBasedModel & {
  /** 订单ID */
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 装箱单ID */
  sheetId?: Maybe<Scalars['ID']>;
  /** 订单号 */
  code: Scalars['String'];
  /** 客户 */
  customer?: Maybe<MdeCustomer>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 订单款数 */
  epCount?: Maybe<Scalars['Int']>;
  /** 订单数量 */
  orderQty?: Maybe<Scalars['Float']>;
  /** 装箱单状态 */
  boxingState: Scalars['ValuedEnum'];
  /** 箱号状态 */
  boxNoState: Scalars['ValuedEnum'];
  /** 待处理款数 */
  tbpEpCount?: Maybe<Scalars['Int']>;
  /** 装箱备注 */
  boxingNote?: Maybe<Scalars['String']>;
  /** 装箱数量 */
  boxingQty?: Maybe<Scalars['Float']>;
  /** 未装箱数量 */
  noBoxingQty?: Maybe<Scalars['Float']>;
  /** 总件数 */
  totalBoxQty?: Maybe<Scalars['Int']>;
  /** 未出货件数 */
  undeliveredBoxQty?: Maybe<Scalars['Float']>;
  /** 总体积(m³) */
  totalVolume?: Maybe<Scalars['Float']>;
  /** 总毛重(kg) */
  totalGrossWeight?: Maybe<Scalars['Float']>;
  /** 总净重(kg) */
  totalNetWeight?: Maybe<Scalars['Float']>;
  /** 纸箱工单数 */
  boxWorkOrderCount?: Maybe<Scalars['Int']>;
  /** 自选待填 */
  freeTbcEntryCount?: Maybe<Scalars['Int']>;
  /** 已设箱重 */
  boxWeightSettled?: Maybe<Scalars['Boolean']>;
  /** 是否需实称 */
  needWeigh?: Maybe<Scalars['Boolean']>;
  /** 已实称 */
  actualWeightSettled?: Maybe<Scalars['Boolean']>;
  /** 出货状态 */
  deliveryChargedState: Scalars['ValuedEnum'];
  /** 订单备注 */
  note?: Maybe<Scalars['String']>;
  /** 下单人员 */
  principals?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** 无需装箱 */
  ignoreBoxing?: Maybe<Scalars['Boolean']>;
  /** 属性 */
  attributes: Array<Maybe<MdAttribute>>;
  /** 内盒材质 */
  innerMaterial?: Maybe<MdDictionaryEntry>;
  /** 外箱材质 */
  outerMaterial?: Maybe<MdDictionaryEntry>;
  /** 装箱单记录 */
  entries?: Maybe<Array<Maybe<MdoBoxingSheetEntry>>>;
  /** 装箱单成品 */
  endProducts?: Maybe<Array<Maybe<MdoEndProduct>>>;
  /** 未设置装箱记录成品 */
  tbpEndProducts?: Maybe<Array<Maybe<MdoBoxingEndProduct>>>;
  /** 待更新的装箱记录成品 */
  needUpdateEndProducts?: Maybe<Array<Maybe<MdoBoxingEndProduct>>>;
};

/** 订单装箱单 */
export type MdoBoxingSheetNeedUpdateEndProductsArgs = {
  endProductIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

/** 装箱单记录 */
export type MdoBoxingSheetEntry = IdBasedModel & {
  id?: Maybe<Scalars['ID']>;
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 装箱单 */
  sheet?: Maybe<MdoBoxingSheet>;
  /** 待更新提示 */
  needUpdatedNote?: Maybe<Scalars['String']>;
  /** 状态 */
  state: Scalars['ValuedEnum'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 自定义分组 */
  customGroup: Scalars['Boolean'];
  /** 箱号批次 */
  shippingGroup?: Maybe<Scalars['String']>;
  /** 内盒材质 */
  innerMaterial?: Maybe<MdDictionaryEntry>;
  /** 外箱材质 */
  outerMaterial?: Maybe<MdDictionaryEntry>;
  /** 唛头模板 */
  markPrintTemplate?: Maybe<MdoMarkPrintTemplate>;
  /** 是否匹配曾经用过 */
  matchUsed: Scalars['Boolean'];
  /** 客户PO */
  splitKey?: Maybe<Scalars['String']>;
  /** 目的港 */
  splitNote?: Maybe<Scalars['String']>;
  /** 装箱类型 */
  boxingType?: Maybe<Scalars['ValuedEnum']>;
  /** 成品明细 */
  endProducts?: Maybe<Array<Maybe<MdoBoxingEndProduct>>>;
  /** 自选装箱方式 */
  free: Scalars['Boolean'];
  /** 装箱方式 */
  packingOption?: Maybe<MdoPackingOption>;
  /** 件数 */
  boxQty?: Maybe<Scalars['Int']>;
  /** 装箱数量 */
  boxingQty?: Maybe<Scalars['Float']>;
  /** 排序Key */
  sortKey: Scalars['String'];
  /** 摘要 */
  summary?: Maybe<Scalars['String']>;
  /** 箱号状态 */
  boxNoState: Scalars['ValuedEnum'];
  /** 箱号 */
  boxNo?: Maybe<Scalars['String']>;
  /** 额外箱号 */
  extraBoxNo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 单箱毛重 */
  boxGrossWeight?: Maybe<Scalars['Float']>;
  /** 单箱净重 */
  boxNetWeight?: Maybe<Scalars['Float']>;
  /** 实称毛重 */
  actualGrossWeight?: Maybe<Scalars['Float']>;
  /** 实称净重 */
  actualNetWeight?: Maybe<Scalars['Float']>;
  /** 实称箱重异常 */
  anomalousWeight: Scalars['Boolean'];
  /** 忽略实称箱重异常 */
  ignoreAnomalousWeight: Scalars['Boolean'];
  /** 内盒装数 */
  innerBoxLoads?: Maybe<Scalars['Int']>;
  /** 内盒尺寸 */
  innerBoxSize?: Maybe<Scalars['String']>;
  /** 外箱装数 */
  outerBoxLoads?: Maybe<Scalars['Int']>;
  /** 外箱尺寸 */
  outerBoxSize?: Maybe<Scalars['String']>;
  /** 单位体积(m³) */
  outerBoxVolume?: Maybe<Scalars['Float']>;
  /** 出货状态 */
  deliveryChargedState: Scalars['ValuedEnum'];
  /** 未出货件数 */
  undeliveredBoxQty?: Maybe<Scalars['Float']>;
  /** 已出货件数 */
  deliveredBoxQty?: Maybe<Scalars['Float']>;
};

export type MdoBoxingSheetEntryBoxNoInput = {
  entryId: Scalars['ID'];
  ver: Scalars['Int'];
  freeBoxQty?: Maybe<Scalars['Int']>;
  freeBoxVolume?: Maybe<Scalars['Float']>;
  shippingGroup?: Maybe<Scalars['String']>;
  boxNo?: Maybe<Scalars['String']>;
  extraBoxNo?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MdoBoxingSheetEntryEndProductInput = {
  endProductId: Scalars['ID'];
  splitKey?: Maybe<Scalars['String']>;
  splitNote?: Maybe<Scalars['String']>;
  splitQty?: Maybe<Scalars['Float']>;
  totalSplitQty?: Maybe<Scalars['Float']>;
  boxingQty?: Maybe<Scalars['Float']>;
  residualQty?: Maybe<Scalars['Float']>;
};

export type MdoBoxingSheetEntryFreeInput = {
  uid?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  boxQty: Scalars['Int'];
  boxVolume: Scalars['Float'];
  boxGrossWeight?: Maybe<Scalars['Float']>;
  boxNetWeight?: Maybe<Scalars['Float']>;
  weightSources?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MdoBoxingSheetEntryGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdoBoxingSheetEntryInput = {
  uid?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  shippingGroup?: Maybe<Scalars['String']>;
  customGroup?: Maybe<Scalars['Boolean']>;
  endProducts: Array<Maybe<MdoBoxingSheetEntryEndProductInput>>;
  boxingType: Scalars['ValuedEnum'];
  free: Scalars['Boolean'];
  innerMaterialId: Scalars['ID'];
  outerMaterialId: Scalars['ID'];
  markPrintTemplateId?: Maybe<Scalars['ID']>;
  packingOptionId?: Maybe<Scalars['ID']>;
  boxQty?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  boxGrossWeight?: Maybe<Scalars['Float']>;
  boxNetWeight?: Maybe<Scalars['Float']>;
  weightSources?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MdoBoxingSheetEntryPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdoBoxingSheetEntry>>>;
};

/** 装箱单历史记录 */
export type MdoBoxingSheetEntryView = IdBasedModel & {
  /** 装箱单记录 */
  id?: Maybe<Scalars['ID']>;
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 装箱单 */
  sheet?: Maybe<MdoBoxingSheet>;
  /** 待更新提示 */
  needUpdatedNote?: Maybe<Scalars['String']>;
  /** 状态 */
  state: Scalars['ValuedEnum'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 自定义分组 */
  customGroup: Scalars['Boolean'];
  /** 箱号批次 */
  shippingGroup?: Maybe<Scalars['String']>;
  /** 内盒材质 */
  innerMaterial?: Maybe<MdDictionaryEntry>;
  /** 外箱材质 */
  outerMaterial?: Maybe<MdDictionaryEntry>;
  /** 唛头模板 */
  markPrintTemplate?: Maybe<MdoMarkPrintTemplate>;
  /** 是否匹配曾经用过 */
  matchUsed: Scalars['Boolean'];
  /** 装箱类型 */
  boxingType?: Maybe<Scalars['ValuedEnum']>;
  /** 成品明细 */
  endProducts?: Maybe<Array<Maybe<MdoBoxingEndProduct>>>;
  /** 自选装箱方式 */
  free: Scalars['Boolean'];
  /** 装箱方式 */
  packingOption?: Maybe<MdoPackingOption>;
  /** 件数 */
  boxQty?: Maybe<Scalars['Int']>;
  /** 排序Key */
  sortKey: Scalars['String'];
  /** 摘要 */
  summary?: Maybe<Scalars['String']>;
  /** 箱号状态 */
  boxNoState: Scalars['ValuedEnum'];
  /** 箱号 */
  boxNo?: Maybe<Scalars['String']>;
  /** 额外箱号 */
  extraBoxNo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 单箱毛重 */
  boxGrossWeight?: Maybe<Scalars['Float']>;
  /** 单箱净重 */
  boxNetWeight?: Maybe<Scalars['Float']>;
  /** 内盒装数 */
  innerBoxLoads?: Maybe<Scalars['Int']>;
  /** 内盒尺寸 */
  innerBoxSize?: Maybe<Scalars['String']>;
  /** 外箱装数 */
  outerBoxLoads?: Maybe<Scalars['Int']>;
  /** 外箱尺寸 */
  outerBoxSize?: Maybe<Scalars['String']>;
  /** 单位体积(m³) */
  outerBoxVolume?: Maybe<Scalars['Float']>;
  /** 成品装箱数量 */
  boxingQty?: Maybe<Scalars['Float']>;
  /** 成品品号 */
  mdDataCode?: Maybe<Scalars['String']>;
  /** 成品品名 */
  mdDataName?: Maybe<Scalars['String']>;
  /** 成品规格 */
  mdDataSpec?: Maybe<Scalars['String']>;
};

export type MdoBoxingSheetEntryViewGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdoBoxingSheetEntryViewPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdoBoxingSheetEntryView>>>;
};

export type MdoBoxingSheetEntryWeightInput = {
  entryId: Scalars['ID'];
  ver: Scalars['Int'];
  boxNetWeight?: Maybe<Scalars['Float']>;
  boxGrossWeight?: Maybe<Scalars['Float']>;
};

export type MdoBoxingSheetGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdoBoxingSheetPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdoBoxingSheet>>>;
};

export type MdoBoxingSimpleEndProductInput = {
  id: Scalars['ID'];
  mixinGroup?: Maybe<Scalars['String']>;
  mixinRate?: Maybe<Scalars['Int']>;
  orderQty: Scalars['Float'];
};

/** 出货单记录 */
export type MdoDeliveryEp = {
  /** 成品id */
  epId?: Maybe<Scalars['ID']>;
  /** 成品序号 */
  epSortKey?: Maybe<Scalars['String']>;
  /** 类别Key */
  categoryKey?: Maybe<Scalars['String']>;
  /** 类别信息 */
  categoryKeyValue?: Maybe<Scalars['String']>;
  /** 型号 */
  productModel?: Maybe<Scalars['String']>;
  /** 总装箱数量 */
  boxingQty?: Maybe<Scalars['Float']>;
  /** 成品主数据 */
  mdDataCode: Scalars['String'];
  /** 成品品名 */
  mdDataName: Scalars['String'];
  /** 成品规格 */
  mdDataSpec: Scalars['String'];
  /** 出货数量 */
  deliveryQty?: Maybe<Scalars['Float']>;
  /** 单价 */
  unitPrice?: Maybe<Scalars['Float']>;
  /** 币种 */
  currency?: Maybe<MdeCurrency>;
  /** 汇率 */
  exchangeRate?: Maybe<Scalars['Float']>;
  /** 订单号 */
  productOrderCode?: Maybe<Scalars['String']>;
  /** 客户PO */
  customerPo?: Maybe<Scalars['String']>;
  /** 目的港 */
  destination?: Maybe<Scalars['String']>;
};

export type MdoDeliveryEpInput = {
  epId?: Maybe<Scalars['ID']>;
  epSortKey?: Maybe<Scalars['String']>;
  categoryKey?: Maybe<Scalars['String']>;
  categoryKeyValue?: Maybe<Scalars['String']>;
  productModel?: Maybe<Scalars['String']>;
  boxingQty?: Maybe<Scalars['Float']>;
  mdDataCode: Scalars['String'];
  mdDataName: Scalars['String'];
  mdDataSpec: Scalars['String'];
  deliveryQty?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  productOrderCode?: Maybe<Scalars['String']>;
  customerPo?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
};

/** 出货单成品成本 */
export type MdoDeliveryEpView = CodeBasedModel & {
  code?: Maybe<Scalars['ID']>;
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 订单号 */
  productOrderCode?: Maybe<Scalars['String']>;
  /** 类别 */
  category: MdCategoryInfo;
  /** 型号 */
  productModel: Scalars['String'];
  /** 成品品号 */
  mdDataCode?: Maybe<Scalars['String']>;
  /** 成品品名 */
  mdDataName?: Maybe<Scalars['String']>;
  /** 成品规格 */
  mdDataSpec?: Maybe<Scalars['String']>;
  /** 出货数量 */
  deliveryQty?: Maybe<Scalars['Float']>;
  /** 成品装箱数量 */
  boxingQty?: Maybe<Scalars['Float']>;
  /** PO单价 */
  unitPrice?: Maybe<Scalars['Float']>;
  /** 币种 */
  currency?: Maybe<MdeCurrency>;
  /** 补充信息 */
  extra?: Maybe<Scalars['Map']>;
  /** 出货单序号 */
  seq?: Maybe<Scalars['Int']>;
};

export type MdoDeliveryEpViewGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdoDeliveryEpViewPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdoDeliveryEpView>>>;
};

/** 出货单 */
export type MdoDeliverySheet = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 单号 */
  code?: Maybe<Scalars['String']>;
  /** 客户 */
  customer?: Maybe<MdeCustomer>;
  /** 出货日期 */
  loadingDate?: Maybe<Scalars['Date']>;
  /** 开单日期 */
  appliedDate: Scalars['Date'];
  /** 收货地址 */
  deliveryAddress?: Maybe<MdeAddress>;
  /** 运输方式 */
  transportType: MdDictionaryEntry;
  /** 运输信息 */
  transportAttributes?: Maybe<Array<Maybe<MdAttribute>>>;
  /** 外贸条款 */
  incoterms?: Maybe<MdeIncoterms>;
  /** 开单人员 */
  applicant?: Maybe<User>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 出货状态 */
  state?: Maybe<Scalars['ValuedEnum']>;
  /** 总件数 */
  deliveryBoxQtySum?: Maybe<Scalars['Float']>;
  /** 总数量 */
  deliveryQtySum?: Maybe<Scalars['Float']>;
  /** 总体积(m³) */
  outerBoxVolumeSum?: Maybe<Scalars['Float']>;
  /** 总毛重(kg) */
  grossWeightSum?: Maybe<Scalars['Float']>;
  /** 总净重(kg) */
  netWeightSum?: Maybe<Scalars['Float']>;
  /** 出货单记录 */
  sheetEntries?: Maybe<Array<Maybe<MdoDeliverySheetEntry>>>;
  /** 出货订单 */
  productOrders?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 发票号码 */
  invoiceCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 发票文件 */
  invoiceFiles?: Maybe<Array<Maybe<Scalars['File']>>>;
  /** 报关单号 */
  customDecCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 报关文件 */
  customDecFiles?: Maybe<Array<Maybe<Scalars['File']>>>;
  /** 货运单号 */
  cargoCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 货运单文件 */
  cargoFiles?: Maybe<Array<Maybe<Scalars['File']>>>;
  /** 其它文件 */
  otherFiles?: Maybe<Array<Maybe<Scalars['File']>>>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** ATS销货单同步状态 */
  syncState?: Maybe<Scalars['ValuedEnum']>;
};

/** 出货单记录 */
export type MdoDeliverySheetEntry = IdBasedModel & {
  id?: Maybe<Scalars['ID']>;
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 出货单 */
  sheet: MdoDeliverySheet;
  /** 出货单号 */
  sheetCode: Scalars['String'];
  /** 订单号 */
  productOrderCode?: Maybe<Scalars['String']>;
  /** PO号 */
  customerPo?: Maybe<Scalars['String']>;
  /** 目的港 */
  destination?: Maybe<Scalars['String']>;
  /** 下单人员 */
  principals?: Maybe<Array<Maybe<User>>>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 装箱单记录 */
  boxingSheetEntry?: Maybe<MdoBoxingSheetEntry>;
  /** 装箱单总件数 */
  boxQty: Scalars['Int'];
  /** 装箱方式摘要 */
  packingSummary?: Maybe<Scalars['String']>;
  /** 装箱备注 */
  boxingNote?: Maybe<Scalars['String']>;
  /** 单箱毛重 */
  boxGrossWeight?: Maybe<Scalars['Float']>;
  /** 单箱净重 */
  boxNetWeight?: Maybe<Scalars['Float']>;
  /** 单位体积(m³) */
  outerBoxVolume?: Maybe<Scalars['Float']>;
  /** 外箱尺寸 */
  outerBoxSize?: Maybe<Scalars['String']>;
  /** 内盒尺寸 */
  innerBoxSize?: Maybe<Scalars['String']>;
  /** 来源 */
  source: Scalars['ValuedEnum'];
  /** 序号 */
  seq?: Maybe<Scalars['Int']>;
  /** 本次出货数量 */
  deliveryBoxQty: Scalars['Float'];
  /** 成品数量明细 */
  endProducts: Array<Maybe<MdoDeliveryEp>>;
};

export type MdoDeliverySheetEntryGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdoDeliverySheetEntryInput = {
  uid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  productOrderCode?: Maybe<Scalars['String']>;
  customerPo?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  boxingSheetEntryId?: Maybe<Scalars['ID']>;
  boxQty?: Maybe<Scalars['Int']>;
  packingSummary?: Maybe<Scalars['String']>;
  boxingNote?: Maybe<Scalars['String']>;
  boxGrossWeight?: Maybe<Scalars['Float']>;
  boxNetWeight?: Maybe<Scalars['Float']>;
  outerBoxVolume?: Maybe<Scalars['Float']>;
  outerBoxSize?: Maybe<Scalars['String']>;
  innerBoxSize?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  deliveryBoxQty?: Maybe<Scalars['Float']>;
  endProducts?: Maybe<Array<Maybe<MdoDeliveryEpInput>>>;
};

export type MdoDeliverySheetEntryPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdoDeliverySheetEntry>>>;
};

export type MdoDeliverySheetGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdoDeliverySheetPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdoDeliverySheet>>>;
};

/** 订单图档 */
export type MdoDesignDocument = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 客户 */
  customer?: Maybe<MdeCustomer>;
  /** 订单号 */
  productOrderCode: Scalars['String'];
  /** 客户PO号 */
  customerPOCode?: Maybe<Scalars['String']>;
  /** 备注 */
  note: Scalars['String'];
  /** 订单图档 */
  documents?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MdoDesignDocumentGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

/**
 * ##############################################################################
 *  Inputs
 * ##############################################################################
 */
export type MdoDesignDocumentInput = {
  productOrderCode: Scalars['String'];
  customerPOCode?: Maybe<Scalars['String']>;
  customer: Scalars['String'];
  note: Scalars['String'];
  documents?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MdoDesignDocumentPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdoDesignDocument>>>;
};

/** 产品图档 */
export type MdoDesignDocumentUnit = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 类别 */
  category: MdCategoryInfo;
  /** 名称 */
  name: Scalars['String'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 属性 */
  attributes?: Maybe<Array<Maybe<MdAttribute>>>;
  /** 图档 */
  documentUnits?: Maybe<Array<Scalars['String']>>;
  /** 效果图 */
  snapshots?: Maybe<Array<Scalars['String']>>;
  /** 订单图档 */
  designDocument: MdoDesignDocument;
};

export type MdoDesignDocumentUnitGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdoDesignDocumentUnitInput = {
  name: Scalars['String'];
  attributes?: Maybe<Array<MdAttributeInput>>;
  documentUnits?: Maybe<Array<Scalars['String']>>;
  snapshots?: Maybe<Array<Scalars['String']>>;
  note: Scalars['String'];
};

export type MdoDesignDocumentUnitPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdoDesignDocumentUnit>>>;
};

/** 订单成品 */
export type MdoEndProduct = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 订单号 */
  productOrderCode?: Maybe<Scalars['String']>;
  /** 类别 */
  category: MdCategoryInfo;
  /** 型号 */
  productModel: Scalars['String'];
  /** 序号 */
  seq?: Maybe<Scalars['Int']>;
  /** 排序序号 */
  sortKey?: Maybe<Scalars['String']>;
  /** 客户 */
  customer?: Maybe<MdeCustomer>;
  /** 下单人员 */
  principals?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** 跟单人员 */
  participants?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** 属性 */
  attributes?: Maybe<Array<MdAttribute>>;
  /** 主要产品 */
  mainProducts: Array<Maybe<MdoMainProduct>>;
  /** 成品信息 */
  infos: Array<Maybe<MdoEndProductInfo>>;
  /** 筛选成品信息 */
  infosByFilter?: Maybe<Array<Maybe<MdoEndProductInfo>>>;
  /** 主数据 */
  mdDataCode?: Maybe<Scalars['String']>;
  /** 主数据 */
  mdData?: Maybe<MdData>;
  /** 成品品名 */
  mdDataName?: Maybe<Scalars['String']>;
  /** 成品规格 */
  mdDataSpec?: Maybe<Scalars['String']>;
  /** 成品状态 */
  state: Scalars['ValuedEnum'];
  /** 订单数量 */
  orderQty?: Maybe<Scalars['Float']>;
  /** 可用库存 */
  availableInvQty?: Maybe<Scalars['Float']>;
  /** 混装比例 */
  mixinRate?: Maybe<Scalars['Float']>;
  /** 混装分组 */
  mixinGroup?: Maybe<Scalars['String']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 币种 */
  currency?: Maybe<Scalars['String']>;
  /** 汇率 */
  exchangeRate?: Maybe<Scalars['Float']>;
  /** 应收单价 */
  receivableUnitPrice?: Maybe<Scalars['Float']>;
  /** 包装物特征 */
  packingAttributes?: Maybe<Array<Maybe<MdAttribute>>>;
  /** 产品特征 */
  productAttributes?: Maybe<Array<Maybe<MdAttribute>>>;
  /** 包装物摘要 */
  packingSummary?: Maybe<Scalars['String']>;
  /** 成品信息摘要 */
  epSummary?: Maybe<Scalars['String']>;
  /** 未销货数量 */
  unVerifiedQty?: Maybe<Scalars['Float']>;
};

/** 订单成品 */
export type MdoEndProductAttributesArgs = {
  mdFieldIds?: Maybe<Array<Scalars['ID']>>;
};

/** 订单成品 */
export type MdoEndProductInfosByFilterArgs = {
  filter?: Maybe<FilterCondition>;
};

export type MdoEndProductGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

/** 订单成品信息 */
export type MdoEndProductInfo = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 名称 */
  name: Scalars['String'];
  /** 类型 */
  type: Scalars['ValuedEnum'];
  /** 属性 */
  attributes: Array<MdAttribute>;
  /** 工艺 */
  craft: Scalars['Boolean'];
  /** 包装物 */
  packingRef: Scalars['Boolean'];
  /** 主数据生成策略 */
  mdDataGeneratingRule: Scalars['ValuedEnum'];
  /** 工程开发状态 */
  engineeringState: Scalars['ValuedEnum'];
  /** 载体Bom路径, 从主要产品Bom中选择 */
  carrierPath?: Maybe<Scalars['String']>;
  /** 载体包装, 选择其它成品信息的uid */
  carrierInfoKey?: Maybe<Scalars['String']>;
  /** 载体主数据 */
  carrierMdData?: Maybe<MdData>;
  /** 生成的主数据 */
  generatedMdData?: Maybe<MdData>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
};

/** 成品信息板字段设置 */
export type MdoEndProductInfoFieldConfig = {
  fieldId: Scalars['ID'];
  /** 字段排序 */
  seq: Scalars['Int'];
  /** 展示在汇总信息 */
  summary?: Maybe<Scalars['Boolean']>;
  /** 参与差异比较 */
  diff?: Maybe<Scalars['Boolean']>;
  /** 在订单单头编辑 */
  editWithProductOrder?: Maybe<Scalars['Boolean']>;
  /** 单独展示在明细表格中 */
  showInTable?: Maybe<Scalars['Boolean']>;
  /** 复制成品时不复制该属性 */
  dontCopy?: Maybe<Scalars['Boolean']>;
  /** 复制成品时不复制该属性 */
  showInBoxingSheetEntryTable?: Maybe<Scalars['Boolean']>;
};

export type MdoEndProductInfoFieldConfigInput = {
  fieldId: Scalars['ID'];
  seq?: Maybe<Scalars['Int']>;
  summary?: Maybe<Scalars['Boolean']>;
  diff?: Maybe<Scalars['Boolean']>;
  editWithProductOrder?: Maybe<Scalars['Boolean']>;
  showInTable?: Maybe<Scalars['Boolean']>;
  dontCopy?: Maybe<Scalars['Boolean']>;
  showInBoxingSheetEntryTable?: Maybe<Scalars['Boolean']>;
};

export type MdoEndProductInfoInput = {
  uid: Scalars['String'];
  templateId: Scalars['ID'];
  attributes: Array<Maybe<MdAttributeInput>>;
  craft?: Maybe<Scalars['Boolean']>;
  mdDataGeneratingRule: Scalars['ValuedEnum'];
  packingRef?: Maybe<Scalars['Boolean']>;
  carrierPath?: Maybe<Scalars['String']>;
  carrierInfoKey?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  devNeeded?: Maybe<Scalars['Boolean']>;
};

/** 成品信息模板 */
export type MdoEndProductInfoTemplate = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 名称 */
  name: Scalars['String'];
  /** 类型 */
  type: Scalars['ValuedEnum'];
  /** 属性 */
  attributes: Array<MdAttribute>;
  /** 字段 */
  mdFields: Array<MdField>;
  /** 字段设置 */
  configs?: Maybe<Array<MdoEndProductInfoFieldConfig>>;
  /** 默认添加 */
  defaultAdded: Scalars['Boolean'];
  /** 工艺 */
  craft: Scalars['Boolean'];
  /** 允许重复添加 */
  multiple: Scalars['Boolean'];
  /** 主数据生成策略 */
  mdDataGeneratingRule: Scalars['ValuedEnum'];
};

export type MdoEndProductInfoTemplateInput = {
  name?: Maybe<Scalars['String']>;
  attributes?: Maybe<Array<MdAttributeInput>>;
  mdFieldIds?: Maybe<Array<Scalars['ID']>>;
  configs?: Maybe<Array<MdoEndProductInfoFieldConfigInput>>;
  defaultAdded?: Maybe<Scalars['Boolean']>;
  craft?: Maybe<Scalars['Boolean']>;
  multiple?: Maybe<Scalars['Boolean']>;
  mdDataGeneratingRule?: Maybe<Scalars['ValuedEnum']>;
};

export type MdoEndProductMixinInput = {
  endProductId?: Maybe<Scalars['ID']>;
  orderQty: Scalars['Float'];
  mixinRate?: Maybe<Scalars['Float']>;
  mixinGroup?: Maybe<Scalars['String']>;
};

export type MdoEndProductPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdoEndProduct>>>;
};

export type MdoEndProductReceivableInput = {
  endProductId?: Maybe<Scalars['ID']>;
  currency?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  taxType?: Maybe<Scalars['ValuedEnum']>;
  taxRate?: Maybe<Scalars['Float']>;
  receivableUnitPrice?: Maybe<Scalars['Float']>;
};

/** 订单汇总信息 */
export type MdoEndProductSummary = {
  /** 类别Key */
  categoryKey?: Maybe<Scalars['String']>;
  /** 类别value */
  categoryKeyValue?: Maybe<Scalars['String']>;
  /** 型号 */
  productModel?: Maybe<Scalars['String']>;
  /** 订单数量 */
  orderQty?: Maybe<Scalars['Float']>;
  /** 成品款数 */
  endProductCount?: Maybe<Scalars['Float']>;
  /** 包装物 */
  packing?: Maybe<Scalars['String']>;
};

/** 订单成品主要产品 */
export type MdoMainProduct = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 类别 */
  category: MdCategoryInfo;
  /** 型号 */
  productModel?: Maybe<Scalars['String']>;
  /** 主数据 */
  mdDataCode?: Maybe<Scalars['String']>;
  /** 主数据 */
  mdData?: Maybe<MdData>;
  /** 用量 */
  count: Scalars['Float'];
  /** 单位 */
  unit: Scalars['String'];
  /** 工程开发状态 */
  engineeringState: Scalars['ValuedEnum'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
};

export type MdoMainProductInput = {
  uid: Scalars['String'];
  mdDataCode: Scalars['String'];
  count: Scalars['Float'];
  unit: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  devNeeded?: Maybe<Scalars['Boolean']>;
};

/** 唛头模板 */
export type MdoMarkPrintTemplate = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 名称 */
  name: Scalars['String'];
  /** 客户 */
  customer?: Maybe<MdeCustomer>;
  /** 主图片 */
  mainPicture?: Maybe<Scalars['File']>;
  /** 图片/文件 */
  files?: Maybe<Array<Maybe<Scalars['File']>>>;
  /** 唛头信息 */
  template?: Maybe<Scalars['String']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 模板字段 */
  fields: Array<Maybe<MdoMarkPrintTemplateField>>;
};

/** 唛头模板字段 */
export type MdoMarkPrintTemplateField = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 序号 */
  seq?: Maybe<Scalars['Int']>;
  /** 名称 */
  name: Scalars['String'];
  /** 模板 */
  markPrintTemplate: MdoMarkPrintTemplate;
  /** 来源 */
  source: Scalars['ValuedEnum'];
  /** 成品属性字段 */
  mdField?: Maybe<MdField>;
  /** 成品字段 */
  endProductModelField?: Maybe<Scalars['ValuedEnum']>;
  /** 装箱单字段 */
  boxingSheetEntryModelField?: Maybe<Scalars['ValuedEnum']>;
  /** 混装信息格式 */
  mixinInfoFormat?: Maybe<Scalars['ValuedEnum']>;
  /** 混装信息固定内容 */
  mixinFieldValue?: Maybe<Scalars['String']>;
  /** 自定义值 */
  customValue?: Maybe<Scalars['String']>;
};

export type MdoMarkPrintTemplateFieldInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  source: Scalars['ValuedEnum'];
  mdFieldId?: Maybe<Scalars['ID']>;
  endProductModelField?: Maybe<Scalars['ValuedEnum']>;
  boxingSheetEntryModelField?: Maybe<Scalars['ValuedEnum']>;
  mixinInfoFormat?: Maybe<Scalars['ValuedEnum']>;
  mixinFieldValue?: Maybe<Scalars['String']>;
  customValue?: Maybe<Scalars['String']>;
  seq?: Maybe<Scalars['Int']>;
};

/** 唛头模板预设字段 */
export type MdoMarkPrintTemplateFieldPreset = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 名称 */
  name: Scalars['String'];
  /** 来源 */
  source: Scalars['ValuedEnum'];
  /** 成品属性字段 */
  mdField?: Maybe<MdField>;
  /** 成品字段 */
  endProductModelField?: Maybe<Scalars['ValuedEnum']>;
  /** 装箱单字段 */
  boxingSheetEntryModelField?: Maybe<Scalars['ValuedEnum']>;
  /** 混装信息格式 */
  mixinInfoFormat?: Maybe<Scalars['ValuedEnum']>;
  /** 混装信息固定内容 */
  mixinFieldValue?: Maybe<Scalars['String']>;
  /** 自定义值 */
  customValue?: Maybe<Scalars['String']>;
  /** 序号 */
  seq?: Maybe<Scalars['Int']>;
};

export type MdoMarkPrintTemplateFieldPresetGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdoMarkPrintTemplateFieldPresetPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdoMarkPrintTemplateFieldPreset>>>;
};

export type MdoMarkPrintTemplateGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

/**
 * ##############################################################################
 *  Inputs
 * ##############################################################################
 */
export type MdoMarkPrintTemplateInput = {
  name: Scalars['String'];
  customerCode?: Maybe<Scalars['String']>;
  mainPicture?: Maybe<Scalars['File']>;
  files?: Maybe<Array<Maybe<Scalars['File']>>>;
  note?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  fields: Array<Maybe<MdoMarkPrintTemplateFieldInput>>;
};

export type MdoMarkPrintTemplatePage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdoMarkPrintTemplate>>>;
};

/** 装箱方式 */
export type MdoPackingOption = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 名称 */
  name: Scalars['String'];
  /** 客户 */
  customer?: Maybe<MdeCustomer>;
  /** 包装物特征 */
  packingAttributes?: Maybe<Array<Maybe<MdAttribute>>>;
  /** 产品特征 */
  productAttributes?: Maybe<Array<Maybe<MdAttribute>>>;
  /** 是否有内盒 */
  needInnerBox?: Maybe<Scalars['Boolean']>;
  /** 内盒装数 */
  innerBoxQty?: Maybe<Scalars['Int']>;
  /** 内盒特征 */
  innerBoxAttributes?: Maybe<Array<Maybe<MdAttribute>>>;
  /** 内盒长 */
  innerBoxLength?: Maybe<Scalars['Float']>;
  /** 内盒宽 */
  innerBoxWidth?: Maybe<Scalars['Float']>;
  /** 内盒高 */
  innerBoxHeight?: Maybe<Scalars['Float']>;
  /** 内盒尺寸 */
  innerBoxSize?: Maybe<Scalars['String']>;
  /** 内盒体积(m³) */
  innerBoxVolume?: Maybe<Scalars['Float']>;
  /** 外箱装数 */
  outerBoxQty?: Maybe<Scalars['Int']>;
  /** 外箱长 */
  outerBoxLength?: Maybe<Scalars['Float']>;
  /** 外箱宽 */
  outerBoxWidth?: Maybe<Scalars['Float']>;
  /** 外箱高 */
  outerBoxHeight?: Maybe<Scalars['Float']>;
  /** 外箱尺寸 */
  outerBoxSize?: Maybe<Scalars['String']>;
  /** 外箱体积(m³) */
  outerBoxVolume?: Maybe<Scalars['Float']>;
  /** 外箱特征 */
  outerBoxAttributes?: Maybe<Array<Maybe<MdAttribute>>>;
  /** 毛重(KG) */
  grossWeight?: Maybe<Scalars['Float']>;
  /** 净重(KG) */
  netWeight?: Maybe<Scalars['Float']>;
  /** 外箱平卡 */
  horizontalSeparator: Scalars['Boolean'];
  /** 外箱平卡数量 */
  horizontalSeparatorQty?: Maybe<Scalars['Int']>;
  /** 内盒平卡 */
  innerHorizontalSeparator: Scalars['Boolean'];
  /** 内盒平卡数量 */
  innerHorizontalSeparatorQty?: Maybe<Scalars['Int']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 摘要 */
  summary?: Maybe<Scalars['String']>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 装箱方式dump */
  dump: Scalars['String'];
};

export type MdoPackingOptionGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

/**
 * ##############################################################################
 *  Inputs
 * ##############################################################################
 */
export type MdoPackingOptionInput = {
  name: Scalars['String'];
  customer?: Maybe<Scalars['String']>;
  packingAttributes?: Maybe<Array<Maybe<MdAttributeInput>>>;
  productAttributes?: Maybe<Array<Maybe<MdAttributeInput>>>;
  needInnerBox?: Maybe<Scalars['Boolean']>;
  innerBoxQty?: Maybe<Scalars['Int']>;
  innerBoxAttributes?: Maybe<Array<Maybe<MdAttributeInput>>>;
  innerBoxLength?: Maybe<Scalars['Float']>;
  innerBoxWidth?: Maybe<Scalars['Float']>;
  innerBoxHeight?: Maybe<Scalars['Float']>;
  outerBoxQty?: Maybe<Scalars['Int']>;
  outerBoxLength?: Maybe<Scalars['Float']>;
  outerBoxWidth?: Maybe<Scalars['Float']>;
  outerBoxHeight?: Maybe<Scalars['Float']>;
  outerBoxAttributes?: Maybe<Array<Maybe<MdAttributeInput>>>;
  grossWeight?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
  horizontalSeparator?: Maybe<Scalars['Boolean']>;
  horizontalSeparatorQty?: Maybe<Scalars['Int']>;
  innerHorizontalSeparator?: Maybe<Scalars['Boolean']>;
  innerHorizontalSeparatorQty?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
};

export type MdoPackingOptionPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdoPackingOption>>>;
};

/** 订单 */
export type MdoProductOrder = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 编号 */
  code: Scalars['String'];
  /** 客户 */
  customer?: Maybe<MdeCustomer>;
  /** 成品 */
  endProducts: Array<Maybe<MdoEndProduct>>;
  /** 总数量 */
  orderQty?: Maybe<Scalars['Float']>;
  /** 总款数 */
  endProductCount?: Maybe<Scalars['Int']>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 下单人员 */
  principals?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** 跟单人员 */
  participants?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** 属性 */
  attributes: Array<Maybe<MdAttribute>>;
  /** 附件 */
  attachments?: Maybe<Array<Maybe<Scalars['File']>>>;
  /** 币种 */
  currency?: Maybe<MdeCurrency>;
  /** 汇率 */
  exchangeRate?: Maybe<Scalars['Float']>;
  /** 税种 */
  taxType?: Maybe<Scalars['ValuedEnum']>;
  /** 税率 */
  taxRate?: Maybe<Scalars['Float']>;
  /** 额外应收 */
  extraReceivableAmount?: Maybe<Scalars['Float']>;
};

/** 订单 */
export type MdoProductOrderEndProductsArgs = {
  rowRequest?: Maybe<RowRequest>;
};

export type MdoProductOrderGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdoProductOrderInput = {
  principals?: Maybe<Array<Maybe<Scalars['ID']>>>;
  participants?: Maybe<Array<Maybe<Scalars['ID']>>>;
  customer?: Maybe<Scalars['String']>;
  attributes?: Maybe<Array<MdAttributeInput>>;
  note?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Maybe<Scalars['File']>>>;
  currency?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  taxType?: Maybe<Scalars['ValuedEnum']>;
  taxRate?: Maybe<Scalars['Float']>;
};

export type MdoProductOrderPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdoProductOrder>>>;
};

/** 曾经用过的装箱单 */
export type MdoUsedPackingOption = IdBasedModel & {
  id?: Maybe<Scalars['ID']>;
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 客户 */
  customer?: Maybe<MdeCustomer>;
  /** 包装物特征 */
  packingAttributes?: Maybe<Array<Maybe<MdAttribute>>>;
  /** 产品特征 */
  productAttributes?: Maybe<Array<Maybe<MdAttribute>>>;
  /** 名称 */
  name: Scalars['String'];
  /** 外箱属性 */
  outerBoxAttributes?: Maybe<Array<Maybe<MdAttribute>>>;
  /** 外箱尺寸 */
  outerBoxSize?: Maybe<Scalars['String']>;
  /** 外箱装数 */
  outerBoxQty?: Maybe<Scalars['Int']>;
  /** 内盒属性 */
  innerBoxAttributes?: Maybe<Array<Maybe<MdAttribute>>>;
  /** 内盒尺寸 */
  innerBoxSize?: Maybe<Scalars['String']>;
  /** 内盒装数 */
  innerBoxQty?: Maybe<Scalars['Int']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 最近使用 */
  usedDate?: Maybe<Scalars['Date']>;
  /** 是否混装 */
  isMixin?: Maybe<Scalars['Boolean']>;
  /** 来源装箱单记录ID */
  boxingSheetEntryId?: Maybe<Scalars['ID']>;
  /** 内盒材质 */
  innerMaterial?: Maybe<MdDictionaryEntry>;
  /** 外箱材质 */
  outerMaterial?: Maybe<MdDictionaryEntry>;
  /** 唛头模板 */
  markPrintTemplate?: Maybe<MdoMarkPrintTemplate>;
  /** 匹配程度 */
  matchLevel?: Maybe<Scalars['Int']>;
  /** 尾数 */
  residualQty?: Maybe<Scalars['Float']>;
  /** 毛重 */
  boxGrossWeight?: Maybe<Scalars['Float']>;
  /** 净重 */
  boxNetWeight?: Maybe<Scalars['Float']>;
  /** 平卡 */
  horizontalSeparatorNote?: Maybe<Scalars['String']>;
  /** 摘要 */
  summary?: Maybe<Scalars['String']>;
};

/** 附加工艺选项 */
export type MdpAdditionalProcessOption = IdBasedModel & {
  id?: Maybe<Scalars['ID']>;
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 工艺名称 */
  name: Scalars['String'];
  /** 单价 */
  unitPrice?: Maybe<Scalars['Float']>;
  /** 线下报价 */
  offerPriceOffline?: Maybe<Scalars['Boolean']>;
  /** 起订量 */
  moq?: Maybe<Scalars['Float']>;
  /** 是否需提供设计图档 */
  needDesignDocument?: Maybe<Scalars['Boolean']>;
  /** 效果图 */
  effectPicture?: Maybe<Scalars['Boolean']>;
};

/** BOM结构元件 */
export type MdpBomEntry = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 所属结构 */
  structureBom: MdpStructure;
  /** 序号 */
  seq: Scalars['Int'];
  /** 元件类别 */
  category: MdCategoryInfo;
  /** 子件要求 */
  optionalFields?: Maybe<Array<Maybe<MdField>>>;
  /** 了件要求值缓存 */
  optionalFieldDictValueSet?: Maybe<Array<Maybe<MdAttribute>>>;
  /** 用量类型 */
  countType: Scalars['ValuedEnum'];
  /** 用量 */
  count?: Maybe<Scalars['Float']>;
  /** 底数 */
  countBase?: Maybe<Scalars['Int']>;
  /** 占比 */
  weight?: Maybe<Scalars['Float']>;
  /** 单位 */
  unit: MdUnit;
  /** 可选 */
  optional: Scalars['Boolean'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 候选结构 */
  candidateStructures?: Maybe<Array<Maybe<MdpStructure>>>;
};

export type MdpBomEntryInput = {
  uid: Scalars['String'];
  categoryKey: Scalars['String'];
  optionalFieldOptions?: Maybe<Array<Maybe<MdFieldExtraOptionInput>>>;
  countType: Scalars['ValuedEnum'];
  count?: Maybe<Scalars['Float']>;
  countBase?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
  optional?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
};

export type MdpCustomComponent = {
  name: Scalars['String'];
  mdFields: Array<Maybe<MdField>>;
};

export type MdpCustomComponentInput = {
  name: Scalars['String'];
  mdFieldIds: Array<Maybe<Scalars['ID']>>;
};

/** 蛙鞋型号 */
export type MdpFinModel = IdBasedModel & {
  id?: Maybe<Scalars['ID']>;
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 编号 */
  code: Scalars['String'];
  /** 名称 */
  name: Scalars['String'];
  /** 描述 */
  description?: Maybe<Scalars['String']>;
  /** 功能特性 */
  specialAttributes?: Maybe<Array<MdAttribute>>;
  /** 其它属性 */
  otherAttributes?: Maybe<Array<MdAttribute>>;
  /** 主数据特征选项字段 */
  featureFields?: Maybe<Array<Maybe<MdField>>>;
  /** 自定义部件 */
  customComponents?: Maybe<Array<Maybe<MdpCustomComponent>>>;
  /** 上架状态 */
  onShelf?: Maybe<Scalars['ValuedEnum']>;
  /** 尺码 */
  sizes?: Maybe<Array<Maybe<MdpFinSize>>>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  extra?: Maybe<Scalars['Map']>;
};

export type MdpFinModelGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdpFinModelPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdpFinModel>>>;
};

/** 蛙鞋尺码 */
export type MdpFinSize = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 型号 */
  model: MdpFinModel;
  /** 适用人群 */
  applicable?: Maybe<MdDictionaryEntry>;
  /** 尺码 */
  size: Scalars['String'];
  /** 选项编号 */
  dictKey: Scalars['String'];
  /** 选项值 */
  dictValue: Scalars['String'];
  /** 尺码对照 */
  sizeLeagues?: Maybe<Scalars['Json']>;
  /** 尺寸 */
  measures?: Maybe<Scalars['Json']>;
  /** 重量 */
  weights?: Maybe<Scalars['Json']>;
  /** 专用客户 */
  privateCustomerCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 上架状态 */
  onShelf?: Maybe<Scalars['ValuedEnum']>;
  seq: Scalars['Int'];
  extra?: Maybe<Scalars['Json']>;
};

export type MdpFinSizeGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdpFinSizeInput = {
  uid?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  applicableId?: Maybe<Scalars['ID']>;
  dictKey: Scalars['String'];
  dictValue: Scalars['String'];
  size: Scalars['String'];
  sizeLeagues?: Maybe<Scalars['Json']>;
  measures?: Maybe<Scalars['Json']>;
  weights?: Maybe<Scalars['Json']>;
  privateCustomerCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  onShelf?: Maybe<Scalars['ValuedEnum']>;
};

export type MdpFinSizePage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdpFinSize>>>;
};

/** 产品目录大类 */
export type MdpGalleryCatalog = CodeBasedModel & {
  code: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 名称 */
  name: Scalars['String'];
  /** 型号数量 */
  modelCount: Scalars['Int'];
  /** 配置 */
  config?: Maybe<MdpGalleryCatalogConfig>;
  modelClassName: Scalars['String'];
};

export type MdpGalleryCatalogConfig = {
  ver?: Maybe<Scalars['Int']>;
  /** 主数据型号字段 */
  modelField?: Maybe<MdField>;
  /** 主数据款式字段 */
  styleField?: Maybe<MdField>;
  /** 主数据尺码字段 */
  sizeField?: Maybe<MdField>;
  /** 主数据特征选项字段, 配置产品版本 */
  featureFields?: Maybe<Array<Maybe<MdField>>>;
  /** 主数据功能特性字段, 筛选型号用 */
  specialFields?: Maybe<Array<Maybe<MdField>>>;
  /** 主数据其它属性字段, 生成主数据用 */
  otherFields?: Maybe<Array<Maybe<MdField>>>;
  /** 主数据类别 */
  mdCategories?: Maybe<Array<Maybe<MdCategoryInfo>>>;
  /** 产品详情模式 */
  styleMode?: Maybe<Scalars['ValuedEnum']>;
};

/**
 * ##############################################################################
 *  Accessory Types
 * ##############################################################################
 */
export type MdpGalleryFeatureOption = {
  mdField: MdField;
  attributes: Array<Maybe<MdAttribute>>;
};

/** 产品型号 */
export type MdpGalleryModel = CodeBasedModel & {
  code?: Maybe<Scalars['ID']>;
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  modelId?: Maybe<Scalars['ID']>;
  /** 型号编号 */
  modelCode: Scalars['String'];
  /** 名称 */
  name: Scalars['String'];
  /** 描述 */
  description?: Maybe<Scalars['String']>;
  /** 功能特性 */
  specialAttributes?: Maybe<Array<Maybe<MdAttribute>>>;
  /** 自定义部件 */
  customComponents?: Maybe<Array<Maybe<MdpCustomComponent>>>;
  /** 上架状态 */
  onShelf?: Maybe<Scalars['ValuedEnum']>;
  /** 配置 */
  config?: Maybe<MdpGalleryModelConfig>;
  /** 款式 */
  styles?: Maybe<Array<Maybe<MdpGalleryStyle>>>;
  /** SKU */
  skus?: Maybe<Array<Maybe<MdpGallerySku>>>;
  /** 码数信息 */
  sizeInfo?: Maybe<Array<Maybe<MdpGallerySizeGroupData>>>;
  /** 额外信息 */
  extra?: Maybe<Scalars['Json']>;
  /** 主数据型号字段 */
  modelField?: Maybe<MdField>;
  /** 主数据款式字段 */
  styleField?: Maybe<MdField>;
  /** 主数据尺码字段 */
  sizeField?: Maybe<MdField>;
  /** 主数据特征选项字段, 配置产品版本 */
  featureFields?: Maybe<Array<Maybe<MdField>>>;
  /** 主数据类别 */
  mdCategories?: Maybe<Array<Maybe<MdCategoryInfo>>>;
  /** 产品详情模式 */
  styleMode?: Maybe<Scalars['ValuedEnum']>;
};

/** 产品型号 */
export type MdpGalleryModelStylesArgs = {
  all?: Maybe<Scalars['Boolean']>;
};

/** 产品型号 */
export type MdpGalleryModelSkusArgs = {
  all?: Maybe<Scalars['Boolean']>;
};

/** 产品型号上架配置 */
export type MdpGalleryModelConfig = {
  ver?: Maybe<Scalars['Int']>;
  /** 尺码附图 */
  sizeAccessories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 排序权重1, 例: 综合 */
  sortWeight1: Scalars['Int'];
  /** 排序权重2, 例: 销量 */
  sortWeight2: Scalars['Int'];
  /** 排序权重3, 例: 上市时间 */
  sortWeight3: Scalars['Int'];
  /** 列表 推广语 1号位 */
  listItemPromotion1?: Maybe<Scalars['String']>;
  /** 列表 推广语 2号位 */
  listItemPromotion2?: Maybe<Scalars['String']>;
  /** 详情文字内容 */
  detailMarkdown?: Maybe<Scalars['String']>;
  /** 示意图配置 */
  sketchJson?: Maybe<Scalars['Json']>;
  /** 型号最低售价 */
  minPrice?: Maybe<Scalars['Float']>;
  /** 型号最高售价 */
  maxPrice?: Maybe<Scalars['Float']>;
  /** 特征选项 */
  featureOptions?: Maybe<Array<Maybe<MdpGalleryFeatureOption>>>;
};

export type MdpGalleryModelGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdpGalleryModelPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdpGalleryModel>>>;
};

export type MdpGallerySizeGroupData = {
  group?: Maybe<Scalars['String']>;
  sizeKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
  headers?: Maybe<Array<Maybe<Scalars['String']>>>;
  sizeIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  data?: Maybe<Scalars['Json']>;
  extraData?: Maybe<Scalars['Json']>;
};

/** 产品SKU */
export type MdpGallerySku = IdBasedModel & {
  id?: Maybe<Scalars['ID']>;
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 型号 */
  galleryModel: MdpGalleryModel;
  /** 款式 */
  style?: Maybe<MdpGalleryStyle>;
  /** 特征属性 */
  attributes?: Maybe<Array<Maybe<MdAttribute>>>;
  /** 品号 */
  mdDataCode?: Maybe<Scalars['String']>;
  /** 品名 */
  mdDataName?: Maybe<Scalars['String']>;
  /** 规格 */
  mdDataSpec?: Maybe<Scalars['String']>;
  /** 品号 */
  mdData?: Maybe<MdData>;
  /** 生产成本单价 */
  grossUnitCost?: Maybe<Scalars['Float']>;
  /** 建议零售价 */
  retailUnitPrice?: Maybe<Scalars['Float']>;
  /** 基准销售单价 */
  salesUnitPrice?: Maybe<Scalars['Float']>;
  /** 起订量 */
  moq?: Maybe<Scalars['Float']>;
  /** 上架状态 */
  onShelf?: Maybe<Scalars['ValuedEnum']>;
  dump: Scalars['String'];
  extra?: Maybe<Scalars['Json']>;
  /** 校验状态 */
  diagState?: Maybe<Scalars['ValuedEnum']>;
};

export type MdpGallerySkuGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdpGallerySkuInput = {
  styleId: Scalars['ID'];
  skuId?: Maybe<Scalars['ID']>;
  attributes: Array<Maybe<MdAttributeInput>>;
  mdDataCode: Scalars['String'];
};

export type MdpGallerySkuOnShelfInput = {
  id: Scalars['ID'];
  grossUnitCost?: Maybe<Scalars['Float']>;
  retailUnitPrice?: Maybe<Scalars['Float']>;
  salesUnitPrice?: Maybe<Scalars['Float']>;
  moq?: Maybe<Scalars['Float']>;
};

export type MdpGallerySkuPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdpGallerySku>>>;
};

/** 产品目录款式 */
export type MdpGalleryStyle = IdBasedModel & {
  id?: Maybe<Scalars['ID']>;
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 款式名称 */
  name: Scalars['String'];
  /** 名称 */
  pictures?: Maybe<Array<Maybe<Scalars['File']>>>;
  /** 主图片 */
  mainPicture?: Maybe<Scalars['File']>;
  /** SKU */
  skus?: Maybe<Array<Maybe<MdpGallerySku>>>;
  /** 上架状态 */
  onShelf?: Maybe<Scalars['ValuedEnum']>;
  /** 关联结构 */
  mdpStructure?: Maybe<MdpStructure>;
  extra?: Maybe<Scalars['Json']>;
};

export type MdpGalleryStyleInput = {
  uid: Scalars['String'];
  name: Scalars['String'];
  pictures?: Maybe<Array<Maybe<Scalars['File']>>>;
  mainPicture: Scalars['File'];
  onShelf?: Maybe<Scalars['ValuedEnum']>;
  mdpStructureId?: Maybe<Scalars['ID']>;
};

/** 蛙鞋型号 */
export type MdpGoggleModel = IdBasedModel & {
  id?: Maybe<Scalars['ID']>;
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 编号 */
  code: Scalars['String'];
  /** 名称 */
  name: Scalars['String'];
  /** 描述 */
  description?: Maybe<Scalars['String']>;
  /** 功能特性 */
  specialAttributes?: Maybe<Array<MdAttribute>>;
  /** 其它属性 */
  otherAttributes?: Maybe<Array<MdAttribute>>;
  /** 主数据特征选项字段 */
  featureFields?: Maybe<Array<Maybe<MdField>>>;
  /** 自定义部件 */
  customComponents?: Maybe<Array<Maybe<MdpCustomComponent>>>;
  /** 上架状态 */
  onShelf?: Maybe<Scalars['ValuedEnum']>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  extra?: Maybe<Scalars['Json']>;
};

export type MdpGoggleModelGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdpGoggleModelPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdpGoggleModel>>>;
};

/** 结构化视图 */
export type MdpMdDataView = IdBasedModel & {
  id?: Maybe<Scalars['ID']>;
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 名称 */
  name: Scalars['String'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 查看字段 */
  viewMdFields?: Maybe<Array<Maybe<Array<MdField>>>>;
  /** 展示字段 */
  displayMdFields?: Maybe<Array<Maybe<MdField>>>;
  /** 查询条件 */
  filterCondition?: Maybe<Scalars['Json']>;
};

export type MdpMdDataViewGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdpMdDataViewPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdpMdDataView>>>;
};

/** 产品结构 */
export type MdpStructure = IdBasedModel & {
  id?: Maybe<Scalars['ID']>;
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 结构类型 */
  structureType: Scalars['ValuedEnum'];
  /** 类别 */
  category: MdCategoryInfo;
  /** 名称 */
  name: Scalars['String'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 属性 */
  attributes?: Maybe<Array<MdAttribute>>;
  /** 可选属性字段 */
  optionalFields?: Maybe<Array<Maybe<MdField>>>;
  /** 结构Bom元件 */
  bomEntries?: Maybe<Array<MdpBomEntry>>;
  /** 包材元件 */
  wrapperBomEntries?: Maybe<Array<MdpWrapperBomEntry>>;
  /** Bom元件个数 */
  bomEntryCount?: Maybe<Scalars['Int']>;
  /** 主图片 */
  mainPicture?: Maybe<Scalars['File']>;
  /** 产品图片 */
  pictures?: Maybe<Array<Scalars['File']>>;
  /** 毛重 */
  grossWeight?: Maybe<Scalars['Float']>;
  /** 毛重 */
  netWeight?: Maybe<Scalars['Float']>;
  /** 属性dump */
  attrDump?: Maybe<Scalars['String']>;
  /** BOM结构dump */
  bomDump?: Maybe<Scalars['String']>;
  /** 关联主数据 */
  mdDataCount: Scalars['Int'];
  /** 校验结果 */
  diagState?: Maybe<Scalars['ValuedEnum']>;
  extra?: Maybe<Scalars['Json']>;
  /** 是否可附加印刷工艺 */
  allowPrintProcess: Scalars['Boolean'];
  printManualTemplate?: Maybe<MdpWorkManualTemplate>;
};

export type MdpStructureGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdpStructureMdDataView = {
  id?: Maybe<Scalars['ID']>;
  /** 结构名称 */
  mdpStructureName?: Maybe<Scalars['String']>;
  /** 上架状态 */
  onShelf?: Maybe<Scalars['ValuedEnum']>;
  /** 品号 */
  mdDataCode: Scalars['String'];
  /** 品名 */
  mdDataName: Scalars['String'];
  /** 规格 */
  mdDataSpec: Scalars['String'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 属性 */
  attributes?: Maybe<Array<MdAttribute>>;
  /** 额外信息 */
  extra?: Maybe<Scalars['Json']>;
  /** Bom元件个数 */
  bomEntryCount?: Maybe<Scalars['Int']>;
  /** 引用Bom数量 */
  referredByBomCount?: Maybe<Scalars['Int']>;
};

export type MdpStructureMdDataViewGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdpStructureMdDataViewPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdpStructureMdDataView>>>;
};

export type MdpStructurePage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdpStructure>>>;
};

/** 产品结构字段 */
export type MdpStructureTemplate = {
  /** 类别 */
  category: MdCategoryInfo;
  /** 字段 */
  mdFields: Array<MdField>;
  /** 可选字段 */
  optionalFields?: Maybe<Array<MdField>>;
  /** 查看模板字段 */
  viewMdFields?: Maybe<Array<MdField>>;
  /** 自动产生结构 */
  generateStructure: Scalars['Boolean'];
};

/** 结构关联的主数据 */
export type MdpStructureToMdData = {
  id?: Maybe<Scalars['ID']>;
  /** 上架状态 */
  onShelf?: Maybe<Scalars['ValuedEnum']>;
  /** 结构 */
  mdpStructure: MdpStructure;
  /** 品号 */
  mdDataCode: Scalars['String'];
  /** 品名 */
  mdDataName: Scalars['String'];
  /** 规格 */
  mdDataSpec: Scalars['String'];
  /** 主数据 */
  mdData?: Maybe<MdData>;
};

export type MdpStructureToMdDataGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdpStructureToMdDataPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdpStructureToMdData>>>;
};

/** 作业指导模板 */
export type MdpWorkManualTemplate = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 名称 */
  name: Scalars['String'];
  /** 描述内容 */
  content: Scalars['String'];
  /** 模板字段 */
  templateFields?: Maybe<Array<Maybe<MdField>>>;
};

export type MdpWorkManualTemplateGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MdpWorkManualTemplatePage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdpWorkManualTemplate>>>;
};

/** 包材结构元件 */
export type MdpWrapperBomEntry = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 所属结构 */
  mdpStructure: MdpStructure;
  /** 元件类别 */
  category: MdCategoryInfo;
  /** 包材要求 */
  packingFieldOptions?: Maybe<Array<Maybe<MdField>>>;
  /** 包材取值范围 */
  packingFieldDictValueSet?: Maybe<Array<Maybe<MdAttribute>>>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
};

export type MdpWrapperBomEntryValues = {
  uid: Scalars['String'];
  categoryKey: Scalars['String'];
  optionalFieldOptions?: Maybe<Array<Maybe<MdFieldExtraOptionInput>>>;
  note?: Maybe<Scalars['String']>;
};

export type MdxAttributeStructureNode = {
  fieldNode?: Maybe<MdxStructureTreeNode>;
  attribute?: Maybe<MdAttribute>;
};

export type MdxDataHelpCodeInput = {
  mdDataId: Scalars['ID'];
  mdDataVer: Scalars['Int'];
  serial: Scalars['Int'];
};

export type MdxDataWithStructure = {
  code: Scalars['String'];
  field0?: Maybe<Scalars['String']>;
  field1?: Maybe<Scalars['String']>;
  field2?: Maybe<Scalars['String']>;
  field3?: Maybe<Scalars['String']>;
  field4?: Maybe<Scalars['String']>;
  field5?: Maybe<Scalars['String']>;
  field6?: Maybe<Scalars['String']>;
  field7?: Maybe<Scalars['String']>;
  field8?: Maybe<Scalars['String']>;
  field9?: Maybe<Scalars['String']>;
  attributes: Array<MdxAttributeStructureNode>;
  errors?: Maybe<Array<ErrorInfo>>;
};

/** 命名规则 */
export type MdxNamingRule = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 类别 */
  category: MdCategoryInfo;
  /** 品号命名规则 */
  productCodeNamingRule?: Maybe<Scalars['Json']>;
  /** 品名命名规则 */
  productNameNamingRule?: Maybe<Scalars['Json']>;
  /** 规则命名规则 */
  productSpecNamingRule?: Maybe<Scalars['Json']>;
  /** 助记码命名规则 */
  productHelpCodeNamingRule?: Maybe<Scalars['Json']>;
  /** 校验结果 */
  diagState?: Maybe<Scalars['ValuedEnum']>;
};

export type MdxNamingRuleGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export enum MdxNamingRuleMdFieldReferenceType {
  Key = 'key',
  Value = 'value',
  ValueForShort = 'valueForShort'
}

export type MdxNamingRulePage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MdxNamingRule>>>;
};

export type MdxNamingRuleValues = {
  productCodeNamingRule?: Maybe<Scalars['Json']>;
  productNameNamingRule?: Maybe<Scalars['Json']>;
  productSpecNamingRule?: Maybe<Scalars['Json']>;
  productHelpCodeNamingRule?: Maybe<Scalars['Json']>;
};

/** 分支节点 */
export type MdxStructureBranchNode = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 父节点，只能是字段节点 */
  parentNode: MdxStructureFieldNode;
  /** 分支名称 */
  name: Scalars['String'];
  /** 分支条件 */
  filterCondition?: Maybe<Scalars['Json']>;
  /** 获取子字段节点 */
  children?: Maybe<Array<MdxStructureBranchNode>>;
  /** 校验结果 */
  diagState?: Maybe<Scalars['ValuedEnum']>;
};

export type MdxStructureBranchNodeValues = {
  name?: Maybe<Scalars['String']>;
  filterCondition?: Maybe<Scalars['Json']>;
};

/** 字段节点 */
export type MdxStructureFieldNode = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 父节点，只能是分支节点 */
  parentNode?: Maybe<MdxStructureBranchNode>;
  /** 别名 */
  alias?: Maybe<Scalars['String']>;
  /** 是否特征属性 */
  feature: Scalars['Boolean'];
  /** bom特征属性 */
  bomFeature: Scalars['Boolean'];
  /** bom级联特征属性,做品号的时候下阶有相同的字段则从主件取默认值 */
  bomCascadingFeature: Scalars['Boolean'];
  /** 是否材质 */
  materialField: Scalars['Boolean'];
  /** 是否产品型号 */
  productTypeField: Scalars['Boolean'];
  /** 是否必填 */
  mandatory: Scalars['Boolean'];
  /** 在父节点中的顺序 */
  seq: Scalars['Int'];
  /** 字段 */
  mdField: MdField;
  /** 字段默认值 */
  defaultValue?: Maybe<Scalars['Json']>;
  /** 取值范围 */
  valueRange?: Maybe<Scalars['Json']>;
  /** 获取子分支节点 */
  children?: Maybe<Array<MdxStructureFieldNode>>;
  /** 校验结果 */
  diagState?: Maybe<Scalars['ValuedEnum']>;
};

export type MdxStructureFieldNodeValues = {
  alias?: Maybe<Scalars['String']>;
  feature?: Maybe<Scalars['Boolean']>;
  bomCascadingFeature?: Maybe<Scalars['Boolean']>;
  bomFeature?: Maybe<Scalars['Boolean']>;
  productTypeField?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['Json']>;
  valueRange?: Maybe<Scalars['Json']>;
  materialField?: Maybe<Scalars['Boolean']>;
  mandatory?: Maybe<Scalars['Boolean']>;
};

export type MdxStructureTreeNode = {
  nodeKey?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  feature?: Maybe<Scalars['Boolean']>;
  bomCascadingFeature?: Maybe<Scalars['Boolean']>;
  bomFeature?: Maybe<Scalars['Boolean']>;
  materialField?: Maybe<Scalars['Boolean']>;
  productTypeField?: Maybe<Scalars['Boolean']>;
  mandatory?: Maybe<Scalars['Boolean']>;
  valueRange?: Maybe<Scalars['Json']>;
  filterCondition?: Maybe<Scalars['Json']>;
  mdField: MdField;
};

/** 销售代理 */
export type MetAgent = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 姓名 */
  name: Scalars['String'];
  /** 别名 */
  nickName?: Maybe<Scalars['String']>;
  /** 联系电话 */
  phone?: Maybe<Scalars['String']>;
  /** 邮箱 */
  email?: Maybe<Scalars['String']>;
  /** 外部人员 */
  external: Scalars['Boolean'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 提成结算状态 */
  bonusState?: Maybe<MetAgentBonusStatus>;
  /** 未结算提成 */
  unsettledBonusAmount?: Maybe<Scalars['Float']>;
};

/** 销售代理 */
export type MetAgentBonusStateArgs = {
  settleType?: Maybe<Scalars['ValuedEnum']>;
};

/** 销售代理 */
export type MetAgentUnsettledBonusAmountArgs = {
  settleType?: Maybe<Scalars['ValuedEnum']>;
};

export type MetAgentBonusStatus = {
  bonusAmount: Scalars['Float'];
  settledAmount: Scalars['Float'];
  surplus: Scalars['Float'];
};

export type MetAgentGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MetAgentPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MetAgent>>>;
};

/** 提成明细 */
export type MetBonus = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 回款记录 */
  receivedRecord: MetParticipantReceivedRecord;
  /** 销售代理 */
  agent: MetAgent;
  /** 结算类型 */
  settleType: Scalars['ValuedEnum'];
  /** 提成比例 */
  bonusRate: Scalars['Float'];
  /** 提成金额 */
  bonusAmount: Scalars['Float'];
  /** 是否已结算 */
  settled: Scalars['Boolean'];
  /** 结算记录 */
  settling?: Maybe<MetBonusSettling>;
};

export type MetBonusGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MetBonusPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MetBonus>>>;
};

/** 提成结算记录 */
export type MetBonusSettling = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 销售代理 */
  agent: MetAgent;
  /** 结算类型 */
  settleType: Scalars['ValuedEnum'];
  /** 结算金额 */
  amount: Scalars['Float'];
  /** 入账时间 */
  operatedAt: Scalars['Date'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 附件 */
  attachments?: Maybe<Array<Maybe<Scalars['File']>>>;
  /** 已结算提成明细 */
  bonuses?: Maybe<Array<Maybe<MetBonus>>>;
};

export type MetBonusSettlingGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MetBonusSettlingPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MetBonusSettling>>>;
};

/** 客户 */
export type MetCustomer = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 公司名称 */
  name: Scalars['String'];
  /** 公司联系人 */
  contacts?: Maybe<Array<Maybe<MetCustomerContact>>>;
  /** 客户来源 */
  introducers?: Maybe<Array<Maybe<MetIntroducer>>>;
  /** 来源信息 */
  introducerInfo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 公司联系人 */
  defaultContact?: Maybe<MetCustomerContact>;
  /** 属性 */
  attributes?: Maybe<Array<Maybe<MdAttribute>>>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 销售负责人 */
  agent?: Maybe<MetAgent>;
  /** 会员状态 */
  memberState: Scalars['ValuedEnum'];
  /** 会员加入时间 */
  membershipJoinedAt?: Maybe<Scalars['Date']>;
  /** 会员到期时间 */
  membershipExpiredAt?: Maybe<Scalars['Date']>;
  /** 上次拒绝时间 */
  membershipRejectedAt?: Maybe<Scalars['Date']>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** 客户 */
export type MetCustomerAttributesArgs = {
  mdFieldIds?: Maybe<Array<Scalars['ID']>>;
};

/** 客户联系人 */
export type MetCustomerContact = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 姓名 */
  name: Scalars['String'];
  /** 职位 */
  position?: Maybe<Scalars['String']>;
  /** 电话 */
  phone?: Maybe<Scalars['String']>;
  /** 微信 */
  wechat?: Maybe<Scalars['String']>;
  /** 邮箱 */
  email?: Maybe<Scalars['String']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 序号 */
  seq?: Maybe<Scalars['Int']>;
  /** 来源渠道 */
  introducers?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MetCustomerContactGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MetCustomerContactInput = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  position?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  wechat?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  introducers?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MetCustomerContactPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MetCustomerContact>>>;
};

export type MetCustomerGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MetCustomerPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MetCustomer>>>;
};

export type MetImportResult = {
  importCount: Scalars['Int'];
  insertCount: Scalars['Int'];
  updateCount: Scalars['Int'];
  errorCount: Scalars['Int'];
};

/** 客户来源 */
export type MetIntroducer = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 客户 */
  customer: MetCustomer;
  /** 转介类型 */
  introducingType: MdDictionaryEntry;
  /** 转介信息 */
  cookedInfo?: Maybe<Scalars['String']>;
  info?: Maybe<Scalars['Json']>;
  /** 转介时间 */
  introducedAt?: Maybe<Scalars['DateTime']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
};

export type MetIntroducerInput = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  introducingTypeId: Scalars['ID'];
  fundProjectId?: Maybe<Scalars['ID']>;
  customerId?: Maybe<Scalars['ID']>;
  contact?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  introducedAt?: Maybe<Scalars['DateTime']>;
  note?: Maybe<Scalars['String']>;
};

/** 会议活动 */
export type MetMeeting = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 名称 */
  name: Scalars['String'];
  /** 计划开始时间 */
  planStartAt?: Maybe<Scalars['DateTime']>;
  /** 计划结束时间 */
  planEndAt?: Maybe<Scalars['DateTime']>;
  /** 活动内容 */
  description?: Maybe<Scalars['String']>;
  /** 活动地点 */
  location?: Maybe<Scalars['String']>;
  /** 附件 */
  attachments?: Maybe<Array<Maybe<Scalars['File']>>>;
  /** 状态 */
  state: Scalars['ValuedEnum'];
  /** 总合同金额 */
  totalContractAmount: Scalars['Float'];
  /** 已收款金额 */
  totalReceivedAmount: Scalars['Float'];
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MetMeetingGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MetMeetingPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MetMeeting>>>;
};

/** 入会流程 */
export type MetMembershipFlow = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 客户 */
  customer: MetCustomer;
  /** 流程状态 */
  state: Scalars['ValuedEnum'];
  /** 流程记录 */
  records?: Maybe<Array<Maybe<MetMembershipFlowRecord>>>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 负责人 */
  principals?: Maybe<Array<Maybe<User>>>;
  /** 销售负责人 */
  agent?: Maybe<MetAgent>;
  /** 标签 */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MetMembershipFlowGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type MetMembershipFlowPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MetMembershipFlow>>>;
};

/**
 * ##############################################################################
 *  Accessory Types
 * ##############################################################################
 */
export type MetMembershipFlowRecord = {
  uid: Scalars['String'];
  user: User;
  oldState: Scalars['ValuedEnum'];
  newState: Scalars['ValuedEnum'];
  content?: Maybe<Scalars['String']>;
  postAt: Scalars['DateTime'];
  attachment?: Maybe<Scalars['String']>;
};

/** 活动参与 */
export type MetParticipant = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 活动 */
  meeting: MetMeeting;
  /** 参与方 */
  customer: MetCustomer;
  /** 销售负责人 */
  agent?: Maybe<MetAgent>;
  /** 合同联系人 */
  contractAgent?: Maybe<MetAgent>;
  /** 合同编号 */
  contractCode?: Maybe<Scalars['String']>;
  /** 展位类型 */
  standType?: Maybe<MdDictionaryEntry>;
  /** 展位面积(m²) */
  standSize?: Maybe<Scalars['Float']>;
  /** 赞助金额 */
  sponsorAmount?: Maybe<Scalars['Float']>;
  /** 赞助详情 */
  sponsorNote?: Maybe<Scalars['String']>;
  /** 广告金额 */
  advertisingAmount?: Maybe<Scalars['Float']>;
  /** 广告详情 */
  advertisingNote?: Maybe<Scalars['String']>;
  /** 合同金额 */
  contractAmount?: Maybe<Scalars['Float']>;
  /** 提成比例 */
  agentBonusRate?: Maybe<Scalars['Float']>;
  /** 分结算方式提成比例 */
  agentSettlingTypeBonusRate?: Maybe<Scalars['Map']>;
  /** 合同状态 */
  contractState?: Maybe<Scalars['ValuedEnum']>;
  /** 合同原件存档 */
  contractDept?: Maybe<MdDictionaryEntry>;
  /** 合同详情 */
  contractNote?: Maybe<Scalars['String']>;
  /** 合同修改内容 */
  contractDiffNote?: Maybe<Scalars['String']>;
  /** 合同文件 */
  contractFiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 合同确认书 */
  contractAckFiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 其它附件 */
  otherFiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 邀请时间 */
  invitedAt?: Maybe<Scalars['DateTime']>;
  /** 接受时间 */
  acceptedAt?: Maybe<Scalars['DateTime']>;
  /** 邀请备注 */
  invitationNote?: Maybe<Scalars['String']>;
  /** 参与状态 */
  attendingState: Scalars['ValuedEnum'];
  /** 发票核销状态 */
  invoiceChargedState: Scalars['ValuedEnum'];
  /** 已开票金额 */
  invoicedAmount?: Maybe<Scalars['Float']>;
  /** 待开发票 */
  hasAppliedInvoice?: Maybe<Scalars['Boolean']>;
  /** 收款核销状态 */
  receivableChargedState: Scalars['ValuedEnum'];
  /** 已收款金额 */
  receivedAmount?: Maybe<Scalars['Float']>;
  /** 回款备注 */
  billingNote?: Maybe<Scalars['String']>;
  /** 发票信息 */
  invoices?: Maybe<Array<Maybe<MetParticipantInvoice>>>;
  /** 付款记录 */
  receivedRecords?: Maybe<Array<Maybe<MetParticipantReceivedRecord>>>;
};

export type MetParticipantGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

/** 参会发票记录 */
export type MetParticipantInvoice = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 活动 */
  meeting: MetMeeting;
  /** 参会客户 */
  participant: MetParticipant;
  /** 发票信息 */
  invoice: MdInvoice;
};

export type MetParticipantPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<MetParticipant>>>;
};

/** 参会收退款记录 */
export type MetParticipantReceivedRecord = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 活动 */
  meeting: MetMeeting;
  /** 参会客户 */
  participant: MetParticipant;
  /** 收退款类型 */
  receiving: Scalars['ValuedEnum'];
  /** 金额 */
  amount: Scalars['Float'];
  /** 入账时间 */
  operatedAt: Scalars['Date'];
  /** 银行交易流水号 */
  bankSerialCode?: Maybe<Scalars['String']>;
  /** 交易银行 */
  bank?: Maybe<Scalars['String']>;
  /** 交易银行账号 */
  bankAccount?: Maybe<Scalars['String']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 附件 */
  attachments?: Maybe<Array<Maybe<Scalars['File']>>>;
  /** 提成明细 */
  bonuses?: Maybe<Array<Maybe<MetBonus>>>;
};

export type Mutation = {
  /** 生成纸箱纸板品号 */
  generateMdoBoxMdDatas?: Maybe<Array<Maybe<MdoBoxingMdData>>>;
  /** 保存纸箱纸板品号 */
  saveMdoBoxMdDatas?: Maybe<Array<Maybe<MdoBoxingMdData>>>;
  /** 下发纸箱工单 */
  saveMdoBoxWorkOrders?: Maybe<Array<Maybe<MdoBoxWorkOrder>>>;
  /** 设置工单采购结单 */
  setMdoBoxWorkOrderPurchaseFinished?: Maybe<Scalars['Void']>;
  /** 设置工单状态 */
  setMdoBoxWorkOrderState?: Maybe<Scalars['Void']>;
  /** 设置采购对象 */
  setMdoBoxWorkOrderPurchaseTarget?: Maybe<Scalars['Void']>;
  /** 编辑工单 */
  editMdoBoxWorkOrder?: Maybe<MdoBoxWorkOrder>;
  /** 复制纸箱工单 */
  copyMdoBoxWorkOrder?: Maybe<MdoBoxWorkOrder>;
  /** 删除纸箱工单 */
  deleteMdoBoxWorkOrder?: Maybe<Scalars['Void']>;
  /** 发送纸片采购需求变更通知 */
  notifyMdoBoxPaperboardPurchaseRequisitionChanges?: Maybe<Scalars['Void']>;
  /** 保存会议活动 */
  saveMetMeeting: MetMeeting;
  /** 设置会议状态 */
  setMetMeetingState: MetMeeting;
  /** 保存客户 */
  saveMetCustomer: MetCustomer;
  /** 保存代理 */
  saveMetAgent: MetAgent;
  /** 添加活动参与 */
  addMetParticipants: Array<Maybe<MetParticipant>>;
  /** 移出活动参与 */
  removeMetParticipants?: Maybe<Scalars['Void']>;
  /** 填写备注 */
  setMetParticipantNote: MetParticipant;
  /** 填写合同信息 */
  setMetParticipantContract: MetParticipant;
  /** 设置参与状态 */
  setMetParticipantInvitation: MetParticipant;
  /** 保存参会发票记录 */
  saveMetParticipantInvoice: MetParticipant;
  /** 删除参会发票记录 */
  deleteMetParticipantInvoice: MetParticipant;
  /** 保存参会收款记录 */
  saveMetParticipantReceivedRecord: MetParticipant;
  /** 删除参会收款记录 */
  deleteMetParticipantReceivedRecord: MetParticipant;
  /** 修改提成 */
  saveMetBonus: MetBonus;
  /** 结算提成 */
  settleMetBonus?: Maybe<MetBonusSettling>;
  /** 撤销结算提成 */
  revokeMetBonusSettling?: Maybe<Scalars['Void']>;
  /** 设置回款 */
  setMetParticipantBillingState: MetParticipant;
  /** 创建导入客户表格 */
  createMetCustomerImportTable?: Maybe<Scalars['String']>;
  /** 执行导入客户数据校验 */
  checkMetCustomerImportData?: Maybe<MetImportResult>;
  /** 执行导入客户数据 */
  executeMetCustomerImport?: Maybe<MetImportResult>;
  /** 创建导入收款记录表格 */
  createMetParticipantReceivedRecordImportTable?: Maybe<Scalars['String']>;
  /** 执行导入收款记录数据校验 */
  checkMetParticipantReceivedRecordImportData?: Maybe<MetImportResult>;
  /** 执行导入收款记录数据 */
  executeMetParticipantReceivedRecordImport?: Maybe<MetImportResult>;
  /** 创建入会流程 */
  createMetMembershipFlow: MetMembershipFlow;
  /** 入会流程操作 */
  takeMetMembershipFlowAction: MetMembershipFlow;
  /** 指定入会流程负责人 */
  assignMetMembershipFlowPrincipals: MetMembershipFlow;
  /** 保存产品结构字段 */
  saveMdaStructureTemplate?: Maybe<MdaStructureTemplate>;
  /** 添加字段子节点 */
  createMdxStructureFieldNode?: Maybe<Scalars['Json']>;
  /** 编辑字段节点 */
  updateMdxStructureFieldNode?: Maybe<Scalars['Json']>;
  /** 移动字段节点 */
  moveMdxStructureNode?: Maybe<Scalars['Json']>;
  deleteMdxStructureFieldNode?: Maybe<Scalars['Int']>;
  /** 添加分支节点 */
  createMdxStructureBranchNode?: Maybe<Scalars['Json']>;
  /** 编辑分支节点 */
  updateMdxStructureBranchNode?: Maybe<Scalars['Json']>;
  /** 工程品号转正 */
  convertEngineeringMdData?: Maybe<Array<Maybe<MdDataSavingResult>>>;
  deleteMdxStructureBranchNode?: Maybe<Scalars['Int']>;
  validateMdxStructureTree?: Maybe<Array<Maybe<MdDiagnosis>>>;
  createMdDataWithAttribute?: Maybe<MdData>;
  batchUpdateMdDataHelpCode?: Maybe<Array<Maybe<MdData>>>;
  /** 创建订单设计稿 */
  insertMdoDesignDocument?: Maybe<MdoDesignDocument>;
  /** 修改订单设计稿 */
  updateMdoDesignDocument?: Maybe<MdoDesignDocument>;
  /** 创建订单图档 */
  insertMdoDesignDocumentUnit?: Maybe<MdoDesignDocumentUnit>;
  /** 修改订单图档 */
  updateMdoDesignDocumentUnit?: Maybe<MdoDesignDocumentUnit>;
  /** 继续导入 */
  migrateMdTally?: Maybe<MdmTallyMigrateResult>;
  /** 清除数据并重新导入 */
  resetData?: Maybe<MdmTallyMigrateResult>;
  /** 保存蛙鞋型号 */
  saveMdpFinModel?: Maybe<MdpFinModel>;
  /** 保存泳镜型号 */
  saveMdpGoggleModel?: Maybe<MdpFinModel>;
  /** 更新选项表迁移设置 */
  updateMdDictionaryEntryNeedSync?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** 迁移选项表 */
  syncMdDictionaryEntry?: Maybe<Array<Maybe<ErrorInfo>>>;
  /** 创建产品结构 */
  createMdpStructure?: Maybe<MdpStructure>;
  /** 修改产品结构 */
  updateMdpStructure?: Maybe<MdpStructure>;
  /** 保存产品结构图片 */
  saveMdpStructurePictures?: Maybe<MdpStructure>;
  /** 保存产品结构模板 */
  saveMdpStructureTemplate?: Maybe<MdpStructureTemplate>;
  /** 关联品号 */
  saveStructureToMdData?: Maybe<Scalars['Void']>;
  /** 取消关联品号 */
  deleteMdpStructureToMdData?: Maybe<Scalars['Void']>;
  /** 上架/下架 */
  setStructureToMdDataOnShelf?: Maybe<Scalars['Void']>;
  updateMdpBomEntryFieldDictValueSet?: Maybe<Scalars['Void']>;
  /** 修改包材结构 */
  updateMdpWrapperBomEntry?: Maybe<MdpStructure>;
  /** 修改结构化视图 */
  saveMdpMdDataView?: Maybe<MdpMdDataView>;
  /** 设置分析结果 */
  saveMdaLensColours?: Maybe<Scalars['Void']>;
  saveMdScriptProcessor?: Maybe<MdScriptProcessor>;
  deleteMdScriptProcessor?: Maybe<Scalars['Void']>;
  executeMdScriptProcessor?: Maybe<MdScriptExecutionResult>;
  /** 基于类别设置参数 */
  setMdcCategoryParam?: Maybe<MdCategory>;
  /** 创建唛头模板 */
  insertMdoMarkPrintTemplate?: Maybe<MdoMarkPrintTemplate>;
  /** 修改创建唛头模板 */
  updateMdoMarkPrintTemplate?: Maybe<MdoMarkPrintTemplate>;
  /** 新建主数据检索条件 */
  createMdcDataSearch?: Maybe<MdcDataSearch>;
  /** 更新主数据检索条件 */
  updateMdcDataSearch?: Maybe<MdcDataSearch>;
  /** 保存订单 */
  saveMdoProductOrder?: Maybe<MdoProductOrder>;
  deleteMdoProductOrder?: Maybe<Scalars['Void']>;
  /** 保存订单成品基本信息 */
  saveMdoEndProduct?: Maybe<MdoEndProduct>;
  /** 复制订单成品 */
  copyMdoEndProduct?: Maybe<MdoEndProduct>;
  /** 保存应收与数量 */
  saveMdoEndProductReceivable?: Maybe<MdoEndProduct>;
  /** 设置成品混装比例数量 */
  saveMdoEndProductMixinQty?: Maybe<Array<Maybe<MdoEndProduct>>>;
  closeMdoEndProduct?: Maybe<Scalars['Void']>;
  /** 设置成品信息的工程部分 */
  updateMdoEndProductPeInfo?: Maybe<MdoEndProductInfo>;
  /** 从成品信息更新成品的只读属性 */
  updateMdoEndProductAttrs?: Maybe<Scalars['Int']>;
  /** 设置主要产品信息 */
  setMdoMainProduct?: Maybe<MdoMainProduct>;
  /** 提交成品状态 */
  submitMdoEndProductState?: Maybe<MdoEndProduct>;
  /** 提交主要产品开发状态 */
  submitMdoMainProductEngineeringState?: Maybe<MdoMainProduct>;
  /** 提交成品信息工程开发状态 */
  submitMdoEndProductEngineeringState?: Maybe<MdoEndProductInfo>;
  /** 删除订单成品 */
  deleteMdoEndProduct?: Maybe<Scalars['Void']>;
  /** 设置是否需要装箱单 */
  setMdoBoxingIgnore?: Maybe<MdoBoxingSheet>;
  /** 保存装箱单 */
  saveMdoBoxingSheet?: Maybe<MdoBoxingSheet>;
  /** 设置自选装箱单记录箱数 */
  saveFreeMdoBoxingSheetEntries?: Maybe<MdoBoxingSheet>;
  /** 保存箱号 */
  saveMdoBoxingSheetEntryBoxNo?: Maybe<MdoBoxingSheet>;
  /** 保存箱重 */
  saveMdoBoxingSheetEntryWeight?: Maybe<MdoBoxingSheet>;
  /** 设置是否需实称 */
  setMdoBoxingNeedWeigh?: Maybe<MdoBoxingSheet>;
  /** 保存实称箱重 */
  saveMdoBoxingSheetEntryActualWeight?: Maybe<MdoBoxingSheet>;
  /** 禁用/启用装箱单历史 */
  setMdoBoxingSheetEntryMatchUsed?: Maybe<Scalars['Void']>;
  /** 采用或忽略异常实称箱重 */
  applyMdoBoxingActualWeight?: Maybe<MdoBoxingSheetEntry>;
  /** 创建成品信息模板 */
  insertMdoEndProductInfoTemplate?: Maybe<MdoEndProductInfoTemplate>;
  /** 修改成品信息模板 */
  updateMdoEndProductInfoTemplate?: Maybe<MdoEndProductInfoTemplate>;
  deleteMdoEndProductInfoTemplate?: Maybe<Scalars['Void']>;
  /** 跟单人员设置 */
  setUserFollowers?: Maybe<Scalars['Json']>;
  /** 保存发票 */
  saveMdInvoice?: Maybe<MdInvoice>;
  /** 设置发票已开 */
  setMdInvoiceIssued?: Maybe<MdInvoice>;
  /** 设置发票撤回 */
  setMdInvoiceRevoked?: Maybe<MdInvoice>;
  saveMdFieldTemplate: MdFieldTemplate;
  saveMdFieldTemplateEntry: MdFieldTemplateEntry;
  moveMdFieldTemplateEntry: MdFieldTemplateEntry;
  deleteMdFieldTemplateEntry?: Maybe<Scalars['Void']>;
  saveMdFieldTemplateBranch: MdFieldTemplateBranch;
  deleteMdFieldTemplateBranch?: Maybe<Scalars['Void']>;
  /** 新增工作组 */
  createRoleGroup?: Maybe<RoleGroup>;
  /** 修改工作组 */
  updateRoleGroup?: Maybe<RoleGroup>;
  /** 新增角色 */
  createRole?: Maybe<Role>;
  /** 修改角色 */
  updateRole?: Maybe<Role>;
  /** 给角色分配用户 */
  assignUserToRole?: Maybe<Role>;
  /** 从角色中移除用户 */
  removeUserFromRole?: Maybe<Role>;
  /** 创建权限特例 */
  createPermissionSpecial?: Maybe<PermissionSpecial>;
  /** 修改权限特例 */
  updatePermissionSpecial?: Maybe<PermissionSpecial>;
  /** 删除权限特例 */
  deletePermissionSpecial?: Maybe<Scalars['Int']>;
  /** 关闭权限 */
  closePermission?: Maybe<Permission>;
  /** 开放权限 */
  openPermission?: Maybe<Permission>;
  /** 恢复关闭或开放权限 */
  restorePermission?: Maybe<Permission>;
  /** 保存单位 */
  saveMdUnit?: Maybe<MdUnit>;
  /** 保存单位转换关系 */
  saveMdUnitConversion?: Maybe<MdUnitConversion>;
  saveMdUnitConversions?: Maybe<Scalars['Void']>;
  /** 新建文件 */
  newMdFile?: Maybe<Scalars['Void']>;
  /** 新建文件夹 */
  newMdFileDir?: Maybe<MdFile>;
  /** 上传文件新版本 */
  newMdFileVersion?: Maybe<MdFile>;
  /** 移动文件 */
  moveMdFile?: Maybe<Scalars['Void']>;
  /** 重命名文件 */
  renameMdFile?: Maybe<MdFile>;
  /** 共享文件 */
  shareMdFile?: Maybe<Scalars['Void']>;
  /** 取消共享文件 */
  cancelShareMdFile?: Maybe<Scalars['Void']>;
  /** 对所有人共享文件 */
  shareMdFileToEveryone?: Maybe<MdFileRootDir>;
  /** 关注文件夹 */
  followMdFileDirs?: Maybe<Scalars['Json']>;
  /** 取消关注文件夹 */
  unFollowMdFileDirs?: Maybe<Scalars['Json']>;
  /** 设置是否只展示关注文件夹 */
  setShowFollowedDirOnly?: Maybe<Scalars['Json']>;
  /** 创建仓库 */
  createMdeWarehouse?: Maybe<MdeWarehouse>;
  /** 修改仓库 */
  updateMdeWarehouse?: Maybe<MdeWarehouse>;
  /** 创建币种 */
  createMdeCurrency?: Maybe<MdeCurrency>;
  /** 添加汇率 */
  saveMdeCurrencyExchangeRate?: Maybe<MdeCurrencyExchangeRate>;
  /** 修改币种 */
  updateMdeCurrency?: Maybe<MdeCurrency>;
  /** 创建供应商 */
  createMdeSupplier?: Maybe<MdeSupplier>;
  /** 修改供应商 */
  updateMdeSupplier?: Maybe<MdeSupplier>;
  /** 创建客户 */
  createMdeCustomer?: Maybe<MdeCustomer>;
  /** 修改客户 */
  updateMdeCustomer?: Maybe<MdeCustomer>;
  /** 创建外贸条款 */
  createMdeIncoterms?: Maybe<MdeIncoterms>;
  /** 修改外贸条款 */
  updateIncoterms?: Maybe<MdeIncoterms>;
  /** 创建装箱方式 */
  insertMdoPackingOption?: Maybe<MdoPackingOption>;
  /** 修改装箱方式 */
  updateMdoPackingOption?: Maybe<MdoPackingOption>;
  /** 单个迁移装箱方式 */
  saveMdoPackingOption?: Maybe<MdoPackingOption>;
  /** 迁移装箱方式 */
  syncMdmPackingOption?: Maybe<Scalars['String']>;
  /** 无法迁移 */
  setInvalidate?: Maybe<Scalars['Void']>;
  /** 重新迁移 */
  resetMigStatus?: Maybe<Scalars['Void']>;
  /** 编辑备注 */
  updatePackingOptionInboundNote?: Maybe<Scalars['Void']>;
  /** 编辑备注 */
  insertPackingOptionInboundRecord?: Maybe<Scalars['Void']>;
  /** 删除装箱历史数据 */
  deleteMdmPacking?: Maybe<Scalars['Void']>;
  /** 保存选项表 */
  saveMdDictionary?: Maybe<MdDictionary>;
  /** 保存选项表选项 */
  saveMdDictionaryEntries?: Maybe<Array<Maybe<MdDictionaryEntry>>>;
  updateMdDomain?: Maybe<MdDomain>;
  insertMdData?: Maybe<MdData>;
  updateMdData?: Maybe<MdData>;
  /** 设置主数据属性 */
  saveMdDataAttributes?: Maybe<MdData>;
  /** 移除主数据属性 */
  removeMdDataAttributes?: Maybe<MdData>;
  /** 设置主数据Bom */
  setMdDataBom?: Maybe<MdData>;
  setMdDataExtra?: Maybe<MdData>;
  /** 批量保存MdData */
  batchSaveMdData?: Maybe<Array<MdDataSavingResult>>;
  /** 保存主数据图片 */
  saveMdDataPictures?: Maybe<MdData>;
  /** 更新主数据属性冗余信息 */
  updateMdDataAttributes?: Maybe<Scalars['Void']>;
  /** 关联Bom模板 */
  switchMdDataBomTemplate?: Maybe<Array<MdData>>;
  /** 解除Bom模板关联 */
  detachMdDataBomTemplate?: Maybe<Array<MdData>>;
  /** 同步ATS请购单 */
  syncPurchaseRequisitionToAts?: Maybe<Scalars['Void']>;
  /** 创建ATS品号 */
  syncMdDataToAts?: Maybe<Array<Maybe<ErrorInfo>>>;
  /** 导出销货单到ATS */
  syncPoInvSheetToAts?: Maybe<ErrorInfo>;
  /** 撤回ATS销货单 */
  revokeAtsPoInvSheet?: Maybe<Scalars['Void']>;
  /** 创建通用目录 */
  createBrCatalog?: Maybe<BrCatalog>;
  /** 修改通用目录 */
  updateBrCatalog?: Maybe<BrCatalog>;
  /** 移动通用目录 */
  moveBrCatalog?: Maybe<Scalars['Void']>;
  /** 删除通用目录 */
  deleteBrCatalog?: Maybe<Scalars['Void']>;
  /** 设置数据目录 */
  setEntityCatalog?: Maybe<Scalars['Void']>;
  /** 保存出货单 */
  saveMdoDeliverySheet?: Maybe<MdoDeliverySheet>;
  /** 删除出货单 */
  deleteMdoDeliverySheet?: Maybe<Scalars['Void']>;
  /** 设置出货单已出货 */
  deliverMdoDeliverySheet?: Maybe<MdoDeliverySheet>;
  /** 撤回出货已出货状态 */
  revokeMdoDeliverySheet?: Maybe<MdoDeliverySheet>;
  /** 保存出货单发票信息 */
  saveMdoDeliverySheetInvoice?: Maybe<MdoDeliverySheet>;
  /** 设置电镀分析结果 */
  saveMdaLensElectroplateColours?: Maybe<Scalars['Void']>;
  /** 设置型号分析结果 */
  saveMdaLensProductModelColours?: Maybe<Scalars['Void']>;
  /** 设为未迁移 */
  updateRqaTallyNeedSync?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** 执行迁移 */
  migrateRqaMdTally?: Maybe<Scalars['Void']>;
  disableUser?: Maybe<User>;
  enableUser?: Maybe<User>;
  setUserAdminLevel?: Maybe<User>;
  adminSetUserInfo?: Maybe<User>;
  createUser?: Maybe<User>;
  approveJoiningApplication?: Maybe<Array<Maybe<JoiningApplication>>>;
  rejectJoiningApplication?: Maybe<Array<Maybe<JoiningApplication>>>;
  saveExplorerView?: Maybe<ExplorerView>;
  deleteExplorerView?: Maybe<Scalars['Void']>;
  saveChartView?: Maybe<ChartView>;
  copyChartView?: Maybe<Scalars['Void']>;
  saveChartDashboard?: Maybe<ChartDashboard>;
  deleteChartDashboard?: Maybe<Scalars['Void']>;
  createExternalDataSource?: Maybe<ExternalDataSource>;
  updateExternalDataSource?: Maybe<ExternalDataSource>;
  deleteExternalDataSource?: Maybe<Scalars['Void']>;
  /** 保存用户设置 */
  saveUserDashboardGaugeConfig?: Maybe<Scalars['Json']>;
  /** 复制用户设置 */
  copyUserDashboardGaugeSettings?: Maybe<Scalars['Json']>;
  updateMyProfile?: Maybe<User>;
  /** 保存打印导出模板 */
  saveSysExportTemplate: SysExportTemplate;
  /** 生成数据批次 */
  saveDataBatchIds: Scalars['String'];
  /** 生成分享密语 */
  generateEstInvitationToken: Scalars['String'];
  /** 从模板创建账本 */
  createEstAccountBook: EstAccountBook;
  /** 保存账本 */
  saveEstAccountBook: EstAccountBook;
  /** 保存账本用户 */
  saveEstAccountBookUser: EstAccountBook;
  /** 转让账本管理员 */
  switchEstAccountBookOwner: EstAccountBook;
  /** 指定账本成员加入协作组 */
  setEstAccountBookCooperator: EstAccountBook;
  /** 指定账本成员加入只读组 */
  setEstAccountBookReader: EstAccountBook;
  /** 将用户移出账本 */
  removeEstAccountBookUser: EstAccountBook;
  /** 加入账本 */
  joinEstAccountBook: Scalars['Boolean'];
  /** 退出共享账本 */
  quitEstAccountBook?: Maybe<Scalars['Void']>;
  /** 保存账本标签 */
  saveEstAccountBookTags: EstAccountBook;
  /** 保存账本收款方式 */
  saveEstReceivableMethods: EstAccountBook;
  /** 保存交易对象 */
  saveEstTradingTarget: EstTradingTarget;
  /** 保存交易对象收款方式 */
  saveEstTradingTargetReceiveMethods?: Maybe<EstTradingTarget>;
  /** 插入流水条目 */
  insertEstAccountEntry?: Maybe<EstAccountTallyEntry>;
  /** 修改流水条目 */
  modifyEstAccountEntry?: Maybe<EstAccountTallyEntry>;
  /** 删除流水条目 */
  deleteEstAccountEntry?: Maybe<Scalars['Void']>;
  /** 保存项目 */
  saveEstContract?: Maybe<EstContract>;
  /** 设置项目关闭状态 */
  updateEstContractState?: Maybe<EstContract>;
  /** 批量保存待办 */
  batchSaveEstContractTodos?: Maybe<EstContract>;
  /** 保存账本待办 */
  saveEstTodo?: Maybe<EstTodo>;
  deleteEstTodo?: Maybe<Scalars['Void']>;
  /** 生成账本待办链接Hash */
  generateEstTodoRequestHash?: Maybe<Scalars['String']>;
  /** 保存收款计划 */
  saveEstReceivablePlan: EstReceivablePlan;
  /** 删除收款计划 */
  deleteEstReceivablePlan?: Maybe<Scalars['Void']>;
  /** *提交收款任务附言 */
  postEstReceivableStepComment?: Maybe<Scalars['Void']>;
  /** 保存收款任务收款方式 */
  saveEstReceivableStepReceivableMethod: EstReceivableStep;
  /** *上传付款方附件(水单) */
  markEstReceivableStepPaid: EstReceivableStep;
  /** *确认收款任务 */
  confirmEstReceivableStep: EstReceivableStep;
  /** 关闭收款计划. 全部收款任务状态为已确认可以关闭封账 */
  closeEstReceivablePlan: EstReceivablePlan;
  /** 调整应付结余 */
  alterEstReceivableSurplus?: Maybe<MdTally>;
  /** 调整应收结余 */
  alterEstPayableSurplus?: Maybe<MdTally>;
  markEstReceivablePlanOperation?: Maybe<EstReceivablePlan>;
  markEstReceivableStepOperation?: Maybe<EstReceivableStep>;
  /** 保存类别设置 */
  saveMdpGalleryCatalogConfig?: Maybe<MdpGalleryCatalog>;
  /** 设置型号上架 */
  setMdpGalleryModelOnShelf?: Maybe<MdpGalleryModel>;
  /** 设置型号下架 */
  setMdpGalleryModelOffShelf?: Maybe<MdpGalleryModel>;
  /** 保存型号款式 */
  saveMdpGalleryStyles?: Maybe<MdpGalleryModel>;
  /** 关联并保存SKU */
  saveMdpGallerySku?: Maybe<Array<Maybe<MdpGallerySku>>>;
  /** 上架SKU */
  setMdpGallerySkuOnShelf?: Maybe<Array<Maybe<MdpGallerySku>>>;
  /** 下架SKU */
  setMdpGallerySkuOffShelf?: Maybe<Array<Maybe<MdpGallerySku>>>;
  /** 删除SKU */
  deleteMdpGallerySku?: Maybe<Scalars['Void']>;
  /** 保存账套配置 */
  saveMdTallyAccountSet?: Maybe<MdTallyAccountSet>;
  /** 重新计算账套结余 */
  rebuildMdTallySurplus?: Maybe<Scalars['Void']>;
  /** 重新计算全部结余 */
  rebuildAllMdTallySurplus?: Maybe<Scalars['Void']>;
  /** 对默认账套发起修正数量和单位 */
  alterMdTallySurplus?: Maybe<Scalars['Void']>;
  /** 直接修改流水 */
  modifyMdTally?: Maybe<MdTally>;
  /** 插入调整流水 */
  adjustMdTally?: Maybe<MdTally>;
  /** 删除流水 */
  deleteMdTallies?: Maybe<Scalars['Void']>;
  /** 刷新时间线结余 */
  refreshMdTallyTimeSerialSurplusesView?: Maybe<Scalars['Void']>;
  /** 保存人员信息 */
  saveKtiTalent?: Maybe<KtiTalent>;
  /** 保存出勤信息 */
  saveKtiAttendance?: Maybe<KtiAttendance>;
  /** 删除出勤信息 */
  deleteKtiAttendance?: Maybe<Scalars['Void']>;
  /** 保存薪资信息 */
  saveKtiSalary?: Maybe<Scalars['Void']>;
  /** 保存工作汇报 */
  saveKtiWorkingReport?: Maybe<KtiWorkingReport>;
  /** 删除工作汇报 */
  deleteKtiWorkingReport?: Maybe<Scalars['Void']>;
  /** 保存人员考核 */
  saveKtiTalentAssessment?: Maybe<KtiTalentAssessment>;
  /** 删除人员考核 */
  deleteKtiTalentAssessment?: Maybe<Scalars['Void']>;
  /** 保存科研成果 */
  saveKtiResearchAchievement?: Maybe<KtiResearchAchievement>;
  /** 删除科研成果 */
  deleteKtiResearchAchievement?: Maybe<Scalars['Void']>;
  /** 保存导师评价 */
  saveKtiSupervisorComment?: Maybe<KtiSupervisorComment>;
  /** 保存知识产权 */
  saveKtiIntellectualProperty?: Maybe<KtiIntellectualProperty>;
  /** 删除知识产权 */
  deleteKtiIntellectualProperty?: Maybe<Scalars['Void']>;
  saveQrtzJob?: Maybe<QrtzJob>;
  pauseAllQrtzJobs?: Maybe<Scalars['Void']>;
  resumeAllQrtzJobs?: Maybe<Scalars['Void']>;
  executeQrtzJobImmediately?: Maybe<QrtzExecLog>;
  pauseTrigger?: Maybe<QrtzTrigger>;
  resumeTrigger?: Maybe<QrtzTrigger>;
  deleteQrtzJob?: Maybe<Scalars['Void']>;
  /** 删除定时任务执行记录 */
  deleteQrtzExecLog?: Maybe<Scalars['Void']>;
  /** 执行指定的校验规则 */
  executeMdValidation: Array<MdDiagnosis>;
  /** 修改校验规则 */
  updateMdValidationRule: MdValidationRule;
  /** 忽略校验规则 */
  ignoreMdDiagnosis?: Maybe<MdDiagnosis>;
  /** 保存作业指导模板 */
  saveMdpWorkManualTemplate?: Maybe<MdpWorkManualTemplate>;
  /** 保存结构工艺 */
  saveMdpStructureWorkProcess?: Maybe<MdpStructure>;
  /** 批量重构主数据 */
  batchRefactorMdData?: Maybe<Array<MdDataSavingResult>>;
  /** 确认主数据重构记录 */
  confirmMdmDataRefactor?: Maybe<Array<Maybe<MdDataSavingResult>>>;
  /** 回滚主数据 */
  rollBackMdmDataRefactor?: Maybe<Array<Maybe<MdDataSavingResult>>>;
  /** 忽略主数据重构记录 */
  ignoreMdmDataRefactor?: Maybe<Scalars['Void']>;
  /** 删除主数据重构批次 */
  deleteMdmDataRefactor?: Maybe<Scalars['Void']>;
  /** 创建字段 */
  createMdField?: Maybe<MdField>;
  /** 修改字段 */
  updateMdField?: Maybe<MdField>;
  /** 创建智能表格定义 */
  createMdTable?: Maybe<MdTable>;
  /** 修改智能表格定义 */
  updateMdTable?: Maybe<MdTable>;
  /** 修改智能表格权限 */
  saveMdTableOwnership?: Maybe<MdTable>;
  /** 保存智能表格数据 */
  saveMdTableData?: Maybe<MdTableData>;
  /** 批量保存智能表格数据 */
  batchSaveMdTableData?: Maybe<Scalars['Void']>;
  /** 批量保存智能表格列数据 */
  batchSaveMdTableDataColumnValue?: Maybe<Scalars['Void']>;
  /** 重置自增列 */
  resetMdTableAutoIncrementalColumn?: Maybe<Scalars['Void']>;
  /** 删除智能表格数据 */
  deleteMdTableData?: Maybe<Scalars['Void']>;
  /** 保存导入数据预选配置 */
  saveMdTableImportMappingPreset?: Maybe<MdTableImportMappingPreset>;
  /** 保存导入数据预选配置 */
  deleteMdTableImportMappingPreset?: Maybe<Scalars['Void']>;
  /** 开始智能表格导入作业 */
  startMdTableImport?: Maybe<MdTableImportContext>;
  /** 开始智能表格从Sql导入作业 */
  startMdTableImportFromSql?: Maybe<MdTableImportContext>;
  /** 取预设值开始智能表格从Sql导入作业 */
  startMdTableImportFromSqlWithPreset?: Maybe<MdTableImportContext>;
  /** 保存智能表格导入映射配置 */
  saveMdTableImportMappingConfig?: Maybe<MdTableImportContext>;
  /** 修改导入数据 */
  updateMdTableImportData?: Maybe<MdTableImportData>;
  /** 修改合并策略 */
  saveMdTableImportMerging?: Maybe<MdTableImportData>;
  /** 智能表格执行导入 */
  doMdTableImport?: Maybe<MdTableImportResult>;
  /** 取消智能表格执行导入作业 */
  cancelTableImport?: Maybe<Scalars['Void']>;
  /** 复制智能表格 */
  copyMdTable?: Maybe<MdTable>;
  /** 保存智能表格访问记录 */
  saveMdTableVisitHistory?: Maybe<Scalars['Json']>;
  /** 更新主数据迁移设置 */
  updateMdDataNeedSync?: Maybe<Scalars['Void']>;
  /** 主数据设为不一致 */
  setMdDataInboundConsistency?: Maybe<Scalars['Void']>;
  /** 批量保存MdmMdDataInboundRecord, 如果code已存在则update, 如果code不存在则insert */
  saveMdmMdDataMigrationResult?: Maybe<Scalars['Void']>;
  syncMdDataBom?: Maybe<Array<Maybe<MdDataSavingResult>>>;
  /** Bom设为不一致 */
  setBomInboundInconsistent?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** 更新Bom迁移设置 */
  updateBomNeedSync?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** 迁移ATS订单 */
  migrateProductOrder?: Maybe<Array<Maybe<ErrorInfo>>>;
  /** 新建命名规则 */
  createMdxNamingRule?: Maybe<MdxNamingRule>;
  /** 编辑命名规则 */
  updateMdxNamingRule?: Maybe<MdxNamingRule>;
  validateNamingRules?: Maybe<Array<Maybe<MdDiagnosis>>>;
  deleteMdoMarkPrintTemplate?: Maybe<Scalars['Int']>;
  deleteMdpFinModel?: Maybe<Scalars['Int']>;
  addTagsMdpFinModel?: Maybe<Scalars['Int']>;
  removeTagMdpFinModel?: Maybe<Scalars['Int']>;
  setTagsMdpFinModel?: Maybe<Scalars['Int']>;
  deleteMdData?: Maybe<Scalars['Int']>;
  addTagsMdData?: Maybe<Scalars['Int']>;
  removeTagMdData?: Maybe<Scalars['Int']>;
  setTagsMdData?: Maybe<Scalars['Int']>;
  setSeqMdpBomEntry?: Maybe<Scalars['Int']>;
  addTagsKtiIntellectualProperty?: Maybe<Scalars['Int']>;
  removeTagKtiIntellectualProperty?: Maybe<Scalars['Int']>;
  setTagsKtiIntellectualProperty?: Maybe<Scalars['Int']>;
  addTagsMdaLensProductModelColour?: Maybe<Scalars['Int']>;
  removeTagMdaLensProductModelColour?: Maybe<Scalars['Int']>;
  setTagsMdaLensProductModelColour?: Maybe<Scalars['Int']>;
  deleteRegRecycleWorker?: Maybe<Scalars['Int']>;
  deletePermission?: Maybe<Scalars['Int']>;
  deleteMdDictionaryEntry?: Maybe<Scalars['Int']>;
  setSeqMdDictionaryEntry?: Maybe<Scalars['Int']>;
  deleteMdeCurrency?: Maybe<Scalars['Int']>;
  deleteMdoDesignDocument?: Maybe<Scalars['Int']>;
  deleteEstContract?: Maybe<Scalars['Int']>;
  addTagsMdoBoxingSheet?: Maybe<Scalars['Int']>;
  removeTagMdoBoxingSheet?: Maybe<Scalars['Int']>;
  setTagsMdoBoxingSheet?: Maybe<Scalars['Int']>;
  deleteSysExportTemplate?: Maybe<Scalars['Int']>;
  deleteMdoUsedPackingOption?: Maybe<Scalars['Int']>;
  addTagsMdoUsedPackingOption?: Maybe<Scalars['Int']>;
  removeTagMdoUsedPackingOption?: Maybe<Scalars['Int']>;
  setTagsMdoUsedPackingOption?: Maybe<Scalars['Int']>;
  deleteMdpStructure?: Maybe<Scalars['Int']>;
  addTagsMdpStructure?: Maybe<Scalars['Int']>;
  removeTagMdpStructure?: Maybe<Scalars['Int']>;
  setTagsMdpStructure?: Maybe<Scalars['Int']>;
  deleteChartView?: Maybe<Scalars['Int']>;
  deleteMdoDesignDocumentUnit?: Maybe<Scalars['Int']>;
  addTagsMetMembershipFlow?: Maybe<Scalars['Int']>;
  removeTagMetMembershipFlow?: Maybe<Scalars['Int']>;
  setTagsMetMembershipFlow?: Maybe<Scalars['Int']>;
  deleteMdFieldTemplate?: Maybe<Scalars['Int']>;
  addTagsEstAccountEntry?: Maybe<Scalars['Int']>;
  removeTagEstAccountEntry?: Maybe<Scalars['Int']>;
  setTagsEstAccountEntry?: Maybe<Scalars['Int']>;
  deleteMdeSupplier?: Maybe<Scalars['Int']>;
  deleteEstAccountBook?: Maybe<Scalars['Int']>;
  addTagsKtiResearchAchievement?: Maybe<Scalars['Int']>;
  removeTagKtiResearchAchievement?: Maybe<Scalars['Int']>;
  setTagsKtiResearchAchievement?: Maybe<Scalars['Int']>;
  setSeqMdDataBomEntry?: Maybe<Scalars['Int']>;
  deleteEstTradingTarget?: Maybe<Scalars['Int']>;
  addTagsKtiServiceContract?: Maybe<Scalars['Int']>;
  removeTagKtiServiceContract?: Maybe<Scalars['Int']>;
  setTagsKtiServiceContract?: Maybe<Scalars['Int']>;
  deleteKtiTalent?: Maybe<Scalars['Int']>;
  addTagsKtiTalent?: Maybe<Scalars['Int']>;
  removeTagKtiTalent?: Maybe<Scalars['Int']>;
  setTagsKtiTalent?: Maybe<Scalars['Int']>;
  addTagsMdoDeliverySheet?: Maybe<Scalars['Int']>;
  removeTagMdoDeliverySheet?: Maybe<Scalars['Int']>;
  setTagsMdoDeliverySheet?: Maybe<Scalars['Int']>;
  deleteMdField?: Maybe<Scalars['Int']>;
  addTagsMdField?: Maybe<Scalars['Int']>;
  removeTagMdField?: Maybe<Scalars['Int']>;
  setTagsMdField?: Maybe<Scalars['Int']>;
  setSeqMdaBomEntry?: Maybe<Scalars['Int']>;
  deletePersistentRealm?: Maybe<Scalars['Int']>;
  addTagsMdaLensElectroplateColour?: Maybe<Scalars['Int']>;
  removeTagMdaLensElectroplateColour?: Maybe<Scalars['Int']>;
  setTagsMdaLensElectroplateColour?: Maybe<Scalars['Int']>;
  deleteMetCustomer?: Maybe<Scalars['Int']>;
  addTagsMetCustomer?: Maybe<Scalars['Int']>;
  removeTagMetCustomer?: Maybe<Scalars['Int']>;
  setTagsMetCustomer?: Maybe<Scalars['Int']>;
  deleteMdpFinSize?: Maybe<Scalars['Int']>;
  setSeqMdpFinSize?: Maybe<Scalars['Int']>;
  addTagsMdaLensColour?: Maybe<Scalars['Int']>;
  removeTagMdaLensColour?: Maybe<Scalars['Int']>;
  setTagsMdaLensColour?: Maybe<Scalars['Int']>;
  addTagsMdoProductOrder?: Maybe<Scalars['Int']>;
  removeTagMdoProductOrder?: Maybe<Scalars['Int']>;
  setTagsMdoProductOrder?: Maybe<Scalars['Int']>;
  setSeqEstReceivableStep?: Maybe<Scalars['Int']>;
  deleteMdoPackingOption?: Maybe<Scalars['Int']>;
  addTagsMdoPackingOption?: Maybe<Scalars['Int']>;
  removeTagMdoPackingOption?: Maybe<Scalars['Int']>;
  setTagsMdoPackingOption?: Maybe<Scalars['Int']>;
  deleteMdTallyAccountSet?: Maybe<Scalars['Int']>;
  addTagsKtiAttendance?: Maybe<Scalars['Int']>;
  removeTagKtiAttendance?: Maybe<Scalars['Int']>;
  setTagsKtiAttendance?: Maybe<Scalars['Int']>;
  deleteMdDictionary?: Maybe<Scalars['Int']>;
  addTagsMdDictionary?: Maybe<Scalars['Int']>;
  removeTagMdDictionary?: Maybe<Scalars['Int']>;
  setTagsMdDictionary?: Maybe<Scalars['Int']>;
  deleteMetMeeting?: Maybe<Scalars['Int']>;
  addTagsMetMeeting?: Maybe<Scalars['Int']>;
  removeTagMetMeeting?: Maybe<Scalars['Int']>;
  setTagsMetMeeting?: Maybe<Scalars['Int']>;
  setSeqMdFieldTemplateEntry?: Maybe<Scalars['Int']>;
  deleteMdaStructure?: Maybe<Scalars['Int']>;
  addTagsMdaStructure?: Maybe<Scalars['Int']>;
  removeTagMdaStructure?: Maybe<Scalars['Int']>;
  setTagsMdaStructure?: Maybe<Scalars['Int']>;
  deleteRoleGroup?: Maybe<Scalars['Int']>;
  addTagsRoleGroup?: Maybe<Scalars['Int']>;
  removeTagRoleGroup?: Maybe<Scalars['Int']>;
  setTagsRoleGroup?: Maybe<Scalars['Int']>;
  deleteRole?: Maybe<Scalars['Int']>;
  setSeqMdxStructureBranchNode?: Maybe<Scalars['Int']>;
  setSeqMetCustomerContact?: Maybe<Scalars['Int']>;
  deleteMdeWarehouse?: Maybe<Scalars['Int']>;
  setSeqBrCatalog?: Maybe<Scalars['Int']>;
  deleteMdFile?: Maybe<Scalars['Int']>;
  deleteMdUnit?: Maybe<Scalars['Int']>;
  deleteMetParticipant?: Maybe<Scalars['Int']>;
  addTagsMetParticipant?: Maybe<Scalars['Int']>;
  removeTagMetParticipant?: Maybe<Scalars['Int']>;
  setTagsMetParticipant?: Maybe<Scalars['Int']>;
  deleteMdTableColumn?: Maybe<Scalars['Int']>;
  setSeqMdTableColumn?: Maybe<Scalars['Int']>;
  deleteMdeCurrencyExchangeRate?: Maybe<Scalars['Int']>;
  setSeqMdoDeliverySheetEntry?: Maybe<Scalars['Int']>;
  deleteMdeIncoterms?: Maybe<Scalars['Int']>;
  addTagsKtiTalentAssessment?: Maybe<Scalars['Int']>;
  removeTagKtiTalentAssessment?: Maybe<Scalars['Int']>;
  setTagsKtiTalentAssessment?: Maybe<Scalars['Int']>;
  deleteMdpGoggleModel?: Maybe<Scalars['Int']>;
  addTagsMdpGoggleModel?: Maybe<Scalars['Int']>;
  removeTagMdpGoggleModel?: Maybe<Scalars['Int']>;
  setTagsMdpGoggleModel?: Maybe<Scalars['Int']>;
  setSeqMdpGalleryStyle?: Maybe<Scalars['Int']>;
  deleteMdTable?: Maybe<Scalars['Int']>;
  addTagsMdTable?: Maybe<Scalars['Int']>;
  removeTagMdTable?: Maybe<Scalars['Int']>;
  setTagsMdTable?: Maybe<Scalars['Int']>;
  addTagsEstAccountTallyEntry?: Maybe<Scalars['Int']>;
  removeTagEstAccountTallyEntry?: Maybe<Scalars['Int']>;
  setTagsEstAccountTallyEntry?: Maybe<Scalars['Int']>;
  deleteMdpWorkManualTemplate?: Maybe<Scalars['Int']>;
  addTagsEstReceivablePlan?: Maybe<Scalars['Int']>;
  removeTagEstReceivablePlan?: Maybe<Scalars['Int']>;
  setTagsEstReceivablePlan?: Maybe<Scalars['Int']>;
  deleteMdInvoice?: Maybe<Scalars['Int']>;
  addTagsMdInvoice?: Maybe<Scalars['Int']>;
  removeTagMdInvoice?: Maybe<Scalars['Int']>;
  setTagsMdInvoice?: Maybe<Scalars['Int']>;
  deleteMetAgent?: Maybe<Scalars['Int']>;
  addTagsMetAgent?: Maybe<Scalars['Int']>;
  removeTagMetAgent?: Maybe<Scalars['Int']>;
  setTagsMetAgent?: Maybe<Scalars['Int']>;
  setSeqMdFieldTemplateBranch?: Maybe<Scalars['Int']>;
  addTagsKtiWorkingReport?: Maybe<Scalars['Int']>;
  removeTagKtiWorkingReport?: Maybe<Scalars['Int']>;
  setTagsKtiWorkingReport?: Maybe<Scalars['Int']>;
  deleteMdeColour?: Maybe<Scalars['Int']>;
  deleteMdeCustomer?: Maybe<Scalars['Int']>;
  addTagsMdeCustomer?: Maybe<Scalars['Int']>;
  removeTagMdeCustomer?: Maybe<Scalars['Int']>;
  setTagsMdeCustomer?: Maybe<Scalars['Int']>;
  setSeqMdxStructureFieldNode?: Maybe<Scalars['Int']>;
};

export type MutationGenerateMdoBoxMdDatasArgs = {
  productOrderCode: Scalars['String'];
  innerSeparatorMaterialDictId?: Maybe<Scalars['ID']>;
  outerSeparatorMaterialDictId?: Maybe<Scalars['ID']>;
};

export type MutationSaveMdoBoxMdDatasArgs = {
  productOrderCode: Scalars['String'];
  mdoBoxingMdDataInputs: Array<Maybe<MdoBoxingMdDataInput>>;
};

export type MutationSaveMdoBoxWorkOrdersArgs = {
  productOrderCode: Scalars['String'];
  mdoBoxWorkOrderInputs: Array<MdoBoxWorkOrderInput>;
};

export type MutationSetMdoBoxWorkOrderPurchaseFinishedArgs = {
  workOrderIds: Array<Maybe<Scalars['ID']>>;
  finished: Scalars['Boolean'];
};

export type MutationSetMdoBoxWorkOrderStateArgs = {
  workOrderIds: Array<Maybe<Scalars['ID']>>;
  state: Scalars['String'];
};

export type MutationSetMdoBoxWorkOrderPurchaseTargetArgs = {
  workOrderIds: Array<Maybe<Scalars['ID']>>;
  purchaseTarget: Scalars['String'];
};

export type MutationEditMdoBoxWorkOrderArgs = {
  input: MdoBoxWorkOrderEditInput;
};

export type MutationCopyMdoBoxWorkOrderArgs = {
  mdoBoxWorkOrderInput: MdoBoxWorkOrderCopyInput;
};

export type MutationDeleteMdoBoxWorkOrderArgs = {
  id: Scalars['ID'];
};

export type MutationNotifyMdoBoxPaperboardPurchaseRequisitionChangesArgs = {
  productOrderCode: Scalars['String'];
  mdDataCodes: Array<Maybe<Scalars['String']>>;
};

export type MutationSaveMetMeetingArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  planStartAt?: Maybe<Scalars['DateTime']>;
  planEndAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Maybe<Scalars['File']>>>;
};

export type MutationSetMetMeetingStateArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  state: Scalars['ValuedEnum'];
};

export type MutationSaveMetCustomerArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['ID']>;
  contacts?: Maybe<Array<Maybe<MetCustomerContactInput>>>;
  introducers?: Maybe<Array<Maybe<MetIntroducerInput>>>;
  attributes?: Maybe<Array<Maybe<MdAttributeInput>>>;
};

export type MutationSaveMetAgentArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  nickName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  external: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
};

export type MutationAddMetParticipantsArgs = {
  meetingId: Scalars['ID'];
  customerIds: Array<Maybe<Scalars['ID']>>;
};

export type MutationRemoveMetParticipantsArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
};

export type MutationSetMetParticipantNoteArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  otherFiles?: Maybe<Array<Maybe<Scalars['File']>>>;
};

export type MutationSetMetParticipantContractArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  agentId?: Maybe<Scalars['ID']>;
  contractAgentId?: Maybe<Scalars['ID']>;
  contractCode?: Maybe<Scalars['String']>;
  standTypeId?: Maybe<Scalars['ID']>;
  standSize?: Maybe<Scalars['Float']>;
  sponsorAmount?: Maybe<Scalars['Float']>;
  sponsorNote?: Maybe<Scalars['String']>;
  advertisingAmount?: Maybe<Scalars['Float']>;
  advertisingNote?: Maybe<Scalars['String']>;
  contractAmount?: Maybe<Scalars['Float']>;
  agentBonusRate?: Maybe<Scalars['Float']>;
  agentSettlingTypeBonusRate?: Maybe<Scalars['Map']>;
  contractState: Scalars['ValuedEnum'];
  contractDeptId?: Maybe<Scalars['ID']>;
  contractNote?: Maybe<Scalars['String']>;
  contractDiffNote?: Maybe<Scalars['String']>;
  contractFiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  contractAckFiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  billingNote?: Maybe<Scalars['String']>;
};

export type MutationSetMetParticipantInvitationArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  invitedAt?: Maybe<Scalars['DateTime']>;
  acceptedAt?: Maybe<Scalars['DateTime']>;
  invitationNote?: Maybe<Scalars['String']>;
  attendingState: Scalars['ValuedEnum'];
};

export type MutationSaveMetParticipantInvoiceArgs = {
  participantId: Scalars['ID'];
  invoiceId?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  invoice: MdInvoiceInput;
};

export type MutationDeleteMetParticipantInvoiceArgs = {
  id: Scalars['ID'];
};

export type MutationSaveMetParticipantReceivedRecordArgs = {
  participantId: Scalars['ID'];
  receivedRecordId?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  receiving: Scalars['ValuedEnum'];
  amount: Scalars['Float'];
  operatedAt: Scalars['Date'];
  bankSerialCode?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Maybe<Scalars['File']>>>;
};

export type MutationDeleteMetParticipantReceivedRecordArgs = {
  id: Scalars['ID'];
};

export type MutationSaveMetBonusArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  bonusRate?: Maybe<Scalars['Float']>;
  bonusAmount?: Maybe<Scalars['Float']>;
};

export type MutationSettleMetBonusArgs = {
  bonusIds: Array<Maybe<Scalars['ID']>>;
  amount: Scalars['Float'];
  operatedAt: Scalars['Date'];
  note?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Maybe<Scalars['File']>>>;
};

export type MutationRevokeMetBonusSettlingArgs = {
  settlingId?: Maybe<Scalars['ID']>;
};

export type MutationSetMetParticipantBillingStateArgs = {
  participantId: Scalars['ID'];
  needInvoice?: Maybe<Scalars['Boolean']>;
  needReceiving?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateMetCustomerImportTableArgs = {
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};

export type MutationCheckMetCustomerImportDataArgs = {
  mdTableCode: Scalars['String'];
};

export type MutationExecuteMetCustomerImportArgs = {
  mdTableCode: Scalars['String'];
};

export type MutationCreateMetParticipantReceivedRecordImportTableArgs = {
  name: Scalars['String'];
  meetingId: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
};

export type MutationCheckMetParticipantReceivedRecordImportDataArgs = {
  mdTableCode: Scalars['String'];
};

export type MutationExecuteMetParticipantReceivedRecordImportArgs = {
  mdTableCode: Scalars['String'];
};

export type MutationCreateMetMembershipFlowArgs = {
  customerId: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  principalUserIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  agentId?: Maybe<Scalars['ID']>;
};

export type MutationTakeMetMembershipFlowActionArgs = {
  flowId: Scalars['ID'];
  ver: Scalars['Int'];
  content?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['String']>;
  action: Scalars['ValuedEnum'];
};

export type MutationAssignMetMembershipFlowPrincipalsArgs = {
  flowId: Scalars['ID'];
  ver: Scalars['Int'];
  userIds: Array<Maybe<Scalars['ID']>>;
  note?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['ID']>;
};

export type MutationSaveMdaStructureTemplateArgs = {
  categoryKey: Scalars['String'];
  mdFieldIds?: Maybe<Array<Scalars['ID']>>;
};

export type MutationCreateMdxStructureFieldNodeArgs = {
  parentNodeId?: Maybe<Scalars['ID']>;
  alias?: Maybe<Scalars['String']>;
  feature?: Maybe<Scalars['Boolean']>;
  bomCascadingFeature?: Maybe<Scalars['Boolean']>;
  bomFeature?: Maybe<Scalars['Boolean']>;
  materialField?: Maybe<Scalars['Boolean']>;
  productTypeField?: Maybe<Scalars['Boolean']>;
  mandatory?: Maybe<Scalars['Boolean']>;
  mdFieldId: Scalars['Int'];
  defaultValue?: Maybe<Scalars['Json']>;
  valueRange?: Maybe<Scalars['Json']>;
};

export type MutationUpdateMdxStructureFieldNodeArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  values?: Maybe<MdxStructureFieldNodeValues>;
};

export type MutationMoveMdxStructureNodeArgs = {
  draggingNodeId: Scalars['ID'];
  draggingNodeVer: Scalars['Int'];
  draggingNodeParentKey: Scalars['String'];
  dropNodeId: Scalars['ID'];
  dropNodeVer: Scalars['Int'];
  dropNodeParentKey: Scalars['String'];
  dropType: Scalars['String'];
  draggingNodeType: Scalars['String'];
};

export type MutationDeleteMdxStructureFieldNodeArgs = {
  id: Scalars['ID'];
};

export type MutationCreateMdxStructureBranchNodeArgs = {
  parentNodeId: Scalars['ID'];
  name: Scalars['String'];
  filterCondition?: Maybe<Scalars['Json']>;
};

export type MutationUpdateMdxStructureBranchNodeArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  values?: Maybe<MdxStructureBranchNodeValues>;
};

export type MutationConvertEngineeringMdDataArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationDeleteMdxStructureBranchNodeArgs = {
  id: Scalars['ID'];
};

export type MutationValidateMdxStructureTreeArgs = {
  ruleIds?: Maybe<Array<Scalars['ID']>>;
};

export type MutationCreateMdDataWithAttributeArgs = {
  attributes: Array<MdAttributeInput>;
};

export type MutationBatchUpdateMdDataHelpCodeArgs = {
  values: Array<MdxDataHelpCodeInput>;
};

export type MutationInsertMdoDesignDocumentArgs = {
  customer: Scalars['String'];
  productOrderCode: Scalars['String'];
  customerPOCode?: Maybe<Scalars['String']>;
  note: Scalars['String'];
  documents?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationUpdateMdoDesignDocumentArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  values: MdoDesignDocumentInput;
};

export type MutationInsertMdoDesignDocumentUnitArgs = {
  categoryKey: Scalars['String'];
  designDocumentId: Scalars['ID'];
  name: Scalars['String'];
  attributes?: Maybe<Array<MdAttributeInput>>;
  documentUnits?: Maybe<Array<Scalars['String']>>;
  snapshots?: Maybe<Array<Scalars['String']>>;
  note: Scalars['String'];
};

export type MutationUpdateMdoDesignDocumentUnitArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  values: MdoDesignDocumentUnitInput;
};

export type MutationSaveMdpFinModelArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  code: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  specialAttributes?: Maybe<Array<Maybe<MdAttributeInput>>>;
  otherAttributes?: Maybe<Array<Maybe<MdAttributeInput>>>;
  featureFieldIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  customComponents?: Maybe<Array<Maybe<MdpCustomComponentInput>>>;
  sizes?: Maybe<Array<Maybe<MdpFinSizeInput>>>;
};

export type MutationSaveMdpGoggleModelArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  code: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  specialAttributes?: Maybe<Array<Maybe<MdAttributeInput>>>;
  otherAttributes?: Maybe<Array<Maybe<MdAttributeInput>>>;
  featureFieldIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  customComponents?: Maybe<Array<Maybe<MdpCustomComponentInput>>>;
};

export type MutationUpdateMdDictionaryEntryNeedSyncArgs = {
  codes: Array<Scalars['String']>;
  needSync: Scalars['Boolean'];
};

export type MutationSyncMdDictionaryEntryArgs = {
  codes: Array<Scalars['String']>;
};

export type MutationCreateMdpStructureArgs = {
  uid: Scalars['String'];
  structureType: Scalars['String'];
  categoryKey: Scalars['String'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  attributes?: Maybe<Array<Maybe<MdAttributeInput>>>;
  bomEntries?: Maybe<Array<Maybe<MdpBomEntryInput>>>;
  optionalFieldOptions?: Maybe<Array<Maybe<MdFieldExtraOptionInput>>>;
  grossWeight?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
};

export type MutationUpdateMdpStructureArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  structureType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  attributes?: Maybe<Array<Maybe<MdAttributeInput>>>;
  bomEntries?: Maybe<Array<Maybe<MdpBomEntryInput>>>;
  optionalFieldOptions?: Maybe<Array<Maybe<MdFieldExtraOptionInput>>>;
  grossWeight?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
};

export type MutationSaveMdpStructurePicturesArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  mainPicture?: Maybe<Scalars['File']>;
  pictures?: Maybe<Array<Maybe<Scalars['File']>>>;
};

export type MutationSaveMdpStructureTemplateArgs = {
  categoryKey: Scalars['String'];
  mdFieldIds?: Maybe<Array<Scalars['ID']>>;
  optionalFieldIds?: Maybe<Array<Scalars['ID']>>;
  viewMdFieldIds?: Maybe<Array<Scalars['ID']>>;
  generateStructure: Scalars['Boolean'];
};

export type MutationSaveStructureToMdDataArgs = {
  mdDataIds: Array<Maybe<Scalars['ID']>>;
  structureId: Scalars['ID'];
};

export type MutationDeleteMdpStructureToMdDataArgs = {
  mdDataIds: Array<Maybe<Scalars['ID']>>;
};

export type MutationSetStructureToMdDataOnShelfArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
  onShelf: Scalars['Int'];
};

export type MutationUpdateMdpWrapperBomEntryArgs = {
  structureId: Scalars['ID'];
  ver: Scalars['Int'];
  wrapperBomEntries?: Maybe<Array<Maybe<MdpWrapperBomEntryValues>>>;
};

export type MutationSaveMdpMdDataViewArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  filterCondition?: Maybe<FilterCondition>;
  viewMdFieldIds?: Maybe<Scalars['Json']>;
  displayMdFieldIds?: Maybe<Array<Scalars['ID']>>;
};

export type MutationSaveMdaLensColoursArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  shown?: Maybe<Scalars['Boolean']>;
  commonUsed?: Maybe<Scalars['Boolean']>;
  customColourFamily?: Maybe<Scalars['String']>;
  moq?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationSaveMdScriptProcessorArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  targetModel: Scalars['String'];
  filter?: Maybe<FilterCondition>;
  batchSize: Scalars['Int'];
  startingScript?: Maybe<Scalars['String']>;
  actionScript: Scalars['String'];
  failedScript?: Maybe<Scalars['String']>;
  closingScript?: Maybe<Scalars['String']>;
};

export type MutationDeleteMdScriptProcessorArgs = {
  id: Scalars['ID'];
};

export type MutationExecuteMdScriptProcessorArgs = {
  id: Scalars['ID'];
};

export type MutationSetMdcCategoryParamArgs = {
  categoryKey: Scalars['String'];
  paramKey: Scalars['String'];
  value?: Maybe<Scalars['Json']>;
};

export type MutationInsertMdoMarkPrintTemplateArgs = {
  values: MdoMarkPrintTemplateInput;
};

export type MutationUpdateMdoMarkPrintTemplateArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  values: MdoMarkPrintTemplateInput;
};

export type MutationCreateMdcDataSearchArgs = {
  categoryKey: Scalars['String'];
  productSearch?: Maybe<Scalars['Boolean']>;
  packingSearch?: Maybe<Scalars['Boolean']>;
  searchFieldIds?: Maybe<Array<Scalars['ID']>>;
  bomEntrySearchItems?: Maybe<Array<BomEntrySearchItemInput>>;
};

export type MutationUpdateMdcDataSearchArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  values?: Maybe<MdcDataSearchValues>;
};

export type MutationSaveMdoProductOrderArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  code: Scalars['String'];
  customer: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  principals?: Maybe<Array<Maybe<Scalars['ID']>>>;
  participants?: Maybe<Array<Maybe<Scalars['ID']>>>;
  attributes?: Maybe<Array<Maybe<MdAttributeInput>>>;
  attachments?: Maybe<Array<Maybe<Scalars['File']>>>;
  currency?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  taxType?: Maybe<Scalars['ValuedEnum']>;
  taxRate?: Maybe<Scalars['Float']>;
};

export type MutationDeleteMdoProductOrderArgs = {
  id: Scalars['ID'];
};

export type MutationSaveMdoEndProductArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  productOrderCode: Scalars['String'];
  categoryKey: Scalars['String'];
  mainProducts: Array<Maybe<MdoMainProductInput>>;
  infos: Array<Maybe<MdoEndProductInfoInput>>;
  orderQty?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationCopyMdoEndProductArgs = {
  productOrderId: Scalars['ID'];
  copyFromEndProductId: Scalars['ID'];
};

export type MutationSaveMdoEndProductReceivableArgs = {
  endProductId: Scalars['ID'];
  orderQty?: Maybe<Scalars['Float']>;
  receivableUnitPrice?: Maybe<Scalars['Float']>;
};

export type MutationSaveMdoEndProductMixinQtyArgs = {
  mixinValues: Array<Maybe<MdoEndProductMixinInput>>;
};

export type MutationCloseMdoEndProductArgs = {
  id: Scalars['ID'];
  deleteDraft?: Maybe<Scalars['Boolean']>;
};

export type MutationUpdateMdoEndProductPeInfoArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  mdDataCode?: Maybe<Scalars['String']>;
  engineeringAttributes?: Maybe<Array<Maybe<MdAttributeInput>>>;
  mdDataGeneratingRule?: Maybe<Scalars['ValuedEnum']>;
  craft?: Maybe<Scalars['Boolean']>;
  packingRef?: Maybe<Scalars['Boolean']>;
  carrierPath?: Maybe<Scalars['String']>;
  carrierInfoKey?: Maybe<Scalars['String']>;
};

export type MutationUpdateMdoEndProductAttrsArgs = {
  productOrderCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationSetMdoMainProductArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  mdDataCode: Scalars['String'];
};

export type MutationSubmitMdoEndProductStateArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  action: Scalars['ValuedEnum'];
};

export type MutationSubmitMdoMainProductEngineeringStateArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  action: Scalars['ValuedEnum'];
};

export type MutationSubmitMdoEndProductEngineeringStateArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  action: Scalars['ValuedEnum'];
};

export type MutationDeleteMdoEndProductArgs = {
  id: Scalars['ID'];
};

export type MutationSetMdoBoxingIgnoreArgs = {
  productOrderCode: Scalars['String'];
  ignored: Scalars['Boolean'];
};

export type MutationSaveMdoBoxingSheetArgs = {
  productOrderCode: Scalars['String'];
  entries?: Maybe<Array<Maybe<MdoBoxingSheetEntryInput>>>;
  note?: Maybe<Scalars['String']>;
};

export type MutationSaveFreeMdoBoxingSheetEntriesArgs = {
  sheetId: Scalars['ID'];
  entries?: Maybe<Array<Maybe<MdoBoxingSheetEntryFreeInput>>>;
};

export type MutationSaveMdoBoxingSheetEntryBoxNoArgs = {
  sheetId: Scalars['ID'];
  boxNos: Array<Maybe<MdoBoxingSheetEntryBoxNoInput>>;
};

export type MutationSaveMdoBoxingSheetEntryWeightArgs = {
  sheetId: Scalars['ID'];
  weights: Array<Maybe<MdoBoxingSheetEntryWeightInput>>;
};

export type MutationSetMdoBoxingNeedWeighArgs = {
  sheetId: Scalars['ID'];
  needWeigh: Scalars['Boolean'];
};

export type MutationSaveMdoBoxingSheetEntryActualWeightArgs = {
  sheetId: Scalars['ID'];
  weights: Array<Maybe<MdoBoxingSheetEntryWeightInput>>;
};

export type MutationSetMdoBoxingSheetEntryMatchUsedArgs = {
  entryIds: Array<Maybe<Scalars['ID']>>;
  matchUsed: Scalars['Boolean'];
};

export type MutationApplyMdoBoxingActualWeightArgs = {
  entryId: Scalars['ID'];
  ver: Scalars['Int'];
  applyOrIgnore: Scalars['Boolean'];
};

export type MutationInsertMdoEndProductInfoTemplateArgs = {
  name: Scalars['String'];
  type: Scalars['ValuedEnum'];
  attributes: Array<MdAttributeInput>;
  mdFieldIds: Array<Scalars['ID']>;
  configs: Array<MdoEndProductInfoFieldConfigInput>;
  defaultAdded: Scalars['Boolean'];
  craft: Scalars['Boolean'];
  multiple: Scalars['Boolean'];
  mdDataGeneratingRule: Scalars['ValuedEnum'];
};

export type MutationUpdateMdoEndProductInfoTemplateArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  values: MdoEndProductInfoTemplateInput;
};

export type MutationDeleteMdoEndProductInfoTemplateArgs = {
  id: Scalars['ID'];
};

export type MutationSetUserFollowersArgs = {
  userId: Scalars['ID'];
  followerIds: Array<Scalars['ID']>;
};

export type MutationSaveMdInvoiceArgs = {
  input?: Maybe<MdInvoiceInput>;
};

export type MutationSetMdInvoiceIssuedArgs = {
  invoiceId: Scalars['ID'];
  ver: Scalars['Int'];
};

export type MutationSetMdInvoiceRevokedArgs = {
  invoiceId: Scalars['ID'];
  ver: Scalars['Int'];
};

export type MutationSaveMdFieldTemplateArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  topic: Scalars['String'];
  name: Scalars['String'];
};

export type MutationSaveMdFieldTemplateEntryArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  templateId: Scalars['ID'];
  branchId?: Maybe<Scalars['ID']>;
  alias?: Maybe<Scalars['String']>;
  mdFieldId: Scalars['ID'];
  option?: Maybe<Scalars['Json']>;
  forceDefaultValue: Scalars['Boolean'];
  attrExtra?: Maybe<Scalars['Map']>;
};

export type MutationMoveMdFieldTemplateEntryArgs = {
  entryId: Scalars['ID'];
  parentBranchId?: Maybe<Scalars['ID']>;
  seq?: Maybe<Scalars['Int']>;
};

export type MutationDeleteMdFieldTemplateEntryArgs = {
  entryId: Scalars['ID'];
  cascade?: Maybe<Scalars['Boolean']>;
};

export type MutationSaveMdFieldTemplateBranchArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  templateId: Scalars['ID'];
  entryId: Scalars['ID'];
  name: Scalars['String'];
  defaultBranch: Scalars['Boolean'];
  branchFilter?: Maybe<Scalars['Json']>;
};

export type MutationDeleteMdFieldTemplateBranchArgs = {
  branchId: Scalars['ID'];
  cascade?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateRoleGroupArgs = {
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  adminIds: Array<Scalars['ID']>;
  permissionCodes?: Maybe<Array<Scalars['ID']>>;
};

export type MutationUpdateRoleGroupArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  values?: Maybe<RoleGroupValues>;
};

export type MutationCreateRoleArgs = {
  name: Scalars['String'];
  roleGroupId: Scalars['ID'];
  permissionCodes?: Maybe<Array<Scalars['ID']>>;
};

export type MutationUpdateRoleArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  values?: Maybe<RoleValues>;
};

export type MutationAssignUserToRoleArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  userIds: Array<Scalars['ID']>;
};

export type MutationRemoveUserFromRoleArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  userIds: Array<Scalars['ID']>;
};

export type MutationCreatePermissionSpecialArgs = {
  permissionCode: Scalars['ID'];
  type: Scalars['ValuedEnum'];
  expiredAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['ID'];
  supervisorId: Scalars['ID'];
};

export type MutationUpdatePermissionSpecialArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  values?: Maybe<PermissionSpecialValues>;
};

export type MutationDeletePermissionSpecialArgs = {
  id: Scalars['ID'];
};

export type MutationClosePermissionArgs = {
  code: Scalars['ID'];
};

export type MutationOpenPermissionArgs = {
  code: Scalars['ID'];
};

export type MutationRestorePermissionArgs = {
  code: Scalars['ID'];
};

export type MutationSaveMdUnitArgs = {
  code?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationSaveMdUnitConversionArgs = {
  sourceUnit: Scalars['ID'];
  targetUnit: Scalars['ID'];
  factor: Scalars['Float'];
};

export type MutationSaveMdUnitConversionsArgs = {
  sourceUnit: Scalars['ID'];
  targetUnits?: Maybe<Scalars['Map']>;
};

export type MutationNewMdFileArgs = {
  files: Array<Maybe<MdFileInput>>;
  parentId: Scalars['ID'];
};

export type MutationNewMdFileDirArgs = {
  name: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
};

export type MutationNewMdFileVersionArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  fileKey: Scalars['String'];
  fileName: Scalars['String'];
};

export type MutationMoveMdFileArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
  destDirId?: Maybe<Scalars['ID']>;
};

export type MutationRenameMdFileArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  fileName: Scalars['String'];
};

export type MutationShareMdFileArgs = {
  id: Scalars['ID'];
  userIds: Array<Scalars['ID']>;
  writable?: Maybe<Scalars['Boolean']>;
  deletable?: Maybe<Scalars['Boolean']>;
};

export type MutationCancelShareMdFileArgs = {
  id: Scalars['ID'];
};

export type MutationShareMdFileToEveryoneArgs = {
  dirId: Scalars['ID'];
  shareToEveryone: Scalars['Boolean'];
};

export type MutationFollowMdFileDirsArgs = {
  mdFileIds: Array<Maybe<Scalars['ID']>>;
};

export type MutationUnFollowMdFileDirsArgs = {
  mdFileIds: Array<Maybe<Scalars['ID']>>;
};

export type MutationSetShowFollowedDirOnlyArgs = {
  mdFileId: Scalars['ID'];
  showFollowedDirOnly: Scalars['Boolean'];
};

export type MutationCreateMdeWarehouseArgs = {
  code: Scalars['String'];
  name: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  lineSide?: Maybe<Scalars['Boolean']>;
  allowExceed?: Maybe<Scalars['Boolean']>;
  countAsAvailable?: Maybe<Scalars['Boolean']>;
  defect?: Maybe<Scalars['Boolean']>;
  limitation?: Maybe<Scalars['Float']>;
};

export type MutationUpdateMdeWarehouseArgs = {
  code: Scalars['ID'];
  ver: Scalars['Int'];
  values?: Maybe<MdeWarehouseInput>;
};

export type MutationCreateMdeCurrencyArgs = {
  code: Scalars['String'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  supplierDefault?: Maybe<Scalars['Boolean']>;
  customerDefault?: Maybe<Scalars['Boolean']>;
};

export type MutationSaveMdeCurrencyExchangeRateArgs = {
  currency: Scalars['String'];
  effectiveDate: Scalars['DateTime'];
  buyInRate?: Maybe<Scalars['Float']>;
  clearanceRate?: Maybe<Scalars['Float']>;
};

export type MutationUpdateMdeCurrencyArgs = {
  code: Scalars['ID'];
  ver: Scalars['Int'];
  values?: Maybe<MdeCurrencyInput>;
};

export type MutationCreateMdeSupplierArgs = {
  code: Scalars['String'];
  name: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  paymentConditionId?: Maybe<Scalars['ID']>;
  currencyCode?: Maybe<Scalars['String']>;
  taxType?: Maybe<Scalars['Int']>;
  taxRate?: Maybe<Scalars['Float']>;
  depositRate?: Maybe<Scalars['Float']>;
  manager?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  contactNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  faxNumber?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  remittingBank?: Maybe<Scalars['String']>;
  remittingAccount?: Maybe<Scalars['String']>;
  taxAccount?: Maybe<Scalars['String']>;
  purchaser?: Maybe<Scalars['String']>;
  contactAddress?: Maybe<Scalars['String']>;
  billAddress?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationUpdateMdeSupplierArgs = {
  code: Scalars['ID'];
  ver: Scalars['Int'];
  values?: Maybe<MdeSupplierInput>;
};

export type MutationCreateMdeCustomerArgs = {
  code: Scalars['String'];
  name: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  paymentConditionId?: Maybe<Scalars['ID']>;
  currencyCode?: Maybe<Scalars['String']>;
  taxType?: Maybe<Scalars['Int']>;
  taxRate?: Maybe<Scalars['Float']>;
  depositRate?: Maybe<Scalars['Float']>;
  manager?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  contactNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  faxNumber?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  remittingBank?: Maybe<Scalars['String']>;
  remittingAccount?: Maybe<Scalars['String']>;
  taxAccount?: Maybe<Scalars['String']>;
  contactAddress?: Maybe<Scalars['String']>;
  billAddress?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationUpdateMdeCustomerArgs = {
  code: Scalars['ID'];
  ver: Scalars['Int'];
  values?: Maybe<MdeCustomerInput>;
};

export type MutationCreateMdeIncotermsArgs = {
  code: Scalars['String'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};

export type MutationUpdateIncotermsArgs = {
  code: Scalars['ID'];
  ver: Scalars['Int'];
  values?: Maybe<MdeIncotermsInput>;
};

export type MutationInsertMdoPackingOptionArgs = {
  name: Scalars['String'];
  customer?: Maybe<Scalars['String']>;
  packingAttributes?: Maybe<Array<Maybe<MdAttributeInput>>>;
  productAttributes?: Maybe<Array<Maybe<MdAttributeInput>>>;
  needInnerBox?: Maybe<Scalars['Boolean']>;
  innerBoxQty?: Maybe<Scalars['Int']>;
  innerBoxAttributes?: Maybe<Array<Maybe<MdAttributeInput>>>;
  innerBoxLength?: Maybe<Scalars['Float']>;
  innerBoxWidth?: Maybe<Scalars['Float']>;
  innerBoxHeight?: Maybe<Scalars['Float']>;
  outerBoxQty?: Maybe<Scalars['Int']>;
  outerBoxLength?: Maybe<Scalars['Float']>;
  outerBoxWidth?: Maybe<Scalars['Float']>;
  outerBoxHeight?: Maybe<Scalars['Float']>;
  outerBoxAttributes?: Maybe<Array<Maybe<MdAttributeInput>>>;
  grossWeight?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
  horizontalSeparator?: Maybe<Scalars['Boolean']>;
  horizontalSeparatorQty?: Maybe<Scalars['Int']>;
  innerHorizontalSeparator?: Maybe<Scalars['Boolean']>;
  innerHorizontalSeparatorQty?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationUpdateMdoPackingOptionArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  values: MdoPackingOptionInput;
};

export type MutationSaveMdoPackingOptionArgs = {
  name: Scalars['String'];
  customer?: Maybe<Scalars['String']>;
  packingAttributes?: Maybe<Array<Maybe<MdAttributeInput>>>;
  productAttributes?: Maybe<Array<Maybe<MdAttributeInput>>>;
  needInnerBox?: Maybe<Scalars['Boolean']>;
  innerBoxQty?: Maybe<Scalars['Int']>;
  innerBoxAttributes?: Maybe<Array<Maybe<MdAttributeInput>>>;
  innerBoxLength?: Maybe<Scalars['Float']>;
  innerBoxWidth?: Maybe<Scalars['Float']>;
  innerBoxHeight?: Maybe<Scalars['Float']>;
  outerBoxQty?: Maybe<Scalars['Int']>;
  outerBoxLength?: Maybe<Scalars['Float']>;
  outerBoxWidth?: Maybe<Scalars['Float']>;
  outerBoxHeight?: Maybe<Scalars['Float']>;
  outerBoxAttributes?: Maybe<Array<Maybe<MdAttributeInput>>>;
  grossWeight?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
  horizontalSeparator?: Maybe<Scalars['Boolean']>;
  horizontalSeparatorQty?: Maybe<Scalars['Int']>;
  innerHorizontalSeparator?: Maybe<Scalars['Boolean']>;
  innerHorizontalSeparatorQty?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationSyncMdmPackingOptionArgs = {
  codes: Array<Scalars['String']>;
  syncCustomer: Scalars['Boolean'];
};

export type MutationSetInvalidateArgs = {
  codes: Array<Scalars['String']>;
  reason: Scalars['String'];
};

export type MutationResetMigStatusArgs = {
  codes: Array<Scalars['String']>;
};

export type MutationUpdatePackingOptionInboundNoteArgs = {
  codes: Array<Scalars['String']>;
  note: Scalars['String'];
};

export type MutationInsertPackingOptionInboundRecordArgs = {
  packingOptionUid: Scalars['String'];
  inboundCode: Scalars['String'];
};

export type MutationDeleteMdmPackingArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
};

export type MutationSaveMdDictionaryArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  dictEntries?: Maybe<Array<MdDictionaryEntryValues>>;
};

export type MutationSaveMdDictionaryEntriesArgs = {
  dictionaryId: Scalars['ID'];
  values: Array<MdDictionaryEntryValues>;
};

export type MutationUpdateMdDomainArgs = {
  name: Scalars['String'];
  field0Option?: Maybe<MdDataFixedFieldOptionInput>;
  field1Option?: Maybe<MdDataFixedFieldOptionInput>;
  field2Option?: Maybe<MdDataFixedFieldOptionInput>;
  field3Option?: Maybe<MdDataFixedFieldOptionInput>;
  field4Option?: Maybe<MdDataFixedFieldOptionInput>;
  field5Option?: Maybe<MdDataFixedFieldOptionInput>;
  field6Option?: Maybe<MdDataFixedFieldOptionInput>;
  field7Option?: Maybe<MdDataFixedFieldOptionInput>;
  field8Option?: Maybe<MdDataFixedFieldOptionInput>;
  field9Option?: Maybe<MdDataFixedFieldOptionInput>;
};

export type MutationInsertMdDataArgs = {
  code: Scalars['String'];
  field0?: Maybe<Scalars['String']>;
  field1?: Maybe<Scalars['String']>;
  field2?: Maybe<Scalars['String']>;
  field3?: Maybe<Scalars['String']>;
  field4?: Maybe<Scalars['String']>;
  field5?: Maybe<Scalars['String']>;
  field6?: Maybe<Scalars['String']>;
  field7?: Maybe<Scalars['String']>;
  field8?: Maybe<Scalars['String']>;
  field9?: Maybe<Scalars['String']>;
  attributes?: Maybe<Array<MdAttributeInput>>;
  structureKey?: Maybe<Scalars['String']>;
  bomEntries?: Maybe<Array<MdDataBomEntryInput>>;
};

export type MutationUpdateMdDataArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  values?: Maybe<MdDataValues>;
};

export type MutationSaveMdDataAttributesArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  attributes: Array<MdAttributeInput>;
};

export type MutationRemoveMdDataAttributesArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  fieldIds: Array<Scalars['ID']>;
};

export type MutationSetMdDataBomArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  structureKey?: Maybe<Scalars['String']>;
  bomEntries: Array<MdDataBomEntryInput>;
};

export type MutationSetMdDataExtraArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  extraKey: Scalars['String'];
  extraValue?: Maybe<Scalars['Json']>;
};

export type MutationBatchSaveMdDataArgs = {
  values?: Maybe<Array<MdDataValues>>;
  createIfNotExists?: Maybe<Scalars['Boolean']>;
};

export type MutationSaveMdDataPicturesArgs = {
  mdDataId?: Maybe<Scalars['ID']>;
  pictures: Array<Maybe<Scalars['File']>>;
  mainPicture?: Maybe<Scalars['File']>;
};

export type MutationUpdateMdDataAttributesArgs = {
  mdDataIds?: Maybe<Array<Scalars['ID']>>;
  filterCondition?: Maybe<FilterCondition>;
};

export type MutationSwitchMdDataBomTemplateArgs = {
  mdDataIds: Array<Scalars['ID']>;
  structureKey: Scalars['String'];
};

export type MutationDetachMdDataBomTemplateArgs = {
  mdDataIds: Array<Scalars['ID']>;
};

export type MutationSyncPurchaseRequisitionToAtsArgs = {
  productOrderCode: Scalars['String'];
  entries: Array<Maybe<AtsPurchaseRequisitionEntryInput>>;
};

export type MutationSyncMdDataToAtsArgs = {
  mdDataCodes: Array<Maybe<Scalars['String']>>;
  productOrderCode?: Maybe<Scalars['String']>;
};

export type MutationSyncPoInvSheetToAtsArgs = {
  mdoDeliverySheetCode: Scalars['String'];
  customer: Scalars['String'];
  applicant: Scalars['String'];
  loadingDate: Scalars['Date'];
  entries?: Maybe<Array<Maybe<AtsPoInvSheetEntryInput>>>;
  noSourceEntries?: Maybe<Array<Maybe<NoSourcePoInvSheetEntryInput>>>;
};

export type MutationRevokeAtsPoInvSheetArgs = {
  mdoDeliverySheetCode: Scalars['String'];
};

export type MutationCreateBrCatalogArgs = {
  name: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
  modelName: Scalars['String'];
};

export type MutationUpdateBrCatalogArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  name: Scalars['String'];
};

export type MutationMoveBrCatalogArgs = {
  catalogId: Scalars['ID'];
  parentId?: Maybe<Scalars['ID']>;
  newSeq: Scalars['Int'];
};

export type MutationDeleteBrCatalogArgs = {
  id: Scalars['ID'];
};

export type MutationSetEntityCatalogArgs = {
  catalogId: Scalars['ID'];
  entityIds: Array<Maybe<Scalars['ID']>>;
};

export type MutationSaveMdoDeliverySheetArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  code: Scalars['String'];
  customerCode: Scalars['ID'];
  deliveryAddress?: Maybe<Scalars['Json']>;
  incotermsCode?: Maybe<Scalars['ID']>;
  appliedDate: Scalars['DateTime'];
  transportType: Scalars['ID'];
  transportAttributes?: Maybe<Array<Maybe<MdAttributeInput>>>;
  applicantId?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
  sheetEntries?: Maybe<Array<Maybe<MdoDeliverySheetEntryInput>>>;
};

export type MutationDeleteMdoDeliverySheetArgs = {
  id: Scalars['ID'];
};

export type MutationDeliverMdoDeliverySheetArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
};

export type MutationRevokeMdoDeliverySheetArgs = {
  id: Scalars['ID'];
};

export type MutationSaveMdoDeliverySheetInvoiceArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  invoiceCodes: Array<Maybe<Scalars['String']>>;
  invoiceFiles: Array<Maybe<Scalars['File']>>;
  customDecCodes: Array<Maybe<Scalars['String']>>;
  customDecFiles: Array<Maybe<Scalars['File']>>;
  cargoCodes: Array<Maybe<Scalars['String']>>;
  cargoFiles: Array<Maybe<Scalars['File']>>;
  otherFiles?: Maybe<Array<Maybe<Scalars['File']>>>;
  note?: Maybe<Scalars['String']>;
};

export type MutationSaveMdaLensElectroplateColoursArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  shown?: Maybe<Scalars['Boolean']>;
  commonUsed?: Maybe<Scalars['Boolean']>;
  customColourFamily?: Maybe<Scalars['String']>;
  moq?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationSaveMdaLensProductModelColoursArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  shown?: Maybe<Scalars['Boolean']>;
  commonUsed?: Maybe<Scalars['Boolean']>;
  customColourFamily?: Maybe<Scalars['String']>;
  moq?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationUpdateRqaTallyNeedSyncArgs = {
  inputs: Array<Maybe<MdmRqaTallyInboundRecordInput>>;
};

export type MutationMigrateRqaMdTallyArgs = {
  topic?: Maybe<Scalars['String']>;
  resetData?: Maybe<Scalars['Boolean']>;
};

export type MutationDisableUserArgs = {
  userId: Scalars['ID'];
};

export type MutationEnableUserArgs = {
  userId: Scalars['ID'];
};

export type MutationSetUserAdminLevelArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  adminLevel: Scalars['Int'];
};

export type MutationAdminSetUserInfoArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  mobile: Scalars['String'];
  displayName: Scalars['String'];
  employeeNo?: Maybe<Scalars['String']>;
};

export type MutationCreateUserArgs = {
  displayName: Scalars['String'];
  employeeNo?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
};

export type MutationApproveJoiningApplicationArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
};

export type MutationRejectJoiningApplicationArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
};

export type MutationSaveExplorerViewArgs = {
  modelName: Scalars['String'];
  name: Scalars['String'];
  hash?: Maybe<Scalars['String']>;
  publicScope: Scalars['Boolean'];
  options: Scalars['Json'];
};

export type MutationDeleteExplorerViewArgs = {
  modelName: Scalars['String'];
  hash: Scalars['String'];
};

export type MutationSaveChartViewArgs = {
  modelName?: Maybe<Scalars['String']>;
  chartType: Scalars['ValuedEnum'];
  name: Scalars['String'];
  hash?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  snapshot?: Maybe<Scalars['String']>;
  rowRequest?: Maybe<Scalars['Json']>;
  chartOptions?: Maybe<Scalars['Json']>;
  labelSql?: Maybe<Scalars['String']>;
  tableSql?: Maybe<Scalars['String']>;
  externalDataSourceId?: Maybe<Scalars['ID']>;
  labelExpress?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationCopyChartViewArgs = {
  id: Scalars['ID'];
};

export type MutationSaveChartDashboardArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  catalogId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  rows?: Maybe<Scalars['Json']>;
  readerIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  chartViewIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  chartCatalogId?: Maybe<Scalars['ID']>;
  copyChartView?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteChartDashboardArgs = {
  id: Scalars['ID'];
};

export type MutationCreateExternalDataSourceArgs = {
  name: Scalars['String'];
  url: Scalars['String'];
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  driver: Scalars['ValuedEnum'];
  note?: Maybe<Scalars['String']>;
};

export type MutationUpdateExternalDataSourceArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  driver?: Maybe<Scalars['ValuedEnum']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationDeleteExternalDataSourceArgs = {
  id: Scalars['ID'];
};

export type MutationSaveUserDashboardGaugeConfigArgs = {
  userId: Scalars['ID'];
  config: Array<Maybe<DashboardGaugeConfigInput>>;
};

export type MutationCopyUserDashboardGaugeSettingsArgs = {
  sourceUserId: Scalars['ID'];
  destUserIds: Array<Maybe<Scalars['ID']>>;
};

export type MutationUpdateMyProfileArgs = {
  displayName?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
};

export type MutationSaveSysExportTemplateArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  modelName: Scalars['String'];
  name: Scalars['String'];
  templateFileKey: Scalars['String'];
  forPrint?: Maybe<Scalars['Boolean']>;
  forXlsExport?: Maybe<Scalars['Boolean']>;
  forPdfExport?: Maybe<Scalars['Boolean']>;
  rowsPerPage?: Maybe<Scalars['Int']>;
  maxRow?: Maybe<Scalars['Int']>;
  groupByScript?: Maybe<Scalars['String']>;
  fileNameScript?: Maybe<Scalars['String']>;
  printPaperWidth?: Maybe<Scalars['Float']>;
  printPaperHeight?: Maybe<Scalars['Float']>;
  printOrientation?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationSaveDataBatchIdsArgs = {
  modelName: Scalars['String'];
  dataIds: Array<Maybe<Scalars['ID']>>;
};

export type MutationGenerateEstInvitationTokenArgs = {
  accountBookId: Scalars['ID'];
  owner: Scalars['Boolean'];
};

export type MutationCreateEstAccountBookArgs = {
  templateType: Scalars['ValuedEnum'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};

export type MutationSaveEstAccountBookArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};

export type MutationSaveEstAccountBookUserArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  ownerIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  cooperatorIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  readerIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type MutationSwitchEstAccountBookOwnerArgs = {
  id: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationSetEstAccountBookCooperatorArgs = {
  id: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationSetEstAccountBookReaderArgs = {
  id: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationRemoveEstAccountBookUserArgs = {
  id: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationJoinEstAccountBookArgs = {
  invitationToken: Scalars['String'];
};

export type MutationQuitEstAccountBookArgs = {
  id: Scalars['ID'];
};

export type MutationSaveEstAccountBookTagsArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  newTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  alterTags?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
  removeTags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationSaveEstReceivableMethodsArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  receivableMethods?: Maybe<Array<Maybe<EstReceivableMethodInput>>>;
};

export type MutationSaveEstTradingTargetArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  accountBookId: Scalars['ID'];
  name: Scalars['String'];
  receivableMethods?: Maybe<Array<Maybe<EstReceivableMethodInput>>>;
  openId?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationSaveEstTradingTargetReceiveMethodsArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  receivableMethods?: Maybe<Array<Maybe<EstReceivableMethodInput>>>;
};

export type MutationInsertEstAccountEntryArgs = {
  accountBookId: Scalars['ID'];
  summary: Scalars['String'];
  accountType: Scalars['ValuedEnum'];
  qty: Scalars['Float'];
  operatedAt: Scalars['Date'];
  note?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Maybe<Scalars['File']>>>;
  tradingTargetId?: Maybe<Scalars['ID']>;
  serviceFee?: Maybe<Scalars['Float']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  estContractId?: Maybe<Scalars['ID']>;
  estTodoId?: Maybe<Scalars['ID']>;
};

export type MutationModifyEstAccountEntryArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  summary: Scalars['String'];
  accountType: Scalars['ValuedEnum'];
  qty: Scalars['Float'];
  operatedAt?: Maybe<Scalars['Date']>;
  note?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Maybe<Scalars['File']>>>;
  tradingTargetId?: Maybe<Scalars['ID']>;
  serviceFee?: Maybe<Scalars['Float']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  estContractId?: Maybe<Scalars['ID']>;
};

export type MutationDeleteEstAccountEntryArgs = {
  id: Scalars['ID'];
};

export type MutationSaveEstContractArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  accountBookId: Scalars['ID'];
  name: Scalars['String'];
  accountType: Scalars['ValuedEnum'];
  tradingTargetId?: Maybe<Scalars['ID']>;
  totalAmount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  attachments?: Maybe<Array<Maybe<Scalars['File']>>>;
  reminderPeriod?: Maybe<Scalars['ValuedEnum']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationUpdateEstContractStateArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  closeState: Scalars['Boolean'];
};

export type MutationBatchSaveEstContractTodosArgs = {
  contractId: Scalars['ID'];
  ver: Scalars['Int'];
  reminderTiming?: Maybe<Scalars['ValuedEnum']>;
  newTodos: Array<Maybe<EstContractTodoInput>>;
};

export type MutationSaveEstTodoArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  accountBookId: Scalars['ID'];
  name: Scalars['String'];
  accountType: Scalars['ValuedEnum'];
  scheduleAt?: Maybe<Scalars['Date']>;
  estimateAmount?: Maybe<Scalars['Float']>;
  attachments?: Maybe<Array<Maybe<Scalars['File']>>>;
  contractId?: Maybe<Scalars['ID']>;
  tradingTargetId?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
  fromRequestHash?: Maybe<Scalars['String']>;
};

export type MutationDeleteEstTodoArgs = {
  todoId: Scalars['ID'];
};

export type MutationGenerateEstTodoRequestHashArgs = {
  todoId: Scalars['ID'];
};

export type MutationSaveEstReceivablePlanArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  accountBookId: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  totalAmount: Scalars['Float'];
  publicAttachments?: Maybe<Array<Maybe<Scalars['File']>>>;
  privateAttachments?: Maybe<Array<Maybe<Scalars['File']>>>;
  payerId?: Maybe<Scalars['ID']>;
  forwardable: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
  steps: Array<Maybe<EstReceivableStepInput>>;
};

export type MutationDeleteEstReceivablePlanArgs = {
  planId: Scalars['ID'];
};

export type MutationPostEstReceivableStepCommentArgs = {
  stepId: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  nickName: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['File']>;
};

export type MutationSaveEstReceivableStepReceivableMethodArgs = {
  stepId: Scalars['ID'];
  ver: Scalars['Int'];
  receivableMethod: EstReceivableMethodInput;
};

export type MutationMarkEstReceivableStepPaidArgs = {
  stepId: Scalars['ID'];
  ver: Scalars['Int'];
  payerAttachments?: Maybe<Array<Maybe<Scalars['File']>>>;
  operatedBy?: Maybe<Scalars['String']>;
};

export type MutationConfirmEstReceivableStepArgs = {
  stepId: Scalars['ID'];
  ver: Scalars['Int'];
};

export type MutationCloseEstReceivablePlanArgs = {
  planId: Scalars['ID'];
  ver?: Maybe<Scalars['Int']>;
};

export type MutationAlterEstReceivableSurplusArgs = {
  accountBookId: Scalars['ID'];
  tradingTargetId: Scalars['ID'];
  targetQty: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
};

export type MutationAlterEstPayableSurplusArgs = {
  accountBookId: Scalars['ID'];
  tradingTargetId: Scalars['ID'];
  targetQty: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
};

export type MutationMarkEstReceivablePlanOperationArgs = {
  planId: Scalars['ID'];
  operation: Scalars['String'];
};

export type MutationMarkEstReceivableStepOperationArgs = {
  stepId: Scalars['ID'];
  operation: Scalars['String'];
};

export type MutationSaveMdpGalleryCatalogConfigArgs = {
  code?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  modelFieldId?: Maybe<Scalars['ID']>;
  styleFieldId?: Maybe<Scalars['ID']>;
  sizeFieldId?: Maybe<Scalars['ID']>;
  featureFieldIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  specialFieldIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  otherFieldIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  mdCategoryKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
  styleMode?: Maybe<Scalars['ValuedEnum']>;
};

export type MutationSetMdpGalleryModelOnShelfArgs = {
  code?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  sizeAccessories?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortWeight1?: Maybe<Scalars['Int']>;
  sortWeight2?: Maybe<Scalars['Int']>;
  sortWeight3?: Maybe<Scalars['Int']>;
  listItemPromotion1?: Maybe<Scalars['String']>;
  listItemPromotion2?: Maybe<Scalars['String']>;
  detailMarkdown?: Maybe<Scalars['String']>;
  sketchJson?: Maybe<Scalars['Json']>;
};

export type MutationSetMdpGalleryModelOffShelfArgs = {
  code?: Maybe<Scalars['ID']>;
};

export type MutationSaveMdpGalleryStylesArgs = {
  modelCode: Scalars['ID'];
  styles: Array<Maybe<MdpGalleryStyleInput>>;
};

export type MutationSaveMdpGallerySkuArgs = {
  modelCode: Scalars['String'];
  values: Array<Maybe<MdpGallerySkuInput>>;
};

export type MutationSetMdpGallerySkuOnShelfArgs = {
  values: Array<Maybe<MdpGallerySkuOnShelfInput>>;
};

export type MutationSetMdpGallerySkuOffShelfArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
};

export type MutationDeleteMdpGallerySkuArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
};

export type MutationSaveMdTallyAccountSetArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  code: Scalars['String'];
  name: Scalars['String'];
  topic: Scalars['String'];
  defaultForTopic: Scalars['Boolean'];
  featureKeys: Array<Maybe<Scalars['String']>>;
  extraKeys: Array<Maybe<Scalars['String']>>;
  matchers: Array<Maybe<MdTallyAccountTypeMatcherInput>>;
  allowNegative?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationRebuildMdTallySurplusArgs = {
  accountSetId: Scalars['ID'];
};

export type MutationRebuildAllMdTallySurplusArgs = {
  topic: Scalars['String'];
};

export type MutationAlterMdTallySurplusArgs = {
  topic: Scalars['String'];
  surplusId: Scalars['ID'];
  alterQty: Scalars['Float'];
  alterUnit: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};

export type MutationModifyMdTallyArgs = {
  topic: Scalars['String'];
  tallyId: Scalars['ID'];
  ver?: Maybe<Scalars['Int']>;
  qty: Scalars['Float'];
  unit: Scalars['String'];
  appliedAt?: Maybe<Scalars['DateTime']>;
  operatedAt?: Maybe<Scalars['DateTime']>;
  info?: Maybe<Scalars['Map']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAdjustMdTallyArgs = {
  topic: Scalars['String'];
  tallyId: Scalars['ID'];
  ver?: Maybe<Scalars['Int']>;
  qty: Scalars['Float'];
  unit: Scalars['String'];
  operatedAt?: Maybe<Scalars['DateTime']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationDeleteMdTalliesArgs = {
  topic: Scalars['String'];
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type MutationRefreshMdTallyTimeSerialSurplusesViewArgs = {
  accountSetId: Scalars['ID'];
  timeSerialType?: Maybe<Scalars['ValuedEnum']>;
};

export type MutationSaveKtiTalentArgs = {
  talentInput?: Maybe<KtiTalentInput>;
};

export type MutationSaveKtiAttendanceArgs = {
  ktiAttendance?: Maybe<KtiAttendanceInput>;
};

export type MutationDeleteKtiAttendanceArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type MutationSaveKtiSalaryArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  paidSalary: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
};

export type MutationSaveKtiWorkingReportArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  talentIds: Array<Maybe<Scalars['ID']>>;
  reportDate?: Maybe<Scalars['Date']>;
  category: Scalars['ValuedEnum'];
  content?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationDeleteKtiWorkingReportArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type MutationSaveKtiTalentAssessmentArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  talentId: Scalars['ID'];
  category: Scalars['ValuedEnum'];
  content?: Maybe<Scalars['String']>;
  state: Scalars['ValuedEnum'];
  comment?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationDeleteKtiTalentAssessmentArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type MutationSaveKtiResearchAchievementArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  talentIds: Array<Maybe<Scalars['ID']>>;
  category: Scalars['ValuedEnum'];
  content?: Maybe<Scalars['String']>;
  researchField?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationDeleteKtiResearchAchievementArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type MutationSaveKtiSupervisorCommentArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  talentId?: Maybe<Scalars['ID']>;
  comment?: Maybe<Scalars['String']>;
};

export type MutationSaveKtiIntellectualPropertyArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  category: Scalars['ValuedEnum'];
  patentSN: Scalars['String'];
  applyDate: Scalars['Date'];
  patentName: Scalars['String'];
  applyBy?: Maybe<Scalars['String']>;
  talentIds: Array<Maybe<Scalars['ID']>>;
  categorySN?: Maybe<Scalars['String']>;
  authorizationSN?: Maybe<Scalars['String']>;
  authorizationDate?: Maybe<Scalars['Date']>;
  state: Scalars['ValuedEnum'];
  note?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationDeleteKtiIntellectualPropertyArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type MutationSaveQrtzJobArgs = {
  name: Scalars['String'];
  originalName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  trigger: QrtzTriggerInput;
  type: Scalars['ValuedEnum'];
  targetService: Scalars['String'];
  sqlStatement?: Maybe<Scalars['String']>;
  serviceApi?: Maybe<Scalars['String']>;
  servicePlugin?: Maybe<Scalars['String']>;
  graphQlApiQuery?: Maybe<Scalars['String']>;
  qlExpressScript?: Maybe<Scalars['String']>;
  catalogId?: Maybe<Scalars['ID']>;
  execParam?: Maybe<Scalars['Json']>;
};

export type MutationExecuteQrtzJobImmediatelyArgs = {
  jobName: Scalars['String'];
};

export type MutationPauseTriggerArgs = {
  triggerName: Scalars['String'];
};

export type MutationResumeTriggerArgs = {
  triggerName: Scalars['String'];
};

export type MutationDeleteQrtzJobArgs = {
  jobName: Scalars['String'];
};

export type MutationDeleteQrtzExecLogArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
};

export type MutationExecuteMdValidationArgs = {
  targetModel: Scalars['String'];
  ruleIds?: Maybe<Array<Scalars['ID']>>;
  filterCondition: FilterCondition;
};

export type MutationUpdateMdValidationRuleArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  enabled?: Maybe<Scalars['Boolean']>;
  checkOnSave?: Maybe<Scalars['Boolean']>;
};

export type MutationIgnoreMdDiagnosisArgs = {
  targetModel: Scalars['String'];
  dataId: Scalars['ID'];
  ruleName: Scalars['String'];
};

export type MutationSaveMdpWorkManualTemplateArgs = {
  id?: Maybe<Scalars['ID']>;
  ver?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  content: Scalars['String'];
  templateFieldIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type MutationSaveMdpStructureWorkProcessArgs = {
  structureId: Scalars['ID'];
  ver: Scalars['Int'];
  allowPrintProcess: Scalars['Boolean'];
  printManualTemplateId?: Maybe<Scalars['ID']>;
};

export type MutationBatchRefactorMdDataArgs = {
  values?: Maybe<Array<MdDataValues>>;
  keyMdFieldNames?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationConfirmMdmDataRefactorArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
  autoConfirm?: Maybe<Scalars['Boolean']>;
};

export type MutationRollBackMdmDataRefactorArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
};

export type MutationIgnoreMdmDataRefactorArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
};

export type MutationDeleteMdmDataRefactorArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
};

export type MutationCreateMdFieldArgs = {
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  dataType: Scalars['String'];
  option?: Maybe<Scalars['Json']>;
};

export type MutationUpdateMdFieldArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  values?: Maybe<MdFieldValues>;
};

export type MutationCreateMdTableArgs = {
  code: Scalars['ID'];
  name: Scalars['String'];
  catalogId?: Maybe<Scalars['Int']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  columns: Array<Maybe<MdTableColumnInput>>;
};

export type MutationUpdateMdTableArgs = {
  code: Scalars['ID'];
  ver: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  columns: Array<Maybe<MdTableColumnInput>>;
};

export type MutationSaveMdTableOwnershipArgs = {
  code: Scalars['ID'];
  ownerIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  cooperatorIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  readerIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type MutationSaveMdTableDataArgs = {
  tableCode: Scalars['String'];
  values?: Maybe<Scalars['Json']>;
};

export type MutationBatchSaveMdTableDataArgs = {
  tableCode: Scalars['String'];
  valueArray?: Maybe<Scalars['Json']>;
};

export type MutationBatchSaveMdTableDataColumnValueArgs = {
  tableCode: Scalars['String'];
  dataIds: Array<Maybe<Scalars['ID']>>;
  values?: Maybe<Scalars['Json']>;
};

export type MutationResetMdTableAutoIncrementalColumnArgs = {
  tableCode: Scalars['String'];
  colName: Scalars['String'];
  sortBy?: Maybe<Array<Maybe<SortColumn>>>;
  startSeq?: Maybe<Scalars['Int']>;
};

export type MutationDeleteMdTableDataArgs = {
  tableCode: Scalars['String'];
  dataIds: Array<Maybe<Scalars['ID']>>;
};

export type MutationSaveMdTableImportMappingPresetArgs = {
  tableCode: Scalars['String'];
  name: Scalars['String'];
  ver?: Maybe<Scalars['Int']>;
  mappings: Array<Maybe<MdTableImportMappingInput>>;
  options?: Maybe<Scalars['Json']>;
};

export type MutationDeleteMdTableImportMappingPresetArgs = {
  tableCode: Scalars['String'];
  name: Scalars['String'];
};

export type MutationStartMdTableImportArgs = {
  tableCode: Scalars['String'];
  columns: Array<Maybe<Scalars['String']>>;
  values: Array<Maybe<Array<Maybe<Scalars['String']>>>>;
  excelFilename?: Maybe<Scalars['String']>;
  sheetName?: Maybe<Scalars['String']>;
  startRow?: Maybe<Scalars['Int']>;
  omitEndRows?: Maybe<Scalars['Int']>;
  specifiedCols?: Maybe<Scalars['String']>;
};

export type MutationStartMdTableImportFromSqlArgs = {
  tableCode: Scalars['String'];
  externalDataSourceId?: Maybe<Scalars['ID']>;
  importFromSql: Scalars['String'];
};

export type MutationStartMdTableImportFromSqlWithPresetArgs = {
  tableCode: Scalars['String'];
};

export type MutationSaveMdTableImportMappingConfigArgs = {
  tableCode: Scalars['String'];
  mappings: Array<Maybe<MdTableImportMappingInput>>;
  options?: Maybe<Scalars['Json']>;
};

export type MutationUpdateMdTableImportDataArgs = {
  tableCode: Scalars['String'];
  importDataId: Scalars['ID'];
  column: Scalars['String'];
  value: Scalars['String'];
};

export type MutationSaveMdTableImportMergingArgs = {
  tableCode: Scalars['String'];
  importDataId: Scalars['ID'];
  merging: Scalars['Boolean'];
};

export type MutationDoMdTableImportArgs = {
  tableCode: Scalars['String'];
};

export type MutationCancelTableImportArgs = {
  tableCode: Scalars['String'];
};

export type MutationCopyMdTableArgs = {
  fromTableCode: Scalars['ID'];
  tableCode: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  withData?: Maybe<Scalars['Boolean']>;
  withOwnership?: Maybe<Scalars['Boolean']>;
};

export type MutationSaveMdTableVisitHistoryArgs = {
  tableCode: Scalars['String'];
};

export type MutationUpdateMdDataNeedSyncArgs = {
  codes: Array<Scalars['String']>;
  needSync?: Maybe<Scalars['Boolean']>;
  migResult?: Maybe<Scalars['ValuedEnum']>;
  migFailedReason?: Maybe<Scalars['String']>;
};

export type MutationSetMdDataInboundConsistencyArgs = {
  codes: Array<Scalars['String']>;
  consistent: Scalars['Boolean'];
};

export type MutationSaveMdmMdDataMigrationResultArgs = {
  codes: Array<Scalars['String']>;
};

export type MutationSyncMdDataBomArgs = {
  codes: Array<Scalars['String']>;
};

export type MutationSetBomInboundInconsistentArgs = {
  codes: Array<Scalars['String']>;
};

export type MutationUpdateBomNeedSyncArgs = {
  codes: Array<Scalars['String']>;
  needSync: Scalars['Boolean'];
};

export type MutationMigrateProductOrderArgs = {
  atsPoId?: Maybe<Scalars['ID']>;
};

export type MutationCreateMdxNamingRuleArgs = {
  categoryKey: Scalars['String'];
  productCodeNamingRule?: Maybe<Scalars['Json']>;
  productNameNamingRule?: Maybe<Scalars['Json']>;
  productSpecNamingRule?: Maybe<Scalars['Json']>;
  productHelpCodeNamingRule?: Maybe<Scalars['Json']>;
};

export type MutationUpdateMdxNamingRuleArgs = {
  id: Scalars['ID'];
  ver: Scalars['Int'];
  values?: Maybe<MdxNamingRuleValues>;
};

export type MutationValidateNamingRulesArgs = {
  ruleIds?: Maybe<Array<Scalars['ID']>>;
};

export type MutationDeleteMdoMarkPrintTemplateArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationDeleteMdpFinModelArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAddTagsMdpFinModelArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagMdpFinModelArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsMdpFinModelArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationDeleteMdDataArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAddTagsMdDataArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagMdDataArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsMdDataArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationSetSeqMdpBomEntryArgs = {
  id: Scalars['ID'];
  newSeq?: Maybe<Scalars['Int']>;
};

export type MutationAddTagsKtiIntellectualPropertyArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagKtiIntellectualPropertyArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsKtiIntellectualPropertyArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationAddTagsMdaLensProductModelColourArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagMdaLensProductModelColourArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsMdaLensProductModelColourArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationDeleteRegRecycleWorkerArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationDeletePermissionArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationDeleteMdDictionaryEntryArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationSetSeqMdDictionaryEntryArgs = {
  id: Scalars['ID'];
  newSeq?: Maybe<Scalars['Int']>;
};

export type MutationDeleteMdeCurrencyArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationDeleteMdoDesignDocumentArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationDeleteEstContractArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAddTagsMdoBoxingSheetArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagMdoBoxingSheetArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsMdoBoxingSheetArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationDeleteSysExportTemplateArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationDeleteMdoUsedPackingOptionArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAddTagsMdoUsedPackingOptionArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagMdoUsedPackingOptionArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsMdoUsedPackingOptionArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationDeleteMdpStructureArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAddTagsMdpStructureArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagMdpStructureArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsMdpStructureArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationDeleteChartViewArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationDeleteMdoDesignDocumentUnitArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAddTagsMetMembershipFlowArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagMetMembershipFlowArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsMetMembershipFlowArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationDeleteMdFieldTemplateArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAddTagsEstAccountEntryArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagEstAccountEntryArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsEstAccountEntryArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationDeleteMdeSupplierArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationDeleteEstAccountBookArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAddTagsKtiResearchAchievementArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagKtiResearchAchievementArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsKtiResearchAchievementArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationSetSeqMdDataBomEntryArgs = {
  id: Scalars['ID'];
  newSeq?: Maybe<Scalars['Int']>;
};

export type MutationDeleteEstTradingTargetArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAddTagsKtiServiceContractArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagKtiServiceContractArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsKtiServiceContractArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationDeleteKtiTalentArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAddTagsKtiTalentArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagKtiTalentArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsKtiTalentArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationAddTagsMdoDeliverySheetArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagMdoDeliverySheetArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsMdoDeliverySheetArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationDeleteMdFieldArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAddTagsMdFieldArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagMdFieldArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsMdFieldArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationSetSeqMdaBomEntryArgs = {
  id: Scalars['ID'];
  newSeq?: Maybe<Scalars['Int']>;
};

export type MutationDeletePersistentRealmArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAddTagsMdaLensElectroplateColourArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagMdaLensElectroplateColourArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsMdaLensElectroplateColourArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationDeleteMetCustomerArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAddTagsMetCustomerArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagMetCustomerArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsMetCustomerArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationDeleteMdpFinSizeArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationSetSeqMdpFinSizeArgs = {
  id: Scalars['ID'];
  newSeq?: Maybe<Scalars['Int']>;
};

export type MutationAddTagsMdaLensColourArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagMdaLensColourArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsMdaLensColourArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationAddTagsMdoProductOrderArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagMdoProductOrderArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsMdoProductOrderArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationSetSeqEstReceivableStepArgs = {
  id: Scalars['ID'];
  newSeq?: Maybe<Scalars['Int']>;
};

export type MutationDeleteMdoPackingOptionArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAddTagsMdoPackingOptionArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagMdoPackingOptionArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsMdoPackingOptionArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationDeleteMdTallyAccountSetArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAddTagsKtiAttendanceArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagKtiAttendanceArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsKtiAttendanceArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationDeleteMdDictionaryArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAddTagsMdDictionaryArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagMdDictionaryArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsMdDictionaryArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationDeleteMetMeetingArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAddTagsMetMeetingArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagMetMeetingArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsMetMeetingArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationSetSeqMdFieldTemplateEntryArgs = {
  id: Scalars['ID'];
  newSeq?: Maybe<Scalars['Int']>;
};

export type MutationDeleteMdaStructureArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAddTagsMdaStructureArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagMdaStructureArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsMdaStructureArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationDeleteRoleGroupArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAddTagsRoleGroupArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagRoleGroupArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsRoleGroupArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationDeleteRoleArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationSetSeqMdxStructureBranchNodeArgs = {
  id: Scalars['ID'];
  newSeq?: Maybe<Scalars['Int']>;
};

export type MutationSetSeqMetCustomerContactArgs = {
  id: Scalars['ID'];
  newSeq?: Maybe<Scalars['Int']>;
};

export type MutationDeleteMdeWarehouseArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationSetSeqBrCatalogArgs = {
  id: Scalars['ID'];
  newSeq?: Maybe<Scalars['Int']>;
};

export type MutationDeleteMdFileArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationDeleteMdUnitArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationDeleteMetParticipantArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAddTagsMetParticipantArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagMetParticipantArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsMetParticipantArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationDeleteMdTableColumnArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationSetSeqMdTableColumnArgs = {
  id: Scalars['ID'];
  newSeq?: Maybe<Scalars['Int']>;
};

export type MutationDeleteMdeCurrencyExchangeRateArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationSetSeqMdoDeliverySheetEntryArgs = {
  id: Scalars['ID'];
  newSeq?: Maybe<Scalars['Int']>;
};

export type MutationDeleteMdeIncotermsArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAddTagsKtiTalentAssessmentArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagKtiTalentAssessmentArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsKtiTalentAssessmentArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationDeleteMdpGoggleModelArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAddTagsMdpGoggleModelArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagMdpGoggleModelArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsMdpGoggleModelArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationSetSeqMdpGalleryStyleArgs = {
  id: Scalars['ID'];
  newSeq?: Maybe<Scalars['Int']>;
};

export type MutationDeleteMdTableArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAddTagsMdTableArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagMdTableArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsMdTableArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationAddTagsEstAccountTallyEntryArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagEstAccountTallyEntryArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsEstAccountTallyEntryArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationDeleteMdpWorkManualTemplateArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAddTagsEstReceivablePlanArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagEstReceivablePlanArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsEstReceivablePlanArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationDeleteMdInvoiceArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAddTagsMdInvoiceArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagMdInvoiceArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsMdInvoiceArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationDeleteMetAgentArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAddTagsMetAgentArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagMetAgentArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsMetAgentArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationSetSeqMdFieldTemplateBranchArgs = {
  id: Scalars['ID'];
  newSeq?: Maybe<Scalars['Int']>;
};

export type MutationAddTagsKtiWorkingReportArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagKtiWorkingReportArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsKtiWorkingReportArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationDeleteMdeColourArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationDeleteMdeCustomerArgs = {
  ids: Array<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type MutationAddTagsMdeCustomerArgs = {
  ids: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationRemoveTagMdeCustomerArgs = {
  ids: Array<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

export type MutationSetTagsMdeCustomerArgs = {
  ids: Array<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationSetSeqMdxStructureFieldNodeArgs = {
  id: Scalars['ID'];
  newSeq?: Maybe<Scalars['Int']>;
};

export type NoSourcePoInvSheetEntryInput = {
  productOrderCode?: Maybe<Scalars['String']>;
  productCode: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  qty: Scalars['Float'];
  unitPrice?: Maybe<Scalars['Float']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
};

/** 权限 */
export type Permission = CodeBasedModel & {
  code: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 模块 */
  realm: Realm;
  /** 名称 */
  name: Scalars['String'];
  /** 拥有该权限的用户 */
  users?: Maybe<Array<Maybe<User>>>;
  /** 安全等级 */
  securityLevel: Scalars['ValuedEnum'];
  /** 所属基础权限 */
  basePermissions?: Maybe<Array<Maybe<Permission>>>;
  /** 包含的操作权限 */
  opsPermissions?: Maybe<Array<Maybe<Permission>>>;
  /** 关闭 */
  closed?: Maybe<Scalars['Boolean']>;
  /** 开放 */
  opened?: Maybe<Scalars['Boolean']>;
  /** 拥有该权限的角色 */
  roles?: Maybe<Array<Role>>;
};

/** 关闭权限 */
export type PermissionClosed = CodeBasedModel & {
  code: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
};

export type PermissionGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

/** 开放权限 */
export type PermissionOpened = CodeBasedModel & {
  code: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
};

export type PermissionPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Permission>>>;
};

/** 权限特例 */
export type PermissionSpecial = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 权限 */
  permission?: Maybe<Permission>;
  /** 特例类型 */
  type: Scalars['ValuedEnum'];
  /** 有效期至 */
  expiredAt: Scalars['DateTime'];
  /** 被授予特例的用户 */
  user?: Maybe<User>;
  /** 授予特例用户 */
  supervisor?: Maybe<User>;
};

export type PermissionSpecialGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type PermissionSpecialPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<PermissionSpecial>>>;
};

export type PermissionSpecialValues = {
  type?: Maybe<Scalars['Int']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['ID']>;
  supervisorId?: Maybe<Scalars['ID']>;
};

/** 定时任务执行日志 */
export type QrtzExecLog = {
  id: Scalars['ID'];
  /** 任务名称 */
  jobName: Scalars['String'];
  /** 触发器名称 */
  triggerName: Scalars['String'];
  /** 执行状态 */
  state?: Maybe<Scalars['ValuedEnum']>;
  /** 执行参数 */
  jobData?: Maybe<Scalars['Json']>;
  /** 进度 */
  progress?: Maybe<Scalars['Float']>;
  /** 开始时间 */
  startDate?: Maybe<Scalars['DateTime']>;
  /** 结束时间 */
  endDate?: Maybe<Scalars['DateTime']>;
  /** 额外信息 */
  extra?: Maybe<Scalars['Json']>;
  /** 错误信息 */
  errors?: Maybe<Array<Maybe<ErrorInfo>>>;
};

export type QrtzExecLogGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type QrtzExecLogPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<QrtzExecLog>>>;
};

/** 定时任务信息 */
export type QrtzJob = {
  /** 名称 */
  name: Scalars['String'];
  /** 任务描述 */
  description?: Maybe<Scalars['String']>;
  /** 目录 */
  catalog?: Maybe<BrCatalog>;
  /** 类型 */
  type?: Maybe<Scalars['ValuedEnum']>;
  /** 目标服务 */
  targetService: Scalars['String'];
  /** 执行SQL */
  sqlStatement?: Maybe<Scalars['String']>;
  /** 调用Api */
  serviceApi?: Maybe<Scalars['String']>;
  /** Api插件 */
  servicePlugin?: Maybe<Scalars['String']>;
  /** QlExpress表达式 */
  qlExpressScript?: Maybe<Scalars['String']>;
  /** 调用GraphQL Api */
  graphQlApiQuery?: Maybe<Scalars['String']>;
  /** 服务Api参数 */
  execParam?: Maybe<Scalars['Json']>;
  /** 任务参数 */
  jobDataMap?: Maybe<Scalars['Json']>;
  /** 定时触发器 */
  trigger: QrtzTrigger;
  /** 上次执行日志 */
  lastExecLog?: Maybe<QrtzExecLog>;
  /** 下次执行时间 */
  nextStartDate?: Maybe<Scalars['DateTime']>;
};

/** 定时任务触发器 */
export type QrtzTrigger = {
  /** 任务 */
  job: QrtzJob;
  /** 名称 */
  name: Scalars['String'];
  /** 任务描述 */
  description?: Maybe<Scalars['String']>;
  /** Cron表达式 */
  cronExpression: Scalars['String'];
  /** 优先级 */
  priority?: Maybe<Scalars['Int']>;
  /** 状态 */
  state?: Maybe<Scalars['ValuedEnum']>;
  /** 下次启动时间 */
  nextFireTime?: Maybe<Scalars['DateTime']>;
  /** 上次启动时间 */
  previousFireTime?: Maybe<Scalars['DateTime']>;
};

/**
 * ##############################################################################
 *  Inputs
 * ##############################################################################
 */
export type QrtzTriggerInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  cronExpression: Scalars['String'];
};

export type Query = {
  /** 获取单条纸箱工单 */
  MdoBoxWorkOrder?: Maybe<MdoBoxWorkOrder>;
  /** 计算纸箱工单 */
  calculateMdoBoxWorkOrders?: Maybe<Array<Maybe<MdoBoxWorkOrder>>>;
  MdaStructureTemplate?: Maybe<MdaStructureTemplate>;
  getRootMdaStructureLevels: Array<MdStructureLevel>;
  /** 获取模板全部节点 */
  getMdxStructureTree?: Maybe<Scalars['Json']>;
  MdxStructureBranchNode?: Maybe<MdxStructureBranchNode>;
  MdxStructureFieldNode?: Maybe<MdxStructureFieldNode>;
  getChildFieldNode?: Maybe<Array<Maybe<MdxStructureFieldNode>>>;
  /** 从选中的管理维度开始获取品号属性树节点 */
  getProductAttributeTree?: Maybe<Scalars['Json']>;
  /** 获取品号属性下级树节点 */
  getNextLevelAttribute?: Maybe<Scalars['Json']>;
  /** 获取经过管理维度的全路径组合 */
  findStructureFullPath?: Maybe<Scalars['Json']>;
  /** 获取经过管理维度的全路径字段节点 */
  getStructurePathFieldNodes?: Maybe<Scalars['Json']>;
  getMdDataWithStructure?: Maybe<Array<Maybe<MdxDataWithStructure>>>;
  /** 查询工程品号和Bom中的工程品号 */
  flattenEngineeringMdData?: Maybe<Array<Maybe<Scalars['ID']>>>;
  getMdDataHelpCode?: Maybe<Array<MdData>>;
  /** 获取同步ats流水状态 */
  getMdmTallySyncState?: Maybe<Scalars['Map']>;
  getMdpFinModelCatalogConfig?: Maybe<MdpGalleryCatalogConfig>;
  getMdpGoggleModelCatalogConfig?: Maybe<MdpGalleryCatalogConfig>;
  getMdpFinMaxDictKey?: Maybe<Scalars['Int']>;
  MdpStructureTemplate?: Maybe<MdpStructureTemplate>;
  getRootMdpStructureLevels: Array<MdStructureLevel>;
  /** 预览Bom匹配结果 */
  previewMdpBomEntryMatch: Array<Maybe<MdpBomEntry>>;
  getMdpMdDataViewRootLevels?: Maybe<MdStructureLevel>;
  /** 获取单条镜片色号 */
  MdaLensColour?: Maybe<MdaLensColour>;
  /** 校验脚本语法, 返回外部参数. 如果脚本语法有误则抛出异常 */
  verifyQlExpress?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 获取类别 */
  MdCategories?: Maybe<MdCategoryPage>;
  /** 获取类别 */
  MdCategory?: Maybe<MdCategory>;
  /** 根据字段值查主数据检索条件 */
  MdcDataSearch?: Maybe<MdcDataSearch>;
  /** 获取单条订单 */
  MdoProductOrder?: Maybe<MdoProductOrder>;
  /** 获取订单汇总信息 */
  mdoEndProductSummary?: Maybe<Array<Maybe<MdoEndProductSummary>>>;
  /** 获取混装分组 */
  getMdoEndProductMixinGroup?: Maybe<Scalars['String']>;
  /** 获取单条装箱单 */
  MdoBoxingSheet?: Maybe<MdoBoxingSheet>;
  /** 是否销货 */
  hasDeliverySheet?: Maybe<Scalars['Void']>;
  /** 生成装箱单记录 */
  initMdoBoxingSheetEntry?: Maybe<MdoBoxingSheetEntry>;
  /** 匹配装箱方式 */
  matchPackingOptions?: Maybe<Array<Maybe<MdoUsedPackingOption>>>;
  /** 获取成品信息模板 */
  MdoEndProductInfoTemplates?: Maybe<Array<MdoEndProductInfoTemplate>>;
  /** 获取单个成品信息模板 */
  MdoEndProductInfoTemplate?: Maybe<MdoEndProductInfoTemplate>;
  /** 获取订单单头编辑字段 */
  getMdoEpInfoFields?: Maybe<Array<Maybe<MdField>>>;
  /** 根据发票文件获取发票 */
  findMdInvoices?: Maybe<Array<Maybe<MdInvoice>>>;
  /** 所有所有业务主题 */
  getAllTemplateTopics?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 返回模板中的所有字段 */
  getAllTemplateFields?: Maybe<Array<Maybe<MdField>>>;
  /** 返回模板根字段 */
  getRootTemplateEntries?: Maybe<Array<Maybe<MdFieldTemplateEntry>>>;
  /** 根据已输入信息返回模板字段 */
  matchMdFieldTemplate?: Maybe<Array<Maybe<MdField>>>;
  /** 清除指定模板缓存, 不传清除所有模板缓存 */
  flushMdFieldTemplateCache?: Maybe<Scalars['Void']>;
  /** 获取单个工作组 */
  RoleGroup?: Maybe<RoleGroup>;
  /** 获取用户权限 */
  findUserPermissions?: Maybe<Array<Maybe<Permission>>>;
  /** 转换单位, 转换不成功返回空值 */
  convertUnit?: Maybe<Scalars['Float']>;
  /** 获取单位转换系数 */
  getUnitConversionFactor?: Maybe<Scalars['Float']>;
  /** 获取我的文件根目录 */
  getMyRootDirs?: Maybe<Array<Maybe<MdFileRootDir>>>;
  /** 获取与我分享的文件根目录 */
  getShareToMeRootDirs?: Maybe<Array<Maybe<MdFileRootDir>>>;
  /** 获取目录文件 */
  getMdFiles?: Maybe<Array<Maybe<MdFile>>>;
  /** 获取我分享的目录 */
  getMySharedRootDir?: Maybe<Array<Maybe<MdFileRootDir>>>;
  /** 获取上级目录路径 */
  getParents?: Maybe<Array<Maybe<MdFile>>>;
  /** 搜索文件 */
  searchMdFile?: Maybe<MdFilePage>;
  /** 获取单条出货单 */
  customerOrDefaultCurrency?: Maybe<MdeCurrency>;
  /** 获取单个选项表 */
  MdDictionary?: Maybe<MdDictionary>;
  /** 主数据域信息 */
  MdDomain?: Maybe<MdDomainCache>;
  /** 获取单条主数据 */
  MdData?: Maybe<MdData>;
  /** 预览主数据保存结果 */
  previewMdData: MdDataSavingResult;
  /** 根据属性匹配模板中相关联的字段 */
  matchRelatedFields: Array<MdField>;
  /** 根据已输入的属性匹配字段模板 */
  matchMdDataAttributeTemplate: Array<MdField>;
  /** 根据已输入的属性匹配Bom模板 */
  matchMdDataBomTemplate: Array<MdDataBomTemplate>;
  MdExtendModels?: Maybe<Array<Maybe<Scalars['String']>>>;
  MdModels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 分页获取扩展数据 */
  MdExtendDatas?: Maybe<MdExtendDataPage>;
  /** 获取单条扩展数据 */
  MdExtendData?: Maybe<MdExtendData>;
  /** 转换扩展数据用的FilterCondition, 设置取值条件用 */
  convertMdExtendDataFilterCondition?: Maybe<Scalars['Json']>;
  /** 获取ATS销货单id */
  getAtsPoInvSheetIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** 获取通用目录 */
  brCatalogs?: Maybe<Array<Maybe<BrCatalog>>>;
  /** 获取单个目录 */
  BrCatalog?: Maybe<BrCatalog>;
  /** 获取单条出货单 */
  MdoDeliverySheet?: Maybe<MdoDeliverySheet>;
  /** 获取单条出货单记录 */
  MdoDeliverySheetEntry?: Maybe<MdoDeliverySheetEntry>;
  /** 根据装箱单记录创建出货单记录 */
  buildMdoDeliverySheetEntries?: Maybe<Array<Maybe<MdoDeliverySheetEntry>>>;
  /** 根据件数生成出货成品明细 */
  calculateDeliveryEpQty: Array<Maybe<MdoDeliveryEp>>;
  /** 根据订单成品生成出货成品明细 */
  buildDeliveryEp: Array<Maybe<MdoDeliveryEp>>;
  /** 获装箱单所有可出货单身 */
  getAvailableBoxingSheetEntries?: Maybe<MdoAvailableDeliveryQty>;
  /** 获取单条镜片电镀色号 */
  MdaLensElectroplateColour?: Maybe<MdaLensElectroplateColour>;
  /** 获取单条镜片型号色号 */
  MdaLensProductModelColour?: Maybe<MdaLensProductModelColour>;
  /** 获取业务分组 */
  findRqaTopics?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 获取单个账号 */
  Account?: Maybe<Account>;
  /** 获取单个用户 */
  User?: Maybe<User>;
  /** 获取权限对应的菜单 */
  getPermissionMenus?: Maybe<Scalars['Json']>;
  /** 获取Model对应的视图 */
  getExplorerViews?: Maybe<Array<Maybe<ExplorerView>>>;
  /** 获取Model对应的图表 */
  getChartViews?: Maybe<Array<Maybe<ChartView>>>;
  /** 获取图表 */
  getChartView?: Maybe<ChartView>;
  /** 获取图表数据 */
  getChartData?: Maybe<Array<Maybe<Scalars['Map']>>>;
  /** 预览文字图表 */
  previewTextChart?: Maybe<Scalars['String']>;
  /** 预览表格图表 */
  previewTableChart?: Maybe<ChartViewSqlTableData>;
  /** 获取单条外部数据源 */
  ExternalDataSource?: Maybe<ExternalDataSource>;
  /** 测试外部数据源连通性 */
  testExternalDataSource?: Maybe<ErrorInfo>;
  /** 获取统计指标 */
  listUserDashboardGauges?: Maybe<Array<Maybe<SysDashboardGauge>>>;
  /** 获取统计指标结果 */
  getDashboardGauge?: Maybe<Scalars['Int']>;
  /** 获取打印导出模板 */
  getSysExportTemplates?: Maybe<Array<Maybe<SysExportTemplate>>>;
  /** 获取批次数据 */
  getDataBatchByHash?: Maybe<Array<Maybe<SysDataBatchHash>>>;
  /** 返回我的账本 */
  getMyEstAccountBooks?: Maybe<Array<Maybe<EstAccountBook>>>;
  /** 修改项目, 已完成的待办不动, 生成新的待办 */
  generateEstTodoFromContract?: Maybe<Array<Maybe<EstContractTodoPreview>>>;
  /** 按月计算流水汇总 */
  sumEstAccountTallyByMonth?: Maybe<MdTallySurplus>;
  /** 获取邀请详情 */
  getEstInvitationToken?: Maybe<EstInvitationToken>;
  MdpGalleryCatalogs?: Maybe<Array<Maybe<MdpGalleryCatalog>>>;
  MdpGalleryCatalogConfig?: Maybe<MdpGalleryCatalogConfig>;
  MdpGalleryModelConfig?: Maybe<MdpGalleryModelConfig>;
  MdTally?: Maybe<MdTally>;
  MdTallies?: Maybe<MdTallyPage>;
  MdTalliesGroupBy?: Maybe<MdTallyPage>;
  MdTallySurplus?: Maybe<MdTallySurplus>;
  MdTallySurpluses?: Maybe<MdTallySurplusPage>;
  MdTallySurplusesGroupBy?: Maybe<MdTallySurplusPage>;
  MdTallyTimeSerialSurplus?: Maybe<MdTallyTimeSerialSurplus>;
  MdTallyTimeSerialSurpluses?: Maybe<MdTallyTimeSerialSurplusPage>;
  MdTallyTimeSerialSurplusesGroupBy?: Maybe<MdTallyTimeSerialSurplusPage>;
  /** 获取结余 */
  getMdTallySurplus?: Maybe<MdTallySurplus>;
  /** 获取结余动态计算流水结余 */
  getMdTallyFlowSurplus?: Maybe<Array<Maybe<MdTallyFlowSurplus>>>;
  /** 获取所有业务主题 */
  getAllMdTallyTopics?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 获取单个成品信息模板 */
  getTalentSalary?: Maybe<Scalars['Float']>;
  getTalentSupervisorComment?: Maybe<KtiSupervisorComment>;
  /** 获取定时任务信息 */
  QrtzJobs?: Maybe<Array<Maybe<QrtzJob>>>;
  /** 获取单个定时任务信息 */
  QrtzJob?: Maybe<QrtzJob>;
  /** 分页获取主数据校验规则 */
  MdValidationRules?: Maybe<MdValidationRulePage>;
  /** 分页获取校验结果 */
  MdDiagnoses?: Maybe<MdDiagnosisPage>;
  /** 获取校验结果 */
  MdDiagnosis?: Maybe<MdDiagnosis>;
  MdDataTypes?: Maybe<Array<Maybe<MdDataType>>>;
  /** 获取单个字段 */
  MdField?: Maybe<MdField>;
  /** 获取使用了某个选项表的字段 */
  findMdFieldsByDictId?: Maybe<Array<Maybe<MdField>>>;
  /** 获取智能表格数据 */
  MdTableDatas?: Maybe<MdTableDataPage>;
  /** 分组获取智能表格数据 */
  MdTableDatasGroupBy?: Maybe<MdTableDataGroupByPage>;
  /** 获取单条智能表格数据 */
  MdTableData?: Maybe<MdTableData>;
  /** 获取智能表格导入映射预设配置 */
  MdTableImportMappingPresets?: Maybe<Array<Maybe<MdTableImportMappingPreset>>>;
  /** 预览从Sql导入的数据 */
  previewMdTableImportFromSql?: Maybe<MdTableImportFromSqlPreview>;
  /** 获取用户关注智能表格 */
  getUserFavoriteMdTable?: Maybe<Array<Maybe<MdTable>>>;
  /** 获取用户最近访问智能表格 */
  getUserMdTableVisitHistory?: Maybe<Array<Maybe<MdTableVisitHistory>>>;
  /** 获取智能表格快捷方式 */
  getMdTableShortcuts?: Maybe<Array<Maybe<MdTable>>>;
  /** 获迁移取主数据 */
  getMdmMdDataInboundByIds?: Maybe<Array<Maybe<MdmDataInboundView>>>;
  /** 根据字段值查询命名规则 */
  MdxNamingRule?: Maybe<MdxNamingRule>;
  /** 预览命名规则 */
  previewMdxNamingRule?: Maybe<Scalars['String']>;
  MdxNamingRules?: Maybe<MdxNamingRulePage>;
  MdxNamingRulesGroupBy?: Maybe<MdxNamingRuleGroupByPage>;
  Roles?: Maybe<RolePage>;
  RolesGroupBy?: Maybe<RoleGroupByPage>;
  Role?: Maybe<Role>;
  MdpStructureToMdDatas?: Maybe<MdpStructureToMdDataPage>;
  MdpStructureToMdDatasGroupBy?: Maybe<MdpStructureToMdDataGroupByPage>;
  MdpStructureToMdData?: Maybe<MdpStructureToMdData>;
  ChartDashboards?: Maybe<ChartDashboardPage>;
  ChartDashboardsGroupBy?: Maybe<ChartDashboardGroupByPage>;
  ChartDashboard?: Maybe<ChartDashboard>;
  MdpFinSizes?: Maybe<MdpFinSizePage>;
  MdpFinSizesGroupBy?: Maybe<MdpFinSizeGroupByPage>;
  MdpFinSize?: Maybe<MdpFinSize>;
  MdoBoxingSheetEntries?: Maybe<MdoBoxingSheetEntryPage>;
  MdoBoxingSheetEntriesGroupBy?: Maybe<MdoBoxingSheetEntryGroupByPage>;
  MdoBoxingSheetEntry?: Maybe<MdoBoxingSheetEntry>;
  MdaLensColours?: Maybe<MdaLensColourPage>;
  MdaLensColoursGroupBy?: Maybe<MdaLensColourGroupByPage>;
  MetBonusSettlings?: Maybe<MetBonusSettlingPage>;
  MetBonusSettlingsGroupBy?: Maybe<MetBonusSettlingGroupByPage>;
  MetBonusSettling?: Maybe<MetBonusSettling>;
  MdTables?: Maybe<MdTablePage>;
  MdTablesGroupBy?: Maybe<MdTableGroupByPage>;
  MdTable?: Maybe<MdTable>;
  KtiSupervisorComments?: Maybe<KtiSupervisorCommentPage>;
  KtiSupervisorCommentsGroupBy?: Maybe<KtiSupervisorCommentGroupByPage>;
  KtiSupervisorComment?: Maybe<KtiSupervisorComment>;
  MetMeetings?: Maybe<MetMeetingPage>;
  MetMeetingsGroupBy?: Maybe<MetMeetingGroupByPage>;
  MetMeeting?: Maybe<MetMeeting>;
  MdoBoxingSheets?: Maybe<MdoBoxingSheetPage>;
  MdoBoxingSheetsGroupBy?: Maybe<MdoBoxingSheetGroupByPage>;
  SysExportTemplates?: Maybe<SysExportTemplatePage>;
  SysExportTemplatesGroupBy?: Maybe<SysExportTemplateGroupByPage>;
  SysExportTemplate?: Maybe<SysExportTemplate>;
  MdaCustomerCatalogs?: Maybe<MdaCustomerCatalogPage>;
  MdaCustomerCatalogsGroupBy?: Maybe<MdaCustomerCatalogGroupByPage>;
  MdaCustomerCatalog?: Maybe<MdaCustomerCatalog>;
  MdeSuppliers?: Maybe<MdeSupplierPage>;
  MdeSuppliersGroupBy?: Maybe<MdeSupplierGroupByPage>;
  MdeSupplier?: Maybe<MdeSupplier>;
  MdmDataRefactors?: Maybe<MdmDataRefactorPage>;
  MdmDataRefactorsGroupBy?: Maybe<MdmDataRefactorGroupByPage>;
  MdmDataRefactor?: Maybe<MdmDataRefactor>;
  MdmRqaDeletedTallyInbounds?: Maybe<MdmRqaDeletedTallyInboundPage>;
  MdmRqaDeletedTallyInboundsGroupBy?: Maybe<MdmRqaDeletedTallyInboundGroupByPage>;
  MdmRqaDeletedTallyInbound?: Maybe<MdmRqaDeletedTallyInbound>;
  Permissions?: Maybe<PermissionPage>;
  PermissionsGroupBy?: Maybe<PermissionGroupByPage>;
  Permission?: Maybe<Permission>;
  EstPayableReconciliations?: Maybe<EstPayableReconciliationPage>;
  EstPayableReconciliationsGroupBy?: Maybe<EstPayableReconciliationGroupByPage>;
  EstPayableReconciliation?: Maybe<EstPayableReconciliation>;
  MdmDictionaryEntryInboundViews?: Maybe<MdmDictionaryEntryInboundViewPage>;
  MdmDictionaryEntryInboundViewsGroupBy?: Maybe<MdmDictionaryEntryInboundViewGroupByPage>;
  MdmDictionaryEntryInboundView?: Maybe<MdmDictionaryEntryInboundView>;
  JoiningApplications?: Maybe<JoiningApplicationPage>;
  JoiningApplicationsGroupBy?: Maybe<JoiningApplicationGroupByPage>;
  JoiningApplication?: Maybe<JoiningApplication>;
  MdpGallerySkus?: Maybe<MdpGallerySkuPage>;
  MdpGallerySkusGroupBy?: Maybe<MdpGallerySkuGroupByPage>;
  MdpGallerySku?: Maybe<MdpGallerySku>;
  MdDictionaries?: Maybe<MdDictionaryPage>;
  MdDictionariesGroupBy?: Maybe<MdDictionaryGroupByPage>;
  MetParticipants?: Maybe<MetParticipantPage>;
  MetParticipantsGroupBy?: Maybe<MetParticipantGroupByPage>;
  MetParticipant?: Maybe<MetParticipant>;
  MdaStructureToMdDatas?: Maybe<MdaStructureToMdDataPage>;
  MdaStructureToMdDatasGroupBy?: Maybe<MdaStructureToMdDataGroupByPage>;
  MdaStructureToMdData?: Maybe<MdaStructureToMdData>;
  MetBonuses?: Maybe<MetBonusPage>;
  MetBonusesGroupBy?: Maybe<MetBonusGroupByPage>;
  MetBonus?: Maybe<MetBonus>;
  MdeCustomers?: Maybe<MdeCustomerPage>;
  MdeCustomersGroupBy?: Maybe<MdeCustomerGroupByPage>;
  MdeCustomer?: Maybe<MdeCustomer>;
  MdoDesignDocuments?: Maybe<MdoDesignDocumentPage>;
  MdoDesignDocumentsGroupBy?: Maybe<MdoDesignDocumentGroupByPage>;
  MdoDesignDocument?: Maybe<MdoDesignDocument>;
  SysDataBatchHashs?: Maybe<SysDataBatchHashPage>;
  SysDataBatchHashsGroupBy?: Maybe<SysDataBatchHashGroupByPage>;
  SysDataBatchHash?: Maybe<SysDataBatchHash>;
  MdoDesignDocumentUnits?: Maybe<MdoDesignDocumentUnitPage>;
  MdoDesignDocumentUnitsGroupBy?: Maybe<MdoDesignDocumentUnitGroupByPage>;
  MdoDesignDocumentUnit?: Maybe<MdoDesignDocumentUnit>;
  KtiServiceContracts?: Maybe<KtiServiceContractPage>;
  KtiServiceContractsGroupBy?: Maybe<KtiServiceContractGroupByPage>;
  KtiServiceContract?: Maybe<KtiServiceContract>;
  MdaLensElectroplateColours?: Maybe<MdaLensElectroplateColourPage>;
  MdaLensElectroplateColoursGroupBy?: Maybe<MdaLensElectroplateColourGroupByPage>;
  MetMembershipFlows?: Maybe<MetMembershipFlowPage>;
  MetMembershipFlowsGroupBy?: Maybe<MetMembershipFlowGroupByPage>;
  MetMembershipFlow?: Maybe<MetMembershipFlow>;
  EstReceivableReconciliations?: Maybe<EstReceivableReconciliationPage>;
  EstReceivableReconciliationsGroupBy?: Maybe<EstReceivableReconciliationGroupByPage>;
  EstReceivableReconciliation?: Maybe<EstReceivableReconciliation>;
  EstReceivableSteps?: Maybe<EstReceivableStepPage>;
  EstReceivableStepsGroupBy?: Maybe<EstReceivableStepGroupByPage>;
  EstReceivableStep?: Maybe<EstReceivableStep>;
  KtiTalents?: Maybe<KtiTalentPage>;
  KtiTalentsGroupBy?: Maybe<KtiTalentGroupByPage>;
  KtiTalent?: Maybe<KtiTalent>;
  MdpGalleryModels?: Maybe<MdpGalleryModelPage>;
  MdpGalleryModelsGroupBy?: Maybe<MdpGalleryModelGroupByPage>;
  MdpGalleryModel?: Maybe<MdpGalleryModel>;
  MdoProductOrders?: Maybe<MdoProductOrderPage>;
  MdoProductOrdersGroupBy?: Maybe<MdoProductOrderGroupByPage>;
  MdcDataSearchs?: Maybe<MdcDataSearchPage>;
  MdcDataSearchsGroupBy?: Maybe<MdcDataSearchGroupByPage>;
  MdoBoxWorkOrders?: Maybe<MdoBoxWorkOrderPage>;
  MdoBoxWorkOrdersGroupBy?: Maybe<MdoBoxWorkOrderGroupByPage>;
  MdpGoggleModels?: Maybe<MdpGoggleModelPage>;
  MdpGoggleModelsGroupBy?: Maybe<MdpGoggleModelGroupByPage>;
  MdpGoggleModel?: Maybe<MdpGoggleModel>;
  MetCustomerContacts?: Maybe<MetCustomerContactPage>;
  MetCustomerContactsGroupBy?: Maybe<MetCustomerContactGroupByPage>;
  MetCustomerContact?: Maybe<MetCustomerContact>;
  MdoDeliverySheetEntries?: Maybe<MdoDeliverySheetEntryPage>;
  MdoDeliverySheetEntriesGroupBy?: Maybe<MdoDeliverySheetEntryGroupByPage>;
  MdaStructures?: Maybe<MdaStructurePage>;
  MdaStructuresGroupBy?: Maybe<MdaStructureGroupByPage>;
  MdaStructure?: Maybe<MdaStructure>;
  Users?: Maybe<UserPage>;
  UsersGroupBy?: Maybe<UserGroupByPage>;
  MdInvoices?: Maybe<MdInvoicePage>;
  MdInvoicesGroupBy?: Maybe<MdInvoiceGroupByPage>;
  MdInvoice?: Maybe<MdInvoice>;
  MdoMarkPrintTemplateFieldPresets?: Maybe<MdoMarkPrintTemplateFieldPresetPage>;
  MdoMarkPrintTemplateFieldPresetsGroupBy?: Maybe<MdoMarkPrintTemplateFieldPresetGroupByPage>;
  MdoMarkPrintTemplateFieldPreset?: Maybe<MdoMarkPrintTemplateFieldPreset>;
  MdaSalesVolumes?: Maybe<MdaSalesVolumePage>;
  MdaSalesVolumesGroupBy?: Maybe<MdaSalesVolumeGroupByPage>;
  MdaSalesVolume?: Maybe<MdaSalesVolume>;
  MdaLensProductModelColours?: Maybe<MdaLensProductModelColourPage>;
  MdaLensProductModelColoursGroupBy?: Maybe<MdaLensProductModelColourGroupByPage>;
  MdFieldTemplateEntries?: Maybe<MdFieldTemplateEntryPage>;
  MdFieldTemplateEntriesGroupBy?: Maybe<MdFieldTemplateEntryGroupByPage>;
  MdFieldTemplateEntry?: Maybe<MdFieldTemplateEntry>;
  MdFields?: Maybe<MdFieldPage>;
  MdFieldsGroupBy?: Maybe<MdFieldGroupByPage>;
  MdpStructureMdDataViews?: Maybe<MdpStructureMdDataViewPage>;
  MdpStructureMdDataViewsGroupBy?: Maybe<MdpStructureMdDataViewGroupByPage>;
  MdpStructureMdDataView?: Maybe<MdpStructureMdDataView>;
  KtiIntellectualPropertys?: Maybe<KtiIntellectualPropertyPage>;
  KtiIntellectualPropertysGroupBy?: Maybe<KtiIntellectualPropertyGroupByPage>;
  KtiIntellectualProperty?: Maybe<KtiIntellectualProperty>;
  MdFieldTemplates?: Maybe<MdFieldTemplatePage>;
  MdFieldTemplatesGroupBy?: Maybe<MdFieldTemplateGroupByPage>;
  MdFieldTemplate?: Maybe<MdFieldTemplate>;
  MdpStructures?: Maybe<MdpStructurePage>;
  MdpStructuresGroupBy?: Maybe<MdpStructureGroupByPage>;
  MdpStructure?: Maybe<MdpStructure>;
  MdaCustomerEndProducts?: Maybe<MdaCustomerEndProductPage>;
  MdaCustomerEndProductsGroupBy?: Maybe<MdaCustomerEndProductGroupByPage>;
  MdaCustomerEndProduct?: Maybe<MdaCustomerEndProduct>;
  MdmBomInboundViews?: Maybe<MdmBomInboundViewPage>;
  MdmBomInboundViewsGroupBy?: Maybe<MdmBomInboundViewGroupByPage>;
  MdmBomInboundView?: Maybe<MdmBomInboundView>;
  EstAccountBooks?: Maybe<EstAccountBookPage>;
  EstAccountBooksGroupBy?: Maybe<EstAccountBookGroupByPage>;
  EstAccountBook?: Maybe<EstAccountBook>;
  KtiAttendances?: Maybe<KtiAttendancePage>;
  KtiAttendancesGroupBy?: Maybe<KtiAttendanceGroupByPage>;
  KtiAttendance?: Maybe<KtiAttendance>;
  MdeWarehouses?: Maybe<MdeWarehousePage>;
  MdeWarehousesGroupBy?: Maybe<MdeWarehouseGroupByPage>;
  MdeWarehouse?: Maybe<MdeWarehouse>;
  MdeColours?: Maybe<MdeColourPage>;
  MdeColoursGroupBy?: Maybe<MdeColourGroupByPage>;
  MdeColour?: Maybe<MdeColour>;
  RoleGroups?: Maybe<RoleGroupPage>;
  RoleGroupsGroupBy?: Maybe<RoleGroupGroupByPage>;
  MdpFinModels?: Maybe<MdpFinModelPage>;
  MdpFinModelsGroupBy?: Maybe<MdpFinModelGroupByPage>;
  MdpFinModel?: Maybe<MdpFinModel>;
  MdoBoxingSheetEntriesView?: Maybe<MdoBoxingSheetEntryViewPage>;
  MdoBoxingSheetEntriesViewGroupBy?: Maybe<MdoBoxingSheetEntryViewGroupByPage>;
  MdoBoxingSheetEntryView?: Maybe<MdoBoxingSheetEntryView>;
  ChartViews?: Maybe<ChartViewPage>;
  ChartViewsGroupBy?: Maybe<ChartViewGroupByPage>;
  ChartView?: Maybe<ChartView>;
  MetAgents?: Maybe<MetAgentPage>;
  MetAgentsGroupBy?: Maybe<MetAgentGroupByPage>;
  MetAgent?: Maybe<MetAgent>;
  MdDatas?: Maybe<MdDataPage>;
  MdDatasGroupBy?: Maybe<MdDataGroupByPage>;
  MdmDataRefactorBatches?: Maybe<MdmDataRefactorBatchPage>;
  MdmDataRefactorBatchesGroupBy?: Maybe<MdmDataRefactorBatchGroupByPage>;
  MdmDataRefactorBatch?: Maybe<MdmDataRefactorBatch>;
  EstContracts?: Maybe<EstContractPage>;
  EstContractsGroupBy?: Maybe<EstContractGroupByPage>;
  EstContract?: Maybe<EstContract>;
  KtiTalentAssessments?: Maybe<KtiTalentAssessmentPage>;
  KtiTalentAssessmentsGroupBy?: Maybe<KtiTalentAssessmentGroupByPage>;
  KtiTalentAssessment?: Maybe<KtiTalentAssessment>;
  MdoEndProducts?: Maybe<MdoEndProductPage>;
  MdoEndProductsGroupBy?: Maybe<MdoEndProductGroupByPage>;
  MdoEndProduct?: Maybe<MdoEndProduct>;
  MetCustomers?: Maybe<MetCustomerPage>;
  MetCustomersGroupBy?: Maybe<MetCustomerGroupByPage>;
  MetCustomer?: Maybe<MetCustomer>;
  MdoDeliverySheets?: Maybe<MdoDeliverySheetPage>;
  MdoDeliverySheetsGroupBy?: Maybe<MdoDeliverySheetGroupByPage>;
  MdoDeliveryEpViews?: Maybe<MdoDeliveryEpViewPage>;
  MdoDeliveryEpViewsGroupBy?: Maybe<MdoDeliveryEpViewGroupByPage>;
  MdoDeliveryEpView?: Maybe<MdoDeliveryEpView>;
  KtiResearchAchievements?: Maybe<KtiResearchAchievementPage>;
  KtiResearchAchievementsGroupBy?: Maybe<KtiResearchAchievementGroupByPage>;
  KtiResearchAchievement?: Maybe<KtiResearchAchievement>;
  MdScriptProcessors?: Maybe<MdScriptProcessorPage>;
  MdScriptProcessorsGroupBy?: Maybe<MdScriptProcessorGroupByPage>;
  MdScriptProcessor?: Maybe<MdScriptProcessor>;
  EstTradingTargets?: Maybe<EstTradingTargetPage>;
  EstTradingTargetsGroupBy?: Maybe<EstTradingTargetGroupByPage>;
  EstTradingTarget?: Maybe<EstTradingTarget>;
  EstReceivablePlans?: Maybe<EstReceivablePlanPage>;
  EstReceivablePlansGroupBy?: Maybe<EstReceivablePlanGroupByPage>;
  EstReceivablePlan?: Maybe<EstReceivablePlan>;
  AuthLogs?: Maybe<AuthLogPage>;
  AuthLogsGroupBy?: Maybe<AuthLogGroupByPage>;
  AuthLog?: Maybe<AuthLog>;
  MdDictionaryEntries?: Maybe<MdDictionaryEntryPage>;
  MdDictionaryEntriesGroupBy?: Maybe<MdDictionaryEntryGroupByPage>;
  MdDictionaryEntry?: Maybe<MdDictionaryEntry>;
  MdUnitConversions?: Maybe<MdUnitConversionPage>;
  MdUnitConversionsGroupBy?: Maybe<MdUnitConversionGroupByPage>;
  MdUnitConversion?: Maybe<MdUnitConversion>;
  MdeCurrencies?: Maybe<MdeCurrencyPage>;
  MdeCurrenciesGroupBy?: Maybe<MdeCurrencyGroupByPage>;
  MdeCurrency?: Maybe<MdeCurrency>;
  MdTallyAccountSets?: Maybe<MdTallyAccountSetPage>;
  MdTallyAccountSetsGroupBy?: Maybe<MdTallyAccountSetGroupByPage>;
  MdTallyAccountSet?: Maybe<MdTallyAccountSet>;
  MdoBoxPaperboardViews?: Maybe<MdoBoxPaperboardViewPage>;
  MdoBoxPaperboardViewsGroupBy?: Maybe<MdoBoxPaperboardViewGroupByPage>;
  MdoBoxPaperboardView?: Maybe<MdoBoxPaperboardView>;
  QrtzExecLogs?: Maybe<QrtzExecLogPage>;
  QrtzExecLogsGroupBy?: Maybe<QrtzExecLogGroupByPage>;
  QrtzExecLog?: Maybe<QrtzExecLog>;
  MdmOrderPackings?: Maybe<MdmOrderPackingPage>;
  MdmOrderPackingsGroupBy?: Maybe<MdmOrderPackingGroupByPage>;
  MdmOrderPacking?: Maybe<MdmOrderPacking>;
  EstTasks?: Maybe<EstTaskPage>;
  EstTasksGroupBy?: Maybe<EstTaskGroupByPage>;
  EstTask?: Maybe<EstTask>;
  MdmRqaTallyInboundViews?: Maybe<MdmRqaTallyInboundViewPage>;
  MdmRqaTallyInboundViewsGroupBy?: Maybe<MdmRqaTallyInboundViewGroupByPage>;
  MdmRqaTallyInboundView?: Maybe<MdmRqaTallyInboundView>;
  MdoMarkPrintTemplates?: Maybe<MdoMarkPrintTemplatePage>;
  MdoMarkPrintTemplatesGroupBy?: Maybe<MdoMarkPrintTemplateGroupByPage>;
  MdoMarkPrintTemplate?: Maybe<MdoMarkPrintTemplate>;
  MdmPackingOptionInboundViews?: Maybe<MdmPackingOptionInboundViewPage>;
  MdmPackingOptionInboundViewsGroupBy?: Maybe<MdmPackingOptionInboundViewGroupByPage>;
  MdmPackingOptionInboundView?: Maybe<MdmPackingOptionInboundView>;
  MdpWorkManualTemplates?: Maybe<MdpWorkManualTemplatePage>;
  MdpWorkManualTemplatesGroupBy?: Maybe<MdpWorkManualTemplateGroupByPage>;
  MdpWorkManualTemplate?: Maybe<MdpWorkManualTemplate>;
  MdmTallySurplusDiffViews?: Maybe<MdmTallySurplusDiffViewPage>;
  MdmTallySurplusDiffViewsGroupBy?: Maybe<MdmTallySurplusDiffViewGroupByPage>;
  MdmTallySurplusDiffView?: Maybe<MdmTallySurplusDiffView>;
  MdpMdDataViews?: Maybe<MdpMdDataViewPage>;
  MdpMdDataViewsGroupBy?: Maybe<MdpMdDataViewGroupByPage>;
  MdpMdDataView?: Maybe<MdpMdDataView>;
  MdFieldTemplateBranchs?: Maybe<MdFieldTemplateBranchPage>;
  MdFieldTemplateBranchsGroupBy?: Maybe<MdFieldTemplateBranchGroupByPage>;
  MdFieldTemplateBranch?: Maybe<MdFieldTemplateBranch>;
  MdUnits?: Maybe<MdUnitPage>;
  MdUnitsGroupBy?: Maybe<MdUnitGroupByPage>;
  MdUnit?: Maybe<MdUnit>;
  MdmDataInboundViews?: Maybe<MdmDataInboundViewPage>;
  MdmDataInboundViewsGroupBy?: Maybe<MdmDataInboundViewGroupByPage>;
  MdmDataInboundView?: Maybe<MdmDataInboundView>;
  PermissionSpecials?: Maybe<PermissionSpecialPage>;
  PermissionSpecialsGroupBy?: Maybe<PermissionSpecialGroupByPage>;
  PermissionSpecial?: Maybe<PermissionSpecial>;
  EstAccountTallyEntries?: Maybe<EstAccountTallyEntryPage>;
  EstAccountTallyEntriesGroupBy?: Maybe<EstAccountTallyEntryGroupByPage>;
  EstAccountTallyEntry?: Maybe<EstAccountTallyEntry>;
  MdoPackingOptions?: Maybe<MdoPackingOptionPage>;
  MdoPackingOptionsGroupBy?: Maybe<MdoPackingOptionGroupByPage>;
  MdoPackingOption?: Maybe<MdoPackingOption>;
  KtiWorkingReports?: Maybe<KtiWorkingReportPage>;
  KtiWorkingReportsGroupBy?: Maybe<KtiWorkingReportGroupByPage>;
  KtiWorkingReport?: Maybe<KtiWorkingReport>;
  MdeIncotermses?: Maybe<MdeIncotermsPage>;
  MdeIncotermsesGroupBy?: Maybe<MdeIncotermsGroupByPage>;
  MdeIncoterms?: Maybe<MdeIncoterms>;
  ExternalDataSources?: Maybe<ExternalDataSourcePage>;
  ExternalDataSourcesGroupBy?: Maybe<ExternalDataSourceGroupByPage>;
  MdmTallyInbounds?: Maybe<MdmTallyInboundPage>;
  MdmTallyInboundsGroupBy?: Maybe<MdmTallyInboundGroupByPage>;
  MdmTallyInbound?: Maybe<MdmTallyInbound>;
  EstTodos?: Maybe<EstTodoPage>;
  EstTodosGroupBy?: Maybe<EstTodoGroupByPage>;
  EstTodo?: Maybe<EstTodo>;
  MdpFinModelTags?: Maybe<Array<Maybe<TagInfo>>>;
  MdDataTags?: Maybe<Array<Maybe<TagInfo>>>;
  KtiIntellectualPropertyTags?: Maybe<Array<Maybe<TagInfo>>>;
  MdaLensProductModelColourTags?: Maybe<Array<Maybe<TagInfo>>>;
  MdoBoxingSheetTags?: Maybe<Array<Maybe<TagInfo>>>;
  MdoUsedPackingOptionTags?: Maybe<Array<Maybe<TagInfo>>>;
  MdpStructureTags?: Maybe<Array<Maybe<TagInfo>>>;
  MetMembershipFlowTags?: Maybe<Array<Maybe<TagInfo>>>;
  EstAccountEntryTags?: Maybe<Array<Maybe<TagInfo>>>;
  KtiResearchAchievementTags?: Maybe<Array<Maybe<TagInfo>>>;
  KtiServiceContractTags?: Maybe<Array<Maybe<TagInfo>>>;
  KtiTalentTags?: Maybe<Array<Maybe<TagInfo>>>;
  MdoDeliverySheetTags?: Maybe<Array<Maybe<TagInfo>>>;
  MdFieldTags?: Maybe<Array<Maybe<TagInfo>>>;
  MdaLensElectroplateColourTags?: Maybe<Array<Maybe<TagInfo>>>;
  MetCustomerTags?: Maybe<Array<Maybe<TagInfo>>>;
  MdaLensColourTags?: Maybe<Array<Maybe<TagInfo>>>;
  MdoProductOrderTags?: Maybe<Array<Maybe<TagInfo>>>;
  MdoPackingOptionTags?: Maybe<Array<Maybe<TagInfo>>>;
  KtiAttendanceTags?: Maybe<Array<Maybe<TagInfo>>>;
  MdDictionaryTags?: Maybe<Array<Maybe<TagInfo>>>;
  MetMeetingTags?: Maybe<Array<Maybe<TagInfo>>>;
  MdaStructureTags?: Maybe<Array<Maybe<TagInfo>>>;
  RoleGroupTags?: Maybe<Array<Maybe<TagInfo>>>;
  MetParticipantTags?: Maybe<Array<Maybe<TagInfo>>>;
  KtiTalentAssessmentTags?: Maybe<Array<Maybe<TagInfo>>>;
  MdpGoggleModelTags?: Maybe<Array<Maybe<TagInfo>>>;
  MdTableTags?: Maybe<Array<Maybe<TagInfo>>>;
  EstAccountTallyEntryTags?: Maybe<Array<Maybe<TagInfo>>>;
  EstReceivablePlanTags?: Maybe<Array<Maybe<TagInfo>>>;
  MdInvoiceTags?: Maybe<Array<Maybe<TagInfo>>>;
  MetAgentTags?: Maybe<Array<Maybe<TagInfo>>>;
  KtiWorkingReportTags?: Maybe<Array<Maybe<TagInfo>>>;
  MdeCustomerTags?: Maybe<Array<Maybe<TagInfo>>>;
};

export type QueryMdoBoxWorkOrderArgs = {
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
};

export type QueryCalculateMdoBoxWorkOrdersArgs = {
  productOrderCode: Scalars['String'];
  reusingRecentDays: Scalars['Int'];
  innerSeparatorMaterialDictId?: Maybe<Scalars['ID']>;
  outerSeparatorMaterialDictId?: Maybe<Scalars['ID']>;
};

export type QueryMdaStructureTemplateArgs = {
  categoryKey: Scalars['String'];
};

export type QueryGetRootMdaStructureLevelsArgs = {
  flushCache?: Maybe<Scalars['Boolean']>;
};

export type QueryGetMdxStructureTreeArgs = {
  refreshCache?: Maybe<Scalars['Boolean']>;
};

export type QueryMdxStructureBranchNodeArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type QueryMdxStructureFieldNodeArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type QueryGetChildFieldNodeArgs = {
  branchNodeId?: Maybe<Scalars['ID']>;
};

export type QueryGetProductAttributeTreeArgs = {
  attrInput?: Maybe<MdAttributeInput>;
};

export type QueryGetNextLevelAttributeArgs = {
  attrInput?: Maybe<MdAttributeInput>;
  fieldNodeId?: Maybe<Scalars['ID']>;
  hasBranchOnly?: Maybe<Scalars['Boolean']>;
};

export type QueryFindStructureFullPathArgs = {
  dictEntryId?: Maybe<Scalars['ID']>;
};

export type QueryGetStructurePathFieldNodesArgs = {
  pathBranchNodeIds: Array<Maybe<Scalars['ID']>>;
};

export type QueryGetMdDataWithStructureArgs = {
  dataIds: Array<Maybe<Scalars['ID']>>;
};

export type QueryFlattenEngineeringMdDataArgs = {
  dataIds: Array<Scalars['ID']>;
};

export type QueryGetMdDataHelpCodeArgs = {
  dataIds: Array<Maybe<Scalars['ID']>>;
};

export type QueryMdpStructureTemplateArgs = {
  categoryKey: Scalars['String'];
};

export type QueryGetRootMdpStructureLevelsArgs = {
  flushCache?: Maybe<Scalars['Boolean']>;
};

export type QueryPreviewMdpBomEntryMatchArgs = {
  bomEntries: Array<Maybe<MdpBomEntryInput>>;
};

export type QueryGetMdpMdDataViewRootLevelsArgs = {
  viewId: Scalars['ID'];
  additionalFilter?: Maybe<FilterCondition>;
};

export type QueryMdaLensColourArgs = {
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
};

export type QueryVerifyQlExpressArgs = {
  script: Scalars['String'];
};

export type QueryMdCategoriesArgs = {
  rowRequest: RowRequest;
};

export type QueryMdCategoryArgs = {
  key?: Maybe<Scalars['String']>;
};

export type QueryMdcDataSearchArgs = {
  id?: Maybe<Scalars['ID']>;
  categoryKey?: Maybe<Scalars['String']>;
};

export type QueryMdoProductOrderArgs = {
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
};

export type QueryMdoEndProductSummaryArgs = {
  productOrderCode: Scalars['String'];
};

export type QueryGetMdoEndProductMixinGroupArgs = {
  productOrderCode: Scalars['String'];
  categoryKey: Scalars['String'];
  productModel: Scalars['String'];
};

export type QueryMdoBoxingSheetArgs = {
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
};

export type QueryHasDeliverySheetArgs = {
  productOrderCode: Scalars['String'];
};

export type QueryInitMdoBoxingSheetEntryArgs = {
  endProducts: Array<Maybe<MdoBoxingSimpleEndProductInput>>;
  packingOptionId: Scalars['ID'];
};

export type QueryMatchPackingOptionsArgs = {
  endProductId: Scalars['ID'];
  matchType: Scalars['String'];
  byCustomer?: Maybe<Scalars['Boolean']>;
  byProduct?: Maybe<Scalars['Boolean']>;
  byPacking?: Maybe<Scalars['Boolean']>;
  orderQty?: Maybe<Scalars['Float']>;
  outerBoxQty?: Maybe<Scalars['Int']>;
};

export type QueryMdoEndProductInfoTemplateArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type QueryFindMdInvoicesArgs = {
  fileKey?: Maybe<Scalars['String']>;
};

export type QueryGetAllTemplateFieldsArgs = {
  templateId: Scalars['ID'];
};

export type QueryGetRootTemplateEntriesArgs = {
  templateId: Scalars['ID'];
};

export type QueryMatchMdFieldTemplateArgs = {
  templateId: Scalars['ID'];
  attrInputs?: Maybe<Array<Maybe<MdAttributeInput>>>;
};

export type QueryFlushMdFieldTemplateCacheArgs = {
  templateId?: Maybe<Scalars['ID']>;
};

export type QueryRoleGroupArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type QueryFindUserPermissionsArgs = {
  userId: Scalars['ID'];
};

export type QueryConvertUnitArgs = {
  sourceUnit: Scalars['String'];
  targetUnit: Scalars['String'];
  count: Scalars['Float'];
};

export type QueryGetUnitConversionFactorArgs = {
  sourceUnit: Scalars['String'];
  targetUnit: Scalars['String'];
};

export type QueryGetMdFilesArgs = {
  dirId?: Maybe<Scalars['ID']>;
  dirOnly?: Maybe<Scalars['Boolean']>;
};

export type QueryGetMySharedRootDirArgs = {
  dirId?: Maybe<Scalars['ID']>;
};

export type QueryGetParentsArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type QuerySearchMdFileArgs = {
  id?: Maybe<Scalars['ID']>;
  searchText?: Maybe<Scalars['String']>;
  shared?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['Int']>;
  rows?: Maybe<Scalars['Int']>;
};

export type QueryCustomerOrDefaultCurrencyArgs = {
  customerCode: Scalars['String'];
};

export type QueryMdDictionaryArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type QueryMdDataArgs = {
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
};

export type QueryPreviewMdDataArgs = {
  mdDataId?: Maybe<Scalars['ID']>;
  attributes: Array<MdAttributeInput>;
};

export type QueryMatchRelatedFieldsArgs = {
  attrInput: MdAttributeInput;
};

export type QueryMatchMdDataAttributeTemplateArgs = {
  attributes: Array<MdAttributeInput>;
};

export type QueryMatchMdDataBomTemplateArgs = {
  attributes?: Maybe<Array<MdAttributeInput>>;
};

export type QueryMdExtendDatasArgs = {
  modelName: Scalars['String'];
  rowRequest: RowRequest;
};

export type QueryMdExtendDataArgs = {
  modelName: Scalars['String'];
  dataId?: Maybe<Scalars['ID']>;
};

export type QueryConvertMdExtendDataFilterConditionArgs = {
  modelName: Scalars['String'];
  filterCondition: FilterCondition;
};

export type QueryGetAtsPoInvSheetIdsArgs = {
  mdoDeliverySheetCode: Scalars['String'];
};

export type QueryBrCatalogsArgs = {
  modelName: Scalars['String'];
  flushCache?: Maybe<Scalars['Boolean']>;
  fetchEntities?: Maybe<Scalars['Boolean']>;
  singleRoot?: Maybe<Scalars['Boolean']>;
  createUndefinedCatalog?: Maybe<Scalars['Boolean']>;
  countEntity?: Maybe<Scalars['Boolean']>;
  ignoreEmptyCatalog?: Maybe<Scalars['Boolean']>;
  filterRootCatalogs?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryBrCatalogArgs = {
  id: Scalars['ID'];
};

export type QueryMdoDeliverySheetArgs = {
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
};

export type QueryMdoDeliverySheetEntryArgs = {
  id?: Maybe<Scalars['ID']>;
  updateFromMdoBoxingSheetEntry?: Maybe<Scalars['Boolean']>;
};

export type QueryBuildMdoDeliverySheetEntriesArgs = {
  boxingSheetEntryIds: Array<Maybe<Scalars['ID']>>;
};

export type QueryCalculateDeliveryEpQtyArgs = {
  boxingSheetEntryId: Scalars['ID'];
  deliveryQty: Scalars['Float'];
  deliverySheetEntryId?: Maybe<Scalars['ID']>;
};

export type QueryBuildDeliveryEpArgs = {
  endProductIds: Array<Maybe<Scalars['ID']>>;
};

export type QueryGetAvailableBoxingSheetEntriesArgs = {
  productOrderCode: Scalars['String'];
  excludeEntryIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type QueryMdaLensElectroplateColourArgs = {
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
};

export type QueryMdaLensProductModelColourArgs = {
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
};

export type QueryAccountArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type QueryUserArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type QueryGetPermissionMenusArgs = {
  permissionCodes: Array<Maybe<Scalars['String']>>;
};

export type QueryGetExplorerViewsArgs = {
  modelName: Scalars['String'];
};

export type QueryGetChartViewsArgs = {
  modelName: Scalars['String'];
};

export type QueryGetChartViewArgs = {
  hash: Scalars['String'];
};

export type QueryGetChartDataArgs = {
  hash: Scalars['String'];
};

export type QueryPreviewTextChartArgs = {
  script: Scalars['String'];
  chartType?: Maybe<Scalars['ValuedEnum']>;
  externalDataSourceId?: Maybe<Scalars['ID']>;
};

export type QueryPreviewTableChartArgs = {
  script: Scalars['String'];
  externalDataSourceId?: Maybe<Scalars['ID']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryExternalDataSourceArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type QueryTestExternalDataSourceArgs = {
  url: Scalars['String'];
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  driver: Scalars['ValuedEnum'];
};

export type QueryListUserDashboardGaugesArgs = {
  userId: Scalars['ID'];
};

export type QueryGetDashboardGaugeArgs = {
  gaugeId: Scalars['ID'];
};

export type QueryGetSysExportTemplatesArgs = {
  modelName: Scalars['String'];
};

export type QueryGetDataBatchByHashArgs = {
  hash: Scalars['String'];
};

export type QueryGenerateEstTodoFromContractArgs = {
  contractId: Scalars['ID'];
  reminderTiming?: Maybe<Scalars['ValuedEnum']>;
  receivedAmount?: Maybe<Scalars['Float']>;
};

export type QuerySumEstAccountTallyByMonthArgs = {
  accountBookId: Scalars['ID'];
  date: Scalars['Date'];
};

export type QueryGetEstInvitationTokenArgs = {
  token: Scalars['String'];
};

export type QueryMdpGalleryCatalogConfigArgs = {
  code: Scalars['ID'];
};

export type QueryMdpGalleryModelConfigArgs = {
  code: Scalars['ID'];
};

export type QueryMdTallyArgs = {
  topic: Scalars['String'];
  id: Scalars['ID'];
  accountSetId?: Maybe<Scalars['ID']>;
};

export type QueryMdTalliesArgs = {
  topic: Scalars['String'];
  rowRequest?: Maybe<RowRequest>;
  accountSetId?: Maybe<Scalars['ID']>;
};

export type QueryMdTalliesGroupByArgs = {
  topic: Scalars['String'];
  rowRequest?: Maybe<RowRequest>;
  accountSetId?: Maybe<Scalars['ID']>;
};

export type QueryMdTallySurplusArgs = {
  topic: Scalars['String'];
  id: Scalars['ID'];
};

export type QueryMdTallySurplusesArgs = {
  topic: Scalars['String'];
  rowRequest?: Maybe<RowRequest>;
};

export type QueryMdTallySurplusesGroupByArgs = {
  topic: Scalars['String'];
  rowRequest?: Maybe<RowRequest>;
};

export type QueryMdTallyTimeSerialSurplusArgs = {
  accountSetId: Scalars['ID'];
  timeSerialType: Scalars['ValuedEnum'];
  id: Scalars['ID'];
};

export type QueryMdTallyTimeSerialSurplusesArgs = {
  accountSetId: Scalars['ID'];
  timeSerialType: Scalars['ValuedEnum'];
  rowRequest?: Maybe<RowRequest>;
};

export type QueryMdTallyTimeSerialSurplusesGroupByArgs = {
  accountSetId: Scalars['ID'];
  timeSerialType: Scalars['ValuedEnum'];
  rowRequest?: Maybe<RowRequest>;
};

export type QueryGetMdTallySurplusArgs = {
  topic: Scalars['String'];
  features: Scalars['Map'];
};

export type QueryGetMdTallyFlowSurplusArgs = {
  topic: Scalars['String'];
  mdTallySurplusId: Scalars['ID'];
};

export type QueryGetTalentSalaryArgs = {
  talentId?: Maybe<Scalars['ID']>;
};

export type QueryGetTalentSupervisorCommentArgs = {
  talentId: Scalars['ID'];
};

export type QueryQrtzJobArgs = {
  name?: Maybe<Scalars['String']>;
};

export type QueryMdValidationRulesArgs = {
  rowRequest: RowRequest;
};

export type QueryMdDiagnosesArgs = {
  rowRequest: RowRequest;
};

export type QueryMdDiagnosisArgs = {
  targetModel: Scalars['String'];
  dataId: Scalars['ID'];
};

export type QueryMdFieldArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type QueryFindMdFieldsByDictIdArgs = {
  dictId?: Maybe<Scalars['ID']>;
};

export type QueryMdTableDatasArgs = {
  tableCode: Scalars['String'];
  rowRequest: RowRequest;
};

export type QueryMdTableDatasGroupByArgs = {
  tableCode: Scalars['String'];
  rowRequest: GroupByRowRequest;
};

export type QueryMdTableDataArgs = {
  tableCode: Scalars['String'];
  dataId: Scalars['ID'];
};

export type QueryMdTableImportMappingPresetsArgs = {
  tableCode: Scalars['String'];
};

export type QueryPreviewMdTableImportFromSqlArgs = {
  tableCode?: Maybe<Scalars['String']>;
  importFromSql: Scalars['String'];
  externalDataSourceId?: Maybe<Scalars['ID']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryGetMdmMdDataInboundByIdsArgs = {
  codes: Array<Scalars['ID']>;
};

export type QueryMdxNamingRuleArgs = {
  id?: Maybe<Scalars['ID']>;
  categoryKey?: Maybe<Scalars['String']>;
};

export type QueryPreviewMdxNamingRuleArgs = {
  ruleField: Scalars['String'];
  ruleCategoryKey: Scalars['String'];
};

export type QueryMdxNamingRulesArgs = {
  rowRequest: RowRequest;
};

export type QueryMdxNamingRulesGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryRolesArgs = {
  rowRequest: RowRequest;
};

export type QueryRolesGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryRoleArgs = {
  id: Scalars['ID'];
};

export type QueryMdpStructureToMdDatasArgs = {
  rowRequest: RowRequest;
};

export type QueryMdpStructureToMdDatasGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdpStructureToMdDataArgs = {
  id: Scalars['ID'];
};

export type QueryChartDashboardsArgs = {
  rowRequest: RowRequest;
};

export type QueryChartDashboardsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryChartDashboardArgs = {
  id: Scalars['ID'];
};

export type QueryMdpFinSizesArgs = {
  rowRequest: RowRequest;
};

export type QueryMdpFinSizesGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdpFinSizeArgs = {
  id: Scalars['ID'];
};

export type QueryMdoBoxingSheetEntriesArgs = {
  rowRequest: RowRequest;
};

export type QueryMdoBoxingSheetEntriesGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdoBoxingSheetEntryArgs = {
  id: Scalars['ID'];
};

export type QueryMdaLensColoursArgs = {
  rowRequest: RowRequest;
};

export type QueryMdaLensColoursGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMetBonusSettlingsArgs = {
  rowRequest: RowRequest;
};

export type QueryMetBonusSettlingsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMetBonusSettlingArgs = {
  id: Scalars['ID'];
};

export type QueryMdTablesArgs = {
  rowRequest: RowRequest;
};

export type QueryMdTablesGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdTableArgs = {
  id: Scalars['ID'];
};

export type QueryKtiSupervisorCommentsArgs = {
  rowRequest: RowRequest;
};

export type QueryKtiSupervisorCommentsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryKtiSupervisorCommentArgs = {
  id: Scalars['ID'];
};

export type QueryMetMeetingsArgs = {
  rowRequest: RowRequest;
};

export type QueryMetMeetingsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMetMeetingArgs = {
  id: Scalars['ID'];
};

export type QueryMdoBoxingSheetsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdoBoxingSheetsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QuerySysExportTemplatesArgs = {
  rowRequest: RowRequest;
};

export type QuerySysExportTemplatesGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QuerySysExportTemplateArgs = {
  id: Scalars['ID'];
};

export type QueryMdaCustomerCatalogsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdaCustomerCatalogsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdaCustomerCatalogArgs = {
  id: Scalars['ID'];
};

export type QueryMdeSuppliersArgs = {
  rowRequest: RowRequest;
};

export type QueryMdeSuppliersGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdeSupplierArgs = {
  id: Scalars['ID'];
};

export type QueryMdmDataRefactorsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdmDataRefactorsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdmDataRefactorArgs = {
  id: Scalars['ID'];
};

export type QueryMdmRqaDeletedTallyInboundsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdmRqaDeletedTallyInboundsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdmRqaDeletedTallyInboundArgs = {
  id: Scalars['ID'];
};

export type QueryPermissionsArgs = {
  rowRequest: RowRequest;
};

export type QueryPermissionsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryPermissionArgs = {
  id: Scalars['ID'];
};

export type QueryEstPayableReconciliationsArgs = {
  rowRequest: RowRequest;
};

export type QueryEstPayableReconciliationsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryEstPayableReconciliationArgs = {
  id: Scalars['ID'];
};

export type QueryMdmDictionaryEntryInboundViewsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdmDictionaryEntryInboundViewsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdmDictionaryEntryInboundViewArgs = {
  id: Scalars['ID'];
};

export type QueryJoiningApplicationsArgs = {
  rowRequest: RowRequest;
};

export type QueryJoiningApplicationsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryJoiningApplicationArgs = {
  id: Scalars['ID'];
};

export type QueryMdpGallerySkusArgs = {
  rowRequest: RowRequest;
};

export type QueryMdpGallerySkusGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdpGallerySkuArgs = {
  id: Scalars['ID'];
};

export type QueryMdDictionariesArgs = {
  rowRequest: RowRequest;
};

export type QueryMdDictionariesGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMetParticipantsArgs = {
  rowRequest: RowRequest;
};

export type QueryMetParticipantsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMetParticipantArgs = {
  id: Scalars['ID'];
};

export type QueryMdaStructureToMdDatasArgs = {
  rowRequest: RowRequest;
};

export type QueryMdaStructureToMdDatasGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdaStructureToMdDataArgs = {
  id: Scalars['ID'];
};

export type QueryMetBonusesArgs = {
  rowRequest: RowRequest;
};

export type QueryMetBonusesGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMetBonusArgs = {
  id: Scalars['ID'];
};

export type QueryMdeCustomersArgs = {
  rowRequest: RowRequest;
};

export type QueryMdeCustomersGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdeCustomerArgs = {
  id: Scalars['ID'];
};

export type QueryMdoDesignDocumentsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdoDesignDocumentsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdoDesignDocumentArgs = {
  id: Scalars['ID'];
};

export type QuerySysDataBatchHashsArgs = {
  rowRequest: RowRequest;
};

export type QuerySysDataBatchHashsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QuerySysDataBatchHashArgs = {
  id: Scalars['ID'];
};

export type QueryMdoDesignDocumentUnitsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdoDesignDocumentUnitsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdoDesignDocumentUnitArgs = {
  id: Scalars['ID'];
};

export type QueryKtiServiceContractsArgs = {
  rowRequest: RowRequest;
};

export type QueryKtiServiceContractsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryKtiServiceContractArgs = {
  id: Scalars['ID'];
};

export type QueryMdaLensElectroplateColoursArgs = {
  rowRequest: RowRequest;
};

export type QueryMdaLensElectroplateColoursGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMetMembershipFlowsArgs = {
  rowRequest: RowRequest;
};

export type QueryMetMembershipFlowsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMetMembershipFlowArgs = {
  id: Scalars['ID'];
};

export type QueryEstReceivableReconciliationsArgs = {
  rowRequest: RowRequest;
};

export type QueryEstReceivableReconciliationsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryEstReceivableReconciliationArgs = {
  id: Scalars['ID'];
};

export type QueryEstReceivableStepsArgs = {
  rowRequest: RowRequest;
};

export type QueryEstReceivableStepsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryEstReceivableStepArgs = {
  id: Scalars['ID'];
};

export type QueryKtiTalentsArgs = {
  rowRequest: RowRequest;
};

export type QueryKtiTalentsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryKtiTalentArgs = {
  id: Scalars['ID'];
};

export type QueryMdpGalleryModelsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdpGalleryModelsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdpGalleryModelArgs = {
  id: Scalars['ID'];
};

export type QueryMdoProductOrdersArgs = {
  rowRequest: RowRequest;
};

export type QueryMdoProductOrdersGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdcDataSearchsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdcDataSearchsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdoBoxWorkOrdersArgs = {
  rowRequest: RowRequest;
};

export type QueryMdoBoxWorkOrdersGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdpGoggleModelsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdpGoggleModelsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdpGoggleModelArgs = {
  id: Scalars['ID'];
};

export type QueryMetCustomerContactsArgs = {
  rowRequest: RowRequest;
};

export type QueryMetCustomerContactsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMetCustomerContactArgs = {
  id: Scalars['ID'];
};

export type QueryMdoDeliverySheetEntriesArgs = {
  rowRequest: RowRequest;
};

export type QueryMdoDeliverySheetEntriesGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdaStructuresArgs = {
  rowRequest: RowRequest;
};

export type QueryMdaStructuresGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdaStructureArgs = {
  id: Scalars['ID'];
};

export type QueryUsersArgs = {
  rowRequest: RowRequest;
};

export type QueryUsersGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdInvoicesArgs = {
  rowRequest: RowRequest;
};

export type QueryMdInvoicesGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdInvoiceArgs = {
  id: Scalars['ID'];
};

export type QueryMdoMarkPrintTemplateFieldPresetsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdoMarkPrintTemplateFieldPresetsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdoMarkPrintTemplateFieldPresetArgs = {
  id: Scalars['ID'];
};

export type QueryMdaSalesVolumesArgs = {
  rowRequest: RowRequest;
};

export type QueryMdaSalesVolumesGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdaSalesVolumeArgs = {
  id: Scalars['ID'];
};

export type QueryMdaLensProductModelColoursArgs = {
  rowRequest: RowRequest;
};

export type QueryMdaLensProductModelColoursGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdFieldTemplateEntriesArgs = {
  rowRequest: RowRequest;
};

export type QueryMdFieldTemplateEntriesGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdFieldTemplateEntryArgs = {
  id: Scalars['ID'];
};

export type QueryMdFieldsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdFieldsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdpStructureMdDataViewsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdpStructureMdDataViewsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdpStructureMdDataViewArgs = {
  id: Scalars['ID'];
};

export type QueryKtiIntellectualPropertysArgs = {
  rowRequest: RowRequest;
};

export type QueryKtiIntellectualPropertysGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryKtiIntellectualPropertyArgs = {
  id: Scalars['ID'];
};

export type QueryMdFieldTemplatesArgs = {
  rowRequest: RowRequest;
};

export type QueryMdFieldTemplatesGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdFieldTemplateArgs = {
  id: Scalars['ID'];
};

export type QueryMdpStructuresArgs = {
  rowRequest: RowRequest;
};

export type QueryMdpStructuresGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdpStructureArgs = {
  id: Scalars['ID'];
};

export type QueryMdaCustomerEndProductsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdaCustomerEndProductsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdaCustomerEndProductArgs = {
  id: Scalars['ID'];
};

export type QueryMdmBomInboundViewsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdmBomInboundViewsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdmBomInboundViewArgs = {
  id: Scalars['ID'];
};

export type QueryEstAccountBooksArgs = {
  rowRequest: RowRequest;
};

export type QueryEstAccountBooksGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryEstAccountBookArgs = {
  id: Scalars['ID'];
};

export type QueryKtiAttendancesArgs = {
  rowRequest: RowRequest;
};

export type QueryKtiAttendancesGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryKtiAttendanceArgs = {
  id: Scalars['ID'];
};

export type QueryMdeWarehousesArgs = {
  rowRequest: RowRequest;
};

export type QueryMdeWarehousesGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdeWarehouseArgs = {
  id: Scalars['ID'];
};

export type QueryMdeColoursArgs = {
  rowRequest: RowRequest;
};

export type QueryMdeColoursGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdeColourArgs = {
  id: Scalars['ID'];
};

export type QueryRoleGroupsArgs = {
  rowRequest: RowRequest;
};

export type QueryRoleGroupsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdpFinModelsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdpFinModelsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdpFinModelArgs = {
  id: Scalars['ID'];
};

export type QueryMdoBoxingSheetEntriesViewArgs = {
  rowRequest: RowRequest;
};

export type QueryMdoBoxingSheetEntriesViewGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdoBoxingSheetEntryViewArgs = {
  id: Scalars['ID'];
};

export type QueryChartViewsArgs = {
  rowRequest: RowRequest;
};

export type QueryChartViewsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryChartViewArgs = {
  id: Scalars['ID'];
};

export type QueryMetAgentsArgs = {
  rowRequest: RowRequest;
};

export type QueryMetAgentsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMetAgentArgs = {
  id: Scalars['ID'];
};

export type QueryMdDatasArgs = {
  rowRequest: RowRequest;
};

export type QueryMdDatasGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdmDataRefactorBatchesArgs = {
  rowRequest: RowRequest;
};

export type QueryMdmDataRefactorBatchesGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdmDataRefactorBatchArgs = {
  id: Scalars['ID'];
};

export type QueryEstContractsArgs = {
  rowRequest: RowRequest;
};

export type QueryEstContractsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryEstContractArgs = {
  id: Scalars['ID'];
};

export type QueryKtiTalentAssessmentsArgs = {
  rowRequest: RowRequest;
};

export type QueryKtiTalentAssessmentsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryKtiTalentAssessmentArgs = {
  id: Scalars['ID'];
};

export type QueryMdoEndProductsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdoEndProductsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdoEndProductArgs = {
  id: Scalars['ID'];
};

export type QueryMetCustomersArgs = {
  rowRequest: RowRequest;
};

export type QueryMetCustomersGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMetCustomerArgs = {
  id: Scalars['ID'];
};

export type QueryMdoDeliverySheetsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdoDeliverySheetsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdoDeliveryEpViewsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdoDeliveryEpViewsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdoDeliveryEpViewArgs = {
  id: Scalars['ID'];
};

export type QueryKtiResearchAchievementsArgs = {
  rowRequest: RowRequest;
};

export type QueryKtiResearchAchievementsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryKtiResearchAchievementArgs = {
  id: Scalars['ID'];
};

export type QueryMdScriptProcessorsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdScriptProcessorsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdScriptProcessorArgs = {
  id: Scalars['ID'];
};

export type QueryEstTradingTargetsArgs = {
  rowRequest: RowRequest;
};

export type QueryEstTradingTargetsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryEstTradingTargetArgs = {
  id: Scalars['ID'];
};

export type QueryEstReceivablePlansArgs = {
  rowRequest: RowRequest;
};

export type QueryEstReceivablePlansGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryEstReceivablePlanArgs = {
  id: Scalars['ID'];
};

export type QueryAuthLogsArgs = {
  rowRequest: RowRequest;
};

export type QueryAuthLogsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryAuthLogArgs = {
  id: Scalars['ID'];
};

export type QueryMdDictionaryEntriesArgs = {
  rowRequest: RowRequest;
};

export type QueryMdDictionaryEntriesGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdDictionaryEntryArgs = {
  id: Scalars['ID'];
};

export type QueryMdUnitConversionsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdUnitConversionsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdUnitConversionArgs = {
  id: Scalars['ID'];
};

export type QueryMdeCurrenciesArgs = {
  rowRequest: RowRequest;
};

export type QueryMdeCurrenciesGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdeCurrencyArgs = {
  id: Scalars['ID'];
};

export type QueryMdTallyAccountSetsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdTallyAccountSetsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdTallyAccountSetArgs = {
  id: Scalars['ID'];
};

export type QueryMdoBoxPaperboardViewsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdoBoxPaperboardViewsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdoBoxPaperboardViewArgs = {
  id: Scalars['ID'];
};

export type QueryQrtzExecLogsArgs = {
  rowRequest: RowRequest;
};

export type QueryQrtzExecLogsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryQrtzExecLogArgs = {
  id: Scalars['ID'];
};

export type QueryMdmOrderPackingsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdmOrderPackingsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdmOrderPackingArgs = {
  id: Scalars['ID'];
};

export type QueryEstTasksArgs = {
  rowRequest: RowRequest;
};

export type QueryEstTasksGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryEstTaskArgs = {
  id: Scalars['ID'];
};

export type QueryMdmRqaTallyInboundViewsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdmRqaTallyInboundViewsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdmRqaTallyInboundViewArgs = {
  id: Scalars['ID'];
};

export type QueryMdoMarkPrintTemplatesArgs = {
  rowRequest: RowRequest;
};

export type QueryMdoMarkPrintTemplatesGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdoMarkPrintTemplateArgs = {
  id: Scalars['ID'];
};

export type QueryMdmPackingOptionInboundViewsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdmPackingOptionInboundViewsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdmPackingOptionInboundViewArgs = {
  id: Scalars['ID'];
};

export type QueryMdpWorkManualTemplatesArgs = {
  rowRequest: RowRequest;
};

export type QueryMdpWorkManualTemplatesGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdpWorkManualTemplateArgs = {
  id: Scalars['ID'];
};

export type QueryMdmTallySurplusDiffViewsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdmTallySurplusDiffViewsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdmTallySurplusDiffViewArgs = {
  id: Scalars['ID'];
};

export type QueryMdpMdDataViewsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdpMdDataViewsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdpMdDataViewArgs = {
  id: Scalars['ID'];
};

export type QueryMdFieldTemplateBranchsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdFieldTemplateBranchsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdFieldTemplateBranchArgs = {
  id: Scalars['ID'];
};

export type QueryMdUnitsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdUnitsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdUnitArgs = {
  id: Scalars['ID'];
};

export type QueryMdmDataInboundViewsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdmDataInboundViewsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdmDataInboundViewArgs = {
  id: Scalars['ID'];
};

export type QueryPermissionSpecialsArgs = {
  rowRequest: RowRequest;
};

export type QueryPermissionSpecialsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryPermissionSpecialArgs = {
  id: Scalars['ID'];
};

export type QueryEstAccountTallyEntriesArgs = {
  rowRequest: RowRequest;
};

export type QueryEstAccountTallyEntriesGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryEstAccountTallyEntryArgs = {
  id: Scalars['ID'];
};

export type QueryMdoPackingOptionsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdoPackingOptionsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdoPackingOptionArgs = {
  id: Scalars['ID'];
};

export type QueryKtiWorkingReportsArgs = {
  rowRequest: RowRequest;
};

export type QueryKtiWorkingReportsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryKtiWorkingReportArgs = {
  id: Scalars['ID'];
};

export type QueryMdeIncotermsesArgs = {
  rowRequest: RowRequest;
};

export type QueryMdeIncotermsesGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdeIncotermsArgs = {
  id: Scalars['ID'];
};

export type QueryExternalDataSourcesArgs = {
  rowRequest: RowRequest;
};

export type QueryExternalDataSourcesGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdmTallyInboundsArgs = {
  rowRequest: RowRequest;
};

export type QueryMdmTallyInboundsGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryMdmTallyInboundArgs = {
  id: Scalars['ID'];
};

export type QueryEstTodosArgs = {
  rowRequest: RowRequest;
};

export type QueryEstTodosGroupByArgs = {
  rowRequest: GroupByRowRequest;
};

export type QueryEstTodoArgs = {
  id: Scalars['ID'];
};

export type QueryMdpFinModelTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryMdDataTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryKtiIntellectualPropertyTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryMdaLensProductModelColourTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryMdoBoxingSheetTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryMdoUsedPackingOptionTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryMdpStructureTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryMetMembershipFlowTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryEstAccountEntryTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryKtiResearchAchievementTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryKtiServiceContractTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryKtiTalentTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryMdoDeliverySheetTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryMdFieldTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryMdaLensElectroplateColourTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryMetCustomerTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryMdaLensColourTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryMdoProductOrderTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryMdoPackingOptionTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryKtiAttendanceTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryMdDictionaryTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryMetMeetingTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryMdaStructureTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryRoleGroupTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryMetParticipantTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryKtiTalentAssessmentTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryMdpGoggleModelTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryMdTableTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryEstAccountTallyEntryTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryEstReceivablePlanTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryMdInvoiceTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryMetAgentTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryKtiWorkingReportTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

export type QueryMdeCustomerTagsArgs = {
  search?: Maybe<Scalars['String']>;
};

/** 功能模块 */
export type Realm = CodeBasedModel & {
  code: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 名称 */
  name: Scalars['String'];
  version?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['ValuedEnum']>;
};

/** 数据引用信息 */
export type ReferenceInfo = {
  /** 引用功能模块 */
  realmCode: Scalars['ID'];
  /** 引用对象 */
  modelName: Scalars['String'];
  /** 引用数量 */
  dataCount: Scalars['Int'];
};

/** 角色 */
export type Role = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 名称 */
  name: Scalars['String'];
  /** 所属工作组 */
  roleGroup: RoleGroup;
  /** 分配用户 */
  users?: Maybe<Array<User>>;
  /** 包含权限 */
  permissions?: Maybe<Array<Permission>>;
};

/** 工作组 */
export type RoleGroup = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 名称 */
  name: Scalars['String'];
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 管理员 */
  admins?: Maybe<Array<User>>;
  /** 包含角色 */
  roles?: Maybe<Array<Role>>;
  /** 包含权限 */
  permissions?: Maybe<Array<Permission>>;
};

export type RoleGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type RoleGroupGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type RoleGroupPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<RoleGroup>>>;
};

export type RoleGroupValues = {
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  adminIds?: Maybe<Array<Scalars['ID']>>;
  permissionCodes?: Maybe<Array<Scalars['ID']>>;
};

export type RolePage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Role>>>;
};

export type RoleValues = {
  name?: Maybe<Scalars['String']>;
  permissionCodes?: Maybe<Array<Scalars['ID']>>;
  userIds?: Maybe<Array<Scalars['ID']>>;
};

export type RowRequest = {
  start?: Maybe<Scalars['Int']>;
  rows?: Maybe<Scalars['Int']>;
  filter?: Maybe<FilterCondition>;
  sort?: Maybe<Array<Maybe<SortColumn>>>;
};

export type SortColumn = {
  field: Scalars['String'];
  sort: SortType;
  extra?: Maybe<Scalars['Map']>;
};

export enum SortType {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** 首页统计指标 */
export type SysDashboardGauge = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 功能模块 */
  realm: Scalars['String'];
  /** 名称 */
  name: Scalars['String'];
  /** 权限 */
  permission?: Maybe<Permission>;
  /** 统计sql */
  countSql?: Maybe<Scalars['String']>;
  /** 排序 */
  seq: Scalars['Int'];
  /** 分组排序 */
  groupSeq: Scalars['Int'];
  /** 跳转链接 */
  pageUrl?: Maybe<Scalars['String']>;
};

/** 数据批次 */
export type SysDataBatchHash = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 数据模型名称 */
  modelName: Scalars['String'];
  /** 批次号 */
  hash: Scalars['String'];
  /** 数据id */
  dataIds: Array<Maybe<Scalars['ID']>>;
  /** 智能表格 */
  mdTable?: Maybe<MdTable>;
};

export type SysDataBatchHashGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type SysDataBatchHashPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<SysDataBatchHash>>>;
};

/** 打印导出模版 */
export type SysExportTemplate = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 数据模型名称 */
  modelName: Scalars['String'];
  /** 名称 */
  name: Scalars['String'];
  /** 模板文件key */
  templateFileKey?: Maybe<Scalars['String']>;
  /** 打印模版 */
  forPrint?: Maybe<Scalars['Boolean']>;
  /** 导出excel模版 */
  forXlsExport?: Maybe<Scalars['Boolean']>;
  /** Pdf导出模版 */
  forPdfExport?: Maybe<Scalars['Boolean']>;
  /** 每页行数 */
  rowsPerPage?: Maybe<Scalars['Int']>;
  /** 最多导出记录条数 */
  maxRow?: Maybe<Scalars['Int']>;
  /** 分组脚本 */
  groupByScript?: Maybe<Scalars['String']>;
  /** 文件名脚本 */
  fileNameScript?: Maybe<Scalars['String']>;
  /** 打印纸张宽度 */
  printPaperWidth?: Maybe<Scalars['Float']>;
  /** 打印纸张高度 */
  printPaperHeight?: Maybe<Scalars['Float']>;
  /** 打印方向,0=横向;1=纵向 */
  printOrientation?: Maybe<Scalars['Int']>;
  /** 备注 */
  note?: Maybe<Scalars['String']>;
  /** 删除标记 */
  deleted?: Maybe<Scalars['Boolean']>;
};

export type SysExportTemplateGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type SysExportTemplatePage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<SysExportTemplate>>>;
};

export type TagInfo = {
  tag: Scalars['String'];
  count: Scalars['Int'];
};

/** 翻译文本 */
export type TranslateText = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 语言地区 */
  locale: Scalars['String'];
  /** 文本 */
  key: Scalars['String'];
  /** 翻译文本 */
  text: Scalars['String'];
};

/** 用户 */
export type User = IdBasedModel & {
  id: Scalars['ID'];
  uid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  ver?: Maybe<Scalars['Int']>;
  /** 员工姓名 */
  displayName: Scalars['String'];
  /** 工号 */
  employeeNo?: Maybe<Scalars['String']>;
  /** 管理员等级 */
  adminLevel?: Maybe<Scalars['ValuedEnum']>;
  /** 是否禁用 */
  disabled?: Maybe<Scalars['Boolean']>;
  /** 有效期至 */
  expiredAt?: Maybe<Scalars['DateTime']>;
  /** 头像 */
  avatar?: Maybe<Scalars['File']>;
  /** 账号信息 */
  account?: Maybe<Account>;
  /** 权限Key集合 */
  permissionKeys?: Maybe<Array<Scalars['String']>>;
  /** 邀请码 */
  joiningApplication?: Maybe<JoiningApplication>;
  /** 工作组 */
  roleGroups?: Maybe<Array<RoleGroup>>;
  /** 拥有角色 */
  roles?: Maybe<Array<Role>>;
  /** 权限特例 */
  permissionSpecials?: Maybe<Array<PermissionSpecial>>;
  /** 权限 */
  permissions?: Maybe<Array<Permission>>;
};

export type UserGroupByPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Scalars['Map']>>>;
};

export type UserPage = {
  total?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<User>>>;
};

export type BomEntrySearchItemInput = {
  categoryKey: Scalars['ID'];
  entrySearchFieldIds?: Maybe<Array<Scalars['ID']>>;
};

export type UserFragment = Pick<User, 'id' | 'displayName' | 'employeeNo'>;

export type PermissionFragment = (
  Pick<Permission, 'code' | 'name' | 'securityLevel'>
  & { realm: Pick<Realm, 'name'>, basePermissions?: Maybe<Array<Maybe<Pick<Permission, 'code'>>>>, opsPermissions?: Maybe<Array<Maybe<Pick<Permission, 'code'>>>> }
);

export type BrCatalogTreeFragment = (
  { children?: Maybe<Array<Maybe<(
    { children?: Maybe<Array<Maybe<(
      { children?: Maybe<Array<Maybe<(
        { children?: Maybe<Array<Maybe<(
          { children?: Maybe<Array<Maybe<(
            { children?: Maybe<Array<Maybe<(
              { children?: Maybe<Array<Maybe<(
                { children?: Maybe<Array<Maybe<(
                  { children?: Maybe<Array<Maybe<(
                    { children?: Maybe<Array<Maybe<BrCatalogFragment>>> }
                    & BrCatalogFragment
                  )>>> }
                  & BrCatalogFragment
                )>>> }
                & BrCatalogFragment
              )>>> }
              & BrCatalogFragment
            )>>> }
            & BrCatalogFragment
          )>>> }
          & BrCatalogFragment
        )>>> }
        & BrCatalogFragment
      )>>> }
      & BrCatalogFragment
    )>>> }
    & BrCatalogFragment
  )>>> }
  & BrCatalogFragment
);

export type BrCatalogFragment = (
  Pick<BrCatalog, 'id' | 'ver' | 'name' | 'seq' | 'entities' | 'updatedAt' | 'entityCount' | 'path'>
  & { parent?: Maybe<Pick<BrCatalog, 'id' | 'name'>> }
);

export type ExportTemplateFragment = Pick<SysExportTemplate, 'id' | 'ver' | 'name' | 'modelName' | 'templateFileKey' | 'maxRow' | 'rowsPerPage' | 'printOrientation' | 'note' | 'deleted' | 'forPdfExport' | 'forXlsExport' | 'forPrint' | 'fileNameScript' | 'groupByScript' | 'printPaperHeight' | 'printPaperWidth'>;

export type ErrorInfoFragment = Pick<ErrorInfo, 'ec' | 'elv' | 'extra' | 'msg'>;

export type MdDictionaryEntryFragment = (
  Pick<MdDictionaryEntry, 'id' | 'ver' | 'key' | 'value' | 'valueForShort' | 'seq' | 'note' | 'groupPath'>
  & { dictionary?: Maybe<Pick<MdDictionary, 'id' | 'name'>> }
);

export type MdFieldFragment = Pick<MdField, 'id' | 'ver' | 'name' | 'note' | 'tags' | 'dataType' | 'option'>;

export type MdFieldWithDictionaryFragment = (
  { dictionary?: Maybe<{ entries?: Maybe<Array<Pick<MdDictionaryEntry, 'id' | 'key' | 'value' | 'note'>>> }> }
  & MdFieldFragment
);

export type MdFieldWithPayloadFragment = (
  { payload?: Maybe<Pick<MdFieldTemplatePayload, 'alias' | 'context' | 'extra' | 'indent' | 'templateBranch'>> }
  & MdFieldFragment
);

export type MdAttributeFragment = (
  Pick<MdAttribute, 'alias' | 'bomFeature' | 'extra' | 'feature' | 'hidden' | 'tbd' | 'value'>
  & { field: Pick<MdField, 'id' | 'name' | 'dataType' | 'option' | 'builtin'> }
);

export type SimpleAttributeFragment = (
  Pick<MdAttribute, 'value'>
  & { field: Pick<MdField, 'id' | 'name' | 'dataType'> }
);

export type MdDataBomEntryFragment = (
  Pick<MdDataBomEntry, 'id' | 'uid' | 'bomTemplateEntryKey' | 'count' | 'countBase' | 'unit' | 'seq' | 'extra'>
  & { entryData: (
    Pick<MdData, 'bomEntryCount' | 'referredByBomCount'>
    & { attributes?: Maybe<Array<MdAttributeFragment>> }
    & MdDataFragment
  ) }
);

export type MdDataFragment = Pick<MdData, 'id' | 'uid' | 'ver' | 'code' | 'field0' | 'field1' | 'field2' | 'field3' | 'field4' | 'field5' | 'field6' | 'field7' | 'field8' | 'field9' | 'structureKey' | 'mainPicture' | 'pictures'>;

export type MdDataSavingResultFragment = Pick<MdDataSavingResult, 'id' | 'uid' | 'ver' | 'code' | 'field0' | 'field1' | 'field2' | 'field3' | 'field4' | 'field5' | 'field6' | 'field7' | 'field8' | 'field9' | 'structureKey'>;

export type MdDataWithAttrsFragment = (
  { attributes?: Maybe<Array<MdAttributeFragment>> }
  & MdDataFragment
);

export type SimpleMdDiagnosisFragment = Pick<MdDiagnosis, 'dataId' | 'diagState'>;

export type MdTableFragment = (
  Pick<MdTable, 'code' | 'ver' | 'name' | 'isPrivate' | 'ownerIds' | 'cooperatorIds' | 'readerIds' | 'note' | 'tags' | 'hasJob' | 'importFromSql'>
  & { catalog?: Maybe<Pick<BrCatalog, 'name'>>, columns: Array<Maybe<MdTableColumnFragment>>, importContext?: Maybe<Pick<MdTableImportContext, 'currentStep' | 'importMode'>> }
);

export type MdTableColumnFragment = Pick<MdTableColumn, 'id' | 'name' | 'colName' | 'seq' | 'option' | 'uniqueKey' | 'readonly' | 'note' | 'dataType'>;

export type MdTableDataFragment = Pick<MdTableData, 'id' | 'uid' | 'createdAt' | 'createdBy' | 'updatedAt' | 'updatedBy' | 'ver' | 'values'>;

export type MdTableImportContextFragment = (
  Pick<MdTableImportContext, 'importColumns' | 'importMode' | 'options' | 'rawData'>
  & { table?: Maybe<(
    Pick<MdTable, 'importFromSql' | 'externalDataSourceId'>
    & MdTableFragment
  )>, mappings?: Maybe<Array<Maybe<(
    Pick<MdTableImportMappingEntry, 'alignKey' | 'ignoreChange' | 'importingColumn'>
    & { mdTableColumn?: Maybe<MdTableColumnFragment> }
  )>>> }
);

export type MdTableImportDataFragment = (
  Pick<MdTableImportData, 'importDataId' | 'importValues' | 'deltaState' | 'merging'>
  & { errors?: Maybe<Array<Maybe<ErrorInfoFragment>>>, importDataPreview?: Maybe<MdTableDataFragment>, tableData?: Maybe<MdTableDataFragment> }
);

export type MdTableImportMappingPresetFragment = (
  Pick<MdTableImportMappingPreset, 'id' | 'ver' | 'name' | 'options'>
  & { mappings: Array<Maybe<Pick<MdTableImportMappingEntry, 'alignKey' | 'ignoreChange' | 'importingColumn' | 'mdTableColName'>>> }
);

export type ListMdFieldsQueryVariables = Exact<{
  rowRequest: RowRequest;
}>;

export type ListMdFieldsQuery = { MdFields?: Maybe<(
    Pick<MdFieldPage, 'total'>
    & { rows?: Maybe<Array<Maybe<MdFieldFragment>>> }
  )> };

export type CountMyQueryQueryVariables = Exact<{
  rowRequest: RowRequest;
}>;

export type CountMyQueryQuery = { MdDatas?: Maybe<Pick<MdDataPage, 'total'>> };

export type GetDictEntriesQueryVariables = Exact<{
  dictName?: Maybe<Scalars['String']>;
}>;

export type GetDictEntriesQuery = { MdDictionary?: Maybe<{ entries?: Maybe<Array<MdDictionaryEntryFragment>> }> };

export type GetOneDictEntryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetOneDictEntryQuery = { MdDictionaryEntry?: Maybe<Pick<MdDictionaryEntry, 'id' | 'key' | 'value'>> };

export type GetOneMdFieldQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
}>;

export type GetOneMdFieldQuery = { MdField?: Maybe<Pick<MdField, 'id' | 'name' | 'note' | 'option'>> };

export type SaveDictValueMutationVariables = Exact<{
  dictionaryId: Scalars['ID'];
  dictEntryId: Scalars['ID'];
  ver: Scalars['Int'];
  dictValue: Scalars['String'];
}>;

export type SaveDictValueMutation = { saveMdDictionaryEntries?: Maybe<Array<Maybe<Pick<MdDictionaryEntry, 'id' | 'key' | 'value'>>>> };

export type MdFileFragment = Pick<MdFile, 'fileName' | 'id' | 'ver' | 'isDirectory' | 'fileKey' | 'createdAt' | 'shared' | 'leaf' | 'revisions' | 'path' | 'pathIds' | 'updatedAt' | 'updatedBy'>;

export type MdFileRootDirFragment = (
  Pick<MdFileRootDir, 'id' | 'shareToUserId' | 'ownerId' | 'shareToEveryone' | 'writable' | 'deletable' | 'owner' | 'shareToUser' | 'createdAt'>
  & { directory: MdFileFragment }
);

export type MdStructureLevelTreeFragment = (
  { children?: Maybe<Array<Maybe<(
    { children?: Maybe<Array<Maybe<(
      { children?: Maybe<Array<Maybe<(
        { children?: Maybe<Array<Maybe<(
          { children?: Maybe<Array<Maybe<(
            { children?: Maybe<Array<Maybe<(
              { children?: Maybe<Array<Maybe<(
                { children?: Maybe<Array<Maybe<(
                  { children?: Maybe<Array<Maybe<(
                    { children?: Maybe<Array<Maybe<MdStructureLevelFragment>>> }
                    & MdStructureLevelFragment
                  )>>> }
                  & MdStructureLevelFragment
                )>>> }
                & MdStructureLevelFragment
              )>>> }
              & MdStructureLevelFragment
            )>>> }
            & MdStructureLevelFragment
          )>>> }
          & MdStructureLevelFragment
        )>>> }
        & MdStructureLevelFragment
      )>>> }
      & MdStructureLevelFragment
    )>>> }
    & MdStructureLevelFragment
  )>>> }
  & MdStructureLevelFragment
);

export type MdStructureLevelFragment = (
  Pick<MdStructureLevel, 'key' | 'label' | 'groupKey' | 'extra' | 'structureCount' | 'illegalStructureCount'>
  & { attributes: Array<Maybe<SimpleAttributeFragment>> }
);

export type MdInvoiceFragment = (
  Pick<MdInvoice, 'id' | 'ver' | 'uid' | 'type' | 'typeDesc' | 'state' | 'amount' | 'submittedAt' | 'note' | 'extraFiles' | 'buyer' | 'buyerTaxId' | 'buyerAddress' | 'buyerPhone' | 'buyerBank' | 'buyerBankAccount' | 'buyerEmail' | 'summary' | 'code' | 'issuedAt' | 'taxAmount' | 'seller' | 'sellerTaxId' | 'data' | 'details' | 'files'>
  & { submittedBy?: Maybe<UserFragment> }
);

export type MdTallyFragment = Pick<MdTally, 'id' | 'ver' | 'code' | 'accountType' | 'qty' | 'appliedAt' | 'operatedAt' | 'note'>;

export type MdFieldTemplateEntryFragment = (
  Pick<MdFieldTemplateEntry, 'id' | 'ver' | 'uid' | 'name' | 'alias' | 'option' | 'builtin' | 'forceDefaultValue' | 'seq'>
  & { mdField?: Maybe<MdFieldWithPayloadFragment>, template: Pick<MdFieldTemplate, 'id' | 'name'>, branch?: Maybe<Pick<MdFieldTemplateBranch, 'id'>> }
);

export type MdFieldTemplateBranchFragment = (
  Pick<MdFieldTemplateBranch, 'id' | 'ver' | 'uid' | 'name' | 'defaultBranch' | 'seq' | 'branchFilter'>
  & { entry?: Maybe<Pick<MdFieldTemplateEntry, 'id'>> }
);

export type MdFieldTemplateEntryTreeFragment = (
  { children?: Maybe<Array<Maybe<(
    { children?: Maybe<Array<Maybe<(
      { children?: Maybe<Array<Maybe<(
        { children?: Maybe<Array<Maybe<(
          { children?: Maybe<Array<Maybe<(
            { children?: Maybe<Array<Maybe<(
              { children?: Maybe<Array<Maybe<(
                { children?: Maybe<Array<Maybe<(
                  { children?: Maybe<Array<Maybe<(
                    { children?: Maybe<Array<Maybe<MdFieldTemplateEntryFragment>>> }
                    & MdFieldTemplateBranchFragment
                  )>>> }
                  & MdFieldTemplateEntryFragment
                )>>> }
                & MdFieldTemplateBranchFragment
              )>>> }
              & MdFieldTemplateEntryFragment
            )>>> }
            & MdFieldTemplateBranchFragment
          )>>> }
          & MdFieldTemplateEntryFragment
        )>>> }
        & MdFieldTemplateBranchFragment
      )>>> }
      & MdFieldTemplateEntryFragment
    )>>> }
    & MdFieldTemplateBranchFragment
  )>>> }
  & MdFieldTemplateEntryFragment
);

export type MdaBomEntriesFragment = (
  Pick<MdaBomEntry, 'id' | 'ver' | 'uid' | 'countBase' | 'count' | 'seq' | 'optional' | 'weight' | 'note'>
  & { category: Pick<MdCategoryInfo, 'key' | 'dataId' | 'keyValue'>, unit: Pick<MdUnit, 'code'>, attributes?: Maybe<Array<MdAttributeFragment>> }
);

export type MdoEndProductInfoTemplateFragment = (
  Pick<MdoEndProductInfoTemplate, 'id' | 'ver' | 'name' | 'type' | 'defaultAdded' | 'craft' | 'multiple' | 'mdDataGeneratingRule'>
  & { attributes: Array<MdAttributeFragment>, mdFields: Array<MdFieldWithPayloadFragment>, configs?: Maybe<Array<Pick<MdoEndProductInfoFieldConfig, 'fieldId' | 'seq' | 'summary' | 'diff' | 'editWithProductOrder' | 'showInTable' | 'dontCopy' | 'showInBoxingSheetEntryTable'>>> }
);

export type MdoMainProductFragment = (
  Pick<MdoMainProduct, 'id' | 'ver' | 'uid' | 'productModel' | 'count' | 'unit' | 'engineeringState' | 'note'>
  & { category: Pick<MdCategoryInfo, 'dataId' | 'key' | 'keyValue'>, mdData?: Maybe<MdDataFragment> }
);

export type MdoEndProductInfoFragment = (
  Pick<MdoEndProductInfo, 'id' | 'ver' | 'uid' | 'name' | 'type' | 'craft' | 'packingRef' | 'mdDataGeneratingRule' | 'engineeringState' | 'note' | 'carrierPath' | 'carrierInfoKey'>
  & { attributes: Array<MdAttributeFragment>, carrierMdData?: Maybe<MdDataFragment>, generatedMdData?: Maybe<MdDataFragment> }
);

export type MdoEndProductFragment = (
  Pick<MdoEndProduct, 'id' | 'ver' | 'productOrderCode' | 'productModel' | 'seq' | 'sortKey' | 'principals' | 'mdDataCode' | 'mdDataName' | 'mdDataSpec' | 'state' | 'orderQty' | 'mixinRate' | 'mixinGroup' | 'receivableUnitPrice' | 'currency'>
  & { category: Pick<MdCategoryInfo, 'dataId' | 'key' | 'keyValue'>, customer?: Maybe<Pick<MdeCustomer, 'code' | 'name' | 'fullName'>>, mainProducts: Array<Maybe<MdoMainProductFragment>>, infos: Array<Maybe<MdoEndProductInfoFragment>>, mdData?: Maybe<MdDataFragment> }
);

export type MdoPackingOptionFragment = (
  Pick<MdoPackingOption, 'id' | 'ver' | 'name' | 'note' | 'horizontalSeparator' | 'needInnerBox' | 'innerBoxQty' | 'innerBoxSize'>
  & { outerBoxQty: MdoPackingOption['outerBoxSize'] }
  & { customer?: Maybe<Pick<MdeCustomer, 'code' | 'name'>>, packingAttributes?: Maybe<Array<Maybe<MdAttributeFragment>>>, productAttributes?: Maybe<Array<Maybe<MdAttributeFragment>>>, innerBoxAttributes?: Maybe<Array<Maybe<MdAttributeFragment>>>, outerBoxAttributes?: Maybe<Array<Maybe<MdAttributeFragment>>> }
);

export type MdoBoxingSheetEntryFragment = (
  Pick<MdoBoxingSheetEntry, 'id' | 'ver' | 'uid' | 'state' | 'note' | 'shippingGroup' | 'sortKey' | 'summary' | 'boxingType' | 'free' | 'customGroup' | 'boxQty' | 'boxNo' | 'extraBoxNo' | 'boxGrossWeight' | 'boxNetWeight' | 'innerBoxLoads' | 'innerBoxSize' | 'outerBoxLoads' | 'outerBoxSize' | 'outerBoxVolume'>
  & { sheet?: Maybe<Pick<MdoBoxingSheet, 'code' | 'sheetId'>>, endProducts?: Maybe<Array<Maybe<MdoBoxingEndProductFragment>>>, packingOption?: Maybe<Pick<MdoPackingOption, 'id' | 'name' | 'note' | 'summary' | 'outerBoxQty'>>, outerMaterial?: Maybe<MdDictionaryEntryFragment>, innerMaterial?: Maybe<MdDictionaryEntryFragment>, markPrintTemplate?: Maybe<MdoMarkPrintTemplateFragment> }
);

export type MdoBoxingEndProductFragment = (
  Pick<MdoBoxingEndProduct, 'splitKey' | 'splitNote' | 'splitQty' | 'boxingQty' | 'residualQty' | 'mixinGroup' | 'mixinRate'>
  & { endProduct?: Maybe<(
    Pick<MdoEndProduct, 'id' | 'productModel' | 'seq' | 'sortKey' | 'mdDataCode' | 'mdDataName' | 'mdDataSpec' | 'mixinGroup' | 'mixinRate' | 'orderQty'>
    & { category: Pick<MdCategoryInfo, 'key' | 'keyValue'> }
  )> }
);

export type MdoMarkPrintTemplateFragment = Pick<MdoMarkPrintTemplate, 'id' | 'ver' | 'name' | 'files' | 'mainPicture' | 'note'>;

export type MdoDeliverySheetEntryFragment = (
  Pick<MdoDeliverySheetEntry, 'id' | 'ver' | 'uid' | 'sheetCode' | 'productOrderCode' | 'customerPo' | 'destination' | 'boxQty' | 'packingSummary' | 'source' | 'boxingNote' | 'boxGrossWeight' | 'boxNetWeight' | 'outerBoxVolume' | 'outerBoxSize' | 'innerBoxSize' | 'note' | 'seq' | 'deliveryBoxQty'>
  & { boxingSheetEntry?: Maybe<(
    Pick<MdoBoxingSheetEntry, 'id' | 'boxingType' | 'deliveredBoxQty'>
    & { sheet?: Maybe<Pick<MdoBoxingSheet, 'totalBoxQty'>> }
  )>, principals?: Maybe<Array<Maybe<(
    Pick<User, 'displayName' | 'id'>
    & { account?: Maybe<Pick<Account, 'identity' | 'username'>> }
  )>>> }
);

export type MdoDeliveryEpFragment = (
  Pick<MdoDeliveryEp, 'epId' | 'epSortKey' | 'categoryKey' | 'categoryKeyValue' | 'productModel' | 'boxingQty' | 'customerPo' | 'destination' | 'mdDataCode' | 'mdDataName' | 'mdDataSpec' | 'deliveryQty' | 'unitPrice' | 'exchangeRate' | 'productOrderCode'>
  & { currency?: Maybe<Pick<MdeCurrency, 'code' | 'symbol' | 'name'>> }
);

export type MdpStructureFragment = (
  Pick<MdpStructure, 'id' | 'ver' | 'name' | 'note' | 'uid' | 'pictures' | 'mainPicture' | 'mdDataCount' | 'structureType' | 'netWeight' | 'grossWeight' | 'allowPrintProcess'>
  & { category: Pick<MdCategoryInfo, 'key' | 'dataId' | 'keyValue'>, attributes?: Maybe<Array<MdAttributeFragment>>, optionalFields?: Maybe<Array<Maybe<MdFieldFragment>>>, printManualTemplate?: Maybe<MdpWorkManualTemplateFragment>, wrapperBomEntries?: Maybe<Array<(
    Pick<MdpWrapperBomEntry, 'uid' | 'id' | 'ver' | 'note'>
    & { category: Pick<MdCategoryInfo, 'key' | 'dataId' | 'keyValue'>, packingFieldOptions?: Maybe<Array<Maybe<MdFieldFragment>>> }
  )>> }
);

export type MdpBomEntriesFragment = (
  Pick<MdpBomEntry, 'id' | 'ver' | 'uid' | 'countBase' | 'count' | 'countType' | 'seq' | 'optional' | 'weight' | 'note'>
  & { category: Pick<MdCategoryInfo, 'key' | 'dataId' | 'keyValue'>, unit: Pick<MdUnit, 'code'>, optionalFields?: Maybe<Array<Maybe<MdFieldFragment>>> }
);

export type MdpGalleryCatalogConfigFragment = (
  Pick<MdpGalleryCatalogConfig, 'ver' | 'styleMode'>
  & { modelField?: Maybe<MdFieldFragment>, styleField?: Maybe<MdFieldFragment>, sizeField?: Maybe<MdFieldFragment>, specialFields?: Maybe<Array<Maybe<MdFieldFragment>>>, featureFields?: Maybe<Array<Maybe<MdFieldWithDictionaryFragment>>>, otherFields?: Maybe<Array<Maybe<MdFieldFragment>>>, mdCategories?: Maybe<Array<Maybe<Pick<MdCategoryInfo, 'dataId' | 'key' | 'keyValue'>>>> }
);

export type MdpFinModelFragment = (
  Pick<MdpFinModel, 'id' | 'ver' | 'uid' | 'code' | 'name' | 'description' | 'onShelf'>
  & { featureFields?: Maybe<Array<Maybe<MdFieldFragment>>>, specialAttributes?: Maybe<Array<MdAttributeFragment>>, otherAttributes?: Maybe<Array<MdAttributeFragment>>, sizes?: Maybe<Array<Maybe<MdpFinSizeFragment>>> }
);

export type MdpGoggleModelFragment = (
  Pick<MdpGoggleModel, 'id' | 'ver' | 'uid' | 'code' | 'name' | 'description' | 'onShelf'>
  & { featureFields?: Maybe<Array<Maybe<MdFieldFragment>>>, specialAttributes?: Maybe<Array<MdAttributeFragment>>, otherAttributes?: Maybe<Array<MdAttributeFragment>> }
);

export type MdpFinSizeFragment = (
  Pick<MdpFinSize, 'id' | 'ver' | 'uid' | 'size' | 'dictKey' | 'dictValue' | 'sizeLeagues' | 'measures' | 'weights' | 'extra' | 'seq' | 'onShelf'>
  & { applicable?: Maybe<MdDictionaryEntryFragment> }
);

export type MdpGalleryGalleryFragment = (
  Pick<MdpGalleryCatalog, 'code' | 'name' | 'modelCount' | 'modelClassName'>
  & { config?: Maybe<{ modelField?: Maybe<MdFieldFragment>, sizeField?: Maybe<MdFieldFragment>, styleField?: Maybe<MdFieldFragment>, featureFields?: Maybe<Array<Maybe<MdFieldFragment>>>, otherFields?: Maybe<Array<Maybe<MdFieldFragment>>> }> }
);

export type SimpleMdpGalleryModelFragment = (
  Pick<MdpGalleryModel, 'code' | 'name' | 'description' | 'styleMode'>
  & { styles?: Maybe<Array<Maybe<(
    Pick<MdpGalleryStyle, 'name' | 'pictures' | 'mainPicture'>
    & { mdpStructure?: Maybe<Pick<MdpStructure, 'id'>> }
  )>>>, specialAttributes?: Maybe<Array<Maybe<MdAttributeFragment>>>, config?: Maybe<Pick<MdpGalleryModelConfig, 'listItemPromotion1' | 'listItemPromotion2' | 'maxPrice' | 'minPrice'>> }
);

export type MdpGalleryModelConfigFragment = Pick<MdpGalleryModelConfig, 'sizeAccessories' | 'sortWeight1' | 'sortWeight2' | 'sortWeight3' | 'listItemPromotion1' | 'listItemPromotion2' | 'detailMarkdown' | 'sketchJson' | 'minPrice' | 'maxPrice' | 'ver'>;

export type MdpWorkManualTemplateFragment = (
  Pick<MdpWorkManualTemplate, 'id' | 'ver' | 'name' | 'content'>
  & { templateFields?: Maybe<Array<Maybe<MdFieldFragment>>> }
);

export type KtiTalentFragment = (
  Pick<KtiTalent, 'id' | 'ver' | 'name' | 'idCard' | 'employeeNO' | 'gender' | 'birthDay' | 'state' | 'qualification' | 'college' | 'nativePlace' | 'mobile' | 'email' | 'employAt' | 'leaveAt' | 'bankAccount' | 'socialSecurityNO' | 'address' | 'supervisor' | 'topic'>
  & { education?: Maybe<Array<Maybe<Pick<KtiEducation, 'school' | 'startDate' | 'endDate' | 'description'>>>>, workExp?: Maybe<Array<Maybe<Pick<KtiWorkExp, 'company' | 'startDate' | 'endDate' | 'description'>>>>, laborContracts?: Maybe<Array<Maybe<Pick<KtiLaborContract, 'startDate' | 'endDate' | 'attachments' | 'note'>>>>, serviceContracts?: Maybe<Array<Maybe<Pick<KtiServiceContract, 'id' | 'ver' | 'startDate' | 'endDate' | 'salary' | 'attachments' | 'activated' | 'note' | 'serveIn' | 'topic'>>>> }
);

export const PermissionFragmentDoc = gql`
    fragment permission on Permission {
  code
  name
  securityLevel
  realm {
    name
  }
  basePermissions {
    code
  }
  opsPermissions {
    code
  }
}
    `
export const BrCatalogFragmentDoc = gql`
    fragment brCatalog on BrCatalog {
  id
  ver
  name
  seq
  entities
  updatedAt
  entityCount
  path
  parent {
    id
    name
  }
}
    `
export const BrCatalogTreeFragmentDoc = gql`
    fragment brCatalogTree on BrCatalog {
  ...brCatalog
  children {
    ...brCatalog
    children {
      ...brCatalog
      children {
        ...brCatalog
        children {
          ...brCatalog
          children {
            ...brCatalog
            children {
              ...brCatalog
              children {
                ...brCatalog
                children {
                  ...brCatalog
                  children {
                    ...brCatalog
                    children {
                      ...brCatalog
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${BrCatalogFragmentDoc}`
export const ExportTemplateFragmentDoc = gql`
    fragment exportTemplate on SysExportTemplate {
  id
  ver
  name
  modelName
  templateFileKey
  maxRow
  rowsPerPage
  printOrientation
  note
  deleted
  forPdfExport
  forXlsExport
  forPrint
  fileNameScript
  groupByScript
  printPaperHeight
  printPaperWidth
}
    `
export const MdDataFragmentDoc = gql`
    fragment mdData on MdData {
  id
  uid
  ver
  code
  field0
  field1
  field2
  field3
  field4
  field5
  field6
  field7
  field8
  field9
  structureKey
  mainPicture
  pictures
}
    `
export const MdAttributeFragmentDoc = gql`
    fragment mdAttribute on MdAttribute {
  field {
    id
    name
    dataType
    option
    builtin
  }
  alias
  bomFeature
  extra
  feature
  hidden
  tbd
  value
}
    `
export const MdDataBomEntryFragmentDoc = gql`
    fragment mdDataBomEntry on MdDataBomEntry {
  id
  uid
  bomTemplateEntryKey
  entryData {
    ...mdData
    attributes(mdFieldIds: []) {
      ...mdAttribute
    }
    bomEntryCount
    referredByBomCount
  }
  count
  countBase
  unit
  seq
  extra
}
    ${MdDataFragmentDoc}
${MdAttributeFragmentDoc}`
export const MdDataSavingResultFragmentDoc = gql`
    fragment mdDataSavingResult on MdDataSavingResult {
  id
  uid
  ver
  code
  field0
  field1
  field2
  field3
  field4
  field5
  field6
  field7
  field8
  field9
  structureKey
}
    `
export const MdDataWithAttrsFragmentDoc = gql`
    fragment mdDataWithAttrs on MdData {
  ...mdData
  attributes(mdFieldIds: []) {
    ...mdAttribute
  }
}
    ${MdDataFragmentDoc}
${MdAttributeFragmentDoc}`
export const SimpleMdDiagnosisFragmentDoc = gql`
    fragment simpleMdDiagnosis on MdDiagnosis {
  dataId
  diagState
}
    `
export const MdTableColumnFragmentDoc = gql`
    fragment mdTableColumn on MdTableColumn {
  id
  name
  colName
  seq
  option
  uniqueKey
  readonly
  note
  dataType
}
    `
export const MdTableFragmentDoc = gql`
    fragment mdTable on MdTable {
  code
  ver
  name
  isPrivate
  catalog {
    name
  }
  ownerIds
  cooperatorIds
  readerIds
  note
  tags
  hasJob
  columns {
    ...mdTableColumn
  }
  importContext {
    currentStep
    importMode
  }
  importFromSql
}
    ${MdTableColumnFragmentDoc}`
export const MdTableImportContextFragmentDoc = gql`
    fragment mdTableImportContext on MdTableImportContext {
  table {
    ...mdTable
    importFromSql
    externalDataSourceId
  }
  importColumns
  mappings {
    alignKey
    ignoreChange
    importingColumn
    mdTableColumn {
      ...mdTableColumn
    }
  }
  importMode
  options
  rawData(rows: 3)
}
    ${MdTableFragmentDoc}
${MdTableColumnFragmentDoc}`
export const ErrorInfoFragmentDoc = gql`
    fragment errorInfo on ErrorInfo {
  ec
  elv
  extra
  msg
}
    `
export const MdTableDataFragmentDoc = gql`
    fragment mdTableData on MdTableData {
  id
  uid
  createdAt
  createdBy
  updatedAt
  updatedBy
  ver
  values
}
    `
export const MdTableImportDataFragmentDoc = gql`
    fragment mdTableImportData on MdTableImportData {
  importDataId
  importValues
  deltaState
  merging
  errors {
    ...errorInfo
  }
  importDataPreview {
    ...mdTableData
  }
  tableData {
    ...mdTableData
  }
}
    ${ErrorInfoFragmentDoc}
${MdTableDataFragmentDoc}`
export const MdTableImportMappingPresetFragmentDoc = gql`
    fragment mdTableImportMappingPreset on MdTableImportMappingPreset {
  id
  ver
  name
  mappings {
    alignKey
    ignoreChange
    importingColumn
    mdTableColName
  }
  options
}
    `
export const MdFileFragmentDoc = gql`
    fragment mdFile on MdFile {
  fileName
  id
  ver
  isDirectory
  fileKey
  createdAt
  shared
  leaf
  revisions
  path
  pathIds
  updatedAt
  updatedBy
}
    `
export const MdFileRootDirFragmentDoc = gql`
    fragment mdFileRootDir on MdFileRootDir {
  id
  shareToUserId
  ownerId
  shareToEveryone
  writable
  deletable
  directory {
    ...mdFile
  }
  owner
  shareToUser
  createdAt
}
    ${MdFileFragmentDoc}`
export const SimpleAttributeFragmentDoc = gql`
    fragment simpleAttribute on MdAttribute {
  field @simpleMdAttribute {
    id
    name
    dataType
  }
  value
}
    `
export const MdStructureLevelFragmentDoc = gql`
    fragment mdStructureLevel on MdStructureLevel {
  key
  label
  groupKey
  extra
  attributes {
    ...simpleAttribute
  }
  structureCount
  illegalStructureCount
}
    ${SimpleAttributeFragmentDoc}`
export const MdStructureLevelTreeFragmentDoc = gql`
    fragment mdStructureLevelTree on MdStructureLevel {
  ...mdStructureLevel
  children {
    ...mdStructureLevel
    children {
      ...mdStructureLevel
      children {
        ...mdStructureLevel
        children {
          ...mdStructureLevel
          children {
            ...mdStructureLevel
            children {
              ...mdStructureLevel
              children {
                ...mdStructureLevel
                children {
                  ...mdStructureLevel
                  children {
                    ...mdStructureLevel
                    children {
                      ...mdStructureLevel
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${MdStructureLevelFragmentDoc}`
export const UserFragmentDoc = gql`
    fragment user on User {
  id
  displayName
  employeeNo
}
    `
export const MdInvoiceFragmentDoc = gql`
    fragment mdInvoice on MdInvoice {
  id
  ver
  uid
  type
  typeDesc
  state
  amount
  submittedBy {
    ...user
  }
  submittedAt
  note
  extraFiles
  buyer
  buyerTaxId
  buyerAddress
  buyerPhone
  buyerBank
  buyerBankAccount
  buyerEmail
  summary
  code
  issuedAt
  taxAmount
  seller
  sellerTaxId
  data
  details
  files
}
    ${UserFragmentDoc}`
export const MdTallyFragmentDoc = gql`
    fragment mdTally on MdTally {
  id
  ver
  code
  accountType
  qty
  appliedAt
  operatedAt
  note
}
    `
export const MdFieldFragmentDoc = gql`
    fragment mdField on MdField {
  id
  ver
  name
  note
  tags
  dataType
  option
}
    `
export const MdFieldWithPayloadFragmentDoc = gql`
    fragment mdFieldWithPayload on MdField {
  ...mdField
  payload {
    alias
    context
    extra
    indent
    templateBranch
  }
}
    ${MdFieldFragmentDoc}`
export const MdFieldTemplateEntryFragmentDoc = gql`
    fragment mdFieldTemplateEntry on MdFieldTemplateEntry {
  id
  ver
  uid
  name
  alias
  option
  builtin
  forceDefaultValue
  seq
  mdField {
    ...mdFieldWithPayload
  }
  template {
    id
    name
  }
  branch {
    id
  }
}
    ${MdFieldWithPayloadFragmentDoc}`
export const MdFieldTemplateBranchFragmentDoc = gql`
    fragment mdFieldTemplateBranch on MdFieldTemplateBranch {
  id
  ver
  uid
  name
  defaultBranch
  seq
  branchFilter
  entry {
    id
  }
  entry {
    id
  }
}
    `
export const MdFieldTemplateEntryTreeFragmentDoc = gql`
    fragment mdFieldTemplateEntryTree on MdFieldTemplateEntry {
  ...mdFieldTemplateEntry
  children {
    ...mdFieldTemplateBranch
    children {
      ...mdFieldTemplateEntry
      children {
        ...mdFieldTemplateBranch
        children {
          ...mdFieldTemplateEntry
          children {
            ...mdFieldTemplateBranch
            children {
              ...mdFieldTemplateEntry
              children {
                ...mdFieldTemplateBranch
                children {
                  ...mdFieldTemplateEntry
                  children {
                    ...mdFieldTemplateBranch
                    children {
                      ...mdFieldTemplateEntry
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${MdFieldTemplateEntryFragmentDoc}
${MdFieldTemplateBranchFragmentDoc}`
export const MdaBomEntriesFragmentDoc = gql`
    fragment mdaBomEntries on MdaBomEntry {
  id
  ver
  uid
  countBase
  count
  seq
  optional
  weight
  note
  category {
    key
    dataId
    keyValue
  }
  unit {
    code
  }
  attributes {
    ...mdAttribute
  }
}
    ${MdAttributeFragmentDoc}`
export const MdoEndProductInfoTemplateFragmentDoc = gql`
    fragment mdoEndProductInfoTemplate on MdoEndProductInfoTemplate {
  id
  ver
  name
  type
  attributes {
    ...mdAttribute
  }
  mdFields {
    ...mdFieldWithPayload
  }
  configs {
    fieldId
    seq
    summary
    diff
    editWithProductOrder
    showInTable
    dontCopy
    showInBoxingSheetEntryTable
  }
  defaultAdded
  craft
  multiple
  mdDataGeneratingRule
}
    ${MdAttributeFragmentDoc}
${MdFieldWithPayloadFragmentDoc}`
export const MdoMainProductFragmentDoc = gql`
    fragment mdoMainProduct on MdoMainProduct {
  id
  ver
  uid
  productModel
  category {
    dataId
    key
    keyValue
  }
  mdData {
    ...mdData
  }
  count
  unit
  engineeringState
  note
}
    ${MdDataFragmentDoc}`
export const MdoEndProductInfoFragmentDoc = gql`
    fragment mdoEndProductInfo on MdoEndProductInfo {
  id
  ver
  uid
  name
  type
  craft
  packingRef
  mdDataGeneratingRule
  engineeringState
  note
  attributes {
    ...mdAttribute
  }
  carrierPath
  carrierInfoKey
  carrierMdData {
    ...mdData
  }
  generatedMdData {
    ...mdData
  }
}
    ${MdAttributeFragmentDoc}
${MdDataFragmentDoc}`
export const MdoEndProductFragmentDoc = gql`
    fragment mdoEndProduct on MdoEndProduct {
  id
  ver
  productOrderCode
  productModel
  seq
  sortKey
  principals
  category {
    dataId
    key
    keyValue
  }
  customer {
    code
    name
    fullName
  }
  mainProducts {
    ...mdoMainProduct
  }
  infos {
    ...mdoEndProductInfo
  }
  mdData {
    ...mdData
  }
  mdDataCode
  mdDataName
  mdDataSpec
  state
  orderQty
  mixinRate
  mixinGroup
  receivableUnitPrice
  currency
}
    ${MdoMainProductFragmentDoc}
${MdoEndProductInfoFragmentDoc}
${MdDataFragmentDoc}`
export const MdoPackingOptionFragmentDoc = gql`
    fragment mdoPackingOption on MdoPackingOption {
  id
  ver
  name
  note
  customer {
    code
    name
  }
  horizontalSeparator
  packingAttributes {
    ...mdAttribute
  }
  productAttributes {
    ...mdAttribute
  }
  needInnerBox
  innerBoxQty
  innerBoxSize
  innerBoxAttributes {
    ...mdAttribute
  }
  outerBoxQty: outerBoxSize
  outerBoxAttributes {
    ...mdAttribute
  }
}
    ${MdAttributeFragmentDoc}`
export const MdoBoxingEndProductFragmentDoc = gql`
    fragment mdoBoxingEndProduct on MdoBoxingEndProduct {
  endProduct {
    id
    category {
      key
      keyValue
    }
    productModel
    seq
    sortKey
    mdDataCode
    mdDataName
    mdDataSpec
    mixinGroup
    mixinRate
    orderQty
  }
  splitKey
  splitNote
  splitQty
  boxingQty
  residualQty
  mixinGroup
  mixinRate
}
    `
export const MdDictionaryEntryFragmentDoc = gql`
    fragment mdDictionaryEntry on MdDictionaryEntry {
  id
  ver
  key
  value
  valueForShort
  seq
  note
  groupPath
  dictionary {
    id
    name
  }
}
    `
export const MdoMarkPrintTemplateFragmentDoc = gql`
    fragment mdoMarkPrintTemplate on MdoMarkPrintTemplate {
  id
  ver
  name
  files
  mainPicture
  note
}
    `
export const MdoBoxingSheetEntryFragmentDoc = gql`
    fragment mdoBoxingSheetEntry on MdoBoxingSheetEntry {
  id
  ver
  uid
  sheet {
    code
    sheetId
  }
  state
  note
  shippingGroup
  sortKey
  summary
  boxingType
  free
  customGroup
  endProducts {
    ...mdoBoxingEndProduct
  }
  packingOption {
    id
    name
    note
    summary
    outerBoxQty
  }
  boxQty
  boxNo
  extraBoxNo
  boxGrossWeight
  boxNetWeight
  innerBoxLoads
  innerBoxSize
  outerBoxLoads
  outerBoxSize
  outerBoxVolume
  outerMaterial {
    ...mdDictionaryEntry
  }
  innerMaterial {
    ...mdDictionaryEntry
  }
  markPrintTemplate {
    ...mdoMarkPrintTemplate
  }
}
    ${MdoBoxingEndProductFragmentDoc}
${MdDictionaryEntryFragmentDoc}
${MdoMarkPrintTemplateFragmentDoc}`
export const MdoDeliverySheetEntryFragmentDoc = gql`
    fragment mdoDeliverySheetEntry on MdoDeliverySheetEntry {
  id
  ver
  uid
  sheetCode
  productOrderCode
  customerPo
  destination
  boxQty
  packingSummary
  source
  boxingNote
  boxGrossWeight
  boxNetWeight
  outerBoxVolume
  outerBoxSize
  innerBoxSize
  note
  seq
  deliveryBoxQty
  boxingSheetEntry {
    id
    boxingType
    deliveredBoxQty
    sheet {
      totalBoxQty
    }
  }
  principals {
    displayName
    id
    account {
      identity
      username
    }
  }
}
    `
export const MdoDeliveryEpFragmentDoc = gql`
    fragment mdoDeliveryEp on MdoDeliveryEp {
  epId
  epSortKey
  categoryKey
  categoryKeyValue
  productModel
  boxingQty
  customerPo
  destination
  mdDataCode
  mdDataName
  mdDataSpec
  deliveryQty
  unitPrice
  exchangeRate
  productOrderCode
  currency {
    code
    symbol
    name
  }
}
    `
export const MdpWorkManualTemplateFragmentDoc = gql`
    fragment mdpWorkManualTemplate on MdpWorkManualTemplate {
  id
  ver
  name
  content
  templateFields {
    ...mdField
  }
}
    ${MdFieldFragmentDoc}`
export const MdpStructureFragmentDoc = gql`
    fragment mdpStructure on MdpStructure {
  id
  ver
  name
  note
  category {
    key
    dataId
    keyValue
  }
  uid
  pictures
  mainPicture
  mdDataCount
  structureType
  netWeight
  grossWeight
  attributes {
    ...mdAttribute
  }
  optionalFields {
    ...mdField
  }
  allowPrintProcess
  printManualTemplate {
    ...mdpWorkManualTemplate
  }
  wrapperBomEntries {
    uid
    id
    ver
    category {
      key
      dataId
      keyValue
    }
    packingFieldOptions {
      ...mdField
    }
    note
  }
}
    ${MdAttributeFragmentDoc}
${MdFieldFragmentDoc}
${MdpWorkManualTemplateFragmentDoc}`
export const MdpBomEntriesFragmentDoc = gql`
    fragment mdpBomEntries on MdpBomEntry {
  id
  ver
  uid
  countBase
  count
  countType
  seq
  optional
  weight
  note
  category {
    key
    dataId
    keyValue
  }
  unit {
    code
  }
  optionalFields {
    ...mdField
  }
}
    ${MdFieldFragmentDoc}`
export const MdFieldWithDictionaryFragmentDoc = gql`
    fragment mdFieldWithDictionary on MdField {
  ...mdField
  dictionary {
    entries {
      id
      key
      value
      note
    }
  }
}
    ${MdFieldFragmentDoc}`
export const MdpGalleryCatalogConfigFragmentDoc = gql`
    fragment mdpGalleryCatalogConfig on MdpGalleryCatalogConfig {
  ver
  styleMode
  modelField {
    ...mdField
  }
  styleField {
    ...mdField
  }
  sizeField {
    ...mdField
  }
  specialFields {
    ...mdField
  }
  featureFields {
    ...mdFieldWithDictionary
  }
  otherFields {
    ...mdField
  }
  mdCategories {
    dataId
    key
    keyValue
  }
}
    ${MdFieldFragmentDoc}
${MdFieldWithDictionaryFragmentDoc}`
export const MdpFinSizeFragmentDoc = gql`
    fragment mdpFinSize on MdpFinSize {
  id
  ver
  uid
  applicable {
    ...mdDictionaryEntry
  }
  size
  dictKey
  dictValue
  sizeLeagues
  measures
  weights
  extra
  seq
  onShelf
}
    ${MdDictionaryEntryFragmentDoc}`
export const MdpFinModelFragmentDoc = gql`
    fragment mdpFinModel on MdpFinModel {
  id
  ver
  uid
  code
  name
  description
  onShelf
  featureFields {
    ...mdField
  }
  specialAttributes {
    ...mdAttribute
  }
  otherAttributes {
    ...mdAttribute
  }
  sizes {
    ...mdpFinSize
  }
}
    ${MdFieldFragmentDoc}
${MdAttributeFragmentDoc}
${MdpFinSizeFragmentDoc}`
export const MdpGoggleModelFragmentDoc = gql`
    fragment mdpGoggleModel on MdpGoggleModel {
  id
  ver
  uid
  code
  name
  description
  onShelf
  featureFields {
    ...mdField
  }
  specialAttributes {
    ...mdAttribute
  }
  otherAttributes {
    ...mdAttribute
  }
}
    ${MdFieldFragmentDoc}
${MdAttributeFragmentDoc}`
export const MdpGalleryGalleryFragmentDoc = gql`
    fragment mdpGalleryGallery on MdpGalleryCatalog {
  code
  name
  modelCount
  modelClassName
  config {
    modelField {
      ...mdField
    }
    sizeField {
      ...mdField
    }
    styleField {
      ...mdField
    }
    featureFields {
      ...mdField
    }
    otherFields {
      ...mdField
    }
  }
}
    ${MdFieldFragmentDoc}`
export const SimpleMdpGalleryModelFragmentDoc = gql`
    fragment simpleMdpGalleryModel on MdpGalleryModel {
  code
  name
  description
  styles {
    name
    pictures
    mainPicture
    mdpStructure {
      id
    }
  }
  specialAttributes {
    ...mdAttribute
  }
  config {
    listItemPromotion1
    listItemPromotion2
    maxPrice
    minPrice
  }
  styleMode
}
    ${MdAttributeFragmentDoc}`
export const MdpGalleryModelConfigFragmentDoc = gql`
    fragment mdpGalleryModelConfig on MdpGalleryModelConfig {
  sizeAccessories
  sortWeight1
  sortWeight2
  sortWeight3
  listItemPromotion1
  listItemPromotion2
  detailMarkdown
  sketchJson
  minPrice
  maxPrice
  ver
}
    `
export const KtiTalentFragmentDoc = gql`
    fragment ktiTalent on KtiTalent {
  id
  ver
  name
  idCard
  employeeNO
  gender
  birthDay
  state
  qualification
  college
  nativePlace
  mobile
  email
  employAt
  leaveAt
  bankAccount
  socialSecurityNO
  address
  supervisor
  topic
  education {
    school
    startDate
    endDate
    description
  }
  workExp {
    company
    startDate
    endDate
    description
  }
  laborContracts {
    startDate
    endDate
    attachments
    note
  }
  serviceContracts {
    id
    ver
    startDate
    endDate
    salary
    attachments
    activated
    note
    serveIn
    topic
  }
}
    `
export const ListMdFieldsDocument = gql`
    query listMdFields($rowRequest: RowRequest!) {
  MdFields(rowRequest: $rowRequest) {
    rows {
      ...mdField
    }
    total
  }
}
    ${MdFieldFragmentDoc}`
export const CountMyQueryDocument = gql`
    query countMyQuery($rowRequest: RowRequest!) {
  MdDatas(rowRequest: $rowRequest) {
    total
  }
}
    `
export const GetDictEntriesDocument = gql`
    query getDictEntries($dictName: String) {
  MdDictionary(name: $dictName) {
    entries {
      ...mdDictionaryEntry
    }
  }
}
    ${MdDictionaryEntryFragmentDoc}`
export const GetOneDictEntryDocument = gql`
    query getOneDictEntry($id: ID!) {
  MdDictionaryEntry(id: $id) {
    id
    key
    value
  }
}
    `
export const GetOneMdFieldDocument = gql`
    query getOneMdField($id: ID, $name: String) {
  MdField(id: $id, name: $name) {
    id
    name
    note
    option
  }
}
    `
export const SaveDictValueDocument = gql`
    mutation saveDictValue($dictionaryId: ID!, $dictEntryId: ID!, $ver: Int!, $dictValue: String!) {
  saveMdDictionaryEntries(dictionaryId: $dictionaryId, values: [{id: $dictEntryId, ver: $ver, value: $dictValue}]) {
    id
    key
    value
  }
}
    `
