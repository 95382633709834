
































































































































































































export default function (Component) {
  Component.options.__source = "src/module/components/lolth-explorer/cmp-explorer-template-form-dialog.vue"
}
