export default [
  {
    path: '/met',
    name: 'PageMetMeeting',
    meta: {
      title: '展会管理',
      auth: true,
      modelName: 'MetMeeting'
    },
    component: () => import('./views/page-met-meeting.vue')
  },
  {
    path: '/met/agent',
    name: 'PageMetAgent',
    meta: {
      title: '销售代理管理',
      auth: true,
      modelName: 'MetAgent'
    },
    component: () => import('./views/page-met-agent.vue')
  },
  {
    path: '/met/customer',
    name: 'PageMetCustomer',
    meta: {
      title: '会员客户',
      auth: true,
      modelName: 'MetCustomer'
    },
    component: () => import('./views/page-met-customer.vue')
  },
  {
    path: '/met/import',
    name: 'PageMetCustomerImport',
    meta: {
      title: '客户资料导入',
      auth: true
    },
    component: () => import('./views/page-met-customer-import.vue')
  },
  {
    path: '/met/membership-flow',
    name: 'PageMetMembershipFlow',
    meta: {
      title: '入会管理',
      auth: true
    },
    component: () => import('./views/page-met-membership-flow.vue')
  },
  {
    path: '/met/import-receive-record',
    name: 'PageMetReceiveRecordImport',
    meta: {
      title: '收款导入',
      auth: true
    },
    component: () => import('./views/page-met-receive-record-import.vue')
  }
]
