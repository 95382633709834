import util from '@/d2admin/libs/util'

export default {
  data() {
    return {
      ctrlHold: false
    }
  },
  created() {
    window.addEventListener('keyup', e => {
      this.ctrlHold = e.ctrlKey || e.metaKey
    })
    window.addEventListener('keydown', e => {
      this.ctrlHold = e.ctrlKey || e.metaKey
    })
  },
  methods: {
    handleMenuSelect(item) {
      const path = item.path ? item.path : item
      if (this.ctrlHold || /^https:\/\/|http:\/\//.test(path)) {
        util.views.openNewPage(path)
      } else if (/^d2-menu-empty-\d+$/.test(path) || path === undefined) {
        this.$message.warning('临时菜单')
      } else {
        let location = {
          path: path
        }
        location.query = {}
        if (item.dataIds && item.dataIds.filter(id => id.indexOf('d2-menu-empty') !== -1).length === 0) {
          location.query.dataIds = item.dataIds
        }
        if (item.mdTable) {
          location.query.t = item.mdTable.code
        }
        util.views.openTab(this, location)
      }
    }
  }
}
