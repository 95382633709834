


























import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator'
import { FilterCondition, SortColumn } from '@/module/graphql'
import { Message } from 'element-ui'
import { SelectionMode } from '@/module/common/types'
import MixinMdcAdvSearch from '@/module/plugin-md-category/views/components/mixin-mdc-adv-search'
import MixinExplorerMdAttributeExtend
  from '@/module/master-data/views/components/explorer-support/mixin-explorer-md-attribute-extend'

@Component
export default class CmpMdDataChooser extends Mixins(MixinMdcAdvSearch, MixinExplorerMdAttributeExtend) {
  @Prop({ default: 'single' }) readonly selectionMode!: SelectionMode
  @Prop() readonly fixedFilterCondition?: FilterCondition
  @Prop() readonly defaultFilterConditions?: FilterCondition[]
  @Prop() readonly defaultSort?: SortColumn[]
  @Prop() readonly explorerViewSuffix?: string

  @PropSync('visible')
  syncedVisible!: boolean

  private mSelectedData: any[] = []

  private confirm() {
    if (this.selectionMode !== SelectionMode.None && this.mSelectedData.length <= 0) {
      Message({
        message: '至少选择一项',
        type: 'error',
        showClose: true
      })
      return
    }
    this.$emit('select', this.mSelectedData)
  }
}
